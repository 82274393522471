import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchFilesProperties } from "../../api/Files";
import { sendToBackend } from "../../api";
import TransacAddTenant from "../../components/Transaction/TransacAddTenant";
import { fetchAllFinanceConfigsForTenant } from "../../api/FinanceConfig";
import { useProfilesIndiv } from "../../hooks/use-profiles-indiv";
import { deleteProfileIndiv, updateProfileIndiv } from "../../api/Profiles";
import { useModal } from "../../hooks/use-modal";
import useAuth from "../../hooks/use-auth";
import useTenants from "../../hooks/use-tenants";
import TenantShow from "../../components/Tenant/TenantShow";
import useTransactions from "../../hooks/use-transactions";
import { useTenantBalances } from "../../hooks/use-balances";
import { ProfileStatus } from "../../models/profile";
import { TenantStatus } from "../../models/tenant";
import { BalanceDomain } from "../../utils/constants/balances";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";

export default function ShowPage() {
  const { properties } = useOutletContext();
  const { updateTenant, deleteTenant, tenants } = useTenants();
  const nav = useNavigate();
  const axios = useAuthenticatedAxios();

  const { tenantId } = useParams();

  const tenant = tenants?.find((tenant) => tenant.id === parseInt(tenantId));
  const property = tenant
    ? properties.find((property) => property.id === parseInt(tenant.id_bien))
    : "";

  const { auth } = useAuth();

  const [files, setFiles] = useState([]);
  const [financeConfigs, setFinanceConfigs] = useState([]);
  const [TransacSyncModal, closeTransacSyncModal, openTransacSyncModal] =
    useModal();
  const { profiles, structProfiles, addOrUpdateProfile, deleteProfile } =
    useProfilesIndiv(tenant ? tenant.profile_nom.profiles : []);

  useEffect(() => {
    (async () => {
      setFiles(
        await fetchFilesProperties(auth.accessToken, null, null, tenantId),
      );
      setFinanceConfigs(
        await fetchAllFinanceConfigsForTenant(auth.accessToken, tenantId),
      );
    })();
  }, [auth.accessToken, tenantId]);

  const { balances, refreshBalances } = useTenantBalances(tenantId, false);
  const {
    transactions: unlinkedTransactions,
    refreshTransactions,
    assignMultipleTransactions,
  } = useTransactions({
    path: "unlinked",
    params: { id_tenant: parseInt(tenantId) },
  });

  function syncUnlinkedTransactions() {
    return refreshTransactions()
      .then((unlinkedTransactions) => {
        if (unlinkedTransactions.length > 0) {
          openTransacSyncModal();
        }
      })
      .catch((e) => console.error(e));
  }

  async function deleteFileHandler(fileId) {
    try {
      await sendToBackend(`files/${fileId}`, auth.accessToken, {
        method: "DELETE",
      });
      const filter = (prevFiles) =>
        prevFiles.filter((file) => file.id !== fileId);
      setFiles(filter);
    } catch (error) {
      console.error(error);
    }
  }

  async function archiveProfileHandler(profileId) {
    const profile = await updateProfileIndiv(auth.accessToken, profileId, {
      status: ProfileStatus.Archive.status,
    });
    addOrUpdateProfile(profile);
  }
  async function activateProfileHandler(profileId) {
    const profile = await updateProfileIndiv(auth.accessToken, profileId, {
      status: ProfileStatus.Actif.status,
    });
    addOrUpdateProfile(profile);
  }

  function profileSubmittedHandler(profile) {
    addOrUpdateProfile(profile);
    syncUnlinkedTransactions();
  }

  async function deleteProfileHandler(profileId) {
    try {
      await deleteProfileIndiv(auth.accessToken, profileId);
      deleteProfile(profileId);
    } catch (e) {
      console.error(e);
    }
  }

  async function refuserCandidat() {
    try {
      await updateTenant(tenant.id, {
        status: TenantStatus.CandidatArchive.status,
      });
      nav("/bailleur/candidats");
    } catch (e) {
      console.error(e);
    }
  }

  async function activerCandidat() {
    try {
      await updateTenant(tenant.id, { status: TenantStatus.Candidat.status });
    } catch (e) {
      console.error(e);
    }
  }

  async function activerLocataire() {
    try {
      await updateTenant(tenant.id, { status: TenantStatus.Actif.status });
    } catch (e) {
      console.error(e);
    }
  }

  async function inviteProfilesHandler(profileIds, tenantId) {
    try {
      const response = await axios.post(`/tenants/${tenantId}/invite`, {
        profile_ids: profileIds,
      });
      for (let profile of response.data) {
        addOrUpdateProfile(profile);
      }
    } catch (e) {
      console.error(e);
    }
  }
  async function supprimerCandidat() {
    try {
      await deleteTenant(tenant.id);
      nav("/bailleur/candidats?tab-candidat-list=candidatsArchives");
    } catch (e) {
      console.error(e);
    }
  }

  async function transactionUpdateHandler(selectedTransactionIds) {
    const data = {
      id_tenant: parseInt(tenantId),
      domaine: BalanceDomain.TENANT,
    };

    try {
      await assignMultipleTransactions(selectedTransactionIds, data);
      refreshBalances();
      closeTransacSyncModal();
    } catch (e) {
      console.error(e);
    }
  }

  const outletContext = {
    tenant,
    balances,
    properties,
    profiles,
    financeConfigs,
    profileSubmittedHandler,
  };
  return (
    <>
      <TenantShow
        tenant={tenant}
        balances={balances}
        profiles={structProfiles}
        files={files}
        property={property}
        financeConfigs={financeConfigs}
        deleteFileHandler={deleteFileHandler}
        archiveProfileHandler={archiveProfileHandler}
        activateProfileHandler={activateProfileHandler}
        inviteProfilesHandler={inviteProfilesHandler}
        deleteGarantHandler={deleteProfileHandler}
        syncTransactionHandler={syncUnlinkedTransactions}
        activerCandidat={activerCandidat}
        refuserCandidat={refuserCandidat}
        activerLocataire={activerLocataire}
        supprimerCandidat={supprimerCandidat}
      />
      <Outlet context={outletContext} />
      <TransacSyncModal size="big">
        <TransacAddTenant
          transactions={unlinkedTransactions}
          onTransactionUpdate={transactionUpdateHandler}
        />
      </TransacSyncModal>
    </>
  );
}
