import Menu from "../../components/Menu/Menu.js";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchProfiles } from "../../api/Profiles";
import useProperties from "../../hooks/use-properties";
import { fetchDrafts } from "../../api/Draft";
import { fetchBalanceTenants } from "../../api/Balance_tenants";
import useAuth from "../../hooks/use-auth";

export const ROOT_PATH = "/bailleur/properties/";

function PropertyRootPage() {
  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [drafts, setDrafts] = useState([]);
  const { properties } = useProperties();
  const [balances, setBalances] = useState([]);

  function draftSavedHandler(savedDraft) {
    setDrafts((currentDrafts) => {
      const currentDraftIndex = currentDrafts.findIndex(
        (draft) => draft.id === savedDraft.id,
      );
      const newDrafts = [...currentDrafts];
      if (currentDraftIndex === -1) {
        newDrafts.push(savedDraft);
      } else {
        newDrafts[currentDraftIndex] = savedDraft;
      }
      return newDrafts;
    });
  }

  async function draftDeletedHandler(draftId) {
    setDrafts((currentDrafts) =>
      currentDrafts.filter((draft) => draft.id !== parseInt(draftId)),
    );
  }

  useEffect(() => {
    (async () => {
      setDrafts(await fetchDrafts(auth.accessToken, 2));
      setProfiles(await fetchProfiles(auth.accessToken));
    })();
  }, [auth.accessToken]);

  function editPropertyHandler(propertyId) {
    navigate(`${ROOT_PATH}${propertyId}/edit`);
  }

  useEffect(() => {
    (async () => {
      setProfiles(await fetchProfiles(auth.accessToken));
    })();
  }, [auth.accessToken]);

  useEffect(() => {
    (async () => {
      setBalances(await fetchBalanceTenants(auth.accessToken));
    })();
  }, [auth.accessToken]);

  return (
    <>
      <Menu isBailleur />
      <Outlet
        context={{
          editPropertyHandler: editPropertyHandler,
          properties,
          profiles: profiles,
          drafts: drafts,
          draftDeletedHandler: draftDeletedHandler,
          draftSavedHandler: draftSavedHandler,
          balances: balances,
        }}
      />
    </>
  );
}

export default PropertyRootPage;
