import useResources from "./use-resources";

const ROOT_PATH = "comptes";

export default function useComptes() {
  const {
    resources: comptes,
    error,
    isLoading,
    update,
  } = useResources(ROOT_PATH);

  return {
    comptes,
    error,
    isLoading,
    updateCompte: update,
  };
}
