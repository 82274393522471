import { Form, Formik } from "formik";
import Button from "../UI/Button";
import { useState } from "react";
import Card from "./Card";
import { handleAPIError } from "../../utils";
import FormikSubmitButton from "./FormikSubmitButton";

function PartForm({
  partForm,
  deleteHandler,
  archiveHandler,
  reactivateHandler,
  validationSchema,
  initialValues,
  submitHandler,
  closeModal,
}) {
  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  async function submit(values, { setSubmitting }) {
    try {
      await submitHandler(values);
      closeModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <Form>
            {partForm}
            <div className={"text-right"}>
              {deleteHandler && (
                <Button
                  type="button"
                  buttonStyle={"secondaryDelete"}
                  isLoading={isDeleting}
                  onClick={async () => {
                    setIsDeleting(true);
                    await deleteHandler();
                    setIsDeleting(false);
                  }}
                  className={"marginR10"}
                >
                  Supprimer
                </Button>
              )}
              {archiveHandler && (
                <Button
                  type="button"
                  buttonStyle={"secondaryYellow"}
                  onClick={archiveHandler}
                  className={"marginR10"}
                >
                  Archiver
                </Button>
              )}
              {reactivateHandler && (
                <Button
                  type="button"
                  buttonStyle={"secondaryGreen"}
                  onClick={reactivateHandler}
                  className={"marginR10"}
                >
                  Réactiver
                </Button>
              )}
              <FormikSubmitButton>Valider</FormikSubmitButton>
              {error && (
                <Card
                  type={"error"}
                  className={"padding10 text-center marginT10"}
                >
                  {error}
                </Card>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PartForm;
