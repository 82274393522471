import Tabs from "../../UI/Tabs";
import WebhookList from "./WebhookList";
import WebhookLogList from "./WebhookLogList";

export default function Webhooks() {
  return (
    <>
      <Tabs id="oodrive-webhooks">
        <Tabs.TabList>
          <Tabs.Tab panelId={"webhooks"}>Webhooks</Tabs.Tab>
          <Tabs.Tab panelId={"logs"}>Logs</Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanel id={"webhooks"}>
          <WebhookList />
        </Tabs.TabPanel>
        <Tabs.TabPanel id={"logs"}>
          <WebhookLogList />
        </Tabs.TabPanel>
      </Tabs>
    </>
  );
}
