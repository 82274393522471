import useAuthenticatedAxios from "./use-authenticated-axios";
import useSWRImmutable from "swr/immutable";

export default function useThumbnail(
  documentId,
  file,
  doctype,
  isSigned = false,
) {
  const axios = useAuthenticatedAxios();
  const {
    data: thumbnail,
    error,
    isLoading,
  } = useSWRImmutable(
    file.thumbnail || !documentId ? null : `documents/${documentId}/download`,
    (url) => {
      return axios
        .get(url, {
          responseType: "blob",
          params: { thumbnail: true, signed: isSigned, doctype },
        })
        .then((res) => res.data);
    },
    {
      shouldRetryOnError: (error) => {
        // We skip retrying if the API is returning 404
        return error.response?.status !== 404;
      },
    },
  );

  return {
    thumbnail: file.thumbnail ? null : thumbnail,
    error,
    isLoading,
  };
}
