import useResources from "./use-resources";

export default function useLeaseTemplates() {
  const {
    resources: leaseTemplates,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources("lease-templates");

  return {
    leaseTemplates,
    error,
    isLoading,
    updateLeaseTemplate: update,
    addLeaseTemplate: create,
    deleteLeaseTemplate: del,
  };
}
