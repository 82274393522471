import { useParams } from "react-router-dom";
import PropertyFolderShow from "../../components/Property/PropertyFolderShow";

function PropertyFolderShowPage() {
  const { propertyFolderId } = useParams();

  return (
    <>
      <PropertyFolderShow propertyFolderId={parseInt(propertyFolderId)} />
    </>
  );
}

export default PropertyFolderShowPage;
