import CardToggle from "../UI/CardToggle";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { MAIL_INITIAL_VALUE, mailValidationSchema } from "../../models/mail";
import MailForm from "./MailForm";
import useMails from "../../hooks/use-mails";

export default function MailShow() {
  const { mails, updateMail } = useMails();
  const [FormModal, closeForm, openForm] = useModal();
  const [mailToEdit, setMailToEdit] = useState();

  let initialValues = toInitialValuesFromSchema(
    mailValidationSchema,
    mailToEdit,
    MAIL_INITIAL_VALUE,
  );

  async function submitHandler(values) {
    const sanitizedValues = sanitizeValues(values);
    try {
      if (mailToEdit) {
        await updateMail(mailToEdit.id, sanitizedValues);
      }
      closeForm();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <FormModal>
        <MailForm initialValues={initialValues} onSubmit={submitHandler} />
      </FormModal>
      {mails?.map((mail) => (
        <CardToggle
          key={mail.id}
          title={
            <div className={"flex align-items-center primaryText"}>
              <Button
                onClick={() => {
                  setMailToEdit(mail);
                  openForm();
                }}
                className={"marginR10"}
              >
                Modifier
              </Button>
              <p style={mail.own_smtp ? { backgroundColor: "orange" } : {}}>
                {mail.own_smtp && "SMTP Client - "}
                {mail.static_id} - {mail.subject}
              </p>
            </div>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: mail.html }} />
        </CardToggle>
      ))}
    </>
  );
}
