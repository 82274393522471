import { array, boolean, number, object, string } from "yup";
import { dateMax, stringMax } from "./utils";

export const edlItemChildValidationSchema = object({
  nom: stringMax(250).required(" "),
  note: number().integer().notRequired(" "),
  materiaux: array().of(string()),
  degradations: array().of(string()),
  template_materiaux: number().notRequired(),
  quantite: number().notRequired(),
});

export const edlItemValidationSchema = object({
  items: array().of(edlItemChildValidationSchema),
  nom: stringMax(250).trim().required("Un nom d'item doit être renseigné"),
  note: number().integer().notRequired(),
  is_shared: boolean(),
  commentaire: string().notRequired(),
  materiaux: array().of(string()),
  degradations: array().of(string()),
  quantite: number().notRequired(),
});

export const edlCleValidationSchema = object({
  nombre: number().required(" "),
  description: stringMax(250).notRequired(),
});

export const edlClesValidationSchema = object({
  cles: array().of(edlCleValidationSchema),
});

export const EDL_CLE_INITIAL_VALUE = {
  nombre: "",
  description: "",
};

export const EDL_CLES_INITIAL_VALUE = {
  cles: [],
};

const profiles = object({
  present: string().required(" "),
  prenom: string().notRequired(),
  nom: string().notRequired(),
  represente_prenom: string().notRequired(),
  represente_nom: string().notRequired(),
  represente_qualite: string().notRequired(),
});

export const edlRealisationValidationSchema = object({
  profiles: array().of(profiles),
  profiles_bailleur: array().of(profiles),
  type_edl: string().required(
    "Le type d'état des lieux doit être renseigné (entrée / sortie)",
  ),
  compteur_elec: stringMax(250).notRequired(),
  elec_heures_creuses: number().notRequired(),
  elec_heures_pleines: number().notRequired(),
  compteur_gaz: stringMax(250).notRequired(),
  description: string().notRequired(),
  gaz: number().notRequired(),
  compteur_eau: stringMax(250).notRequired(),
  eau_froide: number().notRequired(),
  eau_chaude: number().notRequired(),
  date: dateMax().required("La date d'état des lieux doit être renseignée"),
  type_envoi: string().required(" "),
  cles: array().of(
    object({
      quantite: number().required("Un nombre de clés doit être renseigné"),
      description: stringMax(250).required(
        "Une description de clé doit être renseignée",
      ),
    }),
  ),
});
export const EDL_ITEM_INITIAL_VALUE = {
  nom: "",
  note: "",
  items: [],
  materiaux: [],
  degradations: [],
  template: "",
  commentaire: "",
  is_shared: false,
  type: "edl",
  quantite: 0,
};
export const EDL_ITEM_CHILD_VALUE = {
  nom: "",
  note: "",
  items: [],
  materiaux: [],
  degradations: [],
  is_shared: false,
  template_materiaux: "",
  quantite: 0,
};

export const edlItemTemplateChildValidationSchema = object({
  nom: stringMax(250).required(" "),
  materiaux: array().of(string()),
  degradations: array().of(string()),
  template_materiaux: number(),
  quantite: number().notRequired(),
});
export const edlItemTemplateValidationSchema = object({
  items: array().of(edlItemTemplateChildValidationSchema),
  nom: stringMax(250).required(" "),
  is_shared: boolean(),
  materiaux: array().of(string()),
  degradations: array().of(string()),
});

export const EDL_ITEM_TEMPLATE_INITIAL_VALUE = {
  nom: "",
  items: [],
  materiaux: [],
  degradations: [],
  template: "",
  is_shared: false,
  type: "edl",
};
export const EDL_ITEM_TEMPLATE_CHILD_VALUE = {
  nom: "",
  items: [],
  materiaux: [],
  degradations: [],
  template_materiaux: "",
  is_shared: false,
};
export const edlItemMateriauxTemplateValidationSchema = object({
  nom: stringMax(250).required(" "),
  degradations: array().of(string()),
  materiaux: array().of(string()),
});
export const EDL_ITEM_MATERIAUX_TEMPLATE_INITIAL_VALUE = {
  nom: "",
  degradations: [],
  materiaux: [],
};
