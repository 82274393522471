import React from "react";

const DisplayItemIcon = ({ icon, title, content, className }) => {
  return (
    <div
      className={`${className} flex align-items-center justify-content-flex-start`}
    >
      <div className={"marginR5"}>{icon}</div>
      <div>
        <div className={"primaryText"}>{title}</div>
        <div className={"secondaryText"}>{content}</div>
      </div>
    </div>
  );
};

export default DisplayItemIcon;
