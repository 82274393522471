import TextInput from "../../UI/TextInput";
import Button from "../../UI/Button";
import Tabs from "../../UI/Tabs";
import Select from "../../UI/Select";
import FormikSubmitButton from "../../UI/FormikSubmitButton";

export default function AddFolder({ profiles }) {
  const { selectPreviousTab } = Tabs.useActiveTab();
  return (
    <>
      <TextInput
        name={"nom"}
        label={"Nom du dossier"}
        placeholder="Nom du dossier"
        mandatory
      />
      <Select
        name={"id_profile_nom_owner"}
        label="Profil bailleur a affecter"
        mandatory
      >
        <option value="">Quel profil affecter au bien ?</option>
        {/*On vérifie que le profileNom à bien des profiles de déclarés*/}
        {profiles
          ?.filter((filtered) => filtered.profiles.length !== 0)
          .map((option) => (
            <option key={option.id} value={option.id}>
              {option.nom_profile}
            </option>
          ))}
      </Select>
      <div className="flex gap10 wrap justify-content-end">
        <Button onClick={() => selectPreviousTab()} type={"button"}>
          Précédent
        </Button>
        <FormikSubmitButton>Valider</FormikSubmitButton>
      </div>
    </>
  );
}
