import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import axios from "axios";
import useAuth from "../../hooks/use-auth";
import { dateMax, sanitizeValues } from "../../models/utils";
import { TenantStatus } from "../../models/tenant";
import { object } from "yup";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import ProfileCheck from "../Profile/ProfileCheck";

function TenantCandidater({ closeCandidateModal, profiles, propertyId }) {
  const { auth } = useAuth();
  const nav = useNavigate();

  async function submitHandler(values) {
    const newTenant = { ...sanitizeValues(values) };
    const newProfiles = Array.from(checked).map((c) => profiles[c]);
    newTenant.status = TenantStatus.Candidat.status;
    newTenant.id_bien = propertyId;
    newTenant.profile_nom = {
      profiles: newProfiles,
      nom_profile: newProfiles
        .map((p) => `${p.prenom[0]}. ${p.nom}`)
        .join(" & "),
    };
    try {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/candidat`,
        headers: { Authorization: `Bearer ${auth.accessToken}` },
        data: newTenant,
      });
      closeCandidateModal();
      nav("/locataire/candidatures");
    } catch (error) {
      console.error(error);
    }
  }

  const [checked, setChecked] = useState(new Set());

  function checkHandler(pos) {
    setChecked((curChecked) => {
      const newChecked = new Set(curChecked);
      if (curChecked.has(pos)) {
        newChecked.delete(pos);
      } else {
        newChecked.add(pos);
      }
      return newChecked;
    });
  }

  return (
    <>
      <h3 className={"text-center"}>
        Dernière étape avant l'envoi de votre dossier !
      </h3>
      <Formik
        initialValues={{ debut: "", payment_recurrence: 1 }}
        onSubmit={submitHandler}
        validationSchema={object({
          debut: dateMax().required(
            "Une date d'emménagement souhaitée est obligatoire",
          ),
        })}
      >
        <Form>
          <TextInput
            type="date"
            name="debut"
            label="Quelle est la date d'emménagement souhaitée"
            mandatory
          />
          <p>
            Selectionnez les profils avec lesquels vous souhaitez candidater :
          </p>
          {profiles.length !== 0 ? (
            profiles.map((profile, pos) => (
              <div onClick={checkHandler.bind(null, pos)} key={pos}>
                <ProfileCheck
                  profile={profile}
                  id_bien={propertyId}
                  checked={checked.has(pos)}
                  candidater={true}
                />
              </div>
            ))
          ) : (
            <div>Aucun profile trouvé</div>
          )}
          <div className={"text-center"}>
            <FormikSubmitButton
              disabled={checked.size < 1}
              className={"marginT20"}
            >
              Envoyer ma candidature
            </FormikSubmitButton>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default TenantCandidater;
