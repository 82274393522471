import useTenants from "../../hooks/use-tenants";
import Card from "../UI/Card";
import ButtonCard from "../UI/ButtonCard";
import { PlusBank } from "../UI/Icons";
import Select from "../UI/Select";
import { TextOneOrMany } from "../../utils";
import ProfileDisplaySmallCard from "../Profile/ProfileDisplaySmallCard";
import React from "react";
import { ProfileStatus } from "../../models/profile";

export default function TenantAddProfile({
  profileToPush,
  label,
  onAdd,
  onDelete,
  openAddProfileModal,
}) {
  const { tenants } = useTenants();
  let profilesIndiv = [];
  tenants?.forEach((tenant) =>
    tenant.profile_nom.profiles
      .filter((p) => p.status === ProfileStatus.Actif.status)
      .forEach((profile) =>
        profilesIndiv.push({
          profile: profile,
          tenant: {
            status: tenant.status,
            id_bien: tenant.id_bien,
            id: tenant.id,
          },
        }),
      ),
  );
  function handleAddFromList(e) {
    const prof = profilesIndiv.find(
      (profile) => profile.profile.id === parseInt(e.target.value),
    );
    if (
      profileToPush.findIndex(
        (profile) => profile.profile.id === prof.profile.id,
      ) === -1
    ) {
      // Only add if not already in list
      onAdd(prof);
    }
    e.target.value = "";
  }
  return (
    <>
      <Card className={"marginB10"}>
        <p className={"secondaryText text-center"}>{label}</p>
        <ButtonCard
          className={
            "padding5 flex align-items-center marginB10 marginT20 w-100"
          }
          onClick={openAddProfileModal}
        >
          <PlusBank />
          <span className={"marginL10"}>Créer un nouveau profil locataire</span>
        </ButtonCard>
        <Select name="id_profile" onChange={(e) => handleAddFromList(e)}>
          <option value="">Sélectionner un profil existant</option>
          {profilesIndiv.map((option) => (
            <option key={option.profile.id} value={option.profile.id}>
              {option.profile.type === 1 ? (
                <>
                  {option.profile.prenom} {option.profile.nom}
                </>
              ) : (
                option.profile.nom_societe
              )}
            </option>
          ))}
        </Select>
        {profileToPush.length > 0 && (
          <p className={"primaryText"}>
            <TextOneOrMany
              one={"Locataire sélectionné"}
              many={"Liste des locataires sélectionnés"}
              number={profileToPush.length}
            />
          </p>
        )}
        {profileToPush.map((profile, index) => (
          <ProfileDisplaySmallCard
            profile={profile.profile}
            onDelete={() => onDelete(index)}
            key={index}
          />
        ))}
      </Card>
    </>
  );
}
