import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { stripePromise } from "./Stripe";

export default function CheckoutReturn() {
  const [searchParams] = useSearchParams();
  let intentType = "setup";
  let clientSecret = searchParams.get("setup_intent_client_secret");
  if (!clientSecret) {
    intentType = "payment";
    clientSecret = searchParams.get("payment_intent_client_secret");
  }
  useEffect(() => {
    stripePromise
      .then((stripe) =>
        intentType === "setup"
          ? stripe.retrieveSetupIntent(clientSecret)
          : stripe.retrievePaymentIntent(clientSecret),
      )
      .then((result) => {
        window?.parent.stripeCallback(
          result.setupIntent || result.paymentIntent,
          result.error,
        );
      });
  }, [clientSecret, intentType]);
}
