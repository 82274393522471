import { sendToBackend } from "./index";

const ROOT_PATH = "balance_tenants/balance";

export async function fetchBalanceTenants(token, isLocataire = false) {
  try {
    const res = await sendToBackend(ROOT_PATH, token, {
      params: { is_locataire: isLocataire },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
