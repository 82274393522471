import { useOutletContext, useParams } from "react-router-dom";
import ProfileForm from "../../components/Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";

function AddGarantPage({ withMandatoryFields }) {
  const [Modal, closeModal] = useModal(true);

  const { tenant, profileSubmittedHandler } = useOutletContext();

  const { profileId } = useParams();

  return (
    <ProfileForm
      withMandatoryFields={withMandatoryFields}
      isGarant
      isTenant
      tenant={tenant}
      modal={Modal}
      profileId={parseInt(profileId)}
      onProfileSubmitted={(profile) => {
        profileSubmittedHandler(profile);
        closeModal();
      }}
    />
  );
}

export default AddGarantPage;
