import { findAndUpdate } from "../utils";
import useResources from "./use-resources";
import { useCallback } from "react";

const ROOT_PATH = "tenants";

function useTenants(isLocataire = false) {
  const {
    resources: tenants,
    error,
    isLoading,
    create,
    update,
    del,
    mutate,
    axios,
  } = useResources(ROOT_PATH, { params: { is_locataire: isLocataire } });

  function createLease(tenantId, tenant, typeEnvoi) {
    return mutate(
      async (currentTenants) => {
        const response = await axios.put(
          `${ROOT_PATH}/${tenantId}/lease`,
          tenant,
          {
            params: { type_envoi: typeEnvoi || undefined },
          },
        );
        return findAndUpdate(
          currentTenants,
          (tenant) => tenant.id === tenantId,
          response.data.tenant,
        );
      },
      { revalidate: false },
    );
  }

  const createCourrier = useCallback(
    async (tenantId, courrier, preview) => {
      let responseData;
      await mutate(
        async (currentTenants) => {
          const response = await axios.post(`/tenants/${tenantId}/courrier`, {
            ...courrier,
            preview,
          });

          responseData = response.data;
          if (preview) return currentTenants;
          return findAndUpdate(
            currentTenants,
            (tenant) => tenant.id === tenantId,
            responseData,
          );
        },
        { revalidate: false },
      );
      return responseData;
    },
    [axios, mutate],
  );

  return {
    tenants,
    error,
    isLoading,
    addTenant: create,
    updateTenant: update,
    deleteTenant: del,
    createCourrier,
    createLease,
  };
}

export default useTenants;
