import useAuth from "../hooks/use-auth";
import Page from "../components/UI/Page";
import Tabs from "../components/UI/Tabs";
import Menu from "../components/Menu/Menu";
import Impersonate from "../components/Auth/Impersonate";
import EdlTemplateShow from "../components/Edl/EdlTemplateShow";
import LeaseTemplateShow from "../components/LeaseTemplate/LeaseTemplateShow";
import CourrierShow from "../components/Courrier/CourrierShow";
import MailShow from "../components/Mail/MailShow";
import Webhooks from "../components/Admin/Oodrive/Webhooks";
import Map from "../components/Admin/Map";

function AdminPage() {
  const { auth } = useAuth();
  return (
    <>
      <Menu isBailleur />
      <Page
        header={<h2 className={"margin0 padding10"}>Page d'administration</h2>}
        body={
          <Tabs id="admin">
            <div className="container bg-blue paddingT10 border-blue">
              <div className="content">
                <Tabs.TabList>
                  {(auth?.role?.includes("impersonate") ||
                    auth?.role?.includes("toCall")) && (
                    <Tabs.Tab panelId="impersonate">Liste des comptes</Tabs.Tab>
                  )}
                  {auth?.role?.includes("edlTemplate") && (
                    <Tabs.Tab panelId="edl-template">EDL Template</Tabs.Tab>
                  )}
                  {auth?.role?.includes("leaseTemplate") && (
                    <Tabs.Tab panelId="lease-template">
                      Template de bail
                    </Tabs.Tab>
                  )}
                  {auth?.role?.includes("courriers") && (
                    <>
                      <Tabs.Tab panelId="courriers">Courriers</Tabs.Tab>
                      <Tabs.Tab panelId="cautions">Cautions</Tabs.Tab>
                      <Tabs.Tab panelId="avenants">Avenants</Tabs.Tab>
                      <Tabs.Tab panelId="conges">Congés</Tabs.Tab>
                      <Tabs.Tab panelId="mails">Mails</Tabs.Tab>
                    </>
                  )}
                  {auth?.role?.includes("oodrive") && (
                    <Tabs.Tab panelId="oodrive">Oodrive</Tabs.Tab>
                  )}
                  {auth?.role?.includes("statistiques") && (
                    <Tabs.Tab panelId="carte">Carte</Tabs.Tab>
                  )}
                </Tabs.TabList>
              </div>
            </div>
            <div className="blueSpace"></div>
            <div className="content">
              {(auth?.role?.includes("impersonate") ||
                auth?.role?.includes("toCall")) && (
                <Tabs.TabPanel id="impersonate">
                  <Impersonate />
                </Tabs.TabPanel>
              )}
              {auth?.role?.includes("edlTemplate") && (
                <Tabs.TabPanel id="edl-template">
                  <EdlTemplateShow />
                </Tabs.TabPanel>
              )}
              {auth?.role?.includes("leaseTemplate") && (
                <Tabs.TabPanel id="lease-template">
                  <LeaseTemplateShow />
                </Tabs.TabPanel>
              )}
              {auth?.role?.includes("courriers") && (
                <>
                  <Tabs.TabPanel id="courriers">
                    <CourrierShow type={"courrier"} />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel id="cautions">
                    <CourrierShow type={"caution"} />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel id="avenants">
                    <CourrierShow type={"avenant"} />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel id="conges">
                    <CourrierShow type={"conge"} />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel id="mails">
                    <MailShow />
                  </Tabs.TabPanel>
                </>
              )}
              {auth?.role?.includes("oodrive") && (
                <Tabs.TabPanel id="oodrive">
                  <Webhooks />
                </Tabs.TabPanel>
              )}
              {auth?.role?.includes("statistiques") && (
                <Tabs.TabPanel id="carte">
                  <Map />
                </Tabs.TabPanel>
              )}
            </div>
          </Tabs>
        }
      />
    </>
  );
}

export default AdminPage;
