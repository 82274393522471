import Card from "../UI/Card";
import CardToggle from "../UI/CardToggle";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import {
  COURRIER_INITIAL_VALUE,
  courrierValidationSchema,
} from "../../models/courrier";
import useCourriers from "../../hooks/use-courriers";
import CourrierForm from "./CourrierForm";

export default function CourrierShow({ type }) {
  const { courriers, updateCourrier } = useCourriers(type, true);
  const [FormModal, closeForm, openForm] = useModal();
  const [courrierToEdit, setcourrierToEdit] = useState();

  let initialValues = toInitialValuesFromSchema(
    courrierValidationSchema,
    courrierToEdit,
    COURRIER_INITIAL_VALUE,
  );

  async function submitHandler(values) {
    const sanitizedValues = sanitizeValues(values);
    try {
      if (courrierToEdit) {
        await updateCourrier(courrierToEdit.id, sanitizedValues);
      }
      closeForm();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <FormModal>
        <CourrierForm initialValues={initialValues} onSubmit={submitHandler} />
      </FormModal>
      {courriers.map((courrier, i) => (
        <Card className={"marginB10"} key={i}>
          <div className={"flex justify-content-end"}>
            <div>
              {courrier.status !== 1 && (
                <Button
                  buttonStyle="secondaryYellow"
                  disabled
                  className={"marginR10"}
                >
                  Brouillon
                </Button>
              )}
              <Button
                onClick={() => {
                  setcourrierToEdit(courrier);
                  openForm();
                }}
              >
                Modifier
              </Button>
            </div>
          </div>

          <CardToggle
            title={
              <span className={"primaryText"}>
                {courrier.static_id} - {courrier.nom}
              </span>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: courrier.content }} />
          </CardToggle>
        </Card>
      ))}
    </>
  );
}
