import { useNavigate } from "react-router-dom";

import { Bell, BellSlash, DotsAction, Interrogation } from "../UI/Icons";
import style from "./BankCards.module.css";
import moment from "moment";
import Tooltip from "../UI/Tooltip";
import { currencyFormatter } from "../../api/Functions";
import { classnames } from "../../utils";
import useProfileNoms from "../../hooks/use-profile-noms";
import useNordigen from "../../hooks/use-nordigen";

const ROOT_PATH = "/bailleur/finances/";

export default function BankCard({ bank }) {
  const { profileNoms } = useProfileNoms();
  const nav = useNavigate();
  const { selectInstitution } = useNordigen();

  return (
    <div className={style.card}>
      <div className={style.padding}>
        <div className="flex justify-content-flex-start">
          <div>
            <img
              className={style.logo}
              src={bank.bank_metadata.logo}
              alt={bank.bank_metadata.name}
            />
          </div>
          <div>
            <p className={style.nomBank}>{bank.bank_metadata.name}</p>
            <div className="flex">
              <p className={style.nomCompte}>{bank.nom}</p>
              <Tooltip
                content={`Dernière synchronisation le ${moment(
                  bank.date_maj,
                ).format("DD/MM/YYYY")}`}
              >
                <Interrogation className={style.interrogation} />
              </Tooltip>
            </div>
          </div>
          <div className={classnames(style.push, "flex align-items-start")}>
            {bank.alert ? (
              <Tooltip
                content={
                  <>
                    Une alerte sera envoyée par e-mail si le solde du compte
                    descend en dessous de {currencyFormatter(bank.alert)}
                  </>
                }
              >
                <Bell />
              </Tooltip>
            ) : (
              <Tooltip
                content={
                  <>
                    Aucune alerte configurée. Il est possible d'envoyer une
                    alerte par e-mail lorsque le solde du compte descend en
                    dessous d'un certain montant.
                  </>
                }
              >
                <BellSlash />
              </Tooltip>
            )}
            <div
              className={"marginL10"}
              onClick={() => nav(`${ROOT_PATH}edit/${bank.id}`)}
            >
              <DotsAction />
            </div>
          </div>
        </div>
        <div className="flex space-between wrap gap10">
          <div>
            <p className={style.montantLabel}>Solde</p>
            <p className={style.montant}>{currencyFormatter(bank.montant)}</p>
          </div>
          <div>
            <p className={style.montantLabel}>Profil bailleur</p>
            <p>
              {bank.id_profile_nom
                ? profileNoms.find(
                    (profile) => profile.id === bank.id_profile_nom,
                  )?.nom_profile
                : "Aucun"}
            </p>
          </div>
        </div>
      </div>
      {bank.status === 2 ? (
        <div className={style.expired}>
          <button
            onClick={() => selectInstitution(bank.bank_metadata.institution_id)}
          >
            Session expirée. Se reconnecter
          </button>
        </div>
      ) : (
        <div className={style.active}>Session active</div>
      )}
    </div>
  );
}
