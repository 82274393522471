import { useCallback, useState } from "react";

export default function useLoader(callback) {
  const [isLoading, setIsLoading] = useState(false);

  return [
    isLoading,
    useCallback(
      async (...args) => {
        setIsLoading(true);
        try {
          return await callback(...args);
        } finally {
          setIsLoading(false);
        }
      },
      [callback],
    ),
  ];
}
