import { currencyFormatter } from "../../api/Functions";
import Checkbox from "../UI/Checkbox";
import style from "./BankCheckbox.module.css";
import Card from "../UI/Card";

export default function BankCheckbox({ bank, name, value }) {
  return (
    <Checkbox name={name} value={value} className={style.checkbox}>
      <Card padding={"md"}>
        <div className={style.nomBank}>
          <img src={bank.bank_metadata.logo} alt={bank.bank_metadata.name} />
          <p>{bank.bank_metadata.name}</p>
        </div>
        <div className="flex space-between wrap gap10">
          <div>
            <p className={style.montantLabel}>Solde</p>
            <p className={style.montant}>{currencyFormatter(bank.montant)}</p>
          </div>
        </div>
      </Card>
    </Checkbox>
  );
}
