import styles from "../Auth/AuthForm.module.css";
import { Form, Formik } from "formik";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import FileDownloadLink from "../UI/FileDownloadLink";
import React, { useState } from "react";
import Checkbox from "../UI/Checkbox";
import { sanitizeValues } from "../../models/utils";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { classnames, handleAPIError, TextOneOrMany } from "../../utils";
import { boolean, object } from "yup";

export default function SendMailForm({ tenant, file, onSubmitted }) {
  const [error, setError] = useState(false);
  const axios = useAuthenticatedAxios();

  if (!file || !tenant) return null;

  async function onSubmit(values) {
    const sanitizedValues = sanitizeValues(values);
    try {
      await axios.post(`/files/${file.id}/send-mail`, sanitizedValues);
      onSubmitted();
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <>
      <h2>Envoyer par email</h2>
      <p className={"primaryText"}>Document à envoyer : </p>
      <div className={"text-center marginT10"}>
        <FileDownloadLink file={file} />
      </div>
      <Formik
        className={styles.form}
        initialValues={{ send_caution: false, send_locataire: true }}
        onSubmit={onSubmit}
        validationSchema={object({
          send_caution: boolean().optional(),
          send_locataire: boolean().optional(),
        })}
      >
        {({ values }) => {
          const locataires = tenant.profile_nom.profiles.filter(
            (profile) => profile.status === 1 && profile.garantor_of === null,
          );
          const cautions = tenant.profile_nom.profiles.filter(
            (profile) => profile.status === 1 && profile.garantor_of !== null,
          );
          return (
            <Form className={styles.form}>
              <Checkbox
                name={"send_locataire"}
                className={"marginB10 marginT10"}
              >
                <TextOneOrMany
                  one={"Envoyer au locataire"}
                  many={"Envoyer aux locataires"}
                  number={locataires.length}
                />
                <ul>
                  {locataires.map((profile, i) => (
                    <li key={i}>
                      {profile.prenom} {profile.nom} : {profile.mail}
                    </li>
                  ))}
                </ul>
              </Checkbox>
              {cautions.length > 0 && (
                <Checkbox name={"send_caution"} className={"marginB10"}>
                  <TextOneOrMany
                    one={"Envoyer à la caution"}
                    many={"Envoyer aux cautions"}
                    number={cautions.length}
                  />
                  <ul>
                    {cautions.map((profile, i) => (
                      <li key={i}>
                        {profile.prenom} {profile.nom} : {profile.mail}
                      </li>
                    ))}
                  </ul>
                </Checkbox>
              )}
              <p className={"marginB10"}>Vous serez en copie du mail</p>
              <FormikSubmitButton
                disabled={!values.send_locataire && !values.send_caution}
              >
                Envoyer
              </FormikSubmitButton>
              {error && (
                <p className={classnames(styles.error, "marginT10")}>{error}</p>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
