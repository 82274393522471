import { Outlet } from "react-router-dom";
import Page from "../../components/UI/Page";
import LocataireCandidaturesList from "../../components/Locataire/LocataireCandidaturesList";

function LocataireListCandidaturesPages() {
  return (
    <>
      <Page
        header={
          <div
            className={
              "padding20 flex space-between align-items-end paddingB40"
            }
          >
            <div>
              <h2 className={"marginB0"}>Candidatures en cours</h2>
              <p className={"secondaryText"}>
                Visualisez ici toutes les candidatures que vous avez soumises
              </p>
            </div>
          </div>
        }
        body={<LocataireCandidaturesList />}
      />
      <Outlet />
    </>
  );
}

export default LocataireListCandidaturesPages;
