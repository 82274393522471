import { number, object, string } from "yup";
import { stringMax } from "./utils";

export const bankSchema = object({
  nom: stringMax(250).optional(),
  institution_id: string().optional(),
  account_id: string().optional(),
  id_compte: number().optional(),
  id_profile_nom: number().required(""),
  montant: number().optional(),
  alert: number().optional(),
});

export const BANK_INITIAL_VALUES = {
  nom: "",
  institution_id: "",
  account_id: "",
  id_profile_nom: "",
  montant: "",
  alert: "",
};
