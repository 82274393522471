import ProfileCard from "./ProfileCard";

export default function ProfileIndivList({
  profiles,
  editProfileHandler,
  deleteProfileHandler,
  archiveProfileHandler,
  activateProfileHandler,
  inviteProfileHandler,
  addGarant,
  removeGarant,
  dontAddGarant,
  isBailleur,
}) {
  const supprimerActionFactory = (profileId, index) => {
    if (!deleteProfileHandler) {
      return null;
    }
    return () => deleteProfileHandler(profileId, index);
  };
  const archiverActionFactory = (profileId, index) => {
    if (!archiveProfileHandler) {
      return null;
    }
    return () => archiveProfileHandler(profileId, index);
  };
  const activateActionFactory = (profileId, index) => {
    if (!activateProfileHandler) {
      return null;
    }
    return () => activateProfileHandler(profileId, index);
  };
  const modifierActionFactory = (index) => {
    if (!editProfileHandler) {
      return null;
    }
    return (profileId) => editProfileHandler(profileId, index);
  };
  const inviteActionFactory = (profileId) => {
    if (!inviteProfileHandler) {
      return null;
    }
    return () => inviteProfileHandler(profileId);
  };

  return profiles
    ?.filter(
      (filtered) =>
        filtered.garantor_of === undefined || filtered.garantor_of === null,
    )
    .map((profile, index) => (
      <ProfileCard
        profile={profile}
        key={index}
        modifierAction={modifierActionFactory(index)}
        supprimerAction={supprimerActionFactory(profile.id, index)}
        archiverAction={
          profiles.length > 1
            ? archiverActionFactory(profile.id, index)
            : undefined
        }
        activateAction={activateActionFactory(profile.id, index)}
        inviteAction={inviteActionFactory(profile.id)}
        addGarant={addGarant}
        removeGarant={removeGarant}
        dontAddGarant={dontAddGarant}
        isBailleur={isBailleur}
      />
    ));
}
