import { Form, Formik } from "formik";
import { ReactSelect } from "../UI/Select";
import Card from "../UI/Card";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import {
  TRANSACTION_CHILD_INITIAL_VALUE,
  TRANSACTION_SPLIT_FORM_INITIAL_VALUE,
  transactionChildValidationSchema,
  transactionSplitValidationSchema,
} from "../../models/transactionChild";
import Checkbox from "../UI/Checkbox";
import { ModalAwareLink } from "../UI/Modal";
import { currencyFormatter } from "../../api/Functions";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import TransactionSplitFieldArray from "./TransactionSplitFieldArray";
import { handleAPIError } from "../../utils";
import { useState } from "react";
import Button from "../UI/Button";

export default function TransactionSplitForm({
  transaction,
  shouldPrefillSplit,
  splitTemplates,
  onSubmit,
}) {
  let initialValues;
  if (transaction?.balances.length > 0) {
    initialValues = toInitialValuesFromSchema(
      transactionSplitValidationSchema,
      transaction,
      TRANSACTION_SPLIT_FORM_INITIAL_VALUE,
    );
  } else if (shouldPrefillSplit) {
    initialValues = toInitialValuesFromSchema(
      transactionSplitValidationSchema,
      {
        balances: [
          {
            montant: transaction.montant,
            comment: transaction.description,
          },
        ],
      },
      TRANSACTION_SPLIT_FORM_INITIAL_VALUE,
    );
  } else {
    initialValues = TRANSACTION_SPLIT_FORM_INITIAL_VALUE;
  }

  const [error, setError] = useState("");

  async function submitHandler(values, { resetForm }) {
    const sanitizedValues = sanitizeValues(values);
    setError("");
    try {
      await onSubmit(sanitizedValues);
      resetForm();
    } catch (e) {
      setError(handleAPIError(e));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      enableReinitialize={true}
      validationSchema={transactionSplitValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        function changeTemplateSplit(option) {
          setFieldValue("template", option?.value ?? null);
          if (!option) {
            setFieldValue("balances", [TRANSACTION_CHILD_INITIAL_VALUE]);
            return;
          }

          const balances = splitTemplates
            .find((filtered) => filtered.id === parseInt(option.value))
            .splits.map((template) => {
              return {
                ...toInitialValuesFromSchema(
                  transactionChildValidationSchema,
                  template,
                  TRANSACTION_CHILD_INITIAL_VALUE,
                ),
              };
            });
          setFieldValue("balances", balances);
        }

        const splitTemplateOptions = splitTemplates.map((template) => ({
          value: template.id,
          label: template.nom,
        }));

        let totalSplit = 0;
        values.balances.map(
          (transac) => (totalSplit = totalSplit + parseFloat(transac.montant)),
        );

        return (
          <Form>
            <h2 className={"margin0"}>Affecter et répartir une transaction</h2>
            <Card className={"marginT10 marginB10"}>
              <p>
                <span className={"primaryText"}>Montant : </span>
                {currencyFormatter(transaction?.montant)}
              </p>
              <p>
                <span className={"primaryText"}>Description :</span>
                {transaction?.description}
              </p>
            </Card>
            {splitTemplates.length > 0 ? (
              <ReactSelect
                name="template"
                label={"Répartition prédéfinie"}
                placeholder="Template CAF"
                className={"marginB10"}
                onChange={(option) => changeTemplateSplit(option)}
                options={splitTemplateOptions}
              />
            ) : (
              <p className={"secondaryText marginT10 marginB10 text-center"}>
                Vous pouvez définir des templates pour découper vos transactions
                automatiquement dans{" "}
                <ModalAwareLink
                  to={"/bailleur/finances?tab-bank=configuration"}
                >
                  Finances > Configuration
                </ModalAwareLink>
              </p>
            )}
            <TransactionSplitFieldArray name={"balances"} />
            {values.template &&
              values.template !== "" &&
              splitTemplates.length > 0 && (
                <Checkbox name={"update_template"}>
                  Mettre à jour le template avec ces valeurs
                </Checkbox>
              )}
            {!isNaN(totalSplit) &&
              (Math.abs(totalSplit) > Math.abs(transaction?.montant) ? (
                <Card type={"error"} className={"marginB10 padding10"}>
                  Attention, le montant total des transactions découpées est de{" "}
                  {currencyFormatter(totalSplit)} et dépasse le montant de la
                  transaction initiale (
                  {currencyFormatter(transaction?.montant)})
                </Card>
              ) : (
                <Card className={"marginB10 padding10"}>
                  Montant total des transactions découpées :{" "}
                  {currencyFormatter(totalSplit)}
                </Card>
              ))}
            {error && (
              <Card type={"error"} className={"marginB10 padding10"}>
                {error}
              </Card>
            )}

            <div className={"text-right"}>
              <Button
                type={"button"}
                className={"marginR10"}
                buttonStyle={"secondaryDelete"}
                onClick={async () => {
                  await setFieldValue("balances", []);
                  await setFieldValue("template", null);
                  handleSubmit();
                }}
              >
                Supprimer les répartitions
              </Button>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
