import TextInput from "../UI/TextInput";
import BalanceTypeSelect from "./BalanceTypeSelect";
import FileInput from "../UI/FileInput";
import { DocType } from "../../utils/constants/documents";

const DISABLED_FIELD_HINT =
  "La valeur n'est pas modifiable car associée à une transaction de votre compte bancaire.";

export default function BalanceFields({
  isFromTransac,
  domaineBalance,
  balanceId,
  name = "",
  withJustificatif = false,
}) {
  return (
    <>
      <TextInput
        type="date"
        name={`${name}date_transac`}
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        label={"Date de l'opération"}
        mandatory
      />
      <BalanceTypeSelect
        domaineBalance={domaineBalance}
        name={`${name}type`}
        className={"flex-grow"}
      />
      <TextInput
        type="text"
        name={`${name}comment`}
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        placeholder="Virement de M.DUPONT"
        label={"Libellé"}
      />
      <TextInput
        type="number"
        step="0.01"
        name={`${name}montant`}
        placeholder="Montant"
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        label={"Montant"}
        mandatory
        min={null}
        unit={"€"}
      />

      {withJustificatif && (
        <>
          Justificatifs
          <FileInput
            documentId={balanceId}
            doctype={DocType.JUSTIFICATIF_BALANCE}
            path={`${name}files`}
          />
        </>
      )}
    </>
  );
}
