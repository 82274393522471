import { sendToBackend } from "./index";

const ROOT_PATH = "drafts";

export async function saveDraft(values, type, token) {
  try {
    const response = await sendToBackend("drafts", token, {
      method: "POST",
      data: { json: values, type },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDraft(id, values, type, token) {
  try {
    const response = await sendToBackend(`drafts/${id}`, token, {
      method: "PUT",
      data: { json: values, type },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchDrafts(token, type) {
  const params = { type };
  try {
    const res = await sendToBackend(ROOT_PATH, token, { params });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteDraft(token, id) {
  await sendToBackend(`${ROOT_PATH}/${id}`, token, {
    method: "DELETE",
  });
}
