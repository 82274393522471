import { useNavigate } from "react-router-dom";

import { ROOT_PATH } from "./PropertyListShow";
import styles from "./PropertyRow.module.css";
import { ArrowRight, DotsAction } from "../UI/Icons";
import StatusLoc from "../UI/Status";
import moment from "moment";
import useTenants from "../../hooks/use-tenants";
import Dropdown from "../UI/Dropdown";
import { classnames } from "../../utils";
import Card from "../UI/Card";
import useMediaQuery from "../../hooks/use-media-query";
import { currencyFormatter } from "../../api/Functions";
import PropertyDisplayNomLocataire from "./PropertyDisplayNomLocataire";
import { useModal } from "../../hooks/use-modal";
import PropertyMoveToFolder from "./PropertyMoveToFolder";
import { useState } from "react";
import useProperties from "../../hooks/use-properties";
import usePropertyFolders from "../../hooks/use-property-folders";
import { PropertyStatus } from "../../models/property";
import BlurOverlay from "../UI/BlurOverlay";
import DisabledBienNote from "./DisabledBienNote";
import { isPropertyDisabled } from "../../utils/properties";

function PropertyRow({
  property,
  propertyFolder,
  onDuplicateProperty,
  depth = 0,
}) {
  const { tenants } = useTenants();
  const nav = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 1022px)");
  const [MoveFolder, , openMoveFolder] = useModal();
  const [propertyToEdit, setPropertyToEdit] = useState();
  const { updateProperty } = useProperties();
  const { updatePropertyFolder } = usePropertyFolders();

  if (!propertyFolder && !property) return null;

  let body;
  if (propertyFolder) {
    body = (
      <div
        className={classnames(
          styles.row,
          styles.folderRow,
          depth > 0 && styles.deepProperty,
        )}
      >
        {!isSmallScreen && (
          <>
            <div className={styles.folderLink}>
              {Array.from({ length: depth }, (_, i) => (
                <div key={i}></div>
              ))}
            </div>
            <div className={styles.folderDivider}>
              {Array.from({ length: depth }, (_, i) => (
                <div key={i}></div>
              ))}
            </div>
          </>
        )}
        <PropertyDisplayNomLocataire
          property={propertyFolder}
          className={styles.folderDisplay}
          isPropertyFolder
        />
        <div></div>
        <div></div>
        <div></div>
        <Dropdown
          header={<DotsAction />}
          className={classnames(styles.dropDown, styles.folderDisplay)}
          type={"click"}
          body={
            <>
              <div
                onClick={() => nav(`folder/${propertyFolder.id}`)}
                className={"secondaryText"}
              >
                Gérer
              </div>
              <div
                className={"secondaryText"}
                onClick={() => {
                  setPropertyToEdit({
                    id: propertyFolder.id,
                    type: "folder",
                  });
                  openMoveFolder();
                }}
              >
                Déplacer dans un dossier
              </div>
              <div
                className={"secondaryText"}
                onClick={async () => {
                  try {
                    await updatePropertyFolder(propertyFolder.id, {
                      status:
                        propertyFolder.status === PropertyStatus.Actif.status
                          ? PropertyStatus.Archive.status
                          : PropertyStatus.Actif.status,
                    });
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {propertyFolder.status === PropertyStatus.Actif.status
                  ? "Archiver"
                  : "Réactiver"}
              </div>
            </>
          }
        />
        {propertyFolder.properties.map((property) => (
          <PropertyRow
            property={property}
            onDuplicateProperty={onDuplicateProperty}
            key={property.id}
            depth={depth + 1}
          />
        ))}
        {propertyFolder.property_folders.map((propertyFolder) => (
          <PropertyRow
            propertyFolder={propertyFolder}
            onDuplicateProperty={onDuplicateProperty}
            key={propertyFolder.id}
            depth={depth + 1}
          />
        ))}
      </div>
    );
  } else if (property) {
    const locataireActuel = tenants.find(
      (ten) =>
        ten.id_bien === property.id &&
        ten.status === 1 &&
        moment().isBetween(ten.debut, ten.fin),
    );

    const nextLocataire = tenants.find(
      (ten) =>
        ten.id_bien === property.id &&
        ten.status === 1 &&
        moment().isBefore(ten.debut),
    );

    const preavis = tenants.find(
      (ten) =>
        ten.id_bien === property.id &&
        ten.status === 1 &&
        moment().isBetween(ten.debut, ten.fin) &&
        ten.preavis,
    );

    const disabledProperty = isPropertyDisabled(property);

    body = (
      <div
        className={classnames(
          styles.row,
          disabledProperty && styles.disabled,
          depth > 0 && styles.deepProperty,
        )}
      >
        {!isSmallScreen && (
          <>
            <div className={styles.folderLink}>
              {Array.from({ length: depth }, (_, i) => (
                <div key={i}></div>
              ))}
            </div>
            <div className={styles.folderDivider}>
              {Array.from({ length: depth }, (_, i) => (
                <div key={i}></div>
              ))}
            </div>
          </>
        )}
        <PropertyDisplayNomLocataire
          property={property}
          locataire={locataireActuel}
          disabled={disabledProperty}
        />
        <div className="flex align-items-center">
          {locataireActuel ? (
            preavis ? (
              <StatusLoc text={"Préavis"} color={"yellow"} />
            ) : (
              <StatusLoc text={"Loué"} color={"green"} />
            )
          ) : (
            <StatusLoc text={"Vide"} color={"red"} />
          )}
          {nextLocataire && <StatusLoc text={"Reloué"} color={"purple"} />}
        </div>
        <div className="flex align-items-center secondaryText">
          {locataireActuel ? (
            <>
              {currencyFormatter(
                locataireActuel.loyer_hc + locataireActuel.charges,
              )}
            </>
          ) : (
            <>{currencyFormatter(property.loyer_hc + property.charges)}</>
          )}
        </div>
        <div className="flex align-items-center secondaryText">
          {locataireActuel ? (
            <>
              <div className={"marginR10"}>
                {moment(locataireActuel.debut).format(
                  isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
                )}
              </div>
              <ArrowRight />
              <div
                className={classnames(
                  "marginL10",
                  locataireActuel?.preavis && styles.preavis,
                )}
              >
                {moment(locataireActuel.fin).format(
                  isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
                )}
              </div>
            </>
          ) : nextLocataire ? (
            <>
              Arrive le{" "}
              {moment(nextLocataire.debut).format(
                isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
              )}
            </>
          ) : (
            <>Pas de bail en cours</>
          )}
        </div>
        <Dropdown
          header={<DotsAction />}
          type={"click"}
          className={classnames(styles.dropDown)}
          body={
            <>
              {!disabledProperty && (
                <>
                  <div
                    onClick={() => nav(`${ROOT_PATH}${property.id}`)}
                    className={"secondaryText"}
                  >
                    Gérer
                  </div>
                  <div
                    onClick={async () => {
                      try {
                        await onDuplicateProperty(property);
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    className={"secondaryText"}
                  >
                    Dupliquer
                  </div>
                  <div
                    className={"secondaryText"}
                    onClick={() => {
                      setPropertyToEdit({ id: property.id, type: "property" });
                      openMoveFolder();
                    }}
                  >
                    Déplacer dans un dossier
                  </div>
                </>
              )}
              <div
                className={"secondaryText"}
                onClick={async () => {
                  try {
                    await updateProperty(property.id, {
                      status:
                        property.status === PropertyStatus.Actif.status
                          ? PropertyStatus.Archive.status
                          : PropertyStatus.Actif.status,
                    });
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {property.status === PropertyStatus.Actif.status
                  ? "Archiver"
                  : "Réactiver"}
              </div>
            </>
          }
        />

        {disabledProperty && (
          <BlurOverlay className={styles.disabled}>
            <DisabledBienNote className={styles.disabledNote} />
          </BlurOverlay>
        )}
      </div>
    );
  }

  return (
    <>
      <MoveFolder>
        <PropertyMoveToFolder
          onMoveToFolder={async (id_property_folder) => {
            try {
              propertyToEdit.type === "property" &&
                (await updateProperty(propertyToEdit.id, {
                  id_property_folder: id_property_folder,
                }));
              propertyToEdit.type === "folder" &&
                (await updatePropertyFolder(propertyToEdit.id, {
                  id_parent: id_property_folder,
                }));
            } catch (error) {
              console.error(error);
            }
          }}
          folderId={
            propertyToEdit?.type === "folder" ? propertyToEdit.id : null
          }
        />
      </MoveFolder>
      {isSmallScreen ? <Card className={styles.rowCard}>{body}</Card> : body}
    </>
  );
}

export default PropertyRow;
