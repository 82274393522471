import IconAndText from "../UI/IconAndText";
import { ArrowRight } from "../UI/Icons";
import ButtonCard from "../UI/ButtonCard";
import useMediaQuery from "../../hooks/use-media-query";

export default function AddLocataireChoices({
  addLocataire,
  addAvenant,
  inviteCandidat,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 599px)");

  return (
    <>
      <h2 className={"text-center"}>Que souhaitez vous faire ?</h2>
      <div className="grid gap10">
        <ButtonCard
          className={"flex space-between align-items-center w-100 marginT10"}
          onClick={addLocataire}
        >
          <IconAndText
            icon={
              isSmallScreen ? null : (
                <img
                  src={`/ChoseCreerLocataire.jpg`}
                  alt="Créer un locataire"
                  className={"center"}
                />
              )
            }
            text={
              <>
                <p className={"cardTitle text-left"}>Créer un locataire</p>
                <p className={"text-left"}>
                  Créer un nouveau locataire, pour un nouveau bail
                </p>
              </>
            }
          />
          <ArrowRight classname={"marginR10"} />
        </ButtonCard>
        <ButtonCard
          className={"flex space-between align-items-center w-100 marginT10"}
          onClick={addAvenant}
        >
          <IconAndText
            icon={
              isSmallScreen ? null : (
                <img
                  src={`/ChoseAjouterLocataire.jpg`}
                  alt="Ajouter un locataire"
                  className={"center"}
                />
              )
            }
            text={
              <>
                <p className={"cardTitle text-left"}>
                  Ajouter un locataire au bail existant
                </p>
                <p className={"text-left"}>
                  Ajout / suppression d'un conjoint ou d'un colocataire. Cela
                  passe par un avenant au bail
                </p>
              </>
            }
          />
          <ArrowRight classname={"marginR10"} />
        </ButtonCard>
        <ButtonCard
          className={"flex space-between align-items-center w-100 marginT10"}
          onClick={inviteCandidat}
        >
          <IconAndText
            icon={
              isSmallScreen ? null : (
                <img
                  src={`/ChoseInviterCandidat.jpg`}
                  alt="Inviter un locataire"
                  className={"center"}
                />
              )
            }
            text={
              <>
                <p className={"cardTitle text-left"}>Inviter un candidat</p>
                <p className={"text-left"}>
                  Inviter un futur locataire à compléter son dossier de
                  candidature
                </p>
              </>
            }
          />
          <ArrowRight classname={"marginR10"} />
        </ButtonCard>
      </div>
    </>
  );
}
