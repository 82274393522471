import Card from "../UI/Card";
import InitialesLogo from "../UI/InitialesLogo";
import DisplayName from "../UI/DisplayName";
import { TextOneOrMany } from "../../utils";

export default function ProfileCheck({ profile, checked }) {
  return (
    <Card
      checked={checked}
      className={"marginT10 padding10 flex align-items-center"}
    >
      <div className={"marginR10"}>
        <input type="checkbox" checked={checked} readOnly />
      </div>
      <div className="flex align-items-center">
        <div>
          <InitialesLogo
            nom={profile.nom}
            prenom={profile.prenom}
            shape={"rond"}
          />
        </div>
        <div className={"marginL10"}>
          <DisplayName profile={profile} />
          <p>
            <span className="primaryText">
              <TextOneOrMany
                one={"Garant"}
                many={"Garants"}
                none={"Garant"}
                number={profile.garantors}
              />
            </span>{" "}
            :{" "}
            {profile.garantors.length > 0
              ? profile.garantors.map((garantor, i) => (
                  <DisplayName profile={garantor} key={i} />
                ))
              : profile.caution_type ?? "Aucun"}
          </p>
        </div>
      </div>
    </Card>
  );
}
