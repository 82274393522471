import { number, object } from "yup";
import { stringMax } from "./utils";

export const financeConfigSchema = object({
  keyword: stringMax(250).required(" "),
  id_tenant: number().integer().required(" "),
  id_profile_nom: number().integer().required(" "),
});

export const financeConfigInitialValues = {
  keyword: "",
  id_tenant: "",
  id_profile_nom: "",
};
