import { Form, Formik } from "formik";

import {
  getBailleurProfileSchema,
  getTenantProfileSchema,
  PROFILE_INITIAL_VALUE,
  ProfileStatus,
  ProfileType,
} from "../../models/profile";
import ProfileFormDom from "./ProfileFormDom";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { createProfileIndiv, updateProfileIndiv } from "../../api/Profiles";
import ProfileFormPro from "./ProfileFormPro";
import ProfileFormPerso from "./ProfileFormPerso";
import useAuth from "../../hooks/use-auth";
import TabbedForm from "../UI/TabbedForm";
import { useRef, useState } from "react";
import { handleAPIError } from "../../utils";

export default function ProfileForm({
  edit,
  profile,
  tenant,
  modal: ModalElement,
  profileId,
  profileNomId,
  isGarant,
  isTenant,
  onProfileSubmitted,
  isBailleur,
  addTenant,
  isCandidat,
  withMandatoryFields,
}) {
  const { auth } = useAuth();
  const [error, setError] = useState("");
  const formRef = useRef();

  let validationSchema = getTenantProfileSchema(!!withMandatoryFields);
  if (isBailleur) {
    validationSchema = getBailleurProfileSchema(!!withMandatoryFields);
  }
  const initialValues = toInitialValuesFromSchema(
    validationSchema,
    profile,
    PROFILE_INITIAL_VALUE,
  );
  async function submitHandler(values, { resetForm }) {
    const data = {
      ...sanitizeValues(values),
    };
    if (isCandidat) {
      data.profile_type = ProfileType.Candidat;
    }
    if (profileNomId) {
      // Pour quand on créé un profile dans un profileNom (bailleur)
      data.id_profile_nom = profileNomId;
    }
    if (isTenant && !edit) {
      // when creating new profile, we need the profile_nom id to be set
      data.id_profile_nom = tenant.profile_nom.id;
    }

    if (isGarant) {
      data.garantor_of = profileId;
    }

    try {
      let newProfile;
      if (edit && !addTenant) {
        newProfile = await updateProfileIndiv(
          auth.accessToken,
          profileId,
          data,
        );
      } else if (!addTenant) {
        newProfile = await createProfileIndiv(auth.accessToken, data);
      } else {
        // Cas où on ajoute un tenant from scratch depuis l'espace bailleur ou un profile bailleur ->
        // On ne commit rien dans la base, on renvoie juste le profile au master form (on est dans une seconde modal)
        data.status = ProfileStatus.Actif.status;
        data.garantor_of = null;
        newProfile = data;
      }
      resetForm();
      onProfileSubmitted(newProfile);
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <TabbedForm formikProps={formikProps} id="profile-form" ref={formRef}>
            <ModalElement
              stickyHeader
              confirmCloseText={
                "Votre formulaire n'a pas été sauvegardé. Voulez-vous vraiment quitter ?"
              }
              onClose={() => {
                formRef.current?.resetTab();
              }}
              blueHeader
              shouldConfirm={() => formikProps.dirty}
              size={"medium"}
              header={
                <TabbedForm.TabList>
                  <TabbedForm.Tab panelId="info-perso">
                    Informations personnelles
                  </TabbedForm.Tab>
                  <TabbedForm.Tab panelId="domiciliation">
                    Domiciliation
                  </TabbedForm.Tab>
                  {!isBailleur && formikProps.values.type === "1" && (
                    <TabbedForm.Tab panelId="info-pro">
                      Situation professionnelle
                    </TabbedForm.Tab>
                  )}
                </TabbedForm.TabList>
              }
            >
              <Form>
                <div className="marginT10">
                  <TabbedForm.TabPanel id="info-perso">
                    <ProfileFormPerso
                      type={formikProps.values.type}
                      isBailleur={isBailleur}
                      cautionType={formikProps.values.caution_type}
                      isGarant={isGarant}
                      profile={profile}
                      withMandatoryFields={withMandatoryFields}
                    />
                  </TabbedForm.TabPanel>
                  <TabbedForm.TabPanel id="domiciliation">
                    <ProfileFormDom isBailleur={isBailleur} />
                  </TabbedForm.TabPanel>
                  {!isBailleur && (
                    <TabbedForm.TabPanel id="info-pro">
                      <ProfileFormPro />
                    </TabbedForm.TabPanel>
                  )}
                </div>
                <TabbedForm.Actions error={error} />
              </Form>
            </ModalElement>
          </TabbedForm>
        );
      }}
    </Formik>
  );
}
