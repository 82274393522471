import TextInput from "../UI/TextInput";
import { Form, Formik } from "formik";
import { useModal } from "../../hooks/use-modal";
import Button from "../UI/Button";
import {
  PROFILE_NOM_INITIAL_VALUE,
  profileNomNomMandataireSchema,
  profileNomNomSchema,
} from "../../models/profile";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import ProfileForm from "./ProfileForm";
import { useEffect, useState } from "react";
import { produce } from "immer";
import ProfileIndivList from "./ProfileIndivList";
import Card from "../UI/Card";
import { Interrogation, PlusBank } from "../UI/Icons";
import useProfileNoms from "../../hooks/use-profile-noms";
import { useNavigate } from "react-router-dom";
import Tooltip from "../UI/Tooltip";
import { handleAPIError, TextOneOrMany } from "../../utils";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import useAuth from "../../hooks/use-auth";
import MandataireFormFields from "../Account/MandataireFormFields";
import useAccount from "../../hooks/use-account";
import useMandataire from "../../hooks/use-mandataire";
import TextArea from "../UI/TextArea";

function ProfileNomForm({ profileNom, edit }) {
  const [Modal] = useModal(true);
  const { auth } = useAuth();
  const [AddProfileModal, closeAddProfileModal, openAddProfileModal] =
    useModal();
  const [profileIndexToEdit, setProfileIndexToEdit] = useState(null);
  const [profileToPush, setProfileToPush] = useState([]);
  const { updateProfileNom, addProfileNom, deleteProfileNom } =
    useProfileNoms();
  const nav = useNavigate();
  const [error, setError] = useState("");
  const { account } = useAccount();
  const { mandataire } = useMandataire();

  useEffect(() => {
    if (profileNom) {
      setProfileToPush(profileNom.profiles);
    }
  }, [profileNom]);

  if (!account) return null;

  async function submitHandler(values, { setSubmitting }) {
    const sanitizedValues = { ...sanitizeValues(values) };
    const profile = produce(sanitizedValues, (draft) => {
      draft.profiles = [];
      profileToPush.forEach((profile, index) => draft.profiles.push(profile));
    });
    try {
      if (edit) {
        await updateProfileNom(parseInt(profile.id), profile);
      } else {
        await addProfileNom(profile);
      }
      setError("");
      nav("../");
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }

  function updateProfile(profile, index) {
    setProfileToPush((profiles) => {
      const newProfiles = [...profiles];
      newProfiles[index] = profile;
      return newProfiles;
    });
  }

  function addProfile(profile) {
    setProfileToPush((profiles) => [...profiles, profile]);
  }

  let validationSchema = profileNomNomSchema;
  let initialValues = toInitialValuesFromSchema(
    profileNomNomSchema,
    profileNom,
    PROFILE_NOM_INITIAL_VALUE,
  );
  if (account.home === "/pro") {
    validationSchema = profileNomNomMandataireSchema;
    initialValues.remuneration_type = mandataire?.remuneration_type;
    initialValues.remuneration_montant = mandataire?.remuneration_montant;
    initialValues.mandataire_tva = mandataire?.tva;
  }
  return (
    <>
      <ProfileForm
        modal={AddProfileModal}
        onProfileSubmitted={(profile) => {
          if (profileIndexToEdit !== null) {
            updateProfile(profile, profileIndexToEdit);
            setProfileIndexToEdit(null);
          } else {
            addProfile(profile);
          }
          closeAddProfileModal();
        }}
        isBailleur
        addTenant
        profile={
          profileIndexToEdit !== null
            ? profileToPush[profileIndexToEdit]
            : undefined
        }
        edit
        isProfile
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {() => (
          <Modal>
            <Form>
              <TextInput label="Nom du profil" name="nom_profile" />
              {account.home === "/pro" && <MandataireFormFields />}
              <ProfileIndivList
                profiles={profileToPush}
                editProfileHandler={(profileId, index) => {
                  setProfileIndexToEdit(index);
                  openAddProfileModal();
                }}
                deleteProfileHandler={(profileId, index) => {
                  setProfileToPush((curr) =>
                    curr.filter((prof, i) => {
                      return prof.id !== profileId && i !== index;
                    }),
                  );
                }}
                dontAddGarant
                isBailleur
              />
              <Card
                className="flex align-items-center marginB10 marginT10"
                onClick={openAddProfileModal}
              >
                <PlusBank />
                <span className="marginL5">
                  <TextOneOrMany
                    one={"Ajouter un profil propriétaire"}
                    many={"Ajouter un co-propriétaire"}
                    number={profileToPush.length + 1}
                  />
                </span>
              </Card>
              <TextInput
                name={"cc_mail"}
                placeholder={"gestionaire1@qalimo.fr,gestionaire2@qalimo.fr"}
                label={
                  <>
                    Adresses e-mail en copie{" "}
                    <Tooltip
                      content={
                        "Indiquez ici, séparés par une virgule, les adresses mail qui seront en copie de tous les mails envoyés. A noter que pour les signatures électroniques, c'est le mail principal des profils qui sera utilisé."
                      }
                    >
                      <Interrogation />
                    </Tooltip>
                  </>
                }
              />
              <TextArea
                type="text"
                name="notes"
                label="Notes"
                rows="4"
                placeholder="Identifiant CAF, VISAL, information concernant ce profil, etc."
              />

              <div className={"text-right"}>
                {auth.userId === profileNom?.id_compte && (
                  <Button
                    type="button"
                    className={"marginR10"}
                    buttonStyle="secondaryDelete"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Voulez-vous vraiment supprimer ce profil ?",
                        )
                      ) {
                        deleteProfileNom(profileNom.id);
                        nav("../");
                      }
                    }}
                  >
                    Supprimer
                  </Button>
                )}

                {profileToPush.length < 1 ? (
                  <Tooltip
                    content={
                      "Vous devez ajouter au moins un profil propriétaire."
                    }
                    direction="left"
                  >
                    <Button disabled>Valider</Button>
                  </Tooltip>
                ) : (
                  <FormikSubmitButton>Valider</FormikSubmitButton>
                )}
              </div>
              {error && (
                <Card
                  type={"error"}
                  className={"padding10 text-center marginT10"}
                >
                  {error}
                </Card>
              )}
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
}

export default ProfileNomForm;
