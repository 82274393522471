import { sendToBackend } from "./index";

export async function fetchProfiles(token) {
  try {
    const res = await sendToBackend("profiles", token);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateProfileIndiv(token, profileId, data) {
  const response = await sendToBackend(`profiles-indiv/${profileId}`, token, {
    method: "PUT",
    data,
  });
  return response.data;
}

export async function createProfileIndiv(token, data) {
  const response = await sendToBackend("profiles-indiv", token, {
    method: "POST",
    data,
  });
  return response.data;
}

export async function deleteProfileIndiv(token, profileId) {
  const response = await sendToBackend(`profiles-indiv/${profileId}`, token, {
    method: "DELETE",
  });
  return response.data;
}
