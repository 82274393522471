import Card from "../../../UI/Card";
import Select from "../../../UI/Select";
import TextInput from "../../../UI/TextInput";

export default function CongeReprise() {
  return (
    <>
      <Card className={"marginB10"} type={"warning"}>
        Attention : un congé ne peut avoir lieu qu'a l'échénace du bail. il doit
        être envoyé :
        <ul>
          <li>
            Pour un logement meublé, au moins 3 mois avant l'échéance du bail
          </li>
          <li>Pour un logement nu, au moins 6 mois avant l'échéance du bail</li>
        </ul>
        Un congé pour reprise ne peut être délivré que si le nouvel occupant est
        de la famille proche (voir liste déroulante)
      </Card>

      <TextInput
        type={"text"}
        name={"nom"}
        label={"Prénom et NOM du prochain occupant"}
        placeholder={"Jean DUPONT"}
      />
      <TextInput
        type={"text"}
        name={"adresse"}
        label={"Adresse complète du prochain occupant"}
        placeholder={"30 rue de la fontaine, 14000 Caen"}
      />
      <Select name={"lien_parente"}>
        <option value={""}>
          Choisissez un lien de parenté avec le prochain occupant
        </option>
        <option value={"le propriétaire, lui même"}>
          Le propriétaire, lui même
        </option>
        <option
          value={
            "conjoint, partenaire dans un pacte civil de solidarité, ou concubin du propriétaire"
          }
        >
          Son conjoint, son partenaire dans un pacte civil de solidarité ou son
          concubin
        </option>
        <option value={"ascendant du propriétaire"}>Son ascendant</option>
        <option value={"descendant du propriétaire"}>Son descendant</option>
        <option
          value={
            "descendant du conjoint, partenaire de Pacs, ou concubin du propriétaire"
          }
        >
          Descendant de son conjoint, partenaire de Pacs ou concubin
        </option>
      </Select>
    </>
  );
}
