export class Env {
  static PROD = "prod";
  static PREPROD = "preprod";
  static DEV = "dev";

  static get() {
    return process.env.REACT_APP_ENV;
  }

  static isDev() {
    return this.get() === this.DEV;
  }
  static isPreprod() {
    return this.get() === this.PREPROD;
  }
  static isProd() {
    return this.get() === this.PROD;
  }
}
