import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { object, ref, string } from "yup";
import TextInput from "../UI/TextInput";
import styles from "./AuthForm.module.css";
import useAuth from "../../hooks/use-auth";
import { axiosPublic } from "../../api";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function AuthFormForgottenPasswordReconf() {
  const { buildAndSetAuth } = useAuth();
  const nav = useNavigate();
  const { token } = useParams();

  async function resetPassword(values) {
    try {
      const data = {
        password: values.password,
      };
      const response = await axiosPublic.put("/init-password", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      buildAndSetAuth(response.data);

      nav("/");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Formik
      className={styles.form}
      initialValues={{ mail: "", password: "" }}
      validationSchema={object({
        password: string().required(" "),
        dPassword: string()
          .oneOf([ref("password")], "Les mots de passe doivent être identiques")
          .required(" "),
      })}
      onSubmit={resetPassword}
    >
      <Form className={styles.form}>
        <TextInput type="password" name="password" placeholder="Mot de passe" />
        <TextInput
          type="password"
          name="dPassword"
          placeholder="Mot de passe"
        />
        <FormikSubmitButton>Valider</FormikSubmitButton>
      </Form>
    </Formik>
  );
}

export default AuthFormForgottenPasswordReconf;
