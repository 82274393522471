import { useRef } from "react";
import style from "./AddNewCheckbox.module.css";
import { Check } from "./Icons";

function AddNewCheckbox({ addItem }) {
  function handleClick() {
    if (inputRef.current.value !== "") {
      addItem(inputRef.current.value);
      inputRef.current.value = "";
    }
  }

  const inputRef = useRef();
  const buttonRef = useRef();

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      buttonRef.current.click();
      e.preventDefault();
    }
  }

  return (
    <div className={style.container}>
      <input
        type="text"
        name="new"
        ref={inputRef}
        placeholder="Ajouter"
        onKeyDown={handleKeyDown}
      />
      <button type="button" onClick={handleClick} ref={buttonRef}>
        <Check width="25" height="25" color="green" />
      </button>
    </div>
  );
}

export default AddNewCheckbox;
