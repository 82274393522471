import styles from "./Editor.module.css";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "react-quill";
import Quill from "quill";
import "./CustomSvgBlot";
import "./CustomImageBlot";

export default function Editor({ value, onChange }) {
  const modules = {};
  const Parchment = Quill.import("parchment");
  const Check = new Parchment.Attributor.Attribute("class", "class", {
    scope: Parchment.Scope.BLOCK,
  });
  Quill.register(Check);
  modules.toolbar = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"], // blocks
    [{ list: "ordered" }, { list: "bullet" }], // lists
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults
    [{ align: [] }], // text align
  ];

  return (
    <>
      <div>
        <QuillEditor
          className={styles.editor}
          theme="snow"
          value={value}
          onChange={(value) => onChange(value)}
          modules={modules}
        />
      </div>
    </>
  );
}
