import { Form, Formik } from "formik";
import { toInitialValuesFromSchema } from "../../models/utils";
import { useState } from "react";
import Card from "../UI/Card";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { handleAPIError } from "../../utils";
import { actionIV, actionValidationSchema } from "../../models/action";
import TextInput from "../UI/TextInput";

export default function ActionForm({ action, ModalElement, onSubmit }) {
  const [error, setError] = useState("");

  const initialValues = toInitialValuesFromSchema(
    actionValidationSchema,
    action,
    actionIV,
  );

  async function submitHandler(values, { resetForm }) {
    setError("");
    try {
      await onSubmit(values);
      resetForm();
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={actionValidationSchema}
      onSubmit={submitHandler}
    >
      {({ resetForm }) => (
        <ModalElement
          size={"small"}
          onClose={() => {
            resetForm();
            setError("");
          }}
        >
          <p className={"cardTitle text-center marginB20"}>
            <img
              src={`/photo_balance.png`}
              alt="Balance locataire"
              className={"center"}
            />
            Modifier la date d'exécution
          </p>
          <Form>
            <TextInput
              type={"date"}
              name={"action_date"}
              label={"Date de la prochaine exécution"}
            />
            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card
                type={"error"}
                className={"padding10 text-center marginT10"}
              >
                {error}
              </Card>
            )}
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
