import useProperties from "../../hooks/use-properties";
import { Fragment, useState } from "react";
import Checkbox from "../UI/Checkbox";
import { Form, Formik } from "formik";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { array, number, object } from "yup";
import Card from "../UI/Card";
import StandaloneTextInputGroup from "../UI/StandaloneTextInputGroup";
import { handleAPIError } from "../../utils";

export default function DuplicateRooms({ propertyId, onSubmit, ModalElement }) {
  const { properties } = useProperties({
    params: { include: ["edl_items"] },
  });
  const [filter, setFilter] = useState("");
  const [error, setError] = useState("");

  return (
    <Formik
      initialValues={{ pieces: [] }}
      validationSchema={object({
        pieces: array()
          .of(number().integer())
          .min(1, "Veuillez sélectionner au moins une pièce"),
      })}
      onSubmit={async (values) => {
        setError("");
        try {
          await onSubmit(values.pieces.map((id) => parseInt(id)));
        } catch (e) {
          setError(handleAPIError(e));
        }
      }}
    >
      {({ errors, resetForm }) => (
        <ModalElement
          size={"big"}
          onClose={() => {
            resetForm();
            setError("");
          }}
        >
          <Form>
            <div className={"flex space-between wrap gap10 align-items-center"}>
              <h2>Liste des biens et leurs pièces</h2>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            <p className={"marginB20"}>
              Sélectionnez les pièces que vous souhaitez importer dans ce bien.
            </p>
            <StandaloneTextInputGroup
              placeholder={"Filtrer par bien"}
              onChange={(e) => setFilter(e.target.value.toLowerCase())}
              className={"flex justify-content-end marginB20"}
            />
            {(errors.pieces || error) && (
              <Card className={"marginT10"} padding={"md"} type={"error"}>
                {errors.pieces || error}
              </Card>
            )}
            {properties
              .filter(
                (prop) =>
                  prop.id !== propertyId &&
                  prop.nom.toLowerCase().includes(filter),
              )
              .map((property, i) => (
                <Fragment key={i}>
                  <p
                    className={
                      "border-blue primaryBlue w-100 marginT10 marginB10"
                    }
                  >
                    {property.nom}
                  </p>
                  <div className={"flex wrap gap10 "}>
                    {property.edl_items?.map((edlItem, j) => (
                      <Checkbox
                        name="pieces"
                        pillStyle
                        className="marginL5"
                        value={edlItem.id.toString()}
                        key={j}
                      >
                        {edlItem.nom}
                      </Checkbox>
                    ))}
                  </div>
                </Fragment>
              ))}
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
