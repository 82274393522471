export default function StyledForm({
  label,
  icon,
  formElement,
  moreText,
  mandatory,
  className,
}) {
  return (
    <>
      <div className={`flex ${className}`}>
        <div>{icon}</div>
        <div className={"w-100 flex-grow marginL10"}>
          <label className={"primaryText flex"}>
            {label} {mandatory && <span className={"blueStar"}>*</span>}
          </label>
          <div className={"w-100 border-blue marginT5"}></div>
          {moreText && <div className={"marginT5"}>{moreText}</div>}
          <div className={"marginT10"}>{formElement}</div>
        </div>
      </div>
    </>
  );
}
