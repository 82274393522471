import useResources from "./use-resources";
import { findAndUpdate } from "../utils";

const ROOT_PATH = "processes";

export default function useProcesses() {
  const {
    resources: processes,
    error,
    isLoading,
    create,
    update,
    del,
    axios,
    mutate,
  } = useResources(ROOT_PATH);

  async function updateProcessEntry(processId, entry) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.put(
          `${ROOT_PATH}/${processId}/entries`,
          entry,
        );
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === processId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }

  return {
    activeProcesses: processes.filter((process) => process.is_active),
    archivedProcesses: processes.filter((process) => !process.is_active),
    error,
    isLoading,
    createProcess: create,
    updateProcess: update,
    updateProcessEntry,
    deleteProcess: del,
  };
}
