import style from "./DescriptionFinance.module.css";
import {
  Charges,
  Clock,
  Deposit,
  Lease,
  Loyer,
  ZoneTendue,
} from "../../UI/Icons";
import DisplayItemIcon from "../../UI/DisplayItemIcon";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import DescriptionFinanceForm from "./DescriptionFinanceForm";
import { useModal } from "../../../hooks/use-modal";
import useLeaseTemplates from "../../../hooks/use-lease-templates";
import { currencyFormatter } from "../../../api/Functions";

export const ROOT_PATH = "/bailleur/properties/";

function DescriptionFinance({ property, allowEdit }) {
  const [FormModal, closeFormModal, openFormModal] = useModal();
  const [ZoneTendueModal, , openZoneTendueModal] = useModal();
  const { leaseTemplates } = useLeaseTemplates();

  if (!property) return null;
  if (!leaseTemplates) return null;
  const leaseTemplate = leaseTemplates.find(
    (lease) => property.id_lease_template === lease.id,
  );
  return (
    <div>
      <FormModal size={"big"}>
        <DescriptionFinanceForm
          property={property}
          closeModal={closeFormModal}
        />
      </FormModal>
      <ZoneTendueModal>
        <p className={"marginT5"}>
          {property.loyer_reference
            ? "Loyer de reference majoré fixé par arrêté prefectoral"
            : "Pas de loyer de reference"}
        </p>
        <p className={"marginT5"}>
          {property.loyer_reference &&
            "Montant du loyer de référence pour la zone " +
              currencyFormatter(property.montant_loyer_ref) +
              "/m²"}
        </p>
        <p className={"marginT5"}>
          {property.loyer_reference &&
            "Montant du loyer de référence majoré pour la zone " +
              currencyFormatter(property.montant_loyer_ref_maj) +
              "/m²"}
        </p>
        <p className={"marginT5"}>
          {property.loyer_reference &&
            "Complément de loyer prévu : " +
              currencyFormatter(property.complement_loyer)}
        </p>
        <p className={"marginT5"}>
          {property.loyer_reference &&
            "Justificatif complement loyer : " +
              property.justif_complement_loyer}
        </p>
      </ZoneTendueModal>
      <div className={`flex space-between align-items-center`}>
        <span className={"cardTitle"}>Conditions du bail</span>
        {allowEdit && (
          <Button
            className={"marginL5 push"}
            onClick={openFormModal}
            buttonStyle={"secondary"}
          >
            Modifier
          </Button>
        )}
      </div>
      <div className={style.gridFinance}>
        <DisplayItemIcon
          icon={<Loyer />}
          title={currencyFormatter(property.loyer_hc) || "Non renseigné"}
          className={"marginT10"}
          content={"Montant du loyer hors charge"}
        />
        <DisplayItemIcon
          icon={<Charges />}
          title={currencyFormatter(property.charges) || "Non renseigné"}
          className={"marginT10"}
          content={
            property.type_charge === "forfait"
              ? "Montant du forfait de charge"
              : "Montant des provisions sur charge"
          }
        />
        {leaseTemplate?.type !== "mobilite" && (
          <DisplayItemIcon
            icon={<Deposit />}
            title={currencyFormatter(property.deposit) || "Non renseigné"}
            className={"marginT10"}
            content={"Montant du dépôt de garantie"}
          />
        )}
        <DisplayItemIcon
          icon={<Lease />}
          className={"marginT10"}
          title={leaseTemplate ? leaseTemplate.nom : "Aucun bail selectionné"}
          content={`Type de bail ${property.tva ? "avec TVA" : ""}`}
        />
        <DisplayItemIcon
          icon={<Clock />}
          className={"marginT10"}
          title={"Le " + property.day_payment + " du mois"}
          content={"Date de paiement du loyer"}
        />
        {property.type === "habitation" ? (
          <DisplayItemIcon
            icon={<ZoneTendue />}
            title={
              property.zone_tendue ? (
                <Link onClick={openZoneTendueModal}>Zone tendue</Link>
              ) : (
                "Zone non tendue"
              )
            }
            className={"marginT10"}
            content={"Type de zone"}
          />
        ) : (
          <DisplayItemIcon
            icon={<ZoneTendue />}
            title={property.type_indice ?? "Aucun"}
            className={"marginT10"}
            content={"Indice indexation"}
          />
        )}
      </div>
    </div>
  );
}

export default DescriptionFinance;
