import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import { leaseTemplateValidationSchema } from "../../models/leaseTemplate";
import TextArea from "../UI/TextArea";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function LeaseTemplateForm({ initialValues, onSubmit }) {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={leaseTemplateValidationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <TextInput type="text" name="nom" label={"Nom"} mandatory />
          <TextInput
            type="number"
            name="duree"
            label={"Durée"}
            mandatory
            unit={"an(s)"}
          />
          <TextInput type="text" name="type" label={"Type de bail"} mandatory />
          <TextArea name={"content"}></TextArea>
          <div className={"text-right"}>
            <FormikSubmitButton>Valider</FormikSubmitButton>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default LeaseTemplateForm;
