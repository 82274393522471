import Card from "../UI/Card";
import React from "react";
import useAutoActions from "../../hooks/use-auto-actions";
import useTenantAutoActions from "../../hooks/use-tenant-auto-actions";
import Action from "./Action";
import { AutoActionType } from "../../utils/constants/autoActions";

export default function ActionList({ tenant }) {
  const { autoActions } = useAutoActions();
  const {
    tenantAutoActions,
    createTenantAutoAction,
    deleteTenantAutoAction,
    updateTenantAutoAction,
    refresh,
  } = useTenantAutoActions(tenant.id);

  async function onToggle(e, action, tenantAutoAction) {
    try {
      if (e.target.checked) {
        await createTenantAutoAction({
          nom_auto_action: action.static_id,
        });
        refresh();
      } else {
        await deleteTenantAutoAction(tenantAutoAction?.id);
        refresh();
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Card>
      <p className={"cardTitle marginB10"}>Gérer les automatisations</p>
      <img src={"/parametres.png"} alt={"paramètres"} className={"center"} />
      <p className={"text-center secondaryText marginB20 marginT10"}>
        Gérez les notifications, documents, emails et courriers envoyés
        automatiquement à votre locataire ou pour vous prévenir d’une échéance.
      </p>
      {autoActions?.map((action, i) => {
        const tenantAutoAction = tenantAutoActions.find(
          (filtered) => filtered.nom_auto_action === action.static_id,
        );
        let checked = !!tenantAutoAction;
        return (
          <Action
            action={action}
            checked={checked}
            tenantAutoAction={tenantAutoAction}
            onUpdate={updateTenantAutoAction}
            onToggle={onToggle}
            key={i}
            disabled={action.static_id === AutoActionType.APPELS_LOYER}
          />
        );
      })}
    </Card>
  );
}
