import useResources from "./use-resources";

export default function useMailLogs() {
  const {
    resources: mailLogs,
    error,
    isLoading,
  } = useResources("account/mail-logs");

  return {
    mailLogs,
    error,
    isLoading,
  };
}
