import Menu from "../../components/Menu/Menu.js";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import useProperties from "../../hooks/use-properties";
import { fetchBalanceTenants } from "../../api/Balance_tenants";
import useAuth from "../../hooks/use-auth";

function RootPage() {
  const [balances, setBalances] = useState([]);
  const { properties } = useProperties();

  const { auth } = useAuth();

  useEffect(() => {
    (async () => {
      setBalances(await fetchBalanceTenants(auth.accessToken));
    })();
  }, [auth.accessToken]);

  const outletContext = {
    properties,
    balances,
  };

  return (
    <>
      <Menu isBailleur />
      <Outlet context={outletContext} />
    </>
  );
}

export default RootPage;
