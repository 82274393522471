import useSWR from "swr";
import useAuthenticatedAxios from "./use-authenticated-axios";
import useAuth from "./use-auth";

export default function useResource(rootPath, options = {}) {
  const axios = useAuthenticatedAxios();
  const { auth } = useAuth();
  const {
    data: resource,
    error,
    isLoading,
    mutate,
  } = useSWR(
    rootPath ? { url: rootPath, options, userId: auth.userId } : null,
    ({ url, options }) => {
      return axios.get(url, { params: options.params }).then((res) => res.data);
    },
    { fallbackData: null },
  );

  async function create(resource) {
    let newResource;
    await mutate(
      async () => {
        const response = await axios.post(rootPath, resource);
        newResource = response.data;
        return newResource;
      },
      { revalidate: false },
    );
    return newResource;
  }

  function del(resourceId) {
    return mutate(
      async () => {
        await axios.delete(`${rootPath}/${resourceId}`);
        return null;
      },
      { revalidate: false },
    );
  }

  async function update(resourceId, resource) {
    let newResource;
    await mutate(
      async () => {
        const response = await axios.put(`${rootPath}/${resourceId}`, resource);
        newResource = response.data;
        return newResource;
      },
      { revalidate: false },
    );
    return newResource;
  }

  return {
    resource,
    error,
    isLoading,
    create,
    update,
    del,
    mutate,
    axios,
  };
}
