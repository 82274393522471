import { Link } from "react-router-dom";

import styles from "./ButtonLink.module.css";

function ButtonLink({ to, children, buttonStyle, className }) {
  return (
    <Link
      to={to}
      className={`${styles.button} ${styles[buttonStyle]} ${className}`}
    >
      {children}
    </Link>
  );
}

export default ButtonLink;
