import { mixed, object, ref, string } from "yup";
import moment from "moment";

export const eventSchema = object({
  debut: mixed()
    .transform((value) => moment(value, "YYYY-MM-DDTHH:mm"))
    .required("Date de début requise"),
  fin: mixed()
    .transform((value) => moment(value, "YYYY-MM-DDTHH:mm"))
    .test(
      "validate-fin",
      "La date de fin doit être après la date de début",
      function (value) {
        return !value || value.isAfter(this.resolve(ref("debut")));
      },
    )
    .required("Date de fin requise"),
  subject: string().required("Description requise"),
});

export const eventIV = {
  debut: "",
  fin: "",
  subject: "",
};
