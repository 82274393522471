import React, { Fragment, useState } from "react";
import { Selectable } from "../UI/Selectable";
import useProperties from "../../hooks/use-properties";
import useTenants from "../../hooks/use-tenants";
import useTransactions from "../../hooks/use-transactions";
import { currencyFormatter, dateConvert } from "../../api/Functions";
import { Table } from "../UI/Table";
import Card from "../UI/Card";
import InitialesLogo from "../UI/InitialesLogo";
import { useModal } from "../../hooks/use-modal";
import TransactionSplitForm from "./TransactionSplitForm";
import Button from "../UI/Button";
import useFinanceSplitTemplates from "../../hooks/use-finance-split-templates";
import { ReactSelectStandalone } from "../UI/SelectStandalone";
import { DotsAction, Plus } from "../UI/Icons";
import Dropdown from "../UI/Dropdown";
import ButtonCard from "../UI/ButtonCard";
import useDebouncedValue from "../../hooks/use-debounced-value";
import { BalanceDomain } from "../../utils/constants/balances";
import usePropertyFolders from "../../hooks/use-property-folders";
import TextInputStandalone from "../UI/TextInputStandalone";

function TransactionList({ banks }) {
  const [pageIndex, setPageIndex] = useState(1);
  const [transaction, setTransaction] = useState(null);
  const [shouldPrefillSplit, setShouldPrefillSplit] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [filterSearch, setFilterSearch] = useState("");
  const debouncedFilterSearch = useDebouncedValue(filterSearch);
  const [account, setAccount] = useState("");
  const [isBatchLoading, setIsBatchLoading] = useState(false);
  const {
    transactions,
    deleteMultipleTransactions,
    createTransactionBalances,
  } = useTransactions({
    params: {
      page: pageIndex,
      per_page: perPage,
      search: debouncedFilterSearch,
      account: account,
    },
  });
  const { properties } = useProperties();
  const { propertyFolders } = usePropertyFolders();

  const { tenants } = useTenants();
  const { splitTemplates, updateFinanceSplitTemplate } =
    useFinanceSplitTemplates();
  const [SplitModal, closeSplitModal, openSplitModal] = useModal();

  function tenantLogo(tenantId) {
    const tenant = tenants.find((tenant) => tenant.id === tenantId);
    return (
      tenant && (
        <InitialesLogo
          nom={tenant.profile_nom.nom_profile}
          shape="rond"
          hover
        />
      )
    );
  }

  async function splitFormSubmitHandler(newTransaction) {
    if (newTransaction.update_template) {
      await updateFinanceSplitTemplate(parseInt(newTransaction.template), {
        splits: newTransaction.balances,
      });
    }
    await createTransactionBalances(transaction.id, newTransaction.balances);
    closeSplitModal();
    setTransaction(null);
    setShouldPrefillSplit(false);
  }

  const accountOptions = banks.map((option) => ({
    value: option.unique_id,
    label: option.nom ? option.nom : option.bank_metadata.name,
  }));

  const lignesPerPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "100",
      label: "100",
    },
    {
      value: "500",
      label: "500",
    },
  ];

  return (
    <>
      <SplitModal size={"ultra"}>
        <TransactionSplitForm
          transaction={transaction}
          shouldPrefillSplit={shouldPrefillSplit}
          splitTemplates={splitTemplates}
          onSubmit={splitFormSubmitHandler}
        />
      </SplitModal>

      <Card>
        <div className="flex wrap gap10 align-items-start">
          <ReactSelectStandalone
            className={"marginB10"}
            name="bank"
            onChange={(option) => setAccount(option?.value ?? null)}
            options={accountOptions}
            label={"Filtrer par compte"}
            placeholder={"Crédit mutuel"}
            isClearable
          />
          <ReactSelectStandalone
            className={"marginB10"}
            name="perPage"
            onChange={(option) => setPerPage(option?.value ?? null)}
            defaultValue={lignesPerPageOptions[1]}
            options={lignesPerPageOptions}
            label={"Lignes par page"}
          />
          <TextInputStandalone
            placeholder={"Virement de Dupont"}
            onChange={(e) => setFilterSearch(e.target.value)}
            label={"Rechercher par mot-clé"}
          />
        </div>
        <Selectable items={transactions}>
          <Table>
            <Table.Header>
              <Table.Column>
                <Selectable.Checkbox selectAll />
              </Table.Column>
              <Table.Column>Date</Table.Column>
              <Table.Column>Description</Table.Column>
              <Table.Column align={"right"}>Montant</Table.Column>
              <Table.Column align={"center"}>Affectation</Table.Column>
              <Table.Column>Action</Table.Column>
            </Table.Header>
            <Table.Body>
              {transactions.map((transaction, i) => {
                const isTransaction = !transaction.id_transaction;
                const isSplit =
                  isTransaction &&
                  (transaction.balances.length > 1 ||
                    (transaction.balances.length === 1 &&
                      transaction.balances[0].montant !== transaction.montant));

                function getAffectButton(balance) {
                  let affectButtonContent;
                  if (!balance) {
                    affectButtonContent = <Plus />;
                  } else if (balance.domaine === BalanceDomain.PROPERTY) {
                    affectButtonContent = properties.find(
                      (prop) => prop.id === balance.id_bien,
                    )?.nom;
                  } else if (balance.domaine === BalanceDomain.FOLDER) {
                    affectButtonContent = propertyFolders.find(
                      (folder) => folder.id === balance.id_property_folder,
                    )?.nom;
                  } else if (balance.domaine === BalanceDomain.TENANT) {
                    affectButtonContent = tenantLogo(balance.id_tenant);
                  }
                  return (
                    <ButtonCard
                      onClick={() => {
                        setTransaction(transaction);
                        setShouldPrefillSplit(true);
                        openSplitModal();
                      }}
                      padding={"xs"}
                      border={"none"}
                      className={"secondaryText"}
                    >
                      {affectButtonContent}
                    </ButtonCard>
                  );
                }

                return (
                  <Fragment key={i}>
                    <Table.Row>
                      <Table.Cell>
                        {isTransaction && (
                          <Selectable.Checkbox
                            value={transaction.id}
                            index={i}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isTransaction && dateConvert(transaction.date_transac)}
                      </Table.Cell>
                      <Table.Cell>
                        {isTransaction
                          ? transaction.description
                          : transaction.comment}
                      </Table.Cell>
                      <Table.Cell align={"right"}>
                        {currencyFormatter(transaction.montant)}
                      </Table.Cell>
                      <Table.Cell align={"center"}>
                        {!isSplit && getAffectButton(transaction.balances[0])}
                      </Table.Cell>
                      <Table.Cell align={"center"}>
                        {isTransaction && (
                          <Dropdown
                            header={<DotsAction />}
                            type={"click"}
                            body={
                              <div
                                onClick={() => {
                                  setTransaction(transaction);
                                  setShouldPrefillSplit(false);
                                  openSplitModal();
                                }}
                                className={"secondaryText"}
                              >
                                Découper
                              </div>
                            }
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                    {isSplit &&
                      transaction.balances.map((balance, j) => (
                        <Table.Row key={j}>
                          <Table.Cell />
                          <Table.Cell>
                            {dateConvert(transaction.date_transac)}
                          </Table.Cell>
                          <Table.Cell>{balance.comment}</Table.Cell>
                          <Table.Cell align={"right"}>
                            {currencyFormatter(balance.montant)}
                          </Table.Cell>
                          <Table.Cell align={"center"}>
                            {getAffectButton(balance)}
                          </Table.Cell>
                          <Table.Cell />
                        </Table.Row>
                      ))}
                  </Fragment>
                );
              })}
            </Table.Body>
          </Table>

          <div className={"flex wrap marginT20"}>
            <div className="marginB10 marginR10">
              <Selectable.ActionButton
                disabled={isBatchLoading}
                isLoading={isBatchLoading}
                resetOnClick
                onClick={async (selectedItems) => {
                  try {
                    setIsBatchLoading(true);
                    await deleteMultipleTransactions(selectedItems);
                  } catch (e) {
                    console.error(e);
                  }
                  setIsBatchLoading(false);
                }}
              >
                Supprimer
              </Selectable.ActionButton>
            </div>
          </div>
          <div className="text-right">
            <Button onClick={() => setPageIndex(pageIndex - 1)}>
              Précédent
            </Button>
            <Button
              onClick={() => setPageIndex(pageIndex + 1)}
              className={"marginL10"}
            >
              Suivant
            </Button>
          </div>
        </Selectable>
      </Card>
    </>
  );
}

export default TransactionList;
