import { useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import { toNestedStructure } from "../models/utils";
import isEqual from "react-fast-compare";
import useResources from "./use-resources";

export function useProfilesIndiv(initialProfiles, candidat) {
  const initialProfilesRef = useRef(initialProfiles);
  const [profiles, setProfiles] = useImmer(initialProfiles || []);
  const shouldFetch = initialProfiles === undefined || initialProfiles === null;
  const structProfiles = toNestedStructure(
    profiles,
    "garantor_of",
    "garantors",
  );

  const {
    resources: fetchedProfiles,
    isLoading,
    error,
  } = useResources(
    shouldFetch ? `profiles-indiv${candidat ? "?candidat=1" : ""}` : null,
  );

  useEffect(() => {
    // fetch mode : profiles come from the API directly, we fetch them at first render
    if (shouldFetch && !isLoading && fetchedProfiles) {
      setProfiles(fetchedProfiles);
    }
  }, [fetchedProfiles, isLoading, setProfiles, shouldFetch]);

  useEffect(() => {
    // no fetch mode : profiles come from hook param initialProfiles, we update whenever it changes
    if (!shouldFetch && !isEqual(initialProfilesRef.current, initialProfiles)) {
      initialProfilesRef.current = initialProfiles;
      setProfiles(initialProfiles);
    }
  }, [initialProfiles, profiles, setProfiles, shouldFetch]);

  function addOrUpdateProfile(profile) {
    setProfiles((profiles) => {
      const profileIndex = profiles.findIndex((prof) => prof.id === profile.id);
      if (profileIndex === -1) {
        profiles.push(profile);
      } else {
        profiles[profileIndex] = profile;
      }
    });
  }

  function attachGarantor(profileId, garantId) {
    setProfiles((currentProfiles) => {
      currentProfiles.find((profile) => profile.id === garantId).garantor_of =
        profileId;
    });
  }

  function deleteProfile(profileId) {
    setProfiles((currentProfiles) => {
      return currentProfiles.filter(
        (profile) =>
          profile.id !== profileId && profile.garantor_of !== profileId,
      );
    });
  }

  function detachGarantor(garantId) {
    setProfiles((currentProfiles) => {
      currentProfiles.find((profile) => profile.id === garantId).garantor_of =
        null;
    });
  }

  return {
    profiles,
    structProfiles,
    addOrUpdateProfile,
    attachGarantor,
    deleteProfile,
    detachGarantor,
    isLoading,
    error,
  };
}
