import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";

import styles from "./TextArea.module.css";
import { classnames } from "../../utils";

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const classes = `${styles.control} ${
    meta.error && meta.touched ? styles.error : ""
  }`;

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <div className={classes}>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <br />
      <textarea
        {...field}
        {...props}
        className={classnames("margin-form", styles.textInput)}
      />
      {meta.touched && meta.error && <div>{meta.error}</div>}
    </div>
  );
};

export default TextArea;
