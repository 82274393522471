import { useOutletContext, useParams } from "react-router-dom";
import ProfileForm from "../../components/Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";

function ProfileAddPage({
  isGarant,
  isBailleur,
  isCandidat,
  withMandatoryFields,
}) {
  const { profileId, profileNomId } = useParams();
  const [Modal, closeModal] = useModal(true);

  const { profileSubmittedHandler } = useOutletContext();

  return (
    <ProfileForm
      profileId={parseInt(profileId)}
      profileNomId={parseInt(profileNomId)}
      isGarant={isGarant}
      isCandidat={isCandidat}
      onProfileSubmitted={(profile) => {
        profileSubmittedHandler(profile, isCandidat && !isGarant);
        closeModal();
      }}
      isBailleur={isBailleur}
      modal={Modal}
      withMandatoryFields={withMandatoryFields}
    />
  );
}

export default ProfileAddPage;
