import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";
import SelectStandalone, { ReactSelectStandalone } from "./SelectStandalone";

export default function Select({ className = "marginT10", ...props }) {
  const [field, meta] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <SelectStandalone
      className={className}
      isInvalid={meta.error && meta.touched && !props.disabled}
      error={meta.error}
      {...field}
      {...props}
    />
  );
}

export function ReactSelect({ options, onChange, value: valueProp, ...props }) {
  const [
    { value, onChange: _, onBlur, ...field },
    meta,
    { setValue, setTouched },
  ] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  // Take all ungrouped options
  const allOptions = options.filter((option) => !!option.value);
  for (let groupedOptions of options.filter((option) => !!option.options)) {
    allOptions.push(...groupedOptions.options);
  }

  return (
    <ReactSelectStandalone
      isClearable
      options={options}
      isInvalid={meta.error && meta.touched && !props.disabled}
      error={meta.error}
      value={
        allOptions.find(
          (option) => option.value.toString() === (valueProp ?? value),
        ) ?? null
      }
      onChange={(option) => {
        if (onChange) onChange(option);
        return setValue(option?.value.toString() ?? "");
      }}
      onBlur={(e) => {
        onBlur(e);
        return setTouched(true);
      }}
      {...field}
      {...props}
    />
  );
}
