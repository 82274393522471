import { createContext, useCallback, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({});

  const buildAndSetAuth = useCallback((data) => {
    setAuth({
      accessToken: data.access_token,
      userId: data.id_compte,
      mail: data.mail,
      home: data.home,
      role: data.role,
    });
    datadogRum.setUser({
      id: data.id_compte,
      email: data.mail,
    });
  }, []);

  const resetAuth = useCallback(() => {
    setAuth({});
    datadogRum.clearUser();
  }, []);

  const contextValue = {
    auth,
    buildAndSetAuth,
    resetAuth,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;
