import { BalanceDomain } from "./constants/balances";

export function getParentIdPropertyFromDomain(domaineBalance) {
  switch (domaineBalance) {
    case BalanceDomain.PROPERTY:
      return "id_bien";
    case BalanceDomain.FOLDER:
      return "id_property_folder";
    case BalanceDomain.TENANT:
      return "id_tenant";
    default:
      throw new Error(`Unknown domain: ${domaineBalance}`);
  }
}

export function getBalanceParentId(balance) {
  return balance[getParentIdPropertyFromDomain(balance.domaine)];
}
