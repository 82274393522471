import CardNumber from "../UI/CardNumber";
import { Dossier, Exclamation, HousePrivatif, Signature } from "../UI/Icons";
import Card from "../UI/Card";
import { useNavigate } from "react-router-dom";
import LocataireShow from "./LocataireShow";
import useTenants from "../../hooks/use-tenants";
import { TenantStatus } from "../../models/tenant";
import ButtonCard from "../UI/ButtonCard";
import PropertyDisplayImgAddress from "../Property/PropertyDisplayImgAddress";

function LocataireList({ properties }) {
  const { tenants } = useTenants(true);
  const nav = useNavigate();
  const filteredTenants = tenants.filter(
    (tenant) => tenant.status === TenantStatus.Actif.status,
  );
  return (
    <>
      <div className="container">
        <div className="content">
          <div className={"flex marginT20 marginB10 space-between wrap gap10"}>
            <CardNumber
              logo={<Signature />}
              nombre={"0"}
              text={"Documents à signer"}
              underText={"Voir mes documents à signer"}
              className={"flex-grow"}
              disabled
            />
            <CardNumber
              logo={<Dossier />}
              nombre={"0"}
              text={"Dossiers de candidature"}
              underText={"Voir mes candidatures en cours"}
              className={"flex-grow"}
              onClick={() => nav("/candidat")}
            />
            <CardNumber
              logo={<Exclamation />}
              nombre={"0"}
              text={"Actions à effectuer"}
              underText={"Voir mes actions à effectuer"}
              className={"flex-grow"}
              disabled
            />
          </div>
          {filteredTenants.length === 0 && (
            <Card className={"secondaryText text-center"}>
              <p>Vous n'avez aucun contrat de location en cours</p>
              <p className={"marginT10"}>
                Rendez-vous dans le menu "Dossiers de candidature" pour
                compléter votre dossier.
              </p>
              <p className={"marginT10"}>
                Vous aurez besoin du lien communiqué par votre futur
                propriétaire, pour pouvoir postuler à son bien.
              </p>
            </Card>
          )}
          {filteredTenants.length === 1 && (
            <LocataireShow tenant={filteredTenants[0]} />
          )}
          {filteredTenants.length > 1 && (
            <>
              <div
                className={"flex justify-content-flex-start align-items-center"}
              >
                <HousePrivatif />
                <h2 className={"marginL5"}>Contrats de location en cours</h2>
              </div>
              <div className={"flex wrap gap10"}>
                {filteredTenants.map((tenant) => {
                  const property = properties.find(
                    (prop) => prop.id === tenant.id_bien,
                  );
                  if (!property) return null;
                  return (
                    <ButtonCard
                      key={tenant.id}
                      onClick={() => nav(tenant.id.toString())}
                      className={"padding20"}
                    >
                      <p className={"text-left primaryText marginB10"}>
                        {tenant.profile_nom.nom_profile}
                      </p>
                      <div className={"flex space-between"}>
                        <div className={"flex align-items-end"}>
                          <PropertyDisplayImgAddress property={property} />
                        </div>
                      </div>
                    </ButtonCard>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LocataireList;
