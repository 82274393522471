import useSWR from "swr";
import axios from "axios";

export default function useFetch(url, options = {}) {
  const { params, fallbackData } = options;
  return useSWR(
    [url, params],
    async ([url, params]) => {
      return (await axios.get(url, { params })).data;
    },
    { fallbackData },
  );
}
