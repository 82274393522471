import Tooltip from "../../UI/Tooltip";
import { Interrogation, MontantRegul } from "../../UI/Icons";
import TextInput from "../../UI/TextInput";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "../../UI/Card";
import { currencyFormatter } from "../../../api/Functions";
import { useEffect, useState } from "react";
import Button from "../../UI/Button";
import { useFormikContext } from "formik";

export default function RegulForm({ balances }) {
  const [showDetail, setShowDetail] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const finPeriode = values.fin_periode;
  const debutPeriode = values.debut_periode;
  const chargesReelles = parseFloat(values.charges_reelles);

  //[] dans le moment, permet de faire en sorte que les dates soient inclusives
  const balancePeriode = balances.filter((balance) =>
    moment(balance.date_transac).isBetween(
      debutPeriode,
      finPeriode,
      "day",
      "[]",
    ),
  );
  const chargesPayees = Math.abs(
    balancePeriode.reduce(function (accumulator, trans) {
      return accumulator + trans.montant;
    }, 0),
  );

  useEffect(() => {
    setFieldValue("charges_payees", chargesPayees);
  }, [chargesPayees, setFieldValue]);

  return (
    <>
      <p>
        La régularisation ne concerne que les charges locatives qui font l’objet
        d’une provision sur charges. Il n’est pas possible de faire de
        régularisation sur des charges locatives soumises à une forfait de
        charge.
      </p>
      <div className={"primaryText marginT20"}>
        Sélectionnez la période de régulation{" "}
        <Tooltip
          content={
            <>
              Une régularisation de charge ne peut être effectuée que sur{" "}
              <strong>les 3 dernières années maximum</strong>. Vous ne pouvez
              pas réclamer des charges récupérables plus anciennes.
            </>
          }
        >
          <Interrogation />
        </Tooltip>
      </div>
      <div className={"flex wrap gap10 marginT10 w-100"}>
        <TextInput
          name={"debut_periode"}
          type={"date"}
          label={"Début de la période"}
          className={"flex-grow"}
          mandatory
        />
        <TextInput
          name={"fin_periode"}
          type={"date"}
          label={"Fin de la période"}
          className={"flex-grow"}
          mandatory
        />
      </div>
      <Card className={"marginB10 flex padding10"}>
        <div className={"marginT5 marginR10"}>
          <MontantRegul />
        </div>
        <div>
          <p className={"primaryText"}>
            {currencyFormatter(Math.abs(chargesPayees))}
          </p>
          <div className={"secondaryText marginT5"}>
            Montant des charges appelées en provision sur la période{" "}
            <Tooltip
              content={
                "Prend en compte tous les éléments de l'historique des transactions dont le type est 'Appel de charge'"
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
          <Button
            className={"marginT5"}
            buttonStyle={"link"}
            type={"button"}
            onClick={() => setShowDetail((prev) => !prev)}
          >
            Voir le detail
          </Button>
          {showDetail && (
            <div className={"marginT10 secondaryText"}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {balancePeriode.map((balance, i) => (
                  <li key={i}>
                    {moment(balance.date_transac).format("DD/MM/YYYY")}
                    {" : "}
                    {currencyFormatter(balance.montant)}{" "}
                    {balance.comment && (
                      <Tooltip content={balance.comment}>
                        <Interrogation />
                      </Tooltip>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Card>
      <TextInput
        type="number"
        name="charges_reelles"
        placeholder="600"
        unit={"€"}
        label={
          <>
            Montant des charges récupérables réellement payées{" "}
            <Tooltip
              content={
                <>
                  Attention, les charges récupérables auprès du locataire sont
                  strictement limitées par la loi.
                  <br />
                  Le montant des charges récupérables figure généralement sur le
                  décompte de charges trimestrielles adressé par le syndic où il
                  est distingué "part propriétaire" et "part locataire".
                  <br />
                  Vous pouvez également retrouver la liste détaillée{" "}
                  <Link
                    to={
                      "https://www.legifrance.gouv.fr/loda/id/LEGITEXT000006066149/"
                    }
                    target={"_blank"}
                  >
                    ici
                  </Link>
                  .
                </>
              }
            >
              <Interrogation />
            </Tooltip>
          </>
        }
        mandatory
      />
      {chargesReelles >= 0 && debutPeriode && finPeriode && (
        <Card className={"marginB10"}>
          {chargesReelles === chargesPayees && (
            <p>Aucune régularisation de charge n'est nécessaire</p>
          )}
          {chargesReelles > chargesPayees && (
            <>
              <p>Le locataire n'a pas payé assez de provision sur charge.</p>
              <p className={"primaryText"}>
                Vous devez lui faire un appel de charge de{" "}
                {currencyFormatter(chargesReelles - chargesPayees)}
              </p>
              <p>
                Cliquez sur "suivant" pour générer le document d'appel de
                charge.
              </p>
            </>
          )}
          {chargesReelles < chargesPayees && (
            <>
              <p>Le locataire a payé trop de provision sur charge.</p>
              <p className={"primaryText"}>
                Vous devez lui faire un remboursement de{" "}
                {currencyFormatter(chargesPayees - chargesReelles)}
              </p>
              <p>Cliquez sur "suivant" pour générer le document adequat.</p>
            </>
          )}
        </Card>
      )}
    </>
  );
}
