import { Form, Formik } from "formik";
import { number, object } from "yup";
import TextInput from "../UI/TextInput";
import { useNavigate } from "react-router-dom";
import FormikSubmitButton from "../UI/FormikSubmitButton";

export function GoToBien({ onSubmitted }) {
  const nav = useNavigate();

  function submitHandler(values) {
    nav(`/candidat?bien=${values.id_bien}`);
    onSubmitted();
  }

  return (
    <>
      <h2 className={"padding0"}>Recherchez un bien</h2>
      <Formik
        initialValues={{ id_bien: "" }}
        enableReinitialize={true}
        onSubmit={submitHandler}
        validationSchema={object({
          id_bien: number().required("Veuillez saisir un numéro de bien"),
        })}
      >
        {() => {
          return (
            <Form>
              <TextInput
                type={"number"}
                name={"id_bien"}
                label={"Numéro du bien fournit par votre bailleur"}
                placeholer={"55"}
              />
              <div className={"text-right"}>
                <FormikSubmitButton>Valider</FormikSubmitButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
