import Button from "../UI/Button";
import { SIGNATURE_PRICE } from "../../models/signatureElectronique";
import { currencyFormatter } from "../../api/Functions";
import { handleAPIError } from "../../utils";
import useProfileNoms from "../../hooks/use-profile-noms";
import useProperties from "../../hooks/use-properties";
import { useState } from "react";
import Card from "../UI/Card";
import FileDownloadLink from "../UI/FileDownloadLink";
import SignatairesCard from "./SignatairesCard";
import useAccount from "../../hooks/use-account";
import styles from "./ElectronicSigning.module.css";
import BuyCredits from "../Stripe/BuyCredits";

export default function ElectronicSigning({
  file,
  onSendToElectronicSigning,
  propertyId,
  tenantProfiles,
  caution,
}) {
  const { account, refresh: refreshAccount } = useAccount();
  const { profileNoms } = useProfileNoms();
  const { properties } = useProperties();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  if (!account) return null;

  const property = properties.find((property) => property.id === propertyId);
  if (!properties) return null;
  const profileNom = profileNoms.find(
    (profileNom) => profileNom.id === property?.id_profile_nom_owner,
  );
  if (!profileNom) return null;

  return (
    <div>
      <h2 className={"text-center"}>Signature électronique</h2>
      <BuyCredits
        account={account}
        refreshAccount={refreshAccount}
        buyCredit={account.credits < SIGNATURE_PRICE}
      />
      <div className={styles.grid}>
        <SignatairesCard profiles={profileNom.profiles} bailleur />
        <SignatairesCard profiles={tenantProfiles} caution={caution} />
      </div>
      {file && (
        <div>
          <p className={"primaryText primaryBlue marginT20 marginB10"}>
            Document à signer :
          </p>
          <FileDownloadLink file={file} />
        </div>
      )}
      <p className={"text-center secondaryText marginB10 marginT20"}>
        La signature électronique sera facturée{" "}
        {currencyFormatter(SIGNATURE_PRICE)} TTC sur vos crédits disponibles.
      </p>
      {file && (
        <div className={"text-right"}>
          <Button
            type={"button"}
            disabled={account.credits < SIGNATURE_PRICE || isLoading}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await onSendToElectronicSigning();
                setError("");
              } catch (error) {
                setError(handleAPIError(error));
              }
              setIsLoading(false);
            }}
          >
            Envoyer pour signature électronique
          </Button>
          {error && (
            <Card type={"error"} className={"padding10 text-center marginT10"}>
              {error}
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
