import TenantBalance from "../Tenant/TenantBalance";
import TenantLeaseCard from "../Tenant/TenantLeaseCard";
import { Fragment, useEffect, useState } from "react";
import PropertyDisplayImgAddress from "../Property/PropertyDisplayImgAddress";
import Card from "../UI/Card";
import DocumentShowList from "../File/DocumentShowList";
import { fetchProperty } from "../../api/Properties";
import { Email, Tel, ZoneTendue } from "../UI/Icons";
import useAuth from "../../hooks/use-auth";
import { useTenantBalances } from "../../hooks/use-balances";
import IconAndText from "../UI/IconAndText";
import DisplayName from "../UI/DisplayName";
import style from "./LocataireShow.module.css";
import { getBalanceValues } from "../../api/Functions";

function LocataireShow({ tenant }) {
  const { balances } = useTenantBalances(tenant?.id, true);
  const [property, setProperty] = useState({});
  const { auth } = useAuth();

  useEffect(() => {
    (async () => {
      setProperty(
        await fetchProperty(auth.accessToken, tenant.id_bien, tenant.id),
      );
    })();
  }, [tenant.id, tenant.id_bien, auth.accessToken]);

  const { balanceTotal, DGAppele, DGPercu } = getBalanceValues(balances);
  if (!property?.identite_bailleur) return null;

  return (
    <div>
      <div className={style.bailPropertyBailleur}>
        <TenantLeaseCard tenant={tenant} />
        <div className={style.propertyBailleur}>
          <Card>
            <PropertyDisplayImgAddress property={property} />
          </Card>
          <Card>
            <p className={"cardTitle marginB10"}>Bailleur</p>
            {property.identite_bailleur.map((ident, i) => (
              <Fragment key={i}>
                <div className={"flex align-items-center"}>
                  <div>
                    <ZoneTendue />
                  </div>
                  <div className={"marginL5"}>
                    <p className={"primaryText"}>
                      <DisplayName profile={ident} />
                    </p>
                    <p className={"secondaryText"}>{ident.adresse}</p>
                    <p className={"secondaryText"}>
                      {ident.cp} {ident.ville}
                    </p>
                  </div>
                </div>
                <IconAndText
                  icon={<Email />}
                  text={ident.mail}
                  className="primaryText marginT10"
                />
                <IconAndText
                  icon={<Tel />}
                  text={ident.tel}
                  className={"primaryText"}
                />
              </Fragment>
            ))}
          </Card>
        </div>
      </div>
      <div className={"marginT10"}>
        <TenantBalance
          tenant={tenant}
          balances={balances}
          balanceTotal={balanceTotal}
          DGPercu={DGPercu}
          DGAppele={DGAppele}
          isLocataire
        />
      </div>
      <div className={"marginT10"}>
        <DocumentShowList tenant={tenant} isLocataire />
      </div>
    </div>
  );
}

export default LocataireShow;
