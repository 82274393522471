import { Form, Formik } from "formik";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import TextInput from "../UI/TextInput";
import styles from "./AuthForm.module.css";
import axios from "../../api";
import useAuth from "../../hooks/use-auth";
import Checkbox from "../UI/Checkbox";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function AuthForm() {
  const { buildAndSetAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location.state?.from?.pathname ?? "/") +
    (location.state?.from?.search ?? "");

  const [errMsg, setErrMsg] = useState("");

  async function logMeIn(values, { setSubmitting }) {
    setErrMsg("");
    try {
      const data = { ...values };

      const response = await axios.post("/token", data);
      buildAndSetAuth(response.data);

      navigate(from, { replace: true });
    } catch (error) {
      if (!error?.response) {
        setErrMsg("Serveur de connexion injoignable");
        console.error(error);
      } else if (error.response?.status === 401) {
        setErrMsg("Adresse e-mail ou mot de passe incorrect");
      } else {
        setErrMsg("Erreur lors de la connexion");
        console.error(error);
      }
    }
    setSubmitting(false);
  }
  return (
    <Formik
      className={styles.form}
      initialValues={{ email: "", password: "", persist_login: false }}
      validationSchema={object({
        email: string()
          .matches(
            "^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
            "Email invalide. Les caractères spéciaux sont interdits.",
          )
          .required("Adresse e-mail requise "),
      })}
      onSubmit={logMeIn}
    >
      {({ handleChange }) => {
        function handleInputChange(e) {
          handleChange(e);
          setErrMsg("");
        }

        return (
          <Form className={styles.form}>
            <TextInput
              type="email"
              name="email"
              placeholder="Adresse e-mail"
              onChange={handleInputChange}
            />
            <TextInput
              type="password"
              name="password"
              onChange={handleInputChange}
              placeholder="Mot de passe"
            />
            {errMsg && <p className={styles.error}>{errMsg}</p>}
            <FormikSubmitButton>Se connecter</FormikSubmitButton>
            <div className={styles.rememberMe}>
              <Checkbox name="persist_login">Se souvenir de moi</Checkbox>
              <Link to="../reset-password" replace>
                Mot de passe oublié ?
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AuthForm;
