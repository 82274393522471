import TextInput from "../UI/TextInput";
import Select from "../UI/Select";
import { useFormikContext } from "formik";
import { RemunerationType } from "../../utils/constants/mandataire";

export default function MandataireFormFields() {
  const { values } = useFormikContext();
  return (
    <>
      <p>
        Rémunération du mandataire<span className={"blueStar"}> *</span>
      </p>
      <div className="flex wrap gap10">
        <Select name={"remuneration_type"} className={"flex-grow"}>
          <option value={""}>Choisissez un type</option>
          <option value={RemunerationType.FIXE}>Fixe</option>
          <option value={RemunerationType.POURCENTAGE_LOYER_CC}>
            Pourcentage loyer charges comprises
          </option>
          <option value={RemunerationType.POURCENTAGE_LOYER_HC}>
            Pourcentage loyer hors charges
          </option>
        </Select>
        <TextInput
          name={"remuneration_montant"}
          type={"number"}
          step={"0.01"}
          unit={
            RemunerationType.isPourcentage(values.remuneration_type)
              ? "%/mois"
              : "€/mois"
          }
        />
      </div>
    </>
  );
}
