import TextInput from "../UI/TextInput";
import {
  CompteurEau,
  CompteurElec,
  CompteurGaz,
  Conclusion,
} from "../UI/Icons";
import IconAndText from "../UI/IconAndText";
import { useFormikContext } from "formik";
import CardToggle from "../UI/CardToggle";
import Button from "../UI/Button";
import { showFormikFieldsInError } from "../../utils";
import React from "react";

function EdlLastPageForm({ onSubmit, edlPreview }) {
  const { values, validateForm, isSubmitting, setSubmitting, setFieldTouched } =
    useFormikContext();
  return (
    <>
      <div
        className={"flex align-items-center justify-content-center marginT20"}
      >
        <Conclusion />
        <h2 className={"marginL10"}>Conclusion</h2>
      </div>

      <CardToggle
        title={
          <IconAndText
            icon={<CompteurEau />}
            text={<p className={"primaryText"}>Compteur d'eau</p>}
          />
        }
      >
        <TextInput
          name={"compteur_eau"}
          label={"Numéro de compteur (optionel)"}
        />
        <div className={"flex "}>
          <TextInput
            name={"eau_froide"}
            label={"Compteur eau froide"}
            unit={"m³"}
            type={"number"}
            step={"0.01"}
          />
          <TextInput
            name={"eau_chaude"}
            label={"Compteur eau chaude"}
            className={"marginL10"}
            unit={"m³"}
            type={"number"}
            step={"0.01"}
          />
        </div>
      </CardToggle>
      <CardToggle
        title={
          <IconAndText
            icon={<CompteurElec />}
            text={<p className={"primaryText"}>Compteur électrique</p>}
          />
        }
      >
        <TextInput
          name={"compteur_elec"}
          label={"Numéro de compteur (optionel)"}
        />
        <div className={"flex "}>
          <TextInput
            name={"elec_heures_pleines"}
            label={"Compteur heures pleines"}
            unit={"kWh"}
            type={"number"}
            step={"0.01"}
          />
          <TextInput
            name={"elec_heures_creuses"}
            label={"Compteur heures creuses"}
            className={"marginL10"}
            unit={"kWh"}
            type={"number"}
            step={"0.01"}
          />
        </div>
      </CardToggle>
      <CardToggle
        title={
          <IconAndText
            icon={<CompteurGaz />}
            text={<p className={"primaryText"}>Compteur gaz</p>}
          />
        }
      >
        <TextInput
          name={"compteur_gaz"}
          label={"Numéro de compteur (optionel)"}
        />
        <TextInput
          name={"gaz"}
          label={"Relevé compteur gaz"}
          unit={"m³"}
          type={"number"}
          step={"0.01"}
        />
      </CardToggle>

      <Button
        type={"button"}
        className={"marginT10 marginB10"}
        isLoading={isSubmitting}
        disabled={isSubmitting}
        onClick={async () => {
          const errors = await validateForm();
          if (Object.keys(errors).length > 0) {
            showFormikFieldsInError(errors, setFieldTouched);
            return;
          }
          setSubmitting(true);
          await onSubmit(values, true);
          setSubmitting(false);
        }}
      >
        Prévisualiser
      </Button>
      <div dangerouslySetInnerHTML={{ __html: edlPreview }} />
    </>
  );
}

export default EdlLastPageForm;
