import { Form, Formik } from "formik";
import {
  OODRIVE_WEBHOOK_INITIAL_VALUES,
  OODRIVE_WEBHOOK_VALIDATION_SCHEMA,
  OodriveWebhook,
} from "../../../models/admin/oodrive";
import RadioGroup from "../../UI/RadioGroup";
import Radio from "../../UI/Radio";
import TextInput from "../../UI/TextInput";
import Checkbox from "../../UI/Checkbox";
import FormikSubmitButton from "../../UI/FormikSubmitButton";

export default function WebhookForm({ onSubmit, ModalElement }) {
  return (
    <Formik
      initialValues={OODRIVE_WEBHOOK_INITIAL_VALUES}
      validationSchema={OODRIVE_WEBHOOK_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, resetForm, handleChange }) => (
        <ModalElement size={"big"} onClose={resetForm}>
          <Form>
            <p>Type de webhook</p>
            <RadioGroup
              onChange={async (e) => {
                await setFieldValue("events", []);
                handleChange(e);
              }}
            >
              <Radio
                name="type"
                value={OodriveWebhook.contract.type}
                label="Contract"
              />
              <Radio
                name="type"
                value={OodriveWebhook.signature.type}
                label="Signature"
              />
            </RadioGroup>
            <TextInput
              type="text"
              name="description"
              placeholder="Description du webhook"
              mandatory
            />
            <div className="flex wrap gap10 marginB10">
              {values.type &&
                OodriveWebhook.from(values.type).events.map((event, i) => (
                  <Checkbox name="events" value={event} key={i} pillStyle>
                    {event}
                  </Checkbox>
                ))}
            </div>
            <FormikSubmitButton>Envoyer</FormikSubmitButton>
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
