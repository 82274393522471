import useResource from "./use-resource";

const ROOT_PATH = "/laposte/suivi/";

export default function useSuiviRecommande(fileId) {
  const {
    resource: suiviRecommande,
    error,
    isLoading,
  } = useResource(fileId ? `${ROOT_PATH}${fileId}` : null);

  return {
    suiviRecommande,
    error,
    isLoading,
  };
}
