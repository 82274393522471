import useAuth from "../../hooks/use-auth";
import useComptes from "../../hooks/use-comptes";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useNavigate } from "react-router-dom";
import ButtonCard from "../UI/ButtonCard";
import StandaloneTextInputGroup from "../UI/StandaloneTextInputGroup";
import { useState } from "react";
import useCustomerCases from "../../hooks/use-customer-cases";
import moment from "moment";
import Button from "../UI/Button";
import { House, Tel, User, YellowStar } from "../UI/Icons";
import LogAccountInfo from "../Admin/LogAccountInfo";
import { TypeAbonnement } from "../../utils/constants/account";

export default function Impersonate() {
  const { buildAndSetAuth, auth } = useAuth();
  const { customerCases, addCustomerCase } = useCustomerCases();
  const { comptes, updateCompte } = useComptes();
  const axios = useAuthenticatedAxios();
  const nav = useNavigate();
  const [filter, setFilter] = useState("");
  const [toCall, setToCall] = useState(false);
  const [detail, setDetail] = useState(null);
  const customer = {
    compte: comptes.find((compte) => compte.id === detail),
    cases: customerCases.filter((filtered) => filtered.id_compte === detail),
  };

  async function submitForm(values, { resetForm }) {
    await addCustomerCase({ ...values, id_compte: detail });
    resetForm();
  }
  async function checkCall(compte) {
    await updateCompte(compte.id, { initial_call: !compte.initial_call });
  }

  async function sendImpersonate(e) {
    try {
      const response = await axios.post("impersonate", {
        id_compte: e,
      });
      buildAndSetAuth(response.data);
      nav(response.data.home);
      return response.data;
    } catch (error) {
      console.error(error);
    }
    e.target.value = "";
  }
  return (
    <div>
      <div className="flex wrap gap10 marginB10">
        <StandaloneTextInputGroup
          onChange={(e) => setFilter(e.target.value.toLowerCase())}
          placeholder={"Recherche"}
        />
        <Button
          buttonStyle={"secondary"}
          onClick={() => setFilter("/bailleur")}
        >
          Bailleurs <House />
        </Button>
        <Button
          buttonStyle={"secondary"}
          onClick={() => setFilter(TypeAbonnement.EARLYBIRD)}
        >
          EarlyBirds <img src="/oiseau.png" alt="oiseau" />
        </Button>
        <Button
          buttonStyle={"secondary"}
          onClick={() => setFilter(TypeAbonnement.REGULAR)}
        >
          Abonné <YellowStar />
        </Button>
        <Button
          buttonStyle={toCall ? "primary" : "secondary"}
          onClick={() => setToCall(() => !toCall)}
        >
          A Appeler (
          {
            comptes.filter(
              (compte) =>
                compte.initial_call === false &&
                compte.home === "/bailleur" &&
                moment(compte.creation_date).isAfter("2024-09-04"),
            ).length
          }
          )
        </Button>
        <Button buttonStyle={"secondary"} onClick={() => setFilter("")}>
          Reset
        </Button>
      </div>
      {comptes
        .filter(
          (compte) =>
            (compte.mail.toLowerCase().includes(filter) ||
              compte.id.toString() === filter ||
              compte.home === filter ||
              compte.type_abonnement === filter) &&
            (!toCall ||
              (compte.initial_call === false &&
                compte.home === "/bailleur" &&
                moment(compte.creation_date).isAfter("2024-09-04"))),
        )
        .map((compte, i) => (
          <div key={i}>
            <ButtonCard
              disabled={!auth?.role?.includes("impersonate")}
              onClick={() => sendImpersonate(compte.id)}
              className={compte.home === "/bailleur" ? "bg-blue" : ""}
            >
              {compte.id} - {moment(compte.creation_date).format("DD/MM/YYYY")}{" "}
              - {compte.mail} - {compte.telephone} (<House />
              {compte.nb_biens}) (<User />
              {compte.nb_tenants})
              {compte.type_abonnement === TypeAbonnement.EARLYBIRD &&
              moment(compte.expiration_abonnement).isAfter(moment()) ? (
                <img src="/oiseau.png" alt="oiseau" />
              ) : (
                ""
              )}
              {compte.type_abonnement === TypeAbonnement.REGULAR &&
              moment(compte.expiration_abonnement).isAfter(moment()) ? (
                <YellowStar />
              ) : (
                ""
              )}
              {compte.initial_call === true ? <Tel /> : ""}
            </ButtonCard>
            <ButtonCard
              onClick={() => setDetail(detail === compte.id ? null : compte.id)}
            >
              Detail
            </ButtonCard>
            {detail === compte.id && customer && (
              <LogAccountInfo
                customer={customer}
                submitForm={submitForm}
                checkCall={checkCall}
              />
            )}
          </div>
        ))}
    </div>
  );
}
