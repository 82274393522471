import { array, number, object, string } from "yup";
import { dateMax, stringMax } from "./utils";

export const balanceTenantSchema = object({
  date_transac: dateMax().required(" "),
  montant: number().required(" "),
  type: string().required(" "),
  comment: stringMax(250).optional(),
  files: array().of(object()),
});

export const balanceTenantIV = {
  date_transac: "",
  montant: "",
  type: "",
  comment: "",
  files: [],
};

export const balanceMasseValidationSchema = object({
  balances: array().of(balanceTenantSchema),
});

export const BALANCE_MASSE_INITIAL_VALUE = {
  balances: [balanceTenantIV],
};
