import { FolderArrowDown, Visualiser } from "./Icons";
import { useEffect, useRef, useState } from "react";
import style from "./FileDownloadLink.module.css";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useModal } from "../../hooks/use-modal";
import { isImage, isPdf } from "../../utils";
import ButtonAction from "./ButtonAction";
import { DocType } from "../../utils/constants/documents";
import { SignatureContratStatus } from "../../models/signatureElectronique";

export default function DocumentDownloadLink({
  documentId,
  doctype = DocType.GENERIC_FILE,
  file,
  isSigned = file?.signature_electronique_status ===
    SignatureContratStatus.Archived.status,
  save,
  children,
  className,
}) {
  const [signedUrl, setSignedUrl] = useState("");
  const [PreviewModal, , openPreviewModal] = useModal();
  const linkRef = useRef();

  const isImageOrPdf = isImage(file) || isPdf(file);
  const isNewTab = !save && !isImageOrPdf;
  const isModalPreview = !save && isImageOrPdf;
  const isDownload = save;

  let linkIcon = isDownload ? (
    <FolderArrowDown stroke="#0C69F5" />
  ) : (
    <Visualiser />
  );

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  const link = <a href={`${signedUrl}&save`} ref={linkRef}></a>;

  const preview = isImageOrPdf && (
    <PreviewModal noPadding>
      {isImage(file) && (
        <img alt={file.filename} src={signedUrl} className={style.previewImg} />
      )}
      {isPdf(file) && (
        <object
          data={signedUrl}
          type="application/pdf"
          title={file.filename}
          className={style.previewPdf}
        >
          <a href={signedUrl} target="_blank" rel="noreferrer">
            Cliquez ici pour télécharger le document
          </a>
        </object>
      )}
    </PreviewModal>
  );

  useEffect(() => {
    if (signedUrl) {
      if (isModalPreview) {
        openPreviewModal();
      } else if (isDownload) {
        linkRef.current.click();
        setSignedUrl("");
      }
    }
    return () => {
      if (signedUrl) {
        // Revoke file URL if it was a local file
        URL.revokeObjectURL(signedUrl);
      }
    };
  }, [isDownload, isModalPreview, openPreviewModal, signedUrl]);

  const axios = useAuthenticatedAxios();

  async function downloadHandler() {
    let tab;
    if (isNewTab) {
      // Open new tab immediately to avoid popup blocker and set href later
      tab = window.open("", "_blank");
      tab.referer = null;
      tab.opener = null;
    }
    try {
      let fileUrl;
      if (file.id) {
        const response = await axios.get(
          `/documents/${documentId}/signed-url`,
          { params: { doctype, signed: isSigned } },
        );
        fileUrl = response.data;
      } else if (file.localFile) {
        fileUrl = URL.createObjectURL(file.localFile);
      }
      if (isNewTab) {
        // Set new tab href to signed URL
        tab.location.href = fileUrl;
      }
      setSignedUrl(fileUrl);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className={className}>
      {signedUrl && link}
      {signedUrl && preview}
      {children && (
        <button
          className={style.button}
          type={"button"}
          onClick={downloadHandler}
        >
          {children}
        </button>
      )}
      {!children && <ButtonAction onClick={downloadHandler} logo={linkIcon} />}
    </div>
  );
}
