import styles from "./Card.module.css";
import { classnames } from "../../utils";

function Card({
  children,
  checked,
  className,
  style,
  onClick,
  type,
  noCard,
  padding,
}) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={
        noCard
          ? undefined
          : classnames(
              styles.card,
              checked && styles.checked,
              className,
              styles[type],
              styles[`padding-${padding}`],
              onClick && styles.action,
            )
      }
    >
      {children}
    </div>
  );
}

export default Card;
