import styles from "./BuyCredits.module.css";
import BuyCreditsButton from "./BuyCreditsButton";
import useStripePayment from "./Stripe";
import CreditsCard from "../Account/CreditsCard";
import Button from "../UI/Button";
import { useState } from "react";
import useStripePrices from "../../hooks/use-stripe-prices";

export default function BuyCredits({
  account,
  refreshAccount,
  buyCredit = false,
}) {
  const { initiatePayment } = useStripePayment();
  const [isLoadingCredits, setIsLoadingCredits] = useState(false);
  const [buyCredits, setBuyCredits] = useState(buyCredit);
  const { prices } = useStripePrices("credits");

  function initiatePaymentHandler(priceId, paymentMethodId) {
    initiatePayment(priceId, {
      onPaymentSuccessful: refreshCredits,
      paymentMethodId,
    });
  }

  async function refreshCredits(tryNb = 0) {
    setIsLoadingCredits(true);
    setBuyCredits(false);
    const currentCredits = account.credits;
    try {
      const account = await refreshAccount();
      const newCredits = account.credits;
      if (newCredits === currentCredits && tryNb < 20) {
        setTimeout(() => refreshCredits(tryNb + 1), 1000);
        return;
      }
      setIsLoadingCredits(false);
      if (tryNb >= 20) {
        console.error("Processing payment took longer than expected");
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <div className="text-center">
        <CreditsCard credits={account.credits} className="marginAuto" />
        <Button
          className="marginT5"
          type={"button"}
          buttonStyle="link"
          isLoading={isLoadingCredits}
          disabled={isLoadingCredits}
          onClick={() => setBuyCredits((prevState) => !prevState)}
        >
          {isLoadingCredits
            ? "Paiement en cours de traitement"
            : "Acheter des crédits"}
        </Button>
      </div>
      {buyCredits && (
        <div className={styles.grid}>
          {prices
            .sort((p1, p2) => p1.unit_amount - p2.unit_amount)
            .map((price, i) => (
              <BuyCreditsButton
                onClick={initiatePaymentHandler}
                price={price}
                key={i}
              />
            ))}
        </div>
      )}
    </>
  );
}
