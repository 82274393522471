import Quill from "quill";

const BlockEmbed = Quill.import("blots/block/embed");

class CustomImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute("src", value.src);
    if (value.class) {
      node.setAttribute("class", value.class);
    }
    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute("src"),
      class: node.getAttribute("class"),
    };
  }
}

CustomImageBlot.blotName = "customImage";
CustomImageBlot.tagName = "img";

Quill.register(CustomImageBlot);
