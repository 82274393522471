import useAuth from "../../hooks/use-auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Unauthorized from "../../pages/Unauthorized";

function RequireAuth({ adminPage }) {
  const {
    auth: { accessToken, role },
  } = useAuth();
  const location = useLocation();
  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!adminPage || (adminPage && role?.length > 0)) {
    return <Outlet />;
  }
  return <Unauthorized />;
}

export default RequireAuth;
