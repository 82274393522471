import Card from "../UI/Card";
import moment from "moment/moment";
import { Form, Formik } from "formik";
import TextArea from "../UI/TextArea";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { object, string } from "yup";
import ToggleSwitch from "../UI/ToggleSwitch";
import CopyToClipboard from "../UI/CopyToClipboard";

export default function LogAccountInfo({ customer, submitForm, checkCall }) {
  return (
    <Card>
      <div className="flex align-items-center gap10">
        <span>
          <strong>Email : </strong> {customer.compte.mail}{" "}
          <CopyToClipboard toCopy={customer.compte.mail} />
        </span>
        <span>
          <strong>Téléphone : </strong> {customer.compte.telephone}{" "}
          <CopyToClipboard toCopy={customer.compte.telephone} />
        </span>
        <span>
          <strong>Activation Code :</strong> {customer.compte.hash}
        </span>
      </div>
      {customer.cases.map((customerCase, i) => (
        <Card className={"padding10 marginT10"} key={i}>
          <p className={"secondaryText text-right"}>
            le {moment(customerCase.date_add).format("DD/MM/YYYY")} par{" "}
            {customerCase.auteur}
          </p>
          <p>{customerCase.commentaire}</p>
        </Card>
      ))}
      <Card className={"padding10 marginT10"}>
        <Formik
          initialValues={{
            commentaire: "",
            initial_call: customer.compte.initial_call ?? false,
          }}
          enableReinitialize={true}
          validationSchema={object({
            commentaire: string().required("Commentaire requis"),
          })}
          onSubmit={submitForm}
        >
          <Form>
            <TextArea name={"commentaire"}></TextArea>
            <ToggleSwitch
              status={customer.compte.initial_call}
              onToggle={() => {
                checkCall(customer.compte);
              }}
            />{" "}
            Appel initial
            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
          </Form>
        </Formik>
      </Card>
    </Card>
  );
}
