import useOodriveWebhooks, {
  useOodriveWebhooksLogs,
} from "../../../hooks/use-oodrive-webhooks";
import Button from "../../UI/Button";
import Spinner from "../../UI/Spinner";
import WebhookLog from "./WebhookLog";
import useLoader from "../../../hooks/use-loader";
import { useSearchParams } from "react-router-dom";
import SelectStandalone from "../../UI/SelectStandalone";

export default function WebhookLogList() {
  const [params, setParams] = useSearchParams();
  const webhookId = params.get("webhook-id");
  const { logs, isLoading, refreshLogs } = useOodriveWebhooksLogs(webhookId);
  const { webhooks } = useOodriveWebhooks();
  const [refreshing, refreshLogsLoader] = useLoader(refreshLogs);
  return (
    <div className={"marginT10"}>
      <div className="flex gap10 align-items-start">
        <Button onClick={refreshLogsLoader} isLoading={refreshing}>
          Rafraichir
        </Button>
        <SelectStandalone
          className={"marginB10"}
          onChange={(e) =>
            setParams((prev) => {
              const newParams = new URLSearchParams(params);
              if (e.target.value === "all") {
                newParams.delete("webhook-id");
              } else {
                newParams.set("webhook-id", e.target.value);
              }
              return newParams;
            })
          }
          value={webhookId || "all"}
        >
          <option value="all">Tous les webhooks</option>
          {webhooks.map((webhook, i) => (
            <option key={i} value={webhook.webHookId}>
              {webhook.description} - {webhook.webHookId}
            </option>
          ))}
        </SelectStandalone>
      </div>
      {isLoading && <Spinner width={"3em"} />}
      {logs.map((log, i) => (
        <WebhookLog key={i} log={log} webhooks={webhooks} />
      ))}
    </div>
  );
}
