import Status from "../UI/Status";
import Button from "../UI/Button";
import moment from "moment/moment";
import React from "react";

export default function LaposteStatusIndicator({ file, onClick }) {
  if (!file || !file.id_laposte) return null;

  if (file.laposte_delivered_at) {
    return (
      <Status
        color={"green"}
        text={
          <>
            Recommandé livré le{" "}
            <Button type="button" buttonStyle={"link"} onClick={onClick}>
              {moment(file.laposte_delivered_at).format("DD/MM/YYYY")}
            </Button>
          </>
        }
      />
    );
  }

  return (
    <Status
      color={"yellow"}
      text={
        <>
          Recommandé{" "}
          <Button type="button" buttonStyle={"link"} onClick={onClick}>
            N°{file.id_laposte}
          </Button>
        </>
      }
    />
  );
}
