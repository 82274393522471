import { AncienneteStatus, StatusPro } from "../../models/profile";
import Card from "../UI/Card";

export default function IndiceConfiance({ tenant, profile }) {
  let points = 0;

  function getPoints(prof) {
    let points = 0;

    const loyerAnnuel = (tenant.loyer_hc + tenant.charges) * 12;
    if (prof.revenus_brut > loyerAnnuel * 3) {
      points += 5;
    } else if (prof.revenus_brut > loyerAnnuel * 2.5) {
      points += 4;
    } else if (prof.revenus_brut > loyerAnnuel * 2.3) {
      points += 3;
    } else if (prof.revenus_brut > loyerAnnuel * 2.1) {
      points += 2;
    } else {
      points += 1;
    }

    if (prof.status_pro === StatusPro.CDI) {
      points += 5;
    } else if (prof.status_pro === StatusPro.CDD) {
      points += 3;
    } else if (prof.status_pro === StatusPro.INTERIM) {
      points += 3;
    } else if (prof.status_pro === StatusPro.FONCTIONNAIRE) {
      points += 5;
    } else if (prof.status_pro === StatusPro.INDEPENDANT) {
      points += 4;
    } else if (prof.status_pro === StatusPro.RETRAITE) {
      points += 10;
    } else if (prof.status_pro === StatusPro.SANSEMPLOI) {
      points += 1;
    }

    if (prof.anciennete === AncienneteStatus.YEAR2PLUS) {
      points += 5;
    } else if (prof.anciennete === AncienneteStatus.YEAR2) {
      points += 4;
    } else if (prof.anciennete === AncienneteStatus.YEAR1) {
      points += 3;
    } else if (prof.anciennete === AncienneteStatus.MONTH6) {
      points += 2;
    } else if (prof.anciennete === AncienneteStatus.MONTH3) {
      points += 1;
    }

    return points;
  }
  function getBestGarantorPoints(garantors) {
    let highestPoints = 0;

    garantors.forEach((garantor) => {
      const points = getPoints(garantor);
      if (points > highestPoints) {
        highestPoints = points;
      }
    });

    return highestPoints;
  }
  // Si c'est un étudiant avec caution physique et garant, on compte les points du meilleurs garant
  if (
    profile.status_pro === StatusPro.ETUDIANT &&
    profile.caution_type === "Physique" &&
    profile.garantors.length > 0
  ) {
    points = getBestGarantorPoints(profile.garantors);
  } else {
    points = getPoints(profile);
  }

  if (profile.caution_type === "Visale") {
    points += 5;
    // On ajoute des points pour Visale si c'est un étudiant, sinon le pauvre il va pas avoir beaucoup de point
    if (profile.status_pro === StatusPro.ETUDIANT) {
      points += 3;
    }
  } else if (profile.caution_type === "Autre") {
    points += 5;
  } else if (
    profile.caution_type === "Physique" &&
    profile.garantors.length > 0
  ) {
    const bestGarantorPoints = getBestGarantorPoints(profile.garantors);
    if (bestGarantorPoints > 13) {
      points += 5;
    } else if (bestGarantorPoints > 11) {
      points += 4;
    } else if (bestGarantorPoints > 9) {
      points += 3;
    } else if (bestGarantorPoints > 6) {
      points += 2;
    } else {
      points += 1;
    }
  } else {
    points += 1;
  }

  let returnValue = "";
  if (points > 17) {
    returnValue = (
      <Card type={"green"} padding="md">
        5/5
      </Card>
    );
  } else if (points > 14) {
    returnValue = (
      <Card type={"blue"} padding="md">
        4/5
      </Card>
    );
  } else if (points > 11) {
    returnValue = (
      <Card type={"warning"} padding="md">
        3/5
      </Card>
    );
  } else if (points > 9) {
    returnValue = (
      <Card type={"orange"} padding="md">
        2/5
      </Card>
    );
  } else {
    returnValue = (
      <Card type={"error"} padding="md">
        1/5
      </Card>
    );
  }
  return returnValue;
}
