import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import React from "react";
import Editor from "../Quill/Editor";
import { mailValidationSchema } from "../../models/mail";
import TextArea from "../UI/TextArea";

function MailForm({ onSubmit, initialValues }) {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={mailValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          function setValueHandler(value) {
            setFieldValue("html", value);
          }

          return (
            <Form>
              <TextInput
                type="text"
                name="subject"
                label={"Sujet du mail"}
                mandatory
              />
              <TextArea name={"plain"} />
              <Editor value={values.html} onChange={setValueHandler} noSubmit />
              <div className={"text-right"}>
                <FormikSubmitButton>Valider</FormikSubmitButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default MailForm;
