import { useEffect, useState } from "react";

function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(key)) || defaultValue,
  );

  useEffect(() => {
    if (
      localStorage.getItem(key) !== undefined &&
      localStorage.getItem(key) !== null
    ) {
      setValue(JSON.parse(localStorage.getItem(key)));
    } else if (key) {
      localStorage.setItem(key, defaultValue);
    }
  }, [defaultValue, key]);

  function storeValue(value) {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  }

  return [value, storeValue];
}

export default useLocalStorage;
