import moment from "moment/moment";
import { BalanceType } from "../utils/constants/balances";

export const currencyFormatter = (value, withSymbol = true) => {
  if (value === null || value === undefined) return null;
  return `${value?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${withSymbol ? "€" : ""}`;
};

export const dateConvert = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Octets", "Ko", "Mo", "Go"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getBalanceValues(balances) {
  const reducer = (accumulator, balance) => accumulator + balance.montant;
  const balancesHorsDG = balances.filter(
    (balance) =>
      balance.type !== BalanceType.APPEL_DG &&
      balance.type !== BalanceType.REMBOURSEMENT_DG &&
      balance.type !== BalanceType.APPEL_RESTITUTION_DG &&
      balance.type !== BalanceType.PAIEMENT_DG,
  );
  const balanceTotal = balancesHorsDG.reduce(reducer, 0);
  const totalPercus = balancesHorsDG
    .filter((balance) => balance.montant > 0)
    .reduce(reducer, 0);
  const DGAppele = -balances
    .filter(
      (balance) =>
        balance.type === BalanceType.APPEL_DG ||
        balance.type === BalanceType.APPEL_RESTITUTION_DG,
    )
    .reduce(reducer, 0);
  const DGPercu = balances
    .filter(
      (balance) =>
        balance.type === BalanceType.PAIEMENT_DG ||
        balance.type === BalanceType.REMBOURSEMENT_DG,
    )
    .reduce(reducer, 0);
  return { balanceTotal, totalPercus, DGAppele, DGPercu };
}
