import { classnames } from "../../utils";
import { useEffect } from "react";
import styles from "./Img.module.css";
import { Croix } from "./Icons";

export default function Img({
  src,
  alt,
  className,
  blob,
  b64data,
  mimetype = "image/jpeg",
  onDelete,
  isThumbnail,
  style,
  ...rest
}) {
  let img = null;

  if (blob) {
    src = URL.createObjectURL(blob);
  } else if (b64data) {
    src = `data:${mimetype};base64,${b64data}`;
  } else {
    src = "not_found";
  }
  if (src) {
    img = (
      <div className={styles.wrapper} style={style}>
        <img
          alt={alt}
          className={classnames(className, isThumbnail && styles.thumbnail)}
          src={src}
          {...rest}
        />
        {onDelete && (
          <div className={styles.croix} onClick={onDelete}>
            <Croix />
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    return () => {
      // Cleanup object URL when unmounting
      if (blob) {
        URL.revokeObjectURL(src);
      }
    };
  }, [blob, src]);

  return img;
}
