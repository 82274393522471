import HeaderProperty from "./Show/HeaderProperty";
import { sanitizeValues } from "../../models/utils";
import Page from "../UI/Page";
import Tabs from "../UI/Tabs";
import usePropertyFolders from "../../hooks/use-property-folders";
import useProfileNoms from "../../hooks/use-profile-noms";
import useProperties from "../../hooks/use-properties";
import { PropertyStatus } from "../../models/property";
import {
  buildPropertyFoldersTree,
  filterPropertiesAndFoldersByStatus,
  findPropertyFolder,
} from "./PropertyListShow";
import { useNavigate } from "react-router-dom";
import PropertyList from "./PropertyList";
import React from "react";
import ComptaShow from "./ComptaShow";
import { BalanceDomain } from "../../utils/constants/balances";

export default function PropertyFolderShow({ propertyFolderId }) {
  const { propertyFolders, updatePropertyFolder, deletePropertyFolder } =
    usePropertyFolders();
  const { profileNoms } = useProfileNoms();
  const { properties, duplicateProperty, updateProperty } = useProperties();
  const nav = useNavigate();

  const propertyFoldersTree = buildPropertyFoldersTree(
    propertyFolders,
    properties,
  );

  const propertyFolder = findPropertyFolder(
    propertyFoldersTree.property_folders,
    propertyFolderId,
  );

  async function submitHandler(values) {
    await updatePropertyFolder(propertyFolder.id, sanitizeValues(values));
  }

  return (
    <Page
      header={
        propertyFolder && (
          <HeaderProperty
            profiles={profileNoms}
            property={propertyFolder}
            onDelete={async () => {
              try {
                await deletePropertyFolder(propertyFolder.id);
                nav("/bailleur/properties");
              } catch (error) {
                console.error(error);
              }
            }}
            onArchive={async () => {
              try {
                await updatePropertyFolder(propertyFolder.id, {
                  status: PropertyStatus.Archive.status,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            onReactivate={async () => {
              try {
                await updatePropertyFolder(propertyFolder.id, {
                  status: PropertyStatus.Actif.status,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            onUpdate={submitHandler}
            onMoveToFolder={async (propertyId) => {
              try {
                await updateProperty(propertyId, {
                  id_property_folder: propertyFolder.id,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            onMoveFolderToFolder={async (propertyId) => {
              try {
                await updatePropertyFolder(propertyId, {
                  id_parent: propertyFolder.id,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            isFolder
          />
        )
      }
      body={
        <Tabs id="prop-show">
          <div className="container bg-blue paddingT10 border-blue">
            <div className="content">
              <Tabs.TabList>
                <Tabs.Tab panelId="biens-associes-actifs">
                  Biens actifs
                </Tabs.Tab>
                <Tabs.Tab panelId="biens-associes-archives">
                  Biens archivés
                </Tabs.Tab>
                <Tabs.Tab panelId="compta">Comptabilité</Tabs.Tab>
              </Tabs.TabList>
            </div>
          </div>
          <div className="blueSpace"></div>
          <div className="content">
            <Tabs.TabPanel id="biens-associes-actifs">
              <PropertyList
                propertiesAndFolders={filterPropertiesAndFoldersByStatus(
                  propertyFolder,
                  PropertyStatus.Actif.status,
                )}
                onDuplicateProperty={duplicateProperty}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel id="biens-associes-archives">
              <PropertyList
                propertiesAndFolders={filterPropertiesAndFoldersByStatus(
                  propertyFolder,
                  PropertyStatus.Archive.status,
                )}
                onDuplicateProperty={duplicateProperty}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel id="compta">
              <ComptaShow
                domaineBalance={BalanceDomain.FOLDER}
                parentId={propertyFolder?.id}
              />
            </Tabs.TabPanel>
          </div>
        </Tabs>
      }
    />
  );
}
