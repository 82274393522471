import usePhoto from "../../hooks/use-photo";
import { classnames } from "../../utils";
import Img from "./Img";

export default function Photo({
  photoId,
  type = "photo",
  isThumbnail = false,
  alt,
  className,
  onDelete,
}) {
  const { photo } = usePhoto(photoId, isThumbnail, type);

  return (
    <Img
      alt={alt}
      className={classnames(className)}
      blob={photo}
      onDelete={onDelete}
      isThumbnail={isThumbnail}
    />
  );
}
