import styles from "./BlurOverlay.module.css";
import { classnames } from "../../utils";

export default function BlurOverlay({ className, children, style }) {
  return (
    <div className={classnames(styles.overlay, className)} style={style}>
      {children}
    </div>
  );
}
