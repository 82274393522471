function Page({ header, body }) {
  return (
    <div className={"marginB20"}>
      <div className="container bg-blue">
        <div className="content">{header}</div>
      </div>
      {body}
    </div>
  );
}

export default Page;
