import Radio from "../../../UI/Radio";
import { useFormikContext } from "formik";
import TextInput from "../../../UI/TextInput";
import Tooltip from "../../../UI/Tooltip";
import { Interrogation } from "../../../UI/Icons";
import { currencyFormatter, getBalanceValues } from "../../../../api/Functions";
import { useEffect } from "react";
import TextArea from "../../../UI/TextArea";

export default function RestitutionDGForm({ property, balances }) {
  const { values, setFieldValue } = useFormikContext();

  const { balanceTotal: balance, DGPercu: montantDG } =
    getBalanceValues(balances);

  const montantImpaye = balance <= 0 ? Math.abs(balance) : 0;

  const hasDegradation = values.has_degradations === "true";
  const hasProvision = values.conserver_provision === "true";
  const hasImpaye = montantImpaye > 0;

  const degradationFlag = hasDegradation ? 1 : 0;
  const provisionFlag = hasProvision ? 1 << 1 : 0;
  const impayeFlag = hasImpaye ? 1 << 2 : 0;

  const montantReparations = hasDegradation
    ? parseFloat(values.montant_reparations) || 0
    : 0;

  const multiplicateurDG = hasProvision ? 0.8 : 1;

  const paragrapheRetenueReparation = `
      L'état des lieux effectué fait apparaitre un certain nombre de réparations à votre charge,
      dont vous trouverez la liste ci-après. <br />
      Liste des réparations (indiquez le montant de la retenue effectuée 
      auprès du locataire après déduction de la vétusté) : <br />
      ${values.degradations.replaceAll("\n", "<br />")} <br />
      Nous vous informons, en application des dispositions de l'article 22 de la loi du 6 juillet 1989, 
      que la somme de ${currencyFormatter(montantReparations)},
      représentant le montant de la remise en état à effectuer, sera retenue sur le dépôt de garantie.<br />`;

  const paragrapheRetenueProvision = `
      L’arrêté annuel des charges de l’immeuble n’ayant pas encore été établi, nous vous informons, 
      en application de l’article 22 de la loi n° 89-462 du 6 juillet 1989, 
      qu'une retenue de 20% est opérée sur la somme de ${currencyFormatter(
        montantDG,
      )} que vous avez versée au titre du dépôt de garantie.
      La retenue effectuée s’élève donc à la somme de ${currencyFormatter(
        montantDG * 0.2 + montantReparations + montantImpaye,
      )}.
      Dans le mois qui suit l'arrêté définitif des charges de l’immeuble, 
      il vous sera restitué la retenue de 20% du dépôt de garantie,
      déduction faite le cas échéant de la régularisation des charges.`;

  const paragrapheRetenueImpaye = `
      ${
        hasDegradation ? "Par ailleurs" : "Toutefois"
      }, vous n'êtes plus à jour de vos paiements pour un montant total de
      ${currencyFormatter(
        montantImpaye,
      )} charges comprises. En conséquence, et conformément à l’article 22 de la loi n° 89-462 du 6 juillet 1989,
      nous retiendrons ${
        hasDegradation ? " également " : ""
      } cette somme sur votre dépôt de garantie.`;

  const paragrapheNoDegradation = `
      Le logement ayant été restitué en bon état de location, aucune retenue
      n'est effectuée sur le dépôt de garantie au titre des réparations
      locatives.`;

  const paragrapheCoordonneesBancaires = `
      Pour vous restituer ${
        hasProvision || hasDegradation ? " le solde " : " la totalité "
      }
      du dépôt de garantie, je vous remercie de nous transmettre vos coordonnées bancaires afin d'effectuer un virement
    `;

  const paragrapheRestitutionPartielle = `
      ${paragrapheCoordonneesBancaires} de la somme de : ${currencyFormatter(
        montantDG * multiplicateurDG - montantReparations - montantImpaye,
      )} correspondant à ${currencyFormatter(
        montantDG,
      )} au titre du dépôt de garantie
      ${
        hasDegradation
          ? `– ${currencyFormatter(
              montantReparations,
            )} des frais de remise en état`
          : ""
      }
      ${
        montantImpaye > 0
          ? `
            – ${currencyFormatter(montantImpaye)} au titre des loyers et/ou
            charges impayés`
          : ""
      }
      ${
        hasProvision
          ? `
          – ${currencyFormatter(montantDG * (1 - multiplicateurDG))} au titre de
          la retenue provisoire de 20% du dépôt de garantie`
          : ""
      }
      .
    `;

  const paragrapheDGInsuffisant = `
      La somme de ${currencyFormatter(montantDG)} que vous avez versée au titre
      du dépôt de garantie ne suffit pas à régler la somme de
      ${
        montantReparations > 0
          ? `${currencyFormatter(
              montantReparations,
            )} au titre des frais de remise
          en état
        `
          : ""
      }
      ${
        montantImpaye > 0
          ? `${
              montantReparations > 0 ? " augmentée de " : ""
            } la somme de ${currencyFormatter(
              montantImpaye,
            )} au titre des loyers et/ou charges impayés`
          : ""
      }
      . Après déduction du dépôt de garantie, je vous remercie de m’adresser la
      somme de ${currencyFormatter(
        montantReparations + montantImpaye - montantDG,
      )} dans un délai de huit jours à compter de la réception du présent courrier.
      Passé ce délai, je serai contraint d’engager toute procédure nécessaire.`;

  const textLoyerImpaye = <>Le locataire ne semble pas à jour de son loyer.</>;

  const textDGInsuffisant = (
    <>
      {montantImpaye > 0 && textLoyerImpaye} Le dépôt de garantie n’est pas
      suffisant pour couvrir {montantReparations > 0 && " les réparations"}
      {montantReparations > 0 && montantImpaye > 0 && " et "}
      {montantImpaye > 0 && " les loyers impayés"}
      {hasProvision && " plus les 20% de provision"}.
    </>
  );

  const textLocataireRedevance = (
    <>
      {textDGInsuffisant} Après déduction des retenues à effectuer, le locataire
      vous doit la somme de{" "}
      {currencyFormatter(montantReparations + montantImpaye - montantDG)}.
    </>
  );

  const textDGProvisionInsuffisant = (
    <>
      {textDGInsuffisant} Après déduction des retenues à effectuer, vous pouvez
      conserver seulement{" "}
      {currencyFormatter(montantDG - montantReparations - montantImpaye)} du
      dépôt de garantie pour les provisions. Vous n'avez donc rien à lui
      reverser pour le moment.
    </>
  );

  const textDeduction = (
    <>
      {montantImpaye > 0 && textLoyerImpaye} Après déduction des retenues à
      effectuer, vous pouvez restituer{" "}
      {currencyFormatter(
        montantDG * multiplicateurDG - montantReparations - montantImpaye,
      )}{" "}
      du dépôt de garantie au locataire{" "}
      {hasProvision && " et conserver 20% en attente de l'appel de charge"}.
      Pour rappel vous devez lui restituer dans un délai de{" "}
      <strong>deux mois</strong> à compter de la remise des clés.
    </>
  );

  const textRappelDelai = (
    <>
      Pour rappel, vous devez lui restituer dans un délai{" "}
      <strong>d’un mois</strong> à compter de la remise des clés.
    </>
  );

  let text = "";
  let courrier = "";

  switch (impayeFlag | provisionFlag | degradationFlag) {
    case 0: // 000
      text = (
        <>
          Le locataire est à jour de son loyer et des charges. Vous pouvez lui
          restituer la totalité du dépôt de garantie (
          {currencyFormatter(montantDG)}). {textRappelDelai}
        </>
      );
      courrier = `${paragrapheNoDegradation} ${paragrapheCoordonneesBancaires}.`;
      break;
    case 1: // 001
      if (montantReparations > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheNoDegradation} ${paragrapheDGInsuffisant}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRestitutionPartielle}`;
      }
      break;
    case 2: // 010
      text = (
        <>
          Le locataire est à jour de son loyer et des charges. Vous pouvez lui
          restituer son dépôt de garantie, et conserver 20% en attente de
          l'appel de charge. Vous devez donc lui rendre{" "}
          {currencyFormatter(montantDG * multiplicateurDG)}. {textRappelDelai}
        </>
      );
      courrier = `${paragrapheNoDegradation} ${paragrapheRetenueProvision} ${paragrapheCoordonneesBancaires}.`;
      break;
    case 3: // 011
      if (montantReparations > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheRetenueReparation} ${paragrapheDGInsuffisant}`;
      } else if (montantReparations > montantDG * multiplicateurDG) {
        text = textDGProvisionInsuffisant;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      }
      break;
    case 4: // 100
      if (montantImpaye > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheNoDegradation} ${paragrapheRetenueImpaye} ${paragrapheDGInsuffisant}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheNoDegradation} ${paragrapheRetenueImpaye} ${paragrapheRestitutionPartielle}`;
      }
      break;
    case 5: // 101
      if (montantReparations + montantImpaye > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueImpaye} ${paragrapheDGInsuffisant}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueImpaye} ${paragrapheRestitutionPartielle}`;
      }
      break;
    case 6: // 110
      if (montantImpaye > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheRetenueImpaye} ${paragrapheDGInsuffisant}`;
      } else if (montantImpaye > montantDG * multiplicateurDG) {
        text = textDGProvisionInsuffisant;
        courrier = `${paragrapheRetenueImpaye} ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheRetenueImpaye} ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      }
      break;
    case 7: // 111
      if (montantImpaye + montantReparations > montantDG) {
        text = textLocataireRedevance;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueImpaye} ${paragrapheDGInsuffisant}`;
      } else if (
        montantImpaye + montantReparations >
        montantDG * multiplicateurDG
      ) {
        text = textDGProvisionInsuffisant;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueImpaye} 
        ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      } else {
        text = textDeduction;
        courrier = `${paragrapheRetenueReparation} ${paragrapheRetenueImpaye}
        ${paragrapheRetenueProvision} ${paragrapheRestitutionPartielle}`;
      }
      break;
    default:
      break;
  }

  useEffect(() => {
    setFieldValue("courrier_text", courrier);
  }, [courrier, setFieldValue]);

  return (
    <div className={"marginB20"}>
      L’état des lieux de sortie fait-il apparaître de l'usure ou des
      dégradations ?
      <div className={"flex marginT10 marginB20"}>
        <Radio
          name={"has_degradations"}
          value="true"
          label="Oui"
          className={"marginL20"}
        />
        <Radio
          name={"has_degradations"}
          value="false"
          label="Non"
          className={"marginL20"}
        />
      </div>
      {hasDegradation && (
        <>
          <TextInput
            name={"montant_reparations"}
            type="number"
            step={"0.01"}
            unit={"€"}
            label={
              <>
                Montant des réparations à la charge du locataire{" "}
                <Tooltip
                  content={
                    <div>
                      <p className={"cardTitle marginB10"}>
                        Que pouvez-vous facturer ?
                      </p>
                      <p>Toute somme retenue doit être justifiée soit :</p>
                      <ul>
                        <li>par un devis</li>
                        <li>par une facture</li>
                      </ul>
                      <p className={"marginT10"}>
                        Vous pouvez facturer les produits que vous avez achetés
                        pour faire le ménage ou les réparations vous-même. Vous
                        ne pouvez pas imputer de “forfait” pour le temps passé.
                        Dans ce cas faites faire un devis par un professionnel
                        pour pouvoir le déduire.
                      </p>
                      <p className={"marginT10"}>
                        Vous devez communiquer les justificatifs au locataire à
                        sa première demande.
                      </p>
                    </div>
                  }
                >
                  <Interrogation />
                </Tooltip>
              </>
            }
          />
          <TextArea
            type="text"
            name="degradations"
            label="Liste des dégradations"
            rows="4"
            placeholder="Meuble abimé, ménage non fait, ..."
          />
        </>
      )}
      {property.type_propriete === "copropriete" && (
        <>
          Souhaitez-vous conserver une provision de 20% du dépôt de garantie en
          attente de l’arrêté annuel des comptes de l'immeuble ?
          <div className={"flex marginT10 marginB20"}>
            <Radio
              name={"conserver_provision"}
              value="true"
              label="Oui"
              className={"marginL20"}
            />
            <Radio
              name={"conserver_provision"}
              value="false"
              label="Non"
              className={"marginL20"}
            />
          </div>
        </>
      )}
      {text}
    </div>
  );
}
