import usePropertyFolders from "../../hooks/use-property-folders";
import { Link } from "react-router-dom";
import SelectStandalone from "../UI/SelectStandalone";

export default function PropertyMoveToFolder({ onMoveToFolder, folderId }) {
  const { propertyFolders } = usePropertyFolders();

  return propertyFolders.length > 0 ? (
    <SelectStandalone
      onChange={(e) => onMoveToFolder(parseInt(e.target.value))}
    >
      <option>Choisissez un dossier</option>
      <option value={null}>Supprimer l'affectation</option>
      {propertyFolders
        .filter((filtered) => filtered.id !== folderId)
        .map((folder, i) => (
          <option key={i} value={folder.id}>
            {folder.nom}
          </option>
        ))}
    </SelectStandalone>
  ) : (
    <p>
      Vous n'avez aucun dossier créé. Vous pouvez en créer un dans la rubrique{" "}
      <Link to={"/bailleur/properties/add"}>Ajouter un bien</Link>
    </p>
  );
}
