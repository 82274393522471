import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from "./DraggableItem.module.css";

export default function DraggableItem({ id, children }) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };
  return (
    <div ref={setNodeRef} style={style} className={styles.wrapper}>
      <div className={styles.handle} {...attributes} {...listeners}></div>
      {children}
    </div>
  );
}
