import React from "react";
import moment from "moment";
import useSuiviRecommande from "../../hooks/use-suivi-recommande";
import { Link } from "react-router-dom";
import Spinner from "../UI/Spinner";

export default function SuiviRecommande({ file }) {
  const { suiviRecommande, error, isLoading } = useSuiviRecommande(file?.id);

  if (error)
    return (
      <div>
        Vous pouvez suivre votre colis{" "}
        <Link
          to={`https://www.laposte.fr/outils/suivre-vos-envois?code=${file.id_laposte}`}
          target={"_blank"}
        >
          ici
        </Link>
      </div>
    );

  if (isLoading) return <Spinner width={"3em"} />;

  return (
    <div>
      {file?.laposte_delivered_at ? (
        <h2>
          Votre lettre a été livrée le{" "}
          {moment(file.laposte_delivered_at).format("DD/MM/YYYY")}
        </h2>
      ) : (
        <h2>Votre lettre est en cours d'envoi...</h2>
      )}
      <div>
        {suiviRecommande?.event?.map((ev) => (
          <p>
            <span className={"primaryText"}>
              {moment(ev.date).format("DD/MM/YYYY-HH:mm")} :
            </span>{" "}
            {ev.label}
          </p>
        ))}
      </div>
    </div>
  );
}
