import { Selectable } from "../UI/Selectable";
import { Table } from "../UI/Table";
import { currencyFormatter, dateConvert } from "../../api/Functions";

export default function TransacAddTenant({
  transactions,
  onTransactionUpdate,
}) {
  return (
    <>
      <h2>Transactions en lien avec votre locataire</h2>
      <p className={"marginB10"}>
        Nous avons detecté les transactions suivantes, qui semblent être liées
        avec votre locataire.
        <br />
        Sélectionnez celles que vous souhaitez affecter à votre locataire.
      </p>
      <Selectable items={transactions}>
        <Table>
          <Table.Header>
            <Table.Column>
              <Selectable.Checkbox selectAll />
            </Table.Column>
            <Table.Column>Date</Table.Column>
            <Table.Column>Description</Table.Column>
            <Table.Column>Montant</Table.Column>
          </Table.Header>
          <Table.Body>
            {transactions.map((transaction, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Selectable.Checkbox value={transaction.id} index={i} />
                </Table.Cell>
                <Table.Cell>{dateConvert(transaction.date_transac)}</Table.Cell>
                <Table.Cell>{transaction.description}</Table.Cell>
                <Table.Cell>
                  {currencyFormatter(transaction.montant)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Selectable.ActionButton onClick={onTransactionUpdate}>
          Affecter
        </Selectable.ActionButton>
      </Selectable>
    </>
  );
}
