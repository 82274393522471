import TextInput from "../UI/TextInput";
import Radio from "../UI/Radio";
import { FieldArray } from "formik";
import React, { Fragment } from "react";
import Card from "../UI/Card";
import { TextOneOrMany } from "../../utils";
import RepresentedBy from "../UI/RepresentedBy";
import { ProfileStatus } from "../../models/profile";
import TextArea from "../UI/TextArea";
import styles from "./EdlFirstPageForm.module.css";
import { Identification } from "../UI/Icons";
import Tabs from "../UI/Tabs";
import Button from "../UI/Button";

function EdlFirstPageForm({ tenant, values, profileNomBailleur }) {
  const { selectNextTab, selectPreviousTab } = Tabs.useActiveTab();

  return (
    <div className={styles.container}>
      <div
        className={"flex align-items-center justify-content-center marginT20"}
      >
        <Identification />
        <h2 className={"marginL10"}>Identification</h2>
      </div>
      <div className={"marginT10"}>
        <p className={"border-blue primaryBlue"}>
          Sélectionner le type d'état des lieux :
        </p>
        <div className={"marginT10 flex-center "}>
          <div>
            <Radio
              name="type_edl"
              value="Entrée"
              label="État des lieux d'entrée"
            />
          </div>
          <div>
            <Radio
              name="type_edl"
              value="Sortie"
              label="État des lieux de sortie"
            />
          </div>
        </div>
        <TextInput
          type="date"
          name="date"
          className={"marginT20"}
          label={"Date de l'état des lieux"}
        />
        <TextArea
          name="description"
          label={"Commentaire global"}
          placeholder={
            "Exemple : cet état des lieux est intermédiaire et est réalisé à l'occasion de l'entrée de M.X dans la colocation"
          }
          rows={"4"}
        />
        <Card className={"marginT10 marginB20 bg-blue"}>
          <p className={"primaryText"}>
            <TextOneOrMany
              one={"Bailleur présent"}
              many={"Bailleurs présents"}
              number={profileNomBailleur?.profiles?.length}
            />
          </p>
          <FieldArray
            name={"profiles_bailleurs"}
            render={() =>
              profileNomBailleur?.profiles.map((profile_bailleur, index) => {
                return (
                  <Fragment key={index}>
                    <RepresentedBy
                      profile={profile_bailleur}
                      prefixName={`profiles_bailleurs[${index}]`}
                      check={values.profiles_bailleurs?.[index]}
                    />
                  </Fragment>
                );
              })
            }
          />
        </Card>
        <Card className={"bg-blue"}>
          <p className={"primaryText"}>
            <TextOneOrMany
              one={"Locataire présent"}
              many={"Locataires présents"}
              number={
                tenant?.profile_nom.profiles.filter(
                  (p) => p.status === ProfileStatus.Actif.status,
                ).length
              }
            />
          </p>
          <FieldArray
            name={"profiles"}
            render={() =>
              tenant?.profile_nom.profiles
                .filter((p) => p.status === ProfileStatus.Actif.status)
                .map((profile, index) => {
                  return (
                    <div key={index}>
                      <RepresentedBy
                        profile={profile}
                        prefixName={`profiles[${index}]`}
                        check={values.profiles?.[index]}
                      />
                    </div>
                  );
                })
            }
          />
        </Card>
      </div>
      <div className={"marginT20 text-right"}>
        <Button onClick={() => selectPreviousTab()} className={"marginR10"}>
          Précédent
        </Button>
        <Button onClick={() => selectNextTab()}>Suivant</Button>
      </div>
    </div>
  );
}

export default EdlFirstPageForm;
