import { useId } from "react";
import { classnames } from "../../utils";

export default function RadioStandalone({ label, className, ...props }) {
  const id = useId();

  return (
    <span className={classnames(className)}>
      <input {...props} type="radio" id={id} />
      {label && <label htmlFor={id}>{label}</label>}
    </span>
  );
}
