import { Link, useLocation } from "react-router-dom";

import AuthFormForgottenPassword from "../../components/Auth/AuthFormForgottenPassword";
import TemplateAuthentication from "../../components/UI/TemplateAuthentication";

function ForgottenPassword(props) {
  const queryParams = new URLSearchParams(useLocation().search);
  let redirect = "";
  if (queryParams.get("redirect")) {
    redirect = "?redirect=" + queryParams.get("redirect");
  }

  return (
    <TemplateAuthentication>
      <div>
        <h1>Mot de passe oublié</h1>
      </div>
      <div>
        <AuthFormForgottenPassword />
        <div className={"flex space-between"}>
          <Link to={redirect !== "" ? "/signup" + redirect : "../signup"}>
            Créer un compte
          </Link>
          <Link to={redirect !== "" ? "/" + queryParams.get("redirect") : "/"}>
            Se connecter
          </Link>
        </div>
      </div>
    </TemplateAuthentication>
  );
}

export default ForgottenPassword;
