import ProcessDepart from "./ProcessDepart";
import { ProcessType } from "../../../utils/constants/processes";

export default function Process({ process, onFinish }) {
  switch (process.type) {
    case ProcessType.DEPART:
    case ProcessType.DEPART_BAILLEUR:
      return <ProcessDepart processId={process.id} onFinish={onFinish} />;
    default:
      return null;
  }
}
