import Button from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  headerTenantSchema,
  TENANT_INITIAL_VALUE,
  TenantStatus,
} from "../../models/tenant";
import TenantHeaderForm from "./PartForms/TenantHeaderForm";
import PartForm from "../UI/PartForm";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { useModal } from "../../hooks/use-modal";
import useTenants from "../../hooks/use-tenants";
import styles from "./HeaderTenant.module.css";

export const ROOT_PATH = "/bailleur/properties/";

function HeaderTenant({ tenant, property }) {
  const [
    HeaderTenantFormModal,
    closeHeaderTenantFormModal,
    openHeaderTenantFormModal,
  ] = useModal();
  const { updateTenant, deleteTenant } = useTenants();
  const nav = useNavigate();

  const initialValues = toInitialValuesFromSchema(
    headerTenantSchema,
    tenant,
    TENANT_INITIAL_VALUE,
  );

  return (
    <>
      <HeaderTenantFormModal>
        <PartForm
          partForm={<TenantHeaderForm />}
          deleteHandler={
            tenant.status === TenantStatus.Archive.status
              ? () => {
                  deleteTenant(tenant.id);
                  nav("/bailleur/tenants?tab-tenant-list=archives");
                }
              : undefined
          }
          archiveHandler={
            tenant.status === TenantStatus.Actif.status
              ? () =>
                  updateTenant(tenant.id, {
                    status: TenantStatus.Archive.status,
                  })
              : undefined
          }
          reactivateHandler={
            tenant.status === TenantStatus.Archive.status
              ? () =>
                  updateTenant(tenant.id, { status: TenantStatus.Actif.status })
              : undefined
          }
          initialValues={initialValues}
          validationSchema={headerTenantSchema}
          submitHandler={(values) =>
            updateTenant(tenant.id, sanitizeValues(values))
          }
          closeModal={closeHeaderTenantFormModal}
        />
      </HeaderTenantFormModal>
      <div className={styles.header}>
        <div className={styles.tenantInfo}>
          <div className={"titlePage"}>{tenant.profile_nom.nom_profile}</div>
          {property && (
            <Link
              className="inline-block text-center"
              to={`/bailleur/properties/${property.id}`}
            >
              <span className="ws-nowrap">{property.nom} :</span>{" "}
              <span className="ws-nowrap">{property.rue},</span>{" "}
              <span className="ws-nowrap">
                {property.cp} {property.ville}
              </span>
            </Link>
          )}
          {tenant.reference_dossier && (
            <p className={"text-center"}>
              Référence de dossier : {tenant.reference_dossier}
            </p>
          )}
        </div>

        {TenantStatus.CandidatArchive.status !== tenant.status && (
          <div className={styles.button}>
            <Button buttonStyle={"blue"} onClick={openHeaderTenantFormModal}>
              Modifier
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default HeaderTenant;
