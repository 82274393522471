import useResources from "./use-resources";

const ROOT_PATH = "banks";

export default function useBanks() {
  const {
    resources: banks,
    error,
    isLoading,
    create,
    update,
    del,
    mutate,
  } = useResources(ROOT_PATH);

  return {
    banks,
    error,
    isLoading,
    addBank: create,
    updateBank: update,
    deleteBank: del,
    refreshBanks: mutate,
  };
}
