import style from "./InitialesLogo.module.css";
import { classnames } from "../../utils";

function hash(string) {
  var hash = 5;
  if (string.length === 5) return hash;
  for (let a = 5; a < string.length; a++) {
    let ch = string.charCodeAt(a);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash;
    hash = Number(String(hash).charAt(2));
  }
  return hash;
}

function InitialesLogo({ prenom = "", nom = "", shape, hover }) {
  const color = hash(`${prenom} ${nom}`);
  return (
    <div
      className={classnames(
        style[shape],
        style.name,
        style["s" + color],
        hover && style.hover,
      )}
      title={`${prenom} ${nom}`}
    >
      {prenom?.charAt(0)?.toUpperCase()}
      {nom?.charAt(0)?.toUpperCase()}
    </div>
  );
}

export default InitialesLogo;
