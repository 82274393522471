import { object } from "yup";
import { stringMax } from "./utils";

export const addressSchema = object({
  adresse: stringMax(250).required(" "),
  complement_adresse: stringMax(250).optional(),
  cp: stringMax(10).required(" "),
  ville: stringMax(250).required(" "),
  nom: stringMax(250).required(" "),
  telephone: stringMax(250).required(" "),
});

export const ADDRESS_INITIAL_VALUES = {
  adresse: "",
  complement_adresse: "",
  cp: "",
  ville: "",
  nom: "",
  telephone: "",
};
