import axios from "axios";

export const ROOT_PATH = "files";

export async function fetchFilesProperties(token, id_bien, tag, id_tenant) {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/${ROOT_PATH}`,
      params: { id_bien, tag, id_tenant },
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
