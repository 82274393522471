import { Form, Formik } from "formik";
import Button from "../UI/Button";
import TextInput from "../UI/TextInput";
import {
  financeConfigInitialValues,
  financeConfigSchema,
} from "../../models/financeConfig";
import Select from "../UI/Select";
import { toInitialValuesFromSchema } from "../../models/utils";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import useProfileNoms from "../../hooks/use-profile-noms";
import TenantSelect from "../Tenant/TenantSelect";

function FinanceConfigForm({ config, tenantId, onSubmit, onDelete }) {
  const { profileNoms } = useProfileNoms();

  let initialValues = toInitialValuesFromSchema(
    financeConfigSchema,
    config,
    financeConfigInitialValues,
  );
  if (tenantId) {
    // If tenant cannot be edited (e.g. edit from tenant page), manually add the id_tenant as it's required
    initialValues.id_tenant = tenantId;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={financeConfigSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <TextInput type="text" name="keyword" placeholder="keyword" />
          {!tenantId && <TenantSelect name="id_tenant" withArchivedTenants />}
          <Select name="id_profile_nom">
            <option value="">Affecter à un profil bailleur</option>
            {profileNoms.map((option) => (
              <option key={option.id} value={option.id}>
                {option.nom_profile}
              </option>
            ))}
          </Select>
          <div className="text-right">
            <Button
              type="button"
              buttonStyle={"secondaryDelete"}
              disabled={!config?.id}
              onClick={() => onDelete(config.id)}
            >
              Supprimer
            </Button>
            <FormikSubmitButton className={"marginL10"}>
              Valider
            </FormikSubmitButton>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default FinanceConfigForm;
