import { number, object, string } from "yup";
import { stringMax } from "./utils";

export const leaseTemplateValidationSchema = object({
  nom: stringMax(250).required(" "),
  duree: number().integer().required(" "),
  type: stringMax(250).required(" "),
  content: string().required(" "),
});

export const LEASE_TEMPLATE_INITIAL_VALUE = {
  nom: "",
  duree: 1,
  type: "",
  content: "",
};
