import styles from "./StandaloneTextInputGroup.module.css";
import { SearchIcon } from "./Icons";
import { classnames } from "../../utils";

export default function StandaloneTextInputGroup({
  placeholder,
  onChange,
  className,
}) {
  return (
    <div className={classnames(styles.inputGroup, className)}>
      <div className={styles.inputGroupText}>
        <SearchIcon />
      </div>

      <input
        type="text"
        className={styles.inputSearch}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
