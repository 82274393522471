import styles from "./InfoBoxList.module.css";
import { Plus } from "./Icons";
import { classnames } from "../../utils";

function InfoBoxList({ color, text, onClick }) {
  return (
    <div
      className={classnames(
        styles.containerInfoBoxList,
        onClick && styles.action,
      )}
      onClick={onClick}
    >
      {color && <div className={classnames(styles.rond, styles[color])}></div>}
      <div className={styles.textInfoBoxList}>
        {text}
        {onClick && (
          <span className={"marginL5"}>
            <Plus stroke={"#282E37"} />
          </span>
        )}
      </div>
    </div>
  );
}

export default InfoBoxList;
