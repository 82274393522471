import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import style from "./CandidatShow.module.css";
import TenantCandidater from "../Tenant/TenantCandidater";
import { HousePrivatif, PlusBank } from "../UI/Icons";
import { sendToBackend } from "../../api";
import Card from "../UI/Card";
import ProfileIndivList from "../Profile/ProfileIndivList";
import { useModal } from "../../hooks/use-modal";
import useAuth from "../../hooks/use-auth";
import Page from "../UI/Page";
import PropertyShowCandidat from "../Property/PropertyShowCandidat";
import DescriptionFinance from "../Property/Show/DescriptionFinance";
import { classnames, TextOneOrMany } from "../../utils";
import JoyrideQalimo from "../JoyrideQalimo/JoyrideQalimo";
import useTenants from "../../hooks/use-tenants";
import { GoToBien } from "./GoToBien";
import { ProfileType } from "../../models/profile";

function CandidatShow({
  deleteProfileHandler,
  profiles,
  isLoadingProfiles,
  errorProfiles,
  property,
  addGarantToStateProfile,
  removeGarant,
}) {
  const navigate = useNavigate();
  const { tenants, isLoading: isLoadingTenants } = useTenants(true);
  const [CandidateModal, closeCandidateModal, openCandidateModal] =
    useModal(false);
  const [BienModal, closeBienModal, openBienModal] = useModal(false);
  const { auth } = useAuth();
  const profilesCandidat = profiles.filter(
    (profile) => profile.profile_type === ProfileType.Candidat,
  );

  async function addGarant(garantId, profileId) {
    try {
      await sendToBackend(`profiles-indiv/${garantId}`, auth.accessToken, {
        method: "PUT",
        data: { garantor_of: profileId },
      });
      addGarantToStateProfile(profileId, garantId);
    } catch (error) {
      console.error(error);
    }
  }

  const bienSearchParam = property ? `?bien=${property.id}` : "";

  let steps = [];
  let currentStep = "";
  if (
    profiles?.filter((profile) => profile.profile_type === ProfileType.Candidat)
      ?.length === 0 &&
    !isLoadingProfiles &&
    !errorProfiles
  ) {
    currentStep = "profile";
    steps = [
      {
        target: "[data-joyride='profile']",
        content: (
          <>
            <p className={"primaryText"}>Bienvenue sur Qalimo.</p>
            <p className={"secondaryText marginT10"}>
              Qalimo permet de constituer votre dossier locataire, et de
              postuler au bien envoyé par votre bailleur. La 1ère étape est de
              créer votre profil, et d'y intégrer vos documents.
            </p>
          </>
        ),
        disableBeacon: true,
      },
    ];
  } else if (tenants?.length === 0 && !isLoadingTenants) {
    currentStep = "candidatures";
    steps = [
      {
        target: "[data-joyride='candidatures']",
        content: (
          <>
            <p className={"primaryText"}>Soumettez votre candidature</p>
            <p className={"secondaryText marginT10"}>
              Votre dossier de candidature est prêt. Cliquez simplement sur
              "Envoyer ma candidature" pour la transmettre au bailleur.
            </p>
          </>
        ),
        disableBeacon: true,
      },
    ];
  }

  return (
    <>
      <JoyrideQalimo steps={steps} currentStep={currentStep} />
      <Page
        header={
          <div
            className={
              "padding20 paddingB40 flex space-between align-items-end wrap gap10"
            }
          >
            <div>
              <h2 className={"marginB0"}>Espace candidat</h2>
              <p className={"secondaryText"}>
                Envoyez votre dossier de candidature de manière 100%
                dématérialisée
              </p>
            </div>
            {property && (
              <span data-joyride="candidatures">
                <Button
                  onClick={openCandidateModal}
                  className={"paddingL20 paddingR20"}
                >
                  Envoyer ma candidature
                </Button>
              </span>
            )}
          </div>
        }
        body={
          <>
            <CandidateModal>
              <TenantCandidater
                profiles={profiles.filter(
                  (profile) => profile.profile_type === ProfileType.Candidat,
                )}
                propertyId={property?.id}
                closeCandidateModal={closeCandidateModal}
              />
            </CandidateModal>
            <BienModal>
              <GoToBien onSubmitted={closeBienModal} />
            </BienModal>
            <div className="container">
              <div className="content marginT-20">
                {property && (
                  <>
                    <div className={style.grid2Cols}>
                      <PropertyShowCandidat property={property} />
                      <Card>
                        <DescriptionFinance property={property} />
                      </Card>
                    </div>
                    {property.description_public && (
                      <Card className={"marginT10"}>
                        <pre className="pre-whitespace">
                          {property.description_public}
                        </pre>
                      </Card>
                    )}
                  </>
                )}
                <div
                  className={classnames(
                    "marginB10 flex space-between  wrap-reverse gap10",
                    property ? "marginT20" : "marginT30",
                  )}
                >
                  <div className={"flex align-items-start"}>
                    <HousePrivatif />
                    <h2 className={"marginT0 marginB0 marginL5"}>
                      <TextOneOrMany
                        one={"Votre dossier de candidature"}
                        many={"Vos dossiers de candidature"}
                        number={profilesCandidat.length}
                      />
                    </h2>
                  </div>
                  <div className={"flex wrap gap10"}>
                    {!property && (
                      <Button
                        buttonStyle={"secondary"}
                        onClick={() => openBienModal()}
                      >
                        Postuler à un bien
                      </Button>
                    )}
                    <span data-joyride="profile">
                      <Button
                        buttonStyle={"secondary"}
                        onClick={() => {
                          navigate(`add${bienSearchParam}`);
                        }}
                      >
                        Ajouter un profil candidat
                      </Button>
                    </span>
                  </div>
                </div>
                <ProfileIndivList
                  profiles={profilesCandidat}
                  editProfileHandler={(profileId) =>
                    navigate(`${profileId}${bienSearchParam}`)
                  }
                  deleteProfileHandler={deleteProfileHandler}
                  addGarant={addGarant}
                  removeGarant={removeGarant}
                />
                <Card
                  className={classnames(
                    "text-center marginT10",
                    style.dashedLine,
                  )}
                  onClick={() => {
                    navigate(`add${bienSearchParam}`);
                  }}
                >
                  <div
                    className={"flex align-items-center justify-content-center"}
                  >
                    <PlusBank />
                    <p className={"marginL10"}>Créer un profile</p>
                  </div>
                </Card>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

export default CandidatShow;
