import Card from "../UI/Card";
import { Delete } from "../UI/Icons";
import InitialesLogo from "../UI/InitialesLogo";
import DisplayName from "../UI/DisplayName";

export default function ProfileDisplaySmallCard({
  profile,
  onDelete,
  caution,
}) {
  return (
    <Card
      className={"flex padding10 marginB5 space-between align-items-center"}
    >
      <div className={"flex align-items-center w-100"}>
        <InitialesLogo
          prenom={profile.prenom}
          nom={profile.nom}
          shape={"rond"}
        />
        <div className={"marginL10"}>
          <DisplayName profile={profile} />
        </div>
      </div>

      {onDelete && (
        <div onClick={onDelete} className={"cursorPointer"}>
          <Delete />
        </div>
      )}
    </Card>
  );
}
