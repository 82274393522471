import { useId, useState } from "react";
import { useField } from "formik";
import style from "./FileInput.module.css";
import { FolderArrowUp } from "./Icons";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import Card from "../UI/Card";
import { classnames, handleAPIError } from "../../utils";
import DocumentDownloadLink from "./DocumentDownloadLink";
import DocumentThumbnail from "./DocumentThumbnail";
import { DocType } from "../../utils/constants/documents";

export default function FileInput({
  doctype = DocType.GENERIC_FILE,
  label,
  multiple = false,
  path = "files",
  documentId,
  text = "Déposez vos fichiers",
  className,
  accept = ".png,.jpg,.jpeg,.pdf",
  format = "Format PNG, JPG, JPEG, PDF",
}) {
  const axios = useAuthenticatedAxios();
  const [{ value }, meta, { setValue }] = useField(path);
  const id = useId();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(0);
  const [highlight, setHighlight] = useState(false);

  async function handleSubmit(event, isDrop) {
    event.preventDefault();
    const formData = new FormData();
    const selectedFiles = isDrop
      ? event.dataTransfer.files
      : event.target.files;
    for (const file of selectedFiles) {
      formData.append("documents", file);
      if (!multiple) break;
    }
    try {
      const res = await axios.post("/documents/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          doctype,
        },
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setLoading(percentCompleted);
        },
      });
      const newFiles = res.data.map((file, i) => {
        return {
          ...file,
          localFile: selectedFiles[i],
        };
      });
      // If multiple allowed, add new files to existing ones, otherwise replace
      if (multiple) {
        await setValue([...value, ...newFiles]);
      } else {
        await setValue(newFiles);
      }
      event.target.value = null;
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  async function deleteFileHandler(fileToDelete) {
    await setValue(value.filter((file) => file.src !== fileToDelete.src));
  }

  return (
    <div className={classnames(className)}>
      {label && <p>{label}</p>}
      <>
        <input
          type="file"
          onChange={(e) => handleSubmit(e, false)}
          id={id}
          className={style.input}
          accept={accept}
          multiple={multiple}
        />

        <label
          htmlFor={id}
          className={classnames(
            style.label,
            highlight && style.highlight,
            meta.error && meta.touched && style.error,
          )}
          onDragLeave={() => setHighlight(false)}
          onDrop={(e) => {
            setHighlight(false);
            return handleSubmit(e, true);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setHighlight(true);
          }}
        >
          <div className={style.icon}>
            <FolderArrowUp stroke="#0C69F5" />
          </div>
          <div>
            <p className={style.primary}>{text}</p>
            <p className={classnames(style.secondary, "marginR10")}>{format}</p>
          </div>
        </label>
        {meta.touched && meta.error && (
          <div className={style.error}>{meta.error}</div>
        )}
        {loading > 0 && loading < 100 && (
          <>
            <p className={"secondaryText marginT10"}>
              Envoi du fichier en cours
            </p>
            <progress max="100" value={loading} className={"marginB10"}>
              {loading}%
            </progress>
          </>
        )}
        {error && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {error}
          </Card>
        )}
      </>

      <br />
      {value.map((file, i) => (
        <DocumentDownloadLink
          documentId={documentId}
          doctype={doctype}
          file={file}
          key={i}
        >
          <DocumentThumbnail
            documentId={documentId}
            doctype={doctype}
            file={file}
            onDelete={deleteFileHandler}
          />
        </DocumentDownloadLink>
      ))}
      <br />
    </div>
  );
}
