import TextInput from "../../../UI/TextInput";
import Select from "../../../UI/Select";

export default function ModifChargeInputs({ typeCharge }) {
  return (
    <>
      <TextInput
        type="date"
        name={"date_effet"}
        label={"Date d'effet de l'avenant"}
      />
      <Select name={"type_edit_price"}>
        <option value={""}>Choisir une option</option>
        {typeCharge === "provision" ? (
          <>
            <option value={"augmentation_provision"}>
              Augmentation de la provision sur charge
            </option>
            <option value={"diminution_provision"}>
              Diminution de la provision sur charge
            </option>
            <option value={"provision_forfait"}>
              Passage de provision de charge vers forfait de charge
            </option>
          </>
        ) : (
          <>
            <option value={"augmentation_forfait"}>
              Augmentation du forfait de charge
            </option>
            <option value={"diminution_forfait"}>
              Diminution du forfait de charge
            </option>
            <option value={"forfait_provision"}>
              Passage de forfait de charge vers provision sur charge
            </option>
          </>
        )}
      </Select>
      <TextInput
        type={"number"}
        name={"charges"}
        label={"Nouveau montant des charges"}
        placeholder={"48,20"}
        unit={"€"}
        step={"0.01"}
      />
    </>
  );
}
