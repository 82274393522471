import useResources from "./use-resources";

const ROOT_PATH = "finance/split-templates";

function useFinanceSplitTemplates() {
  const {
    resources: splitTemplates,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources(ROOT_PATH);

  return {
    splitTemplates,
    error,
    isLoading,
    addFinanceSplitTemplate: create,
    updateFinanceSplitTemplate: update,
    deleteFinanceSplitTemplate: del,
  };
}

export default useFinanceSplitTemplates;
