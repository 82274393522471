import Card from "../UI/Card";
import { Link } from "react-router-dom";
import useAccount from "../../hooks/use-account";

export default function DisabledBienNote({ className }) {
  const { account } = useAccount();
  return (
    <Card padding={"md"} className={className}>
      {account.type_abonnement === "gratuit" ? (
        <>
          Utilisation limitée à un bien.{" "}
          <Link to={"/mon-compte"}>Abonnez-vous</Link> (période d’essai
          gratuite). Satisfait ou remboursé 6 mois.
        </>
      ) : (
        <>
          Limite de biens gérables atteinte,{" "}
          <Link to={"/mon-compte"}>augmentez votre abonnement</Link> ou archivez
          des biens actifs.
        </>
      )}
    </Card>
  );
}
