import useResources from "./use-resources";

export default function useMails() {
  const { resources: mails, error, isLoading, update } = useResources("mails");

  return {
    mails,
    error,
    isLoading,
    updateMail: update,
  };
}
