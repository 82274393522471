import {
  EDL_ITEM_MATERIAUX_TEMPLATE_INITIAL_VALUE,
  edlItemMateriauxTemplateValidationSchema,
} from "../../models/edl";
import { FieldArray, Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import ButtonCard from "../UI/ButtonCard";
import { Croix, PlusBank } from "../UI/Icons";
import Button from "../UI/Button";
import { toInitialValuesFromSchema } from "../../models/utils";
import React from "react";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function EdlMateriauxTemplateForm({
  materiauxTemplateToEdit,
  onDelete,
  onSubmit,
}) {
  const initialValues = toInitialValuesFromSchema(
    edlItemMateriauxTemplateValidationSchema,
    materiauxTemplateToEdit,
    EDL_ITEM_MATERIAUX_TEMPLATE_INITIAL_VALUE,
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        return (
          <Form>
            <TextInput type={"text"} name={"nom"} label={"Nom de l'élément"} />
            <FieldArray
              name="materiaux"
              render={(arrayHelpers) => (
                <>
                  {formikProps.values?.materiaux.map((child, index) => (
                    <div className={"flex align-items-center"} key={index}>
                      <TextInput
                        name={`materiaux[${index}]`}
                        type={"text"}
                        label={"materiaux"}
                        className={"marginL10 marginR10"}
                      />
                      <span onClick={() => arrayHelpers.remove(index)}>
                        <Croix />
                      </span>
                    </div>
                  ))}
                  <ButtonCard
                    type={"button"}
                    className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10 w-100`}
                    onClick={() => {
                      arrayHelpers.push("");
                    }}
                  >
                    <PlusBank />
                    <p className={"secondaryText marginL10"}>
                      Ajouter un matériaux
                    </p>
                  </ButtonCard>
                </>
              )}
            />
            <FieldArray
              name="degradations"
              render={(arrayHelpers) => (
                <>
                  {formikProps.values?.degradations.map((child, index) => (
                    <div className={"flex align-items-center"} key={index}>
                      <TextInput
                        name={`degradations[${index}]`}
                        type={"text"}
                        label={"Dégradation"}
                        className={"marginL10 marginR10"}
                      />
                      <span onClick={() => arrayHelpers.remove(index)}>
                        <Croix />
                      </span>
                    </div>
                  ))}
                  <ButtonCard
                    type={"button"}
                    className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10 w-100`}
                    onClick={() => {
                      arrayHelpers.push("");
                    }}
                  >
                    <PlusBank />
                    <p className={"secondaryText marginL10"}>
                      Ajouter une dégradation
                    </p>
                  </ButtonCard>
                </>
              )}
            />
            <div className={"text-right"}>
              <Button
                type="button"
                disabled={formikProps.isSubmitting || !materiauxTemplateToEdit}
                className={"marginL10"}
                onClick={() => onDelete(materiauxTemplateToEdit.id)}
              >
                Supprimer
              </Button>
              <FormikSubmitButton className={"marginL10"}>
                Ajouter
              </FormikSubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EdlMateriauxTemplateForm;
