import { SignatureContratStatus } from "../../models/signatureElectronique";
import Tooltip from "../UI/Tooltip";
import SignatureStatusMark from "./SignatureStatusMark";
import { Interrogation } from "../UI/Icons";
import Status from "../UI/Status";
import React from "react";

export default function ContractStatusIndicator({ file }) {
  if (!file) return null;
  let signatureStatus = "";
  switch (file.signature_electronique_status) {
    case SignatureContratStatus.Opened.status:
    case SignatureContratStatus.Open.status:
      signatureStatus = (
        <Status
          color={"yellow"}
          text={
            <>
              Signature électronique en attente{" "}
              {file.signataires.length > 0 && (
                <Tooltip
                  content={file.signataires.map((signataire, i) => (
                    <p key={i}>
                      {signataire.prenom} {signataire.nom}{" "}
                      <SignatureStatusMark status={signataire.status} />
                    </p>
                  ))}
                >
                  <Interrogation />
                </Tooltip>
              )}
            </>
          }
        />
      );
      break;
    case SignatureContratStatus.Signed.status:
    case SignatureContratStatus.Closed.status:
    case SignatureContratStatus.Archived.status:
      signatureStatus = (
        <Status color={"green"} text={"Signé électroniquement"} />
      );
      break;
    case SignatureContratStatus.Canceled.status:
      signatureStatus = (
        <Status color={"red"} text={"Signature électronique annulée"} />
      );
      break;
    default:
      signatureStatus = "";
  }

  return signatureStatus;
}
