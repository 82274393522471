import useResources from "./use-resources";

export default function useCourriers(type, draft = false) {
  const {
    resources: courriers,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources("courriers", { params: { type: type, draft: draft } });

  return {
    courriers,
    error,
    isLoading,
    updateCourrier: update,
    addCourrier: create,
    deleteCourrier: del,
  };
}
