import { Check, Croix } from "../UI/Icons";
import { SignatureStatus } from "../../models/signatureElectronique";

export default function SignatureStatusMark({ status }) {
  if (status === SignatureStatus.Signed.status) {
    return <Check width="10" height="10" color="green" />;
  } else {
    return <Croix color={"red"} />;
  }
}
