import ButtonCard from "../UI/ButtonCard";
import { classnames } from "../../utils";
import styles from "../Tenant/Actions/SelectCourrier.module.css";
import Tabs from "../UI/Tabs";

export default function CardCourrier({
  onSelectCourrier,
  type,
  disabled,
  courriers,
}) {
  const { selectTab } = Tabs.useActiveTab();
  return (
    <div className={classnames(styles.grid)}>
      {courriers.map((courrier, i) => (
        <ButtonCard
          key={i}
          onClick={() => {
            onSelectCourrier(courrier.static_id);
            selectTab("form");
          }}
          type={type}
          className={"text-top"}
          disabled={disabled}
          padding={"sm"}
        >
          <p className={"primaryText text-left primaryBlue"}>{courrier.nom}</p>
          <p className={"marginT10 secondaryText text-left"}>{courrier.text}</p>
        </ButtonCard>
      ))}
    </div>
  );
}
