import Card from "../UI/Card";
import DisplayField from "../UI/DisplayField";
import {
  Briefcase,
  Croix,
  Email,
  FinanceIcon,
  Question,
  Tel,
  ZoneTendue,
} from "../UI/Icons";
import styles from "./ProfileCard.module.css";
import moment from "moment";
import "moment/min/locales";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import TenantShowFiles from "../Tenant/TenantShowFiles";
import InitialesLogo from "../UI/InitialesLogo";
import { useModal } from "../../hooks/use-modal";
import DisplayName from "../UI/DisplayName";
import { currencyFormatter } from "../../api/Functions";
import { classnames, TextOneOrMany } from "../../utils";
import { RemunerationType } from "../../utils/constants/mandataire";
import { ProfileInviteStatus } from "../../models/profile";
import useLoader from "../../hooks/use-loader";

moment.locale("fr");

export default function ProfileCard({
  modifierAction,
  supprimerAction,
  archiverAction,
  activateAction,
  inviteAction,
  profile,
  candidater,
  isGarant,
  checked,
  removeGarant,
  isDraft,
  isBailleur,
  mandataire,
}) {
  const [Modal, , openModal] = useModal(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [inviteLoading, inviteActionLoader] = useLoader(inviteAction);

  if (mandataire) profile = mandataire.profile;
  if (!profile) return null;

  let inviteStatus = "";
  let disableInvite = false;
  if (profile.status_invite === ProfileInviteStatus.INVITED) {
    inviteStatus = "Invité";
  } else if (profile.status_invite === ProfileInviteStatus.CONNECTED) {
    inviteStatus = "Connecté";
    disableInvite = true;
  } else {
    inviteStatus = "Inviter";
  }

  return (
    <Card checked={checked} className={"marginT10"}>
      {profile && (
        <>
          <Modal>
            <TenantShowFiles files={profile.files} />
          </Modal>
          <div className={`flex space-between wrap-reverse gap10`}>
            <div className={"flex align-items-center flex-grow"}>
              <InitialesLogo
                nom={profile.nom}
                prenom={profile.prenom}
                shape={"rond"}
              />

              <div>
                {parseInt(profile.type) === 1 ? (
                  <>
                    <p className={"primaryText ws-nowrap"}>
                      <DisplayName profile={profile} />
                    </p>
                    <div className={"secondaryText"}>
                      {profile.birthday
                        ? `Né le ${moment(
                            profile.birthday,
                            "YYYY-MM-DD",
                          ).format("DD MMMM YYYY")}
                       (${moment().diff(profile.birthday, "years")} ans) ${
                         profile.lieu_naissance
                           ? " à " + profile.lieu_naissance
                           : ""
                       }`
                        : ""}
                    </div>
                  </>
                ) : (
                  <>
                    <p className={"primaryText"}>
                      <DisplayName profile={profile} />
                    </p>
                    <div className={"secondaryText"}>
                      {"Représentée par " + profile.prenom + " " + profile.nom}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.edit}>
              {activateAction && !candidater && !isGarant && (
                <Button
                  type="button"
                  buttonStyle="secondaryGreen"
                  onClick={() => {
                    activateAction();
                  }}
                >
                  Réactiver
                </Button>
              )}
              {archiverAction && !candidater && !isGarant && (
                <Button
                  type="button"
                  buttonStyle="secondaryDelete"
                  onClick={() => {
                    archiverAction();
                  }}
                >
                  Archiver
                </Button>
              )}
              {supprimerAction && !candidater && !isGarant && (
                <Button
                  type="button"
                  buttonStyle="secondaryDelete"
                  onClick={() => {
                    supprimerAction();
                  }}
                >
                  Supprimer
                </Button>
              )}
              {inviteAction && (
                <Button
                  type="button"
                  buttonStyle="secondaryGreen"
                  onClick={() => {
                    inviteActionLoader();
                  }}
                  isLoading={inviteLoading}
                  disabled={disableInvite}
                >
                  {inviteStatus}
                </Button>
              )}
              {isDraft && (
                <>
                  <Button buttonStyle="secondaryYellow" disabled>
                    Brouillon
                  </Button>
                </>
              )}
              {modifierAction && !candidater && (
                <Button
                  type={"button"}
                  onClick={() => {
                    modifierAction(profile.id);
                  }}
                  buttonStyle="secondary"
                >
                  Modifier
                </Button>
              )}
              {modifierAction && !candidater && !mandataire && (
                <>
                  {!isBailleur && (
                    <Button buttonStyle={"secondary"} onClick={openModal}>
                      Documents
                    </Button>
                  )}
                  {!isBailleur && !isGarant && (
                    <Button
                      onClick={() =>
                        navigate(`${profile.id}/add_garant${location.search}`)
                      }
                      buttonStyle={"secondary"}
                    >
                      Ajouter un garant
                    </Button>
                  )}
                </>
              )}
              {!candidater && isGarant && removeGarant && (
                <span
                  className={"marginL10 cursorPointer"}
                  onClick={() => removeGarant(profile.id)}
                >
                  <Croix />
                </span>
              )}
            </div>
          </div>
          <div className={classnames(styles.fields, "marginT20")}>
            <DisplayField
              icon={<Email />}
              fieldName={"Adresse e-mail"}
              fieldValue={profile.mail || "Non renseigné"}
              copiable={!!profile.mail}
            />
            <DisplayField
              icon={<Tel />}
              fieldName={"Numéro de téléphone"}
              fieldValue={profile.tel || "Non renseigné"}
              copiable={!!profile.tel}
            />
            <DisplayField
              icon={<ZoneTendue size={"14"} />}
              fieldName={
                profile.cp && profile.ville
                  ? profile.cp + " " + profile.ville
                  : "Non renseigné"
              }
              fieldValue={profile.adresse || "Non renseigné"}
              copiable={!!profile.adresse}
            />

            {!isBailleur && !mandataire && (
              <>
                <DisplayField
                  icon={<Briefcase />}
                  fieldName="Activité professionnelle"
                  fieldValue={profile.activite || "Non renseigné"}
                />
                {profile.type === 1 && (
                  <DisplayField
                    icon={<FinanceIcon />}
                    fieldName="Revenus"
                    fieldValue={
                      profile.revenus_brut
                        ? currencyFormatter(profile.revenus_brut)
                        : "Non renseigné"
                    }
                  />
                )}
              </>
            )}
            {profile.type === 2 && !mandataire && (
              <>
                <DisplayField
                  icon={<Question />}
                  fieldName="SCI Familiale ?"
                  fieldValue={profile.sci_famille ? "Oui" : "Non"}
                />
              </>
            )}
            {mandataire && (
              <>
                <DisplayField
                  icon={<Question />}
                  fieldName="Carte G"
                  fieldValue={mandataire.carte_g}
                />
                <DisplayField
                  icon={<Question />}
                  fieldName="Rémunération"
                  fieldValue={`${currencyFormatter(
                    mandataire.remuneration_montant,
                    false,
                  )} ${
                    RemunerationType.isPourcentage(mandataire.remuneration_type)
                      ? "%/mois"
                      : "€/mois"
                  }`}
                />
                <DisplayField
                  icon={<Question />}
                  fieldName="TVA"
                  fieldValue={mandataire.tva ? "Oui" : "Non"}
                />
              </>
            )}
          </div>
        </>
      )}
      {!isBailleur && !isDraft && profile.caution_type === "Visale" ? (
        <>
          <div className={"marginT20"}>
            <div className={styles.line}></div>
            <h3>Garant</h3>
          </div>
          <Card>
            <div className={styles.fields}>
              <Link to={"https://www.visale.fr"} target="_blank">
                <img src={"/logo-visale.png"} alt={"Logo Visale"} />
              </Link>
              <DisplayField
                icon={<Email />}
                fieldName={"Numéro du visa"}
                fieldValue={profile.contract_number || "Non renseigné"}
              />
              <DisplayField
                icon={<Email />}
                fieldName={"Loyer maximum garantie"}
                fieldValue={
                  currencyFormatter(profile.loyer_max_visale) || "Non renseigné"
                }
              />
              <DisplayField
                icon={<Email />}
                fieldName={"Date de validité du visa"}
                fieldValue={
                  moment(profile.validite_visale).format("DD MMMM YYYY") ||
                  "Non renseigné"
                }
              />
            </div>
          </Card>
        </>
      ) : (
        !isBailleur &&
        !isDraft &&
        profile.garantors?.length > 0 && (
          <div className={"marginT20"}>
            <div className={styles.line}></div>
            <h3>
              <TextOneOrMany
                one={"Garant"}
                many={"Garants"}
                number={profile.garantors.length}
                none={""}
              />
            </h3>
            {profile.garantors.map((garantor) => (
              <ProfileCard
                profile={garantor}
                key={garantor.id}
                isGarant
                removeGarant={removeGarant}
                candidater={candidater}
                modifierAction={modifierAction}
              />
            ))}
          </div>
        )
      )}
    </Card>
  );
}
