import {
  TRANSACTION_CHILD_INITIAL_VALUE,
  transactionChildValidationSchema,
} from "./transactionChild";
import { array, number, object } from "yup";
import { stringMax } from "./utils";

export const FINANCE_DECOUPE_FORM_INITIAL_VALUE = {
  splits: [TRANSACTION_CHILD_INITIAL_VALUE],
  id_profile_nom: "",
  nom: "",
};

export const financeDecoupeValidationSchema = object({
  nom: stringMax(250).required(" "),
  id_profile_nom: number().required(" "),
  splits: array().of(transactionChildValidationSchema),
});
