import useAuth from "./use-auth";
import axios from "../api";

function useLogout() {
  const { resetAuth } = useAuth();

  async function logout() {
    resetAuth();
    try {
      await axios.post("/logout");
    } catch (err) {
      console.error(err);
    }
  }

  return logout;
}

export default useLogout;
