import useSWR from "swr";
import axios from "axios";

export default function useFrontVersion() {
  const { data: version } = useSWR(
    `${process.env.REACT_APP_FRONTEND_URL}/version`,
    (url) => axios.get(url).then((res) => res.data),
    {
      refreshInterval: 60000,
      fallbackData: null,
      shouldRetryOnError: false,
    },
  );

  return {
    version,
    upToDate:
      version == null ||
      version === process.env.REACT_APP_VERSION ||
      process.env.REACT_APP_FRONTEND_URL.startsWith("http://"),
  };
}
