import { classnames } from "../../utils";
import styles from "./IconAndText.module.css";

function IconAndText({ icon, text, className }) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <div>{icon}</div>
      <div>{text}</div>
    </div>
  );
}

export default IconAndText;
