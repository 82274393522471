import { DocType } from "../utils/constants/documents";
import useResource from "./use-resource";
import useDocumentBlob from "./use-document-blob";

export default function useDocument(
  url,
  docType = DocType.GENERIC_FILE,
  withBlob = false,
) {
  const {
    resource: document,
    error,
    create,
    del,
    isLoading,
  } = useResource(url);

  const {
    blob: documentBlob,
    error: errorBlob,
    isLoading: isLoadingBlob,
    refresh,
  } = useDocumentBlob(withBlob ? document?.id : null, docType);

  async function createDocument(document) {
    const created = await create(document);
    refresh();
    return created;
  }

  async function deleteDocument(documentId) {
    await del(documentId);
    refresh();
  }

  return {
    document,
    documentBlob,
    error,
    errorBlob,
    isLoading,
    isLoadingBlob,
    createDocument,
    deleteDocument,
  };
}
