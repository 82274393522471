import moment from "moment/moment";
import { Table } from "../UI/Table";
import { TenantStatus } from "../../models/tenant";
import useTenants from "../../hooks/use-tenants";
import InitialesLogo from "../UI/InitialesLogo";
import useProperties from "../../hooks/use-properties";
import { Interrogation, Property } from "../UI/Icons";
import { Link } from "react-router-dom";
import style from "./Dashboard.module.css";
import { currencyFormatter } from "../../api/Functions";
import useBalancesDashboard from "../../hooks/use-balances-dashboard";
import { useAllTenantsBalances } from "../../hooks/use-balances";
import Tooltip from "../UI/Tooltip";

function LoyersShow() {
  const { properties } = useProperties();
  const { tenants } = useTenants();
  const { balancesDashboard } = useBalancesDashboard();
  const { balances } = useAllTenantsBalances(false);

  let rows = tenants
    .filter((filtered) => filtered.status === TenantStatus.Actif.status)
    .map((tenant, i) => {
      const balanceTenant = balances?.find(
        (balance) => balance?.id_tenant === tenant.id,
      );
      const balanceDashboard = balancesDashboard.find(
        (balance) => balance.id_tenant === tenant.id,
      );
      let showDGClass;
      if (balanceTenant?.dg_credit + balanceTenant?.dg_debit >= 0) {
        showDGClass = "green";
      } else {
        showDGClass = "error";
      }
      let showBalanceClass;
      if (balanceTenant?.balance >= 0) {
        showBalanceClass = "green";
      } else if (balanceTenant?.balance < -tenant.loyer_hc - tenant.charges) {
        showBalanceClass = "error";
      } else {
        showBalanceClass = "warning";
      }
      let property = properties.find(
        (property) => property.id === tenant.id_bien,
      );
      return (
        <Table.Row key={i}>
          <Table.Cell>
            <div className={"flex"}>
              <InitialesLogo
                nom={tenant.profile_nom.nom_profile}
                prenom={tenant.profile_nom.nom_profile}
                shape={"rond"}
              />
              <div className={"marginL5"}>
                <div className={"marginB2"}>
                  <Link to={`/bailleur/tenants/${tenant.id}`}>
                    {tenant.profile_nom.nom_profile}
                  </Link>
                </div>
                <div className={style.smallText}>
                  <Link
                    key={property?.id}
                    to={"/bailleur/properties/" + property?.id}
                  >
                    <Property /> {property?.nom}
                  </Link>
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className={`marginL10 text-center ${showDGClass}`}>
              {balanceTenant?.dg_debit}/{-balanceTenant?.dg_credit}€
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className={`marginL10 text-center ${showBalanceClass}`}>
              {balanceTenant?.balance.toLocaleString()}€
            </div>
          </Table.Cell>
          {Array.from({ length: 6 }, (_, i) => {
            let monthMontant = 0;
            let monthDate = "";
            balanceDashboard?.last_payments
              .filter((payments) =>
                moment()
                  .subtract(i, "month")
                  .isSame(moment(payments.date_transac), "month"),
              )
              .forEach((val) => {
                monthMontant = monthMontant + val.montant;
                monthDate = val.date_transac;
              });
            return (
              <Table.Cell key={i} align="right" className="paddingL20">
                <p className={"text-right"}>
                  {monthMontant !== 0 && currencyFormatter(monthMontant)}
                </p>
                <p className={"secondaryText"}>{monthDate}</p>
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Column></Table.Column>
          <Table.Column align={"center"}>
            DG{" "}
            <Tooltip
              content={"Dépôt de garantie perçu / dépôt de garantie appelé"}
            >
              <Interrogation />
            </Tooltip>
          </Table.Column>
          <Table.Column align={"center"}>Solde</Table.Column>
          {Array.from({ length: 6 }, (_, i) => (
            <Table.Column key={i} align="right">
              {moment().subtract(i, "month").format("MMMM YYYY")}
            </Table.Column>
          ))}
        </Table.Header>
        <Table.Body>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Row>
              <Table.Cell colSpan="100%">
                <div className={"text-center secondaryText marginT20"}>
                  Vous n'avez aucun locataire
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

export default LoyersShow;
