import { TenantStatus } from "../../models/tenant";
import useProperties from "../../hooks/use-properties";
import Tabs from "../UI/Tabs";
import ShowCandidatList from "./ShowCandidatList";

function CandidatList({ balances, profiles, tenants }) {
  const { properties } = useProperties();

  const candidats = tenants.filter(
    (tenant) => tenant.status === TenantStatus.Candidat.status,
  );

  const candidatsArchives = tenants.filter(
    (tenant) => tenant.status === TenantStatus.CandidatArchive.status,
  );
  return (
    <Tabs id="candidat-list">
      <div className="container bg-blue paddingT10 border-blue">
        <div className="content">
          <Tabs.TabList>
            <Tabs.Tab panelId="candidats">
              Candidats ({candidats.length})
            </Tabs.Tab>
            <Tabs.Tab panelId="candidatsArchives">Candidats archivés</Tabs.Tab>
          </Tabs.TabList>
        </div>
      </div>
      <div className="blueSpace"></div>
      <div className="content">
        <Tabs.TabPanel id="candidats">
          <ShowCandidatList
            tenants={candidats}
            properties={properties}
            profiles={profiles}
            balances={balances}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel id="candidatsArchives">
          <ShowCandidatList
            tenants={candidatsArchives}
            properties={properties}
            profiles={profiles}
            balances={balances}
          />
        </Tabs.TabPanel>
      </div>
    </Tabs>
  );
}

export default CandidatList;
