import Card from "../UI/Card";
import { useNavigate } from "react-router-dom";
import ProfileIndivList from "./ProfileIndivList";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import ProfileNomShareForm from "./ProfileNomShareForm";
import useAuth from "../../hooks/use-auth";
import { TextOneOrMany } from "../../utils";
import { Croix } from "../UI/Icons";

function ProfileNomCard({ profileNom, onShare, onShareDelete }) {
  const nav = useNavigate();

  const { auth } = useAuth();
  const [ShareModal, closeShareModal, openShareModal] = useModal();

  async function shareProfileHandler(values, { resetForm }) {
    try {
      await onShare(profileNom.id, values.mail);
    } catch (e) {
      console.error(e);
    }
    resetForm();
    closeShareModal();
  }

  return (
    <>
      <ShareModal size={"medium"}>
        <ProfileNomShareForm onSubmit={shareProfileHandler} />
      </ShareModal>
      <Card className={"marginB10"}>
        <div className={"flex space-between"}>
          <p className={"cardTitle"}>{profileNom.nom_profile}</p>
          <div className={"flex wrap gap10"}>
            {auth.userId === profileNom.id_compte && (
              <Button buttonStyle={"secondary"} onClick={openShareModal}>
                Partager la gestion
              </Button>
            )}
            <Button
              buttonStyle={"secondary"}
              onClick={() => nav(`${profileNom.id}`)}
            >
              Modifier
            </Button>
          </div>
        </div>
        <ProfileIndivList
          profiles={profileNom.profiles}
          dontAddGarant
          isBailleur
        />
        {profileNom.comptes?.length > 1 && (
          <Card className={"marginT10"}>
            <p className={"primaryText"}>
              <TextOneOrMany
                one={"Profil bailleur partagé avec le compte Qalimo suivant :"}
                many={
                  "Profil bailleur partagé avec les comptes Qalimo suivants :"
                }
                number={profileNom.comptes?.length - 1}
              />
            </p>
            {profileNom.comptes
              ?.filter((filtered) => filtered.mail !== auth.mail)
              .map((compte, i) => (
                <p className={"secondaryText marginT5"} key={i}>
                  {compte.mail}{" "}
                  {auth.userId === profileNom.id_compte && (
                    <span
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Voulez-vous vraiment supprimer ce partage ?",
                          )
                        ) {
                          try {
                            await onShareDelete(profileNom.id, compte.mail);
                          } catch (e) {
                            console.error(e);
                          }
                        }
                      }}
                    >
                      <Croix />
                    </span>
                  )}
                </p>
              ))}
          </Card>
        )}
      </Card>
    </>
  );
}

export default ProfileNomCard;
