import { Form, Formik } from "formik";
import Card from "../UI/Card";
import { useState } from "react";
import { handleAPIError } from "../../utils";
import { toInitialValuesFromSchema } from "../../models/utils";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { SMTP_INITIAL_VALUES, smtpSchema } from "../../models/monCompte";

export default function SmtpForm({ onSubmit, account }) {
  const [error, setError] = useState("");

  async function submitHandler(values) {
    setError("");
    try {
      await onSubmit(values);
    } catch (error) {
      setError(handleAPIError(error));
    }
  }
  const initialValues = toInitialValuesFromSchema(
    smtpSchema,
    account,
    SMTP_INITIAL_VALUES,
  );
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={smtpSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <div className={"flex wrap gap10"}>
          <TextInput
            name={"smtp_serveur"}
            label={"Adresse du serveur de messagerie"}
            placeholder={"smtp.gmail.com"}
          />
          <TextInput name={"smtp_port"} label={"Port"} placeholder={"465"} />
        </div>
        <TextInput
          name={"smtp_mail"}
          label={"Adresse email"}
          placeholder={"youremail@gmail.com"}
        />
        <TextInput
          name={"smtp_password"}
          type={"password"}
          label={"Mot de passe de l'adresse email"}
        />

        <div className={"text-right"}>
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
        {error && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {error}
          </Card>
        )}
      </Form>
    </Formik>
  );
}
