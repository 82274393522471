import style from "../Property.module.css";
import InitialesLogo from "../../UI/InitialesLogo";
import PropertyDisplayImgAddress from "../PropertyDisplayImgAddress";
import Button from "../../UI/Button";
import HeaderPropertyForm from "../AddFormComponents/HeaderPropertyForm";
import {
  PROPERTY_FOLDER_INITIAL_VALUES,
  PROPERTY_INITIAL_VALUES,
  propertyHeadSchema,
  PropertyStatus,
} from "../../../models/property";
import PartForm from "../../UI/PartForm";
import { useModal } from "../../../hooks/use-modal";
import { Link } from "react-router-dom";
import { toInitialValuesFromSchema } from "../../../models/utils";
import { propertyFolderHeadSchema } from "../../../models/propertyFolder";
import { Plus } from "../../UI/Icons";
import PropertyFolderAddProperty from "../PropertyFolderAddProperty";

function HeaderProperty({
  property,
  onDelete,
  onArchive,
  onReactivate,
  onUpdate,
  profiles,
  isFolder,
  onMoveToFolder,
  onMoveFolderToFolder,
}) {
  const [
    HeaderPropertyFormModal,
    closeHeaderPropertyFormModal,
    openHeaderPropertyFormModal,
  ] = useModal();
  const [MoveFolder, closeMoveFolder, openMoveFolder] = useModal();

  const profileBailleur = profiles?.find(
    (profileNom) => profileNom.id === property.id_profile_nom_owner,
  );

  return (
    <>
      {isFolder && (
        <MoveFolder>
          <PropertyFolderAddProperty
            propertyFolderId={property.id}
            onMoveToFolder={async (propertyId) => {
              await onMoveToFolder(propertyId);
              closeMoveFolder();
            }}
            onMoveFolderToFolder={async (propertyFolderId) => {
              await onMoveFolderToFolder(propertyFolderId);
              closeMoveFolder();
            }}
          />
        </MoveFolder>
      )}
      <HeaderPropertyFormModal>
        <PartForm
          partForm={
            <HeaderPropertyForm profiles={profiles} isFolder={isFolder} />
          }
          validationSchema={
            isFolder ? propertyFolderHeadSchema : propertyHeadSchema
          }
          initialValues={toInitialValuesFromSchema(
            isFolder ? propertyFolderHeadSchema : propertyHeadSchema,
            property,
            isFolder ? PROPERTY_FOLDER_INITIAL_VALUES : PROPERTY_INITIAL_VALUES,
          )}
          submitHandler={onUpdate}
          deleteHandler={
            property.status === PropertyStatus.Archive.status
              ? () => onDelete(property.id)
              : undefined
          }
          archiveHandler={
            property.status === PropertyStatus.Actif.status
              ? () => onArchive(property.id)
              : undefined
          }
          reactivateHandler={
            property.status === PropertyStatus.Archive.status
              ? () => onReactivate(property.id)
              : undefined
          }
          closeModal={closeHeaderPropertyFormModal}
        />
      </HeaderPropertyFormModal>
      <div
        className={
          "flex align-items-start space-between paddingT20 paddingB20 wrap gap10"
        }
      >
        <PropertyDisplayImgAddress
          property={property}
          onPhotoUploaded={(photo) => {
            try {
              onUpdate({ photo1: photo.id });
            } catch (e) {
              console.error(e);
            }
          }}
        />

        <div>
          <h3 className={"marginT0 marginB5"}>Profil du bailleur</h3>
          <div className={"flex align-items-center"} key={profileBailleur?.id}>
            <span className={`${style.primaryText}`}>
              <InitialesLogo
                nom={profileBailleur?.nom_profile}
                prenom={" "}
                shape={"rond"}
              />
            </span>
            <Link to={"/bailleur/profiles"} className={"marginL5"}>
              {profileBailleur?.nom_profile}
            </Link>
          </div>
        </div>

        <div className={"text-right"}>
          <div>
            <Button
              buttonStyle={"secondary"}
              onClick={openHeaderPropertyFormModal}
            >
              Modifier
            </Button>
          </div>
          {isFolder && property.status === PropertyStatus.Actif.status && (
            <div>
              <Button
                onClick={() => {
                  openMoveFolder();
                }}
                className={"marginT10"}
              >
                <Plus stroke={"white"} />
                <span>Ajouter un bien dans ce dossier</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default HeaderProperty;
