import styles from "./TitleSearchAdd.module.css";
import Button from "../UI/Button";
import { Plus } from "./Icons";
import { classnames } from "../../utils";
import StandaloneTextInputGroup from "./StandaloneTextInputGroup";

function TitleSearchAdd({
  title,
  disableSearch,
  placeholder,
  disableAdd,
  textButton,
  addAction,
  setFilter,
}) {
  return (
    <div
      className={classnames("container bg-blue paddingT10", styles.noBorder)}
    >
      <div className={styles.title}>
        <h2>{title}</h2>
        <div className={styles.right}>
          {!disableSearch && (
            <StandaloneTextInputGroup
              onChange={(e) => setFilter(e.target.value.toLowerCase())}
              placeholder={placeholder}
            />
          )}
          {!disableAdd && (
            <Button onClick={addAction} className={"flex align-items-center"}>
              <Plus stroke={"white"} />
              <span className={"marginL10"}>{textButton}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TitleSearchAdd;
