import Card from "../UI/Card";
import { Table } from "../UI/Table";
import { currencyFormatter, dateConvert } from "../../api/Functions";
import useFactures from "../../hooks/use-factures";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import { DocType } from "../../utils/constants/documents";
import { PdfFile } from "../UI/Icons";
import AddressFacturation from "./AddressFacturation";
import Button from "../UI/Button";
import PaymentMethod from "../Stripe/PaymentMethod";
import useStripePaymentMethods from "../../hooks/use-stripe-payment-methods";
import useStripePayment from "../Stripe/Stripe";
import styles from "./Factures.module.css";
import Status from "../UI/Status";

export default function Factures() {
  const {
    paymentMethods,
    defaultPaymentMethod,
    deletePaymentMethod,
    markDefaultPaymentMethod,
    refresh: refreshPaymentMethods,
  } = useStripePaymentMethods();
  const { initiateSetup } = useStripePayment();
  const { factures } = useFactures();
  return (
    <div className={styles.wrapper}>
      <div>
        <Card>
          <div className={"flex space-between align-items-center"}>
            <p className={"cardTitle"}>Moyen de paiement</p>
            <Status
              color={paymentMethods.length > 0 ? "green" : "red"}
              text={paymentMethods.length > 0 ? "Actif" : "Inactif"}
            />
          </div>

          {paymentMethods.length === 0 && (
            <p className={"marginT20 secondaryText text-center"}>
              Aucune carte enregistrée
            </p>
          )}
          <div className={"gridColWrap240 marginT10"}>
            {paymentMethods.length > 0 &&
              paymentMethods.map((method, i) => (
                <PaymentMethod
                  method={method}
                  isDefault={defaultPaymentMethod === method.id}
                  onDelete={deletePaymentMethod}
                  onMarkDefault={markDefaultPaymentMethod}
                  key={i}
                />
              ))}
          </div>
          <div className={"text-right marginT20"}>
            <Button
              buttonStyle={"secondary"}
              className={"marginL10"}
              onClick={() =>
                initiateSetup({ onSetupSuccessful: refreshPaymentMethods })
              }
            >
              Ajouter une carte de paiement
            </Button>
          </div>
        </Card>
        <AddressFacturation />
      </div>
      <Card>
        <p className={"cardTitle marginB10"}>Factures</p>
        <Table>
          <Table.Header>
            <Table.Column>Date</Table.Column>
            <Table.Column>Montant</Table.Column>
            <Table.Column>Document</Table.Column>
          </Table.Header>
          <Table.Body>
            {factures?.map((facture, i) => (
              <Table.Row key={i}>
                <Table.Cell>{dateConvert(facture.date_add)}</Table.Cell>
                <Table.Cell>{currencyFormatter(facture.montant)}</Table.Cell>
                <Table.Cell>
                  <DocumentDownloadLink
                    documentId={facture.id}
                    doctype={DocType.FACTURE}
                    file={facture.file}
                  >
                    <PdfFile />
                  </DocumentDownloadLink>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
}
