import TextInput from "../../UI/TextInput";
import Select from "../../UI/Select";
import useProperties from "../../../hooks/use-properties";

function TenantHeaderForm() {
  const { properties } = useProperties();

  return (
    <>
      <TextInput
        type="text"
        name="profile_nom.nom_profile"
        placeholder="Nom du profil"
        label={"Nom du profil"}
      />

      <Select name="id_bien" label={"Bien rattaché"}>
        <option value="">Selectionnez un bien</option>
        {properties.map((option) => (
          <option key={option.id} value={option.id}>
            {option.nom}
          </option>
        ))}
      </Select>

      <TextInput
        name={"reference_dossier"}
        label={"Référence dossier"}
        placeholder={"R2189"}
      />
    </>
  );
}

export default TenantHeaderForm;
