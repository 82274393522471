import useResource from "./use-resource";

export default function useMandataire() {
  const {
    resource: mandataire,
    error,
    create,
    isLoading,
  } = useResource("mandataire");

  return {
    mandataire,
    error,
    isLoading,
    createOrUpdateMandataire: create,
  };
}
