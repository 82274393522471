import Card from "../../UI/Card";
import style from "./DescriptionBien.module.css";
import {
  Bank,
  CalendarCheck,
  Cctv,
  ConditionsParticulieres,
  DepensesEnergetiques,
  DescriptionLogement,
  HousePlus,
  HousePrivatif,
  HouseTree,
  Immeuble,
  Internet,
  Interrogation,
  Pieces,
  Surface,
  Temperature,
  Water,
} from "../../UI/Icons";
import DisplayItemIcon from "../../UI/DisplayItemIcon";
import Button from "../../UI/Button";
import PartForm from "../../UI/PartForm";
import EquipementsLocal from "../AddFormComponents/EquipementsLocal";
import {
  PROPERTY_INITIAL_VALUES,
  propertyEquipementsLocalSchema,
} from "../../../models/property";
import { useModal } from "../../../hooks/use-modal";
import { toInitialValuesFromSchema } from "../../../models/utils";
import moment from "moment/moment";
import { classnames } from "../../../utils";
import { capitalizeFirstLetter } from "../../../api/Functions";
import Tooltip from "../../UI/Tooltip";
import useLeaseTemplates from "../../../hooks/use-lease-templates";

export const ROOT_PATH = "/bailleur/properties/";

function DescriptionBien({ property, submitHandler }) {
  const { leaseTemplates } = useLeaseTemplates();

  const [Modal, closeModal, openModal] = useModal();

  if (!property) return null;
  const leaseTemplate = leaseTemplates?.find(
    (lease) => parseInt(property.id_lease_template) === lease.id,
  );
  const initialValues = toInitialValuesFromSchema(
    propertyEquipementsLocalSchema,
    property,
    PROPERTY_INITIAL_VALUES,
  );
  return (
    <>
      <Card>
        <Modal>
          <PartForm
            partForm={<EquipementsLocal edit={!!property.id} />}
            validationSchema={propertyEquipementsLocalSchema}
            initialValues={initialValues}
            submitHandler={submitHandler}
            closeModal={closeModal}
          />
        </Modal>
        <div className={`flex space-between align-items-center`}>
          <span className="cardTitle">Description du bien</span>
          <Button
            className={"marginL5"}
            buttonStyle={"secondary"}
            onClick={openModal}
          >
            Modifier
          </Button>
        </div>
        {property.type !== "garage" && (
          <div
            className={classnames(style.grid3Cards, "marginT20", "marginB20")}
          >
            <Card className={"padding10"}>
              <DisplayItemIcon
                icon={<Surface />}
                title={
                  property?.surface_total
                    ? `${property.surface_total}m²`
                    : "Non renseigné"
                }
                content={"Superficie habitable"}
              />
            </Card>
            <Card className={"padding10"}>
              <DisplayItemIcon
                icon={<Pieces />}
                title={
                  property.nb_pieces_principales
                    ? property.nb_pieces_principales >= 1
                      ? `${property.nb_pieces_principales} pièce`
                      : `${property.nb_pieces_principales} pièces`
                    : "Non renseigné"
                }
                content={"Nombre de pièces principales"}
              />
            </Card>
            <Card className={"padding10"}>
              <DisplayItemIcon
                icon={<Immeuble />}
                title={
                  property.type_habitat &&
                  `${capitalizeFirstLetter(property.type_habitat)} en ${
                    property.type_propriete
                  }`
                }
                content={"Régime du logement"}
              />
            </Card>
          </div>
        )}
        <div className={style.gridDescription}>
          {property.type !== "garage" && (
            <DisplayItemIcon
              icon={<HousePlus stroke="#707989" />}
              title={`Composition du ${
                property.type === "habitation" ? "logement" : "local"
              }`}
              content={
                property.autres_pieces?.length !== 0
                  ? property.autres_pieces.join(", ")
                  : "Non renseigné"
              }
              className={"marginT10"}
            />
          )}
          {property.type === "habitation" && (
            <>
              <DisplayItemIcon
                icon={<HousePrivatif />}
                title={"Parties de l'immeuble à usage privatif"}
                className={"marginT10"}
                content={
                  property.locaux_privatifs &&
                  property.locaux_privatifs.length !== 0
                    ? property.locaux_privatifs.join(", ")
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<Cctv />}
                title={"Equipements du logement"}
                className={"marginT10"}
                content={
                  property.equipements && property.equipements.length !== 0
                    ? property.equipements.join(", ")
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<HouseTree />}
                title={"Parties de l'immeuble à usage commun"}
                className={"marginT10"}
                content={
                  property.locaux_communs &&
                  property.locaux_communs.length !== 0
                    ? property.locaux_communs.join(", ")
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<HouseTree />}
                title={"Parties du logement à usage commun"}
                className={"marginT10"}
                content={
                  property.logement_espaces_communs &&
                  property.logement_espaces_communs.length !== 0
                    ? property.logement_espaces_communs.join(", ")
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<HouseTree />}
                title={"Parties du logement à usage privatif"}
                className={"marginT10"}
                content={
                  property.logement_espaces_privatifs &&
                  property.logement_espaces_privatifs.length !== 0
                    ? property.logement_espaces_privatifs.join(", ")
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<CalendarCheck stroke={"#9D9D9D"} />}
                title={"Année de construction"}
                className={"marginT10"}
                content={property.year_built}
              />
              <DisplayItemIcon
                icon={<Temperature />}
                title={"Chauffage"}
                className={"marginT10"}
                content={
                  property.chauffage === "individuel"
                    ? "Chauffage individuel"
                    : "Chauffage collectif"
                }
              />
              <DisplayItemIcon
                icon={<Water />}
                title={"Eau chaude"}
                className={"marginT10"}
                content={
                  property.eau_chaude === "individuel"
                    ? "Production d'eau chaude individuelle"
                    : "Production d'eau chaude collective"
                }
              />
              <DisplayItemIcon
                icon={<Internet />}
                title={"Internet"}
                className={"marginT10"}
                content={
                  property.technologies.length !== 0
                    ? `Type de raccordement à internet : ${property.technologies}`
                    : "Non renseigné"
                }
              />
              <DisplayItemIcon
                icon={<DepensesEnergetiques />}
                title={"Montant des dépenses théoriques"}
                className={"marginT10"}
                content={
                  property.depenses_energetiques_min &&
                  property.depenses_energetiques_max
                    ? `Montant estimé entre ${property.depenses_energetiques_min}€ et ${property.depenses_energetiques_max}€ par an`
                    : "Non renseigné"
                }
              />
            </>
          )}
          <DisplayItemIcon
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            title={"Date d'achat"}
            className={"marginT10"}
            content={
              property.date_achat
                ? moment(property.date_achat).format("DD MMMM YYYY")
                : "Non renseigné"
            }
          />
          <DisplayItemIcon
            icon={<Bank stroke={"#9D9D9D"} />}
            title={"IBAN"}
            className={"marginT10"}
            content={property.iban ?? "Non renseigné"}
          />
          {property.type !== "garage" && (
            <>
              <DisplayItemIcon
                icon={<Bank stroke={"#9D9D9D"} />}
                title={"Indice DPE"}
                className={"marginT10"}
                content={property.indice_dpe ?? "Non renseigné"}
              />
              <DisplayItemIcon
                icon={<Bank stroke={"#9D9D9D"} />}
                title={"Compteur d'eau"}
                className={"marginT10"}
                content={property.compteur_eau ?? "Non renseigné"}
              />
              <DisplayItemIcon
                icon={<Bank stroke={"#9D9D9D"} />}
                title={"Compteur d'électricité"}
                className={"marginT10"}
                content={property.compteur_elec ?? "Non renseigné"}
              />
              <DisplayItemIcon
                icon={<Bank stroke={"#9D9D9D"} />}
                title={"Compteur de gaz"}
                className={"marginT10"}
                content={property.compteur_gaz ?? "Non renseigné"}
              />
            </>
          )}
          {leaseTemplate?.identifiant_fiscal && (
            <DisplayItemIcon
              icon={<Bank stroke={"#9D9D9D"} />}
              title={"Identifiant fiscal"}
              className={"marginT10"}
              content={property.identifiant_fiscal ?? "Non renseigné"}
            />
          )}
        </div>
        <div className={"border-blue marginT20"}></div>
        {property.description && (
          <div className={" marginT20 marginB20"}>
            <DisplayItemIcon
              icon={<DescriptionLogement />}
              title={
                <>
                  Description du bien
                  <span className={"marginL5"}>
                    <Tooltip content={"Ce texte sera intégré au bail."}>
                      <Interrogation />
                    </Tooltip>
                  </span>
                </>
              }
              className={"marginT10"}
              content={`${property.description ?? "Non renseigné"}`}
            />
          </div>
        )}
        <div className={" marginT20 marginB20"}>
          <DisplayItemIcon
            icon={<DescriptionLogement />}
            title={
              <>
                Description publique du bien
                <span className={"marginL5"}>
                  <Tooltip
                    content={
                      "Ce texte sera visible par les candidats lorsqu'ils soumettrons leur dossier."
                    }
                  >
                    <Interrogation />
                  </Tooltip>
                </span>
              </>
            }
            className={"marginT10"}
            content={
              (
                <pre className="pre-whitespace">
                  {property.description_public}
                </pre>
              ) ?? "Non renseigné"
            }
          />
        </div>
        <div className={"marginT20 marginB20"}>
          <DisplayItemIcon
            icon={<ConditionsParticulieres />}
            title={"Article spécifique à faire paraître dans le bail"}
            className={"marginT10"}
            content={
              (
                <pre className="pre-whitespace">
                  {property.article_specific}
                </pre>
              ) ?? "Non renseigné"
            }
          />
        </div>
      </Card>
    </>
  );
}

export default DescriptionBien;
