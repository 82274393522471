import { Form, Formik } from "formik";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import TextArea from "../UI/TextArea";
import useTenants from "../../hooks/use-tenants";

export default function AddNoteTenant({ tenant, onClose }) {
  const { updateTenant } = useTenants();
  async function submitHandler(values) {
    try {
      await updateTenant(tenant.id, { notes: values.notes });
      onClose();
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Formik
      initialValues={{ notes: tenant.notes ?? "" }}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      {() => (
        <Form>
          <TextArea
            type="text"
            name="notes"
            label="Notes"
            rows="4"
            placeholder="Ne souhaite rester que pour 6 mois, etc"
          />
          <div className={"text-right"}>
            <FormikSubmitButton>Valider</FormikSubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}
