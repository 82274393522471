import { sendToBackend } from "./index";

export const ROOT_PATH = "biens";

export async function fetchPublicProperty(id) {
  try {
    const res = await sendToBackend(ROOT_PATH + "/" + id + "/public", 0);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchProperty(token, id, idTenant) {
  try {
    const res = await sendToBackend(`${ROOT_PATH}/${id}`, token, {
      params: { id_tenant: idTenant },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
