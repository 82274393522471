import styles from "./DisplayField.module.css";
import CopyToClipboard from "./CopyToClipboard";
import { classnames } from "../../utils";

function DisplayField({ icon, fieldName, fieldValue, className, copiable }) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.field}>
        <div>
          <p className={"secondaryText marginB5"}>{fieldName}</p>
          <p className={"primaryText"}>{fieldValue}</p>
        </div>
        {copiable && <CopyToClipboard toCopy={fieldValue} />}
      </div>
    </div>
  );
}

export default DisplayField;
