import AuthFormSignupVerify from "../../components/Auth/AuthFormSignupVerify";
import TemplateAuthentication from "../../components/UI/TemplateAuthentication";

function LoginPage() {
  return (
    <TemplateAuthentication>
      <div>
        <h1>Code confirmation</h1>
      </div>
      <div>
        <AuthFormSignupVerify />
      </div>
    </TemplateAuthentication>
  );
}

export default LoginPage;
