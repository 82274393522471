// SignaturePad.js
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import styles from "./SignaturePad.module.css";
import Button from "./Button";

export default function SignaturePad({ onSave }) {
  const sigCanvasRef = useRef(null);

  function clearSignature() {
    sigCanvasRef.current.clear();
  }

  return (
    <div>
      <p>Veuillez reproduire votre signature : </p>
      <SignatureCanvas
        ref={sigCanvasRef}
        canvasProps={{ width: 500, height: 200, className: styles.sigCanvas }}
      />
      <div className="text-right">
        <Button onClick={clearSignature} className={"marginR10"}>
          Réinitialiser
        </Button>
        <Button onClick={() => onSave(sigCanvasRef.current)}>
          Sauvegarder
        </Button>
      </div>
    </div>
  );
}
