import { Outlet, useOutletContext } from "react-router-dom";
import CandidatShow from "../../components/Candidat/CandidatShow";
import ChoseGarant from "../../components/Candidat/ChoseGarant";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";

function CandidatShowPage() {
  const {
    property,
    profiles,
    structProfiles,
    isLoadingProfiles,
    errorProfiles,
    deleteProfileHandler,
    addGarantToStateProfile,
    profileSubmittedHandler,
    removeGarant,
  } = useOutletContext();

  const [ModalChoseGarant, closeModalChoseGarant, openModalChoseGarant] =
    useModal();
  const [profileToGarantee, setProfileToGarantee] = useState();

  const outletContext = {
    profiles,
    profileSubmittedHandler: (profile, isCandidat) => {
      profileSubmittedHandler(profile);
      if (isCandidat) {
        setProfileToGarantee(profile);
        openModalChoseGarant();
      }
    },
  };

  return (
    <>
      <ModalChoseGarant>
        <ChoseGarant
          profile={profileToGarantee}
          closeModalChoseGarant={closeModalChoseGarant}
          propertyId={property?.id}
        />
      </ModalChoseGarant>
      <CandidatShow
        deleteProfileHandler={deleteProfileHandler}
        property={property}
        profiles={structProfiles}
        isLoadingProfiles={isLoadingProfiles}
        errorProfiles={errorProfiles}
        addGarantToStateProfile={addGarantToStateProfile}
        removeGarant={removeGarant}
      />
      <Outlet context={outletContext} />
    </>
  );
}

export default CandidatShowPage;
