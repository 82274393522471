import { useEffect, useId, useRef, useState } from "react";
import { useField } from "formik";
import style from "./FormUpload.module.css";
import { FolderArrowUp } from "../UI/Icons";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import Card from "../UI/Card";
import { classnames, handleAPIError } from "../../utils";
import ListFiles from "../UI/ListFiles";

/** @deprecated Use FileInput */
const FormUpload = ({
  tag,
  status,
  valid_until,
  label,
  path,
  edit,
  text = "Déposez vos fichiers",
  fileType = "file",
}) => {
  const axios = useAuthenticatedAxios();
  const [{ value }, , { setValue }] = useField(path || "files");
  const valueRef = useRef(value);
  const id = useId();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(0);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (!valueRef.current) {
      setValue([]);
      valueRef.current = [];
    }
  }, [setValue]);

  async function handleSubmit(tag, event, valid_until, status, isDrop) {
    event.preventDefault();
    setHighlight(false);
    const formData = new FormData();
    const selectedFiles = isDrop
      ? event.dataTransfer.files
      : event.target.files;
    for (const file of selectedFiles) {
      formData.append("selectedFiles", file);
    }
    formData.append("tag", tag);
    if (valid_until) {
      formData.append("valid_until", valid_until);
    }
    if (status) {
      formData.append("status", status);
    }
    try {
      const res = await axios.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setLoading(percentCompleted);
        },
      });
      const newFiles = res.data.map((file, i) => {
        return {
          ...file,
          localFile: selectedFiles[i],
        };
      });
      setValue([...value, ...newFiles]);
      event.target.value = null;
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  async function deleteFileHandler(fileToDelete) {
    setValue(value.filter((file) => file.src !== fileToDelete.src));
  }
  return (
    <div className={"marginT10"}>
      {label && <p>{label}</p>}
      {!edit && (
        <>
          <input
            type="file"
            onChange={(evt) =>
              handleSubmit(tag, evt, valid_until, status, false)
            }
            id={id}
            className={style.inputFile}
            accept=".png,.jpg,.jpeg,.pdf"
            multiple
          />

          <label
            htmlFor={id}
            className="flex align-items-center justify-content-flex-start"
          >
            <div
              className={classnames(
                style.wrapper,
                highlight && style.highlight,
              )}
              onDragLeave={(e) => setHighlight(false)}
              onDrop={(e) => handleSubmit(tag, e, valid_until, status, true)}
              onDragOver={(e) => {
                e.preventDefault();
                setHighlight(true);
              }}
            >
              <div className={style.icon}>
                <FolderArrowUp stroke="#0C69F5" />
              </div>
              <div>
                <p className={style.primary}>{text}</p>
                <p className={`${style.secondary} marginR10`}>
                  Format PNG, JPG, JPEG, PDF
                </p>
              </div>
            </div>
          </label>
          {loading !== 0 && loading !== 100 && (
            <>
              <p className={"secondaryText marginT10"}>
                Envoi du fichier en cours
              </p>
              <progress max="100" value={loading} className={"marginB10"}>
                {loading}%
              </progress>
            </>
          )}
          {error && (
            <Card type={"error"} className={"padding10 text-center marginT10"}>
              {error}
            </Card>
          )}
        </>
      )}

      <br />
      {value && (
        <ListFiles
          files={value.filter((file) => file.tag === tag)}
          fileType={fileType}
          onDelete={edit ? null : deleteFileHandler}
        />
      )}
      <br />
    </div>
  );
};

export default FormUpload;
