import style from "./Tenant.module.css";
import Card from "../UI/Card";
import { classnames } from "../../utils";
import { Fragment } from "react";
import useMediaQuery from "../../hooks/use-media-query";
import TenantRow from "./TenantRow";
import { ProfileStatus } from "../../models/profile";
import { TenantStatus } from "../../models/tenant";
import { isPropertyDisabled } from "../../utils/properties";

export default function ShowTenantList({
  tenants,
  properties,
  balances,
  isArchived,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 799px)");
  let index = 2;
  const disabledPropertyIds = new Set(
    properties
      .filter((prop) => isPropertyDisabled(prop))
      .map((prop) => prop.id),
  );
  const sortedTenants = tenants
    ? [...tenants].sort((a, b) =>
        a.id_bien in disabledPropertyIds && b.id_bien in disabledPropertyIds
          ? 0
          : a.id_bien in disabledPropertyIds
          ? 1
          : -1,
      )
    : [];

  function listTenants(tenant, archived = false) {
    function rowProfiles(prof, tenant, i) {
      return isSmallScreen ? (
        <Card key={i} className={"marginB10"} style={{ position: "relative" }}>
          <TenantRow
            properties={properties}
            balances={balances}
            profile={prof}
            tenant={tenant}
            index={index++}
          />
        </Card>
      ) : (
        <Fragment key={i}>
          <TenantRow
            properties={properties}
            balances={balances}
            profile={prof}
            tenant={tenant}
            index={index++}
          />
        </Fragment>
      );
    }

    if (archived)
      return tenant.profile_nom.profiles
        .filter(
          (profile) =>
            profile.garantor_of === null &&
            (tenant.status === TenantStatus.Archive.status ||
              profile.status === ProfileStatus.Archive.status),
        )
        .map((prof, i) => rowProfiles(prof, tenant, i));
    else
      return tenant.profile_nom.profiles
        .filter(
          (profile) =>
            profile.garantor_of === null &&
            profile.status === ProfileStatus.Actif.status,
        )
        .map((prof, i) => rowProfiles(prof, tenant, i));
  }

  const tenantList = isArchived ? (
    sortedTenants.length > 0 ? (
      sortedTenants.map((tenant) => listTenants(tenant, true))
    ) : (
      <Card noCard={!isSmallScreen}>Aucun locataire trouvé</Card>
    )
  ) : sortedTenants.length > 0 ? (
    sortedTenants.map((tenant) => listTenants(tenant))
  ) : (
    <Card noCard={!isSmallScreen}>Aucun locataire trouvé</Card>
  );

  return isSmallScreen ? (
    tenantList
  ) : (
    <Card className={`${style.rowLocataire}`}>
      <div className={style.head}>Nom du locataire</div>
      <div className={style.head}>Bien affecté</div>
      <div className={style.head}>Durée du bail</div>
      <div className={classnames(style.head, style.columnCenter)}>Loyer</div>
      <div className={style.head}></div>

      {tenantList}
    </Card>
  );
}
