import { useOutletContext, useParams } from "react-router-dom";
import ProfileForm from "../../components/Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";

function ProfileEditPage({ isProfile, isBailleur, withMandatoryFields }) {
  const { profileId } = useParams();
  const [Modal, closeModal] = useModal(true);

  const { profiles, profileSubmittedHandler } = useOutletContext();

  let profile;
  if (isProfile && profiles) {
    profile = profiles.find((profile) => profile.id === parseInt(profileId));
  }

  function onProfileUpdatedHandler(profile) {
    profileSubmittedHandler(profile);
    closeModal();
  }

  return (
    <ProfileForm
      edit
      isProfile={isProfile}
      profile={profile}
      profileId={parseInt(profileId)}
      onProfileSubmitted={onProfileUpdatedHandler}
      isBailleur={isBailleur}
      modal={Modal}
      withMandatoryFields={withMandatoryFields}
    />
  );
}

export default ProfileEditPage;
