import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import { sendToBackend } from "../../api";
import { contactInitialValues, contactSchema } from "../../models/contact";
import TextArea from "../UI/TextArea";
import useAuth from "../../hooks/use-auth";
import { useState } from "react";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function ContactForm({ closeContactModal }) {
  const { auth } = useAuth();
  const [error, setError] = useState("");

  async function submitHandler(values, { setSubmitting }) {
    try {
      await sendToBackend(`contact`, auth.accessToken, {
        method: "POST",
        data: values,
      });
      closeContactModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={submitHandler}
      initialValues={contactInitialValues}
      validationSchema={contactSchema}
    >
      <Form>
        <h1 className={"text-center"}>Contactez nous</h1>
        <h2>Déclarer un bug / besoin de fonctionnalité</h2>
        <TextInput
          type="text"
          name="sujet"
          placeholder="Comment créer un état des lieux ?"
        />
        <TextArea
          type="text"
          name="message"
          placeholder="Bonjour, je ne trouve pas comment créer un état des lieux, pourriez-vous m'en dire plus ?"
        />
        <div className={"text-right"}>
          <FormikSubmitButton>Envoyer</FormikSubmitButton>
        </div>
        {error && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {error}
          </Card>
        )}
      </Form>
    </Formik>
  );
}

export default ContactForm;
