import {
  BalanceDomain,
  BalanceTypeCategory,
} from "../../utils/constants/balances";
import { ReactSelect } from "../UI/Select";
import { useBalanceTypes } from "../../hooks/use-balances";
import { classnames } from "../../utils";

export default function BalanceTypeSelect({
  name,
  domaineBalance,
  className,
  mandatory,
}) {
  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  if (isLoading) return null;

  let options = [];
  if (domaineBalance === BalanceDomain.TENANT) {
    options = [
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_APPEL],
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_PAIEMENT],
      ...balanceTypesByCategory[BalanceTypeCategory.DG_CREDIT],
      ...balanceTypesByCategory[BalanceTypeCategory.DG_DEBIT],
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_REMBOURSEMENT],
    ].map((balanceType, i) => ({
      label: balanceType.text,
      value: balanceType.static_id,
    }));
  } else if (
    domaineBalance === BalanceDomain.PROPERTY ||
    domaineBalance === BalanceDomain.FOLDER
  ) {
    options = [
      ...balanceTypesByCategory[BalanceTypeCategory.CHARGE],
      ...balanceTypesByCategory[BalanceTypeCategory.TRAVAUX],
    ].map((balanceType, i) => ({
      label: balanceType.text,
      value: balanceType.static_id,
    }));
  }

  return (
    <ReactSelect
      label="Catégorie d'opération"
      placeholder="Appel loyer"
      name={name}
      className={classnames(className)}
      options={options}
      mandatory={mandatory}
    />
  );
}
