import ButtonCard from "../../UI/ButtonCard";
import IconAndText from "../../UI/IconAndText";
import { ArrowRight } from "../../UI/Icons";
import Tabs from "../../UI/Tabs";
import Button from "../../UI/Button";

export default function TypeLogement({ setFieldValue }) {
  const { selectNextTab, selectPreviousTab } = Tabs.useActiveTab();

  function next(type) {
    setFieldValue("type_habitat", type);
    selectNextTab();
  }

  return (
    <>
      <p className={"secondaryText"}>De quel type de logement s'agit-il ?</p>
      <ButtonCard
        className={"flex space-between align-items-center w-100 marginT10"}
        onClick={() => {
          next("collectif");
        }}
      >
        <IconAndText
          icon={
            <img
              src={`/ChoseLogementImmeuble.png`}
              alt="Immeuble"
              className={"center"}
            />
          }
          text={"Un logement dans un immeuble"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      <ButtonCard
        className={
          "flex space-between align-items-center w-100 marginT10 marginB10"
        }
        onClick={() => {
          next("individuel");
        }}
      >
        <IconAndText
          icon={<img src={`/ChoseLogementMaison.png`} alt={"Maison"} />}
          text={"Un logement indépendant"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      <div className={"text-right"} onClick={() => selectPreviousTab()}>
        <Button>Précédent</Button>
      </div>
    </>
  );
}
