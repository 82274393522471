import ButtonCard from "../../UI/ButtonCard";
import IconAndText from "../../UI/IconAndText";
import { ArrowRight } from "../../UI/Icons";
import Tabs from "../../UI/Tabs";

export default function TypeBienForm({ setFieldValue, setTypeBien }) {
  const { selectTab } = Tabs.useActiveTab();

  function next(type) {
    let nextTab = "";
    switch (type) {
      case "habitation":
        nextTab = "type-logement";
        setTypeBien("property");
        break;
      case "garage":
        nextTab = "bien";
        setTypeBien("property");
        break;
      case "folder":
        nextTab = "add-folder";
        setTypeBien("folder");
        break;
      case "commercial":
        nextTab = "bien";
        setTypeBien("property");
        break;
      default:
        nextTab = "bien";
        setTypeBien("property");
    }
    setFieldValue("type", type);
    selectTab(nextTab, true);
  }

  return (
    <>
      <p className={"secondaryText"}>Choisissez le type de bien à ajouter</p>
      <ButtonCard
        className={"flex space-between align-items-center w-100 marginT10"}
        onClick={() => {
          next("habitation");
        }}
      >
        <IconAndText
          icon={
            <img
              src={`/ChoseLocalHabitation.png`}
              alt="Créer une habitation"
              className={"center"}
            />
          }
          text={"Local à usage d'habitation (Maison, appartement, chambre)"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      <ButtonCard
        className={"flex space-between align-items-center w-100 marginT10"}
        onClick={() => {
          next("commercial");
        }}
      >
        <IconAndText
          icon={
            <img src={`/ChoseLocalCommercial.png`} alt={"local commercial"} />
          }
          text={"Local à usage commercial ou professionnel"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      <ButtonCard
        className={
          "flex space-between align-items-center w-100 marginT10 marginB10"
        }
        onClick={() => {
          next("garage");
        }}
      >
        <IconAndText
          icon={
            <img
              src={`/ChoseLocalParking.png`}
              alt="Créer un parking"
              className={"center"}
            />
          }
          text={"Autre type de local (parking, garage, stockage, etc.)"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      <ButtonCard
        className={
          "flex space-between align-items-center w-100 marginT10 marginB10"
        }
        onClick={() => {
          next("folder");
        }}
      >
        <IconAndText
          icon={
            <img
              src={`/ChoseLocalFolder.png`}
              alt="Créer un dossier"
              className={"center"}
            />
          }
          text={"Dossier (pour regrouper plusieurs biens dans un même groupe)"}
        />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
    </>
  );
}
