import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";

import AuthForm from "../../components/Auth/AuthForm";
import styles from "./LoginPage.module.css";
import TemplateAuthentication from "../../components/UI/TemplateAuthentication";
import useAuth from "../../hooks/use-auth";

export default function LoginPage() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idParrain = searchParams.get("referral") || location.state?.referral;

  const {
    auth: { accessToken },
  } = useAuth();

  if (accessToken) {
    return <Navigate to="/" />;
  }

  return (
    <TemplateAuthentication>
      <h1 className={styles.bigScreenOnly}>Bienvenue !</h1>
      <p>
        Vous n'avez pas encore de compte?
        <br />
        <Link
          to="/signup"
          state={{
            from: location.state?.from ?? location,
            referral: idParrain,
          }}
          replace
        >
          Créer un compte
        </Link>
      </p>
      <AuthForm />
    </TemplateAuthentication>
  );
}
