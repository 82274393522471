import { number, object, string } from "yup";
import { stringMax } from "./utils";

export const smtpSchema = object({
  smtp_serveur: stringMax(250).required(" "),
  smtp_mail: stringMax(250).required(" "),
  smtp_port: number().integer().required(" "),
  smtp_password: stringMax(250),
});

export const SMTP_INITIAL_VALUES = {
  smtp_serveur: "",
  smtp_mail: "",
  smtp_password: "",
  smtp_port: 465,
};

export const SignatureMailSchema = object({
  smtp_signature: string().required(" "),
});

export const SIGNATURE_MAIL_INITIAL_VALUES = {
  smtp_signature: "",
};
