import styles from "./RadioGroup.module.css";
import { classnames } from "../../utils";

export default function RadioGroup({
  children,
  onChange,
  className,
  layout,
  styling = "button-style",
}) {
  return (
    <fieldset
      className={classnames(
        "margin-form",
        styles[styling],
        styles[layout],
        styles.default,
        className,
      )}
      onChange={onChange}
    >
      {children}
    </fieldset>
  );
}
