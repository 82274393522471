import useResources from "./use-resources";

export default function useFactures() {
  const {
    resources: factures,
    error,
    isLoading,
  } = useResources("account/factures");

  return {
    factures,
    error,
    isLoading,
  };
}
