import Button from "./Button";
import { useFormikContext } from "formik";

export default function FormikSubmitButton({ children, ...props }) {
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      {...props}
      type="submit"
      disabled={isSubmitting || props.disabled}
      isLoading={isSubmitting || props.isLoading}
    >
      {children}
    </Button>
  );
}
