import { useField } from "formik";
import style from "./Checkbox.module.css";
import { useId } from "react";
import { classnames } from "../../utils";
import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";

export default function Checkbox({
  children,
  pillStyle,
  className,
  dependOn,
  ...props
}) {
  const [field] = useField({ ...props, type: "checkbox" });
  const id = useId();
  useDependentField(props.name, dependOn, usePropertyExtractor(props.name));
  return (
    <div className={classnames(className, pillStyle && style.pillStyle)}>
      <input id={id} type="checkbox" {...field} {...props} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}
