import useResources from "./use-resources";

export default function useTenantAutoActions(tenantId) {
  const {
    resources: tenantAutoActions,
    error,
    isLoading,
    create,
    del,
    update,
    mutate,
  } = useResources(tenantId ? `tenants/${tenantId}/auto-actions` : null);

  return {
    tenantAutoActions,
    error,
    isLoading,
    createTenantAutoAction: create,
    updateTenantAutoAction: update,
    deleteTenantAutoAction: del,
    refresh: () => mutate(),
  };
}
