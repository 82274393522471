import { Link } from "react-router-dom";
import SelectStandalone from "../UI/SelectStandalone";
import useProperties from "../../hooks/use-properties";
import { PropertyStatus } from "../../models/property";
import usePropertyFolders from "../../hooks/use-property-folders";

export default function PropertyFolderAddProperty({
  onMoveToFolder,
  onMoveFolderToFolder,
  propertyFolderId,
}) {
  const { properties } = useProperties();
  const { propertyFolders } = usePropertyFolders();

  return (
    <>
      {properties.length > 0 ? (
        <SelectStandalone
          className={"marginB10"}
          onChange={(e) => {
            onMoveToFolder(parseInt(e.target.value));
            e.target.value = "";
          }}
        >
          <option>Choisissez un bien</option>
          <option disabled>---Biens actifs---</option>
          {properties
            .filter(
              (filtered) =>
                filtered.status === PropertyStatus.Actif.status &&
                filtered.id_property_folder !== propertyFolderId,
            )
            .map((property, i) => (
              <option key={i} value={property.id}>
                {property.nom}
              </option>
            ))}
          <option disabled>---Biens archivés---</option>
          {properties
            .filter(
              (filtered) =>
                filtered.status === PropertyStatus.Archive.status &&
                filtered.id_property_folder !== propertyFolderId,
            )
            .map((property, i) => (
              <option key={i} value={property.id}>
                {property.nom}
              </option>
            ))}
        </SelectStandalone>
      ) : (
        <p>
          Vous n'avez aucune propriété. Vous pouvez en créer une dans la
          rubrique <Link to={"/bailleur/properties/add"}>Ajouter un bien</Link>.
        </p>
      )}
      {propertyFolders.length > 0 && (
        <>
          <p>Ou choisissez un dossier </p>

          <SelectStandalone
            className={"marginB10"}
            onChange={(e) => {
              onMoveFolderToFolder(parseInt(e.target.value));
              e.target.value = "";
            }}
          >
            <option>Choisissez un dossier</option>
            <option disabled>---Dossiers actifs---</option>
            {propertyFolders
              .filter(
                (filtered) =>
                  filtered.status === PropertyStatus.Actif.status &&
                  filtered.id_property_folder !== propertyFolderId &&
                  filtered.id !== propertyFolderId,
              )
              .map((property, i) => (
                <option key={i} value={property.id}>
                  {property.nom}
                </option>
              ))}
            <option disabled>---Dossiers archivés---</option>
            {propertyFolders
              .filter(
                (filtered) =>
                  filtered.status === PropertyStatus.Archive.status &&
                  filtered.id_property_folder !== propertyFolderId &&
                  filtered.id !== propertyFolderId,
              )
              .map((property, i) => (
                <option key={i} value={property.id}>
                  {property.nom}
                </option>
              ))}
          </SelectStandalone>
        </>
      )}
    </>
  );
}
