import useFetch from "./use-fetch";

const API_URL = "https://api-adresse.data.gouv.fr/search";
const fallbackData = { features: [] };

export default function useAddressSearch(query, isCityOnly = false) {
  const { data, ...rest } = useFetch(query.length > 2 ? API_URL : null, {
    params: {
      q: query,
      limit: 5,
      type: isCityOnly ? "municipality" : "housenumber",
    },
    fallbackData,
  });

  return {
    suggestions: data?.features.map((feature) => ({
      ville: feature.properties.city,
      cp: feature.properties.postcode,
      rue: feature.properties.name,
    })),
    ...rest,
  };
}
