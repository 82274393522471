import style from "./Tenant.module.css";
import Card from "../UI/Card";
import { Fragment } from "react";
import useMediaQuery from "../../hooks/use-media-query";
import CandidatRow from "./CandidatRow";
import { classnames } from "../../utils";
import { ProfileStatus } from "../../models/profile";
import { Interrogation } from "../UI/Icons";
import Tooltip from "../UI/Tooltip";

function ShowCandidatList({ tenants, properties, profiles }) {
  const isSmallScreen = useMediaQuery("(max-width: 799px)");
  return (
    <>
      {isSmallScreen ? (
        tenants?.length !== 0 ? (
          tenants?.map((tenant) =>
            tenant.profile_nom.profiles
              .filter(
                (profile) =>
                  profile.garantor_of === null &&
                  profile.status === ProfileStatus.Actif.status,
              )
              .map((prof, i) => (
                <Card
                  key={i}
                  className={classnames(style.relative, "marginB10")}
                >
                  <CandidatRow
                    properties={properties}
                    profiles={profiles}
                    prof={prof}
                    tenant={tenant}
                    isSmallScreen={isSmallScreen}
                  />
                </Card>
              )),
          )
        ) : (
          <>
            <Card className={"text-center"}>Aucun candidat trouvé</Card>
          </>
        )
      ) : (
        <Card className={`${style.rowCandidat}`}>
          <div className={style.head}>Nom du locataire</div>
          <div className={style.head}>Bien affecté</div>
          <div className={style.head}>Date de début</div>
          <div className={style.head}>Status</div>
          <div className={style.head}>Revenus</div>
          <div className={style.head}>Caution</div>
          <div className={style.head}>
            Confiance{" "}
            <Tooltip
              content={
                <div>
                  <p className={"primaryText marginB10"}>Indice de confiance</p>
                  <p>
                    Cet indice est attribué par Qalimo pour vous accompagner
                    dans le choix du locataire. Il est basé sur la situation
                    professionnelle du candidat et le type de garantie.
                  </p>
                </div>
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
          <div className={style.head}></div>
          {tenants?.length !== 0 ? (
            tenants?.map((tenant) =>
              tenant.profile_nom.profiles
                .filter(
                  (profile) =>
                    profile.garantor_of === null &&
                    profile.status === ProfileStatus.Actif.status,
                )
                .map((prof, i) => (
                  <Fragment key={i}>
                    <CandidatRow
                      properties={properties}
                      profiles={profiles}
                      prof={prof}
                      tenant={tenant}
                      isSmallScreen={isSmallScreen}
                    />
                  </Fragment>
                )),
            )
          ) : (
            <div className={style.rowSpan}>Aucun candidat trouvé</div>
          )}
        </Card>
      )}
    </>
  );
}

export default ShowCandidatList;
