import useAuthenticatedAxios from "./use-authenticated-axios";
import useSWRImmutable from "swr/immutable";
import { Env } from "../utils/constants/env";
import useLoader from "./use-loader";
import { useState } from "react";

const ROOT_PATH = "/nordigen";

export default function useNordigen() {
  const axios = useAuthenticatedAxios();
  const {
    data: institutions,
    isLoading,
    error,
  } = useSWRImmutable(`${ROOT_PATH}/institutions`, async (url) => {
    const response = await axios.get(url);
    const institutions = response.data;
    if (!Env.isProd()) {
      institutions.push({
        name: "Sandbox Nordigen",
        id: "SANDBOXFINANCE_SFIN0000",
      });
    }
    return institutions;
  });

  async function selectInstitution(institutionId) {
    try {
      const res = await axios.get(`${ROOT_PATH}/agreement/${institutionId}`);
      // store requisition ID in local storage, keyed by the reference ID
      localStorage.setItem(res.data["reference"], res.data["requisition_id"]);
      window.open(res.data["link"], "_self");
    } catch (error) {
      console.error(error);
    }
  }

  return {
    institutions: institutions || [],
    selectInstitution,
    isLoading,
    error,
  };
}

export function useNordigenTransactions() {
  const axios = useAuthenticatedAxios();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, synchroniseTransactions] = useLoader(sync);
  async function sync(uniqueIds) {
    const res = await axios.post(`${ROOT_PATH}/transactions`, uniqueIds);
    setTransactions(res.data);
  }

  return {
    transactions,
    synchroniseTransactions,
    isLoading,
  };
}

export function useNordigenAccounts(referenceId) {
  const axios = useAuthenticatedAxios();
  const requisitionId = localStorage.getItem(referenceId);
  const url = `${ROOT_PATH}/accounts`;
  const {
    data: accounts,
    error,
    isLoading,
  } = useSWRImmutable(referenceId ? url : null, (url) => {
    return axios
      .get(url, {
        params: {
          reference_id: referenceId,
          requisition_id: requisitionId,
        },
      })
      .then((res) => {
        localStorage.removeItem(referenceId);
        return res.data;
      });
  });

  async function synchronizeAccounts(selectedAccountIds) {
    const res = await axios.post(
      url,
      accounts.filter((account) =>
        selectedAccountIds.includes(account.unique_id),
      ),
    );
    return res.data;
  }

  return {
    accounts: accounts || [],
    error,
    isLoading,
    synchronizeAccounts,
  };
}
