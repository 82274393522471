import { object } from "yup";
import { dateMax } from "./utils";

export const actionValidationSchema = object({
  action_date: dateMax().required(" "),
});

export const actionIV = {
  action_date: "",
};
