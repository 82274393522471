import { useOutletContext, useParams } from "react-router-dom";
import ProfileForm from "../../components/Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";

function EditPage() {
  const { profileId } = useParams();
  const [Modal, closeModal] = useModal(true);

  const { profiles, profileSubmittedHandler } = useOutletContext();

  if (!profiles) {
    return null;
  }

  function onProfileUpdatedHandler(profile) {
    profileSubmittedHandler(profile);
    closeModal();
  }

  return (
    <ProfileForm
      edit
      isProfile
      isTenant
      profileId={parseInt(profileId)}
      profile={profiles.find((profile) => profile.id === parseInt(profileId))}
      onProfileSubmitted={onProfileUpdatedHandler}
      modal={Modal}
    />
  );
}

export default EditPage;
