import TextInput from "../../UI/TextInput";
import { Link } from "react-router-dom";
import AddressFields from "../../UI/AddressFields";
import { ReactSelect } from "../../UI/Select";

function HeaderPropertyForm({ profiles, isFolder }) {
  // On vérifie que le profileNom à bien des profiles de déclarés
  const selectOptions = profiles
    ?.filter((profile) => profile.profiles.length !== 0)
    .map((profile) => ({
      label: profile.nom_profile,
      value: profile.id,
    }));

  return (
    <>
      <TextInput
        type="text"
        name="nom"
        placeholder="Coloc Jeanne d'Arc"
        label={"Nom du bien"}
        mandatory
      />

      <ReactSelect
        name={"id_profile_nom_owner"}
        label={"Profil bailleur à affecter"}
        placeholder="SCI Dupont"
        mandatory
        options={selectOptions}
      />

      <p className={"secondaryText marginB10 marginT5"}>
        Si votre profil n'apparaît pas, vous pouvez le créer dans la rubrique
        <Link to={"/bailleur/profiles"}> Profils bailleur</Link>
      </p>

      {!isFolder && (
        <>
          <AddressFields nameRue={"rue"} mandatory />
        </>
      )}
    </>
  );
}

export default HeaderPropertyForm;
