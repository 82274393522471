import Card from "../../UI/Card";
import moment from "moment/moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../UI/Button";
import style from "./VueEnsemble.module.css";
import InitialesLogo from "../../UI/InitialesLogo";
import { ArrowRight, Plus } from "../../UI/Icons";
import InfoBoxList from "../../UI/InfoBoxList";
import { useModal } from "../../../hooks/use-modal";
import DescriptionBien from "./DescriptionBien";
import DescriptionFinance from "./DescriptionFinance";
import useProperties from "../../../hooks/use-properties";
import TenantAddForm, {
  TenantAddFormType,
} from "../../Tenant/Forms/TenantAddForm";
import ProfileForm from "../../Profile/ProfileForm";
import { useState } from "react";
import useTenants from "../../../hooks/use-tenants";
import DisplayName from "../../UI/DisplayName";
import ShowTenantList from "../../Tenant/ShowTenantList";
import { TenantStatus } from "../../../models/tenant";
import { currencyFormatter } from "../../../api/Functions";
import useMediaQuery from "../../../hooks/use-media-query";
import { ProfileStatus } from "../../../models/profile";
import AddLocataireChoices from "../../Tenant/AddLocataireChoices";
import SelectPropertyTenantAction from "../../UI/SelectPropertyTenantAction";

export const ROOT_PATH = "/bailleur/properties/";

function VueEnsemble({ balances, property, submitHandler }) {
  const { propertyId } = useParams();
  const [TenantAddModal, closeTenantAddModal, openTenantAddModal] = useModal();
  const [ChoseTenant, , openChoseTenant] = useModal();
  const [
    TenantAddChoicesModal,
    closeTenantChoicesAddModal,
    openTenantChoicesAddModal,
  ] = useModal();
  const [HistoriqueModal, , openHistoriqueModal] = useModal();
  const [AddProfileModal, closeAddProfileModal, openAddProfileModal] =
    useModal();
  const nav = useNavigate();
  const { properties } = useProperties();
  const { tenants } = useTenants();
  const [profileToPush, setProfileToPush] = useState([]);
  const [tenantAddFormType, setTenantAddFormType] = useState();
  const isSmallScreen = useMediaQuery("(max-width: 799px)");

  function profileSubmittedHandler(values) {
    setProfileToPush((curr) => [...curr, { profile: values }]);
  }

  const currentTenant = tenants.filter(
    (filteredTenant) =>
      moment().isBetween(filteredTenant.debut, filteredTenant.fin) &&
      filteredTenant.id_bien === parseInt(propertyId) &&
      filteredTenant.status === TenantStatus.Actif.status,
  );
  if (currentTenant.length > 1) {
    // Faut faire un truc sinon ca pète tout. n'afficher que le plus ancien ?'
  }
  const futurTenants = tenants.filter(
    (filteredTenant) =>
      moment(filteredTenant.debut).isAfter() &&
      filteredTenant.id_bien === parseInt(propertyId) &&
      filteredTenant.status === TenantStatus.Actif.status,
  );

  const bienParent = properties?.find(
    (prop) => prop.id === property?.id_parent,
  );

  return (
    <>
      <TenantAddChoicesModal>
        <AddLocataireChoices
          addLocataire={() => {
            setTenantAddFormType(TenantAddFormType.LOCATAIRE);
            closeTenantChoicesAddModal();
            openTenantAddModal();
          }}
          addAvenant={() => {
            closeTenantAddModal();
            openChoseTenant();
          }}
          inviteCandidat={() => {
            setTenantAddFormType(TenantAddFormType.CANDIDAT);
            closeTenantChoicesAddModal();
            openTenantAddModal();
          }}
        />
      </TenantAddChoicesModal>

      <ChoseTenant>
        <SelectPropertyTenantAction
          tenants={tenants.filter(
            (tenant) => tenant.id_bien === parseInt(propertyId),
          )}
          properties={properties.filter(
            (property) => property.id === parseInt(propertyId),
          )}
          filtering={"tenants"}
          onClick={(id) =>
            nav(
              `/bailleur/tenants/${id}?tab-tenant-show=actions&action=avenant&avenant-type=changement_locataire`,
            )
          }
          title={
            <>
              <img
                src={"/ChoseAjouterLocataire.jpg"}
                alt={"ajouter locataire"}
              />
              <p>Avenant au bail</p>
            </>
          }
          text={
            "Sélectionnez le bail / locataire pour lequel vous souhaitez faire un avenant"
          }
        />
      </ChoseTenant>
      <TenantAddForm
        openAddProfileModal={openAddProfileModal}
        profileToPush={profileToPush}
        onAddProfile={(prof) => setProfileToPush((curr) => [...curr, prof])}
        onDeleteProfile={(index) =>
          setProfileToPush((curr) => curr.filter((profile, i) => i !== index))
        }
        propertyId={parseInt(propertyId)}
        closeTenantAddModal={closeTenantAddModal}
        modal={TenantAddModal}
        type={tenantAddFormType}
      />
      <ProfileForm
        onProfileSubmitted={(profile) => {
          profileSubmittedHandler(profile);
          closeAddProfileModal();
        }}
        addTenant
        modal={AddProfileModal}
      />
      <HistoriqueModal>
        {tenants.length !== 0 &&
        tenants.filter(
          (filteredTenants) => filteredTenants.id_bien === parseInt(propertyId),
        ).length !== 0 ? (
          <ShowTenantList
            tenants={tenants.filter(
              (filteredTenants) =>
                filteredTenants.id_bien === parseInt(propertyId),
            )}
            properties={properties}
            balances={balances}
          />
        ) : (
          <Card type={"warning"}>Aucun ancien locataire trouvé</Card>
        )}
      </HistoriqueModal>

      <div className={style.gridTenantEtFinance}>
        <Card className={"flex flex-column space-between"}>
          <div>
            <div className={"flex space-between align-items-center"}>
              <div className={"cardTitle"}>Locataire</div>
              <div>
                <Link to={""}>
                  <Plus stroke={"#0C69F5"} />
                  <span
                    className={"marginL5"}
                    onClick={openTenantChoicesAddModal}
                  >
                    Ajouter un locataire
                  </span>
                </Link>
              </div>
            </div>
            {currentTenant.length !== 0 ? (
              currentTenant[0].profile_nom.profiles
                .filter(
                  (profile) =>
                    profile.garantor_of === null &&
                    profile.status === ProfileStatus.Actif.status,
                )
                .map((profile) => (
                  <div key={profile.id}>
                    <div
                      className={
                        "flex space-between align-items-center marginT5 marginB10"
                      }
                      key={profile.id}
                    >
                      <div
                        className={
                          "flex justify-content-center align-items-center"
                        }
                      >
                        <div>
                          <InitialesLogo
                            nom={profile.nom}
                            prenom={profile.prenom}
                            shape={"rond"}
                          />
                        </div>
                        <div className={"marginL10"}>
                          <Link to={`/bailleur/tenants/${currentTenant[0].id}`}>
                            <DisplayName profile={profile} />
                          </Link>
                          <div className={"secondaryText flex wrap gap10"}>
                            <div>
                              {moment(
                                currentTenant[0].debut,
                                "YYYY-MM-DD",
                              ).format(
                                isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
                              )}
                            </div>
                            {!isSmallScreen && (
                              <div className={"marginL5 marginR5"}>
                                <ArrowRight />
                              </div>
                            )}
                            <div>
                              {moment(
                                currentTenant[0].fin,
                                "YYYY-MM-DD",
                              ).format(
                                isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
                              )}
                              {currentTenant[0].preavis && " - Préavis déposé"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"padding5"}>
                        <p className={"secondaryText text-center"}>
                          {currencyFormatter(
                            currentTenant[0].loyer_hc +
                              currentTenant[0].charges,
                          )}
                          /mois
                        </p>
                        {balances &&
                          balances
                            .filter(
                              (bal) => bal.id_tenant === currentTenant[0].id,
                            )
                            .map((bal) => {
                              let color = "";
                              let text = "";
                              if (bal.balance >= 0) {
                                color = "green";
                                text = "A jour";
                              } else if (
                                bal.balance <
                                (currentTenant[0].loyer_hc +
                                  currentTenant[0].charges) *
                                  -1
                              ) {
                                color = "red";
                                text = "Impayé";
                              } else {
                                color = "yellow";
                                text = "Retard";
                              }
                              return (
                                <InfoBoxList
                                  color={color}
                                  text={text}
                                  key={color}
                                />
                              );
                            })}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <Card
                className={
                  "padding5 marginB10 marginT10 text-center w-15em secondaryText"
                }
              >
                Pas de locataire en cours
              </Card>
            )}
            {futurTenants.length !== 0 && (
              <>
                <p className={"cardTitle"}>Prochain locataire</p>
                {futurTenants.map((futurTenant) => (
                  <div key={futurTenant.id}>
                    {futurTenant.profile_nom.profiles
                      .filter(
                        (profile) =>
                          profile.garantor_of === null &&
                          profile.status === ProfileStatus.Actif.status,
                      )
                      .map((profile) => (
                        <div
                          className={
                            "flex justify-content-flex-start align-items-center marginT5 marginB10"
                          }
                          key={profile.id}
                        >
                          <div>
                            <InitialesLogo
                              nom={profile.nom}
                              prenom={profile.prenom}
                              shape={"rond"}
                            />
                          </div>
                          <div className={"marginL10"}>
                            <Link to={`/bailleur/tenants/${futurTenant.id}`}>
                              <DisplayName profile={profile} />
                            </Link>
                            <p className={"secondaryText"}>
                              Arrivée le{" "}
                              {moment(futurTenant.debut, "YYYY-MM-DD").format(
                                "DD MMMM YYYY",
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={"w-100 flex justify-content-center"}>
            <Button
              buttonStyle={"secondary"}
              onClick={openHistoriqueModal}
              className={"text-center paddingL10 paddingR10"}
            >
              Consulter l'historique des locataires
            </Button>
          </div>
        </Card>
        <Card>
          <DescriptionFinance property={property} allowEdit />
        </Card>
      </div>
      <div className={"w-100 marginT10"}>
        <DescriptionBien
          property={property}
          bienParent={bienParent}
          submitHandler={submitHandler}
        />
      </div>
    </>
  );
}

export default VueEnsemble;
