import { sendToBackend } from "./index";

const ROOT_PATH = "finance/config";

export async function fetchAllFinanceConfigsForTenant(token, idTenant) {
  try {
    const res = await sendToBackend(ROOT_PATH, token, {
      params: { id_tenant: idTenant },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
