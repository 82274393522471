import styles from "./CardNumber.module.css";
import { classnames } from "../../utils";
import ButtonCard from "./ButtonCard";

export default function CardNumber({
  text,
  nombre,
  logo,
  className,
  onClick,
  disabled,
  type,
}) {
  return (
    <ButtonCard className={className} onClick={onClick} disabled={disabled}>
      <div className={styles.logo}>{logo}</div>
      <div className={styles.body}>
        <p className={classnames(styles.number, styles[type])}>{nombre}</p>
        <p className={classnames(styles.text, styles[type], "text-center")}>
          {text}
        </p>
      </div>
    </ButtonCard>
  );
}
