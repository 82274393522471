import { FolderArrowUp, Visualiser } from "./Icons";
import { useEffect, useRef, useState } from "react";
import style from "./FileDownloadLink.module.css";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useModal } from "../../hooks/use-modal";
import { isImage, isPdf } from "../../utils";
import Photo from "./Photo";

/**
 * @deprecated Ne plus utiliser ou modifier, utiliser DocumentDownloadLink à la place
 */
export default function FileDownloadLink({
  file,
  fileType = "file",
  save,
  icon,
  className,
}) {
  const [signedUrl, setSignedUrl] = useState(null);
  const [PreviewModal, , openPreviewModal] = useModal();
  const linkRef = useRef();

  const isImageOrPdf = isImage(file) || isPdf(file);
  const isNewTab = !save && !isImageOrPdf;
  const isModalPreview = !save && isImageOrPdf;
  const isDownload = save;

  let linkIcon = save ? (
    <FolderArrowUp stroke="#0C69F5" />
  ) : (
    <Visualiser className={"marginL10"} />
  );

  if (icon) {
    linkIcon = icon;
  } else if (isImage(file) || isPdf(file)) {
    linkIcon = (
      <Photo
        photoId={file.id}
        type={fileType}
        isThumbnail
        alt={"aperçu document"}
      />
    );
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  const link = <a href={`${signedUrl}&save`} ref={linkRef}></a>;

  const preview = isImageOrPdf && (
    <PreviewModal noPadding>
      {isImage(file) && (
        <img alt={file.filename} src={signedUrl} className={style.previewImg} />
      )}
      {isPdf(file) && (
        <object
          data={signedUrl}
          type="application/pdf"
          title={file.filename}
          className={style.previewPdf}
        >
          <a href={signedUrl}>Cliquez ici</a> pour télécharger le document
        </object>
      )}
    </PreviewModal>
  );

  useEffect(() => {
    if (signedUrl) {
      if (isModalPreview) {
        openPreviewModal();
      } else if (isDownload) {
        linkRef.current.click();
        setSignedUrl(null);
      }
    }
    return () => {
      if (signedUrl) {
        // Revoke file URL if it was a local file
        URL.revokeObjectURL(signedUrl);
      }
    };
  }, [isDownload, isModalPreview, openPreviewModal, signedUrl]);

  const axios = useAuthenticatedAxios();

  async function downloadHandler() {
    let tab;
    if (isNewTab) {
      // Open new tab immediately to avoid popup blocker and set href later
      tab = window.open("", "_blank");
      tab.referer = null;
      tab.opener = null;
    }
    try {
      let fileUrl;
      if (file.id) {
        let url =
          fileType === "edl"
            ? `/edl/photos/${file.id}/signed-url`
            : `/documents/${file.id}/signed-url?doctype=file`;
        const response = await axios.get(url);
        fileUrl = response.data;
      } else if (file.localFile) {
        fileUrl = URL.createObjectURL(file.localFile);
      }
      if (isNewTab) {
        // Set new tab href to signed URL
        tab.location.href = fileUrl;
      }
      setSignedUrl(fileUrl);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className={className}>
      {signedUrl && link}
      {signedUrl && preview}
      <button
        className={style.button}
        type={"button"}
        onClick={downloadHandler}
      >
        {linkIcon}
      </button>
    </div>
  );
}
