import PropertyListShow from "../../components/Property/PropertyListShow.js";
import TitleSearchAdd from "../../components/UI/TitleSearchAdd";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import Page from "../../components/UI/Page";
import { useState } from "react";
import useProperties from "../../hooks/use-properties";

export const ROOT_PATH = "/bailleur/properties/";

function PropertyListPage() {
  const { profiles, drafts, draftDeletedHandler, draftSavedHandler } =
    useOutletContext();
  const nav = useNavigate();
  const { properties, duplicateProperty } = useProperties();
  const [filter, setFilter] = useState("");

  return (
    <>
      <Page
        header={
          <TitleSearchAdd
            rootPath={ROOT_PATH}
            title="Mes biens"
            placeholder="Rechercher un bien"
            textButton="Ajouter un bien"
            addAction={() => nav("add")}
            setFilter={setFilter}
          />
        }
        body={
          <PropertyListShow
            drafts={drafts}
            draftDeletedHandler={draftDeletedHandler}
            draftSavedHandler={draftSavedHandler}
            properties={properties.filter(
              (property) =>
                property.nom.toLowerCase().includes(filter) ||
                property.ville.toLowerCase().includes(filter) ||
                property.rue.toLowerCase().includes(filter),
            )}
            onDuplicateProperty={duplicateProperty}
          />
        }
      />
      <Outlet
        context={{
          profiles: profiles,
          drafts: drafts,
          draftDeletedHandler: draftDeletedHandler,
          draftSavedHandler: draftSavedHandler,
        }}
      />
    </>
  );
}

export default PropertyListPage;
