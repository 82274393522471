import ContactForm from "./ContactForm";
import { useModal } from "../../hooks/use-modal";
import Card from "../UI/Card";
import ButtonCard from "../UI/ButtonCard";
import styles from "./HelpCard.module.css";
import {
  Demonstration,
  Facebook,
  TechnicalIssue,
  Teleconseiller,
} from "../UI/Icons";

export default function HelpCard() {
  const [ContactModal, closeContactModal, openContactModal] = useModal();

  return (
    <>
      <ContactModal>
        <ContactForm closeContactModal={closeContactModal} />
      </ContactModal>
      <h2 className={"text-center"}>Besoin d'aide ?</h2>
      <div className={styles.grid2Cols}>
        <Card>
          <div className={"text-center"}>
            <Teleconseiller />
          </div>
          <p className={"text-center marginT10 primaryBlue"}>
            Joindre un conseiller au 0756949451
          </p>
        </Card>
        <ButtonCard
          onClick={() =>
            window.open(
              "https://www.facebook.com/groups/488560933232604",
              "_blank",
              "noreferrer",
            )
          }
        >
          <div className={"text-center"}>
            <Facebook />
          </div>
          <p className={"text-center marginT10 primaryBlue"}>
            Rejoindre la communauté Qalimo
          </p>
        </ButtonCard>
        <ButtonCard onClick={openContactModal}>
          <div className={"text-center"}>
            <TechnicalIssue />
          </div>
          <p className={"text-center marginT10 primaryBlue"}>
            Déclarer un problème technique
          </p>
        </ButtonCard>
        <ButtonCard
          onClick={() =>
            window.open(
              "https://calendly.com/app-qalimo/30min",
              "_blank",
              "noreferrer",
            )
          }
        >
          <div className={"text-center"}>
            <Demonstration />
          </div>
          <p className={"text-center marginT10 primaryBlue"}>
            Prendre un rendez-vous
          </p>
        </ButtonCard>
      </div>
    </>
  );
}
