import { useCallback, useId, useRef } from "react";
import Modal from "../components/UI/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirm } from "./use-confirm";

export function useModal(routed = false) {
  const modalRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [Confirm, openConfirm] = useConfirm();
  const id = useId();

  const openModal = useCallback(
    () => (routed ? null : modalRef.current?.open()),
    [routed],
  );
  const closeModal = useCallback(() => {
    modalRef.current?.close();
  }, []);

  const getModalReady = useCallback(() => !!modalRef.current, []);

  const modal = useRef(
    ({ confirmCloseText, shouldConfirm = () => false, size, ...props }) => {
      function cancelHandler(e) {
        // If cancelling modal, check if we should ask confirmation, and prevent closing if we do
        if (shouldConfirm()) {
          e.preventDefault();
          openConfirm();
          return;
        }

        if (props.onCancel) props.onCancel();
      }

      function confirmClose(shouldClose) {
        if (shouldClose) {
          if (props.onCancel) props.onCancel();
          modalRef.current.close();
        }
      }

      function closeHandler() {
        if (props.onClose) props.onClose();
        if (routed) {
          navigate(`..${location.search}`);
        }
      }

      return (
        <>
          {confirmCloseText && (
            <Confirm onAnswer={confirmClose}>{confirmCloseText}</Confirm>
          )}
          <Modal
            key={id}
            {...props}
            routed={routed}
            ref={modalRef}
            onClose={closeHandler}
            onCancel={cancelHandler}
            size={size}
          />
        </>
      );
    },
  );

  return [modal.current, closeModal, openModal, getModalReady];
}
