import { Plus } from "../UI/Icons";
import { useModal } from "../../hooks/use-modal";
import React, { useState } from "react";
import useFiles from "../../hooks/use-files";
import styles from "./DocumentShowList.module.css";
import ButtonCard from "../UI/ButtonCard";
import DocumentCard from "./DocumentCard";
import DocumentsFormUpload from "./DocumentsFormUpload";
import { DOCUMENT_LIST } from "../../models/documents";
import moment from "moment/moment";

export default function DocumentShowList({
  property,
  tenant,
  allowDelete,
  isBailleur,
  isLocataire,
  allowEdit,
}) {
  const {
    files,
    deleteFile,
    sendToElectronicSigning,
    updateFile,
    cancelElectronicSigning,
    relanceSignataire,
    sendToLaPoste,
  } = useFiles({
    tenantId: tenant?.id,
    propertyId: property?.id,
    isTenant: isLocataire,
  });
  let filesFiltered = files;
  if (property) {
    filesFiltered = files.filter((file) => !file.id_tenant);
  }
  const [DetailModal, , openDetailModal] = useModal();
  const [DocumentModal, closeDocumentModal, openDocumentModal] = useModal();
  const [detailFiles, setDetailFiles] = useState("");

  const sortedFiles = filesFiltered.sort(
    (a, b) =>
      moment(b.meta?.date_quittance, "MMMM-YYYY") -
      moment(a.meta?.date_quittance, "MMMM-YYYY"),
  );

  async function deleteDocumentHandler(fileId) {
    try {
      await deleteFile(fileId);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <DetailModal size={"ultra"}>
        <div className={styles.grid}>
          {sortedFiles
            .filter((file) => file.tag === detailFiles.type)
            .map((file, i) => (
              <DocumentCard
                files={[file]}
                type={file.tag}
                titre={file.filename}
                deleteDocumentHandler={
                  allowDelete ? deleteDocumentHandler : undefined
                }
                property={property}
                isBailleur={isBailleur}
                isLocataire={isLocataire}
                tenant={tenant}
                updateFile={allowEdit ? updateFile : undefined}
                sendToElectronicSigning={
                  isBailleur ? sendToElectronicSigning : undefined
                }
                sendToLaPoste={isBailleur ? sendToLaPoste : undefined}
                cancelElectronicSigning={cancelElectronicSigning}
                relanceSignataire={relanceSignataire}
                allowEdit={allowEdit}
                key={i}
              />
            ))}
        </div>
      </DetailModal>
      <DocumentModal>
        <DocumentsFormUpload
          property={property}
          tenant={tenant}
          closeDocumentModal={closeDocumentModal}
          isBailleur={isBailleur}
          isLocataire={isLocataire}
        />
      </DocumentModal>
      <div className={styles.grid}>
        {DOCUMENT_LIST.map((document, i) => {
          // On itère à travers tous les types de fichier.
          // Si certains sont vide mais en required, on affiche quand même les cartes.
          // Pour les fichiers de type "autre", on ne les regroupe pas dans un dossier, on affiche tout
          const files = sortedFiles.filter((file) => file.tag === document.tag);
          const shouldShowFileCard =
            files.length > 0 ||
            (!!property && document.requiredProperty) ||
            (!!tenant && document.requiredTenant && isBailleur);
          if (!shouldShowFileCard) return null;
          if (document.tag === "autre") {
            return files.map((file, j) => (
              <DocumentCard
                key={`${i}${j}`}
                files={[file]}
                type={document.tag}
                titre={file.filename}
                deleteDocumentHandler={
                  allowDelete ? deleteDocumentHandler : undefined
                }
                property={property}
                isBailleur={isBailleur}
                tenant={tenant}
                updateFile={allowEdit ? updateFile : undefined}
                sendToElectronicSigning={
                  isBailleur ? sendToElectronicSigning : undefined
                }
                sendToLaPoste={isBailleur ? sendToLaPoste : undefined}
                cancelElectronicSigning={cancelElectronicSigning}
                relanceSignataire={relanceSignataire}
                allowEdit={allowEdit}
              />
            ));
          }
          return (
            <DocumentCard
              key={i}
              files={files}
              type={document.tag}
              titre={document.nom}
              deleteDocumentHandler={
                allowDelete ? deleteDocumentHandler : undefined
              }
              property={property}
              isLocataire={isLocataire}
              isBailleur={isBailleur}
              tenant={tenant}
              updateFile={allowEdit ? updateFile : undefined}
              sendToElectronicSigning={
                isBailleur ? sendToElectronicSigning : undefined
              }
              sendToLaPoste={isBailleur ? sendToLaPoste : undefined}
              onDetailFiles={(type) => {
                openDetailModal();
                setDetailFiles({ type: type, titre: document.nom });
              }}
              cancelElectronicSigning={cancelElectronicSigning}
              relanceSignataire={relanceSignataire}
              allowEdit={allowEdit}
            />
          );
        })}
        <ButtonCard className={"cardTitle"} onClick={() => openDocumentModal()}>
          <Plus size={"30px"} />
          <br />
          Ajouter un document
        </ButtonCard>
      </div>
    </>
  );
}
