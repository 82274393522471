import useSWRImmutable from "swr/immutable";
import useAuthenticatedAxios from "./use-authenticated-axios";

export default function useAutoActions() {
  const axios = useAuthenticatedAxios();
  const {
    data: autoActions,
    error,
    isLoading,
  } = useSWRImmutable("auto-actions", (url) => {
    return axios.get(url).then((res) => res.data);
  });

  return {
    autoActions,
    error,
    isLoading,
  };
}
