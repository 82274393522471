import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useAllProperties } from "../../hooks/use-properties";
import { House } from "../UI/Icons";
import { renderToString } from "react-dom/server";
import { divIcon } from "leaflet/src/layer";

const customIcon = divIcon({
  html: renderToString(<House size={"30"} stroke={"#0c69f5"} />),
  className: "custom-marker-icon",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

export default function Map() {
  const { properties } = useAllProperties();

  return (
    <MapContainer
      center={[46.603354, 1.888334]} // Center of France
      zoom={6} // Zoom level to show the entire country
      style={{ height: "700px", width: "100%", borderRadius: "10px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerClusterGroup>
        {properties?.map(
          (property) =>
            property.latitude &&
            property.longitude && (
              <Marker
                key={property.id}
                position={[property.latitude, property.longitude]}
                icon={customIcon}
              ></Marker>
            ),
        )}
      </MarkerClusterGroup>
    </MapContainer>
  );
}
