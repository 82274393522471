import { Form, Formik } from "formik";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";
import React, { useState } from "react";
import TextInput from "../UI/TextInput";
import ProfileFormPerso from "../Profile/ProfileFormPerso";
import {
  getMandataireProfileSchema,
  MANDATAIRE_INITIAL_VALUE,
} from "../../models/profile";
import { toInitialValuesFromSchema } from "../../models/utils";
import MandataireFormFields from "./MandataireFormFields";
import ProfileFormDom from "../Profile/ProfileFormDom";
import { handleAPIError } from "../../utils";
import Checkbox from "../UI/Checkbox";

export default function MandataireForm({ mandataire, ModalElement, onSubmit }) {
  const [error, setError] = useState("");
  const validationSchema = getMandataireProfileSchema();

  const initialValues = toInitialValuesFromSchema(
    validationSchema,
    mandataire,
    MANDATAIRE_INITIAL_VALUE,
  );

  async function submitHandler(values) {
    setError("");
    try {
      await onSubmit(values);
    } catch (e) {
      setError(handleAPIError(e));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ values, resetForm }) => (
        <ModalElement
          size={"intermediate"}
          onClose={() => {
            setError("");
            resetForm();
          }}
        >
          <Form>
            <ProfileFormPerso
              type={values.profile.type}
              prefix={"profile."}
              isMandataire
            />
            <ProfileFormDom isMandataire prefix={"profile."} />
            <TextInput
              name={"carte_g"}
              label={"Numéro de carte de gestion"}
              mandatory
            />
            <TextInput
              name={"carte_delivree_par"}
              label={"Organisme ayant délivré la carte de gestion"}
              placeholder={"chambre de commerce et de l’industrie de Paris"}
              mandatory
            />
            <TextInput
              name={"carte_delivree_date"}
              type={"date"}
              label={"Date de délivrance de la carte de gestion"}
              mandatory
            />
            <TextInput
              name={"nom_garant"}
              label={"Nom de l'organisme de garantie"}
            />
            <TextInput
              name={"adresse_garant"}
              label={"Adresse, CP, Ville de l'organisme de garantie"}
            />
            <TextInput
              name={"montant_garanti"}
              type={"number"}
              label={"Montant maximum garanti"}
            />
            <p>Mentions sur la carte :</p>
            <div className="flex marginB10 wrap gap10">
              <Checkbox
                name="carte_mentions"
                value={"gestion immobilière"}
                pillStyle
              >
                Gestion immobilière
              </Checkbox>
              <Checkbox name="carte_mentions" value={"transaction"} pillStyle>
                Transactions
              </Checkbox>
              <Checkbox
                name="carte_mentions"
                value={"syndic de copropriété"}
                pillStyle
              >
                Syndic de copropriété
              </Checkbox>
            </div>
            <MandataireFormFields />
            <Checkbox name="tva">Assujetti à la TVA</Checkbox>
            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card type={"error"} className={"padding10 marginT10"}>
                {error}
              </Card>
            )}
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
