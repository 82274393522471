import style from "./FormUpload.module.css";
import { PlusBank } from "../UI/Icons";
import Card from "../UI/Card";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useId } from "react";
import Photo from "../UI/Photo";

export default function PhotoUpload({
  photo,
  onUploaded,
  isPublic,
  disabled,
  alt,
}) {
  const axios = useAuthenticatedAxios();
  const id = useId();

  async function handleSubmit(event) {
    const formData = new FormData();

    for (const file of event.target.files) {
      formData.append("photos", file);
    }

    formData.set("public", (!!isPublic).toString());

    try {
      const res = await axios.post("/photos/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onUploaded(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <input
        type="file"
        onChange={handleSubmit}
        id={id}
        className={`${style.inputFile} noBorder`}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className="flex align-items-center justify-content-flex-start noBorder"
      >
        {photo ? (
          <Photo photoId={photo} alt={alt} className={style.smallImage} />
        ) : (
          <Card className={"text-center"}>
            <PlusBank />
            <p className={`marginT5 secondaryText`}>Ajouter une photo</p>
          </Card>
        )}
      </label>
    </>
  );
}
