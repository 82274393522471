import PropertyForm from "../../components/Property/PropertyForm";
import { useOutletContext } from "react-router-dom";

function PropertyAddPage() {
  const { drafts, profiles, draftSavedHandler, draftDeletedHandler } =
    useOutletContext();
  return (
    <PropertyForm
      isDraft
      drafts={drafts}
      profiles={profiles}
      draftSavedHandler={draftSavedHandler}
      draftDeletedHandler={draftDeletedHandler}
    />
  );
}

export default PropertyAddPage;
