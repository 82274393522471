import Card from "../../UI/Card";
import Editor from "../../Quill/Editor";
import Button from "../../UI/Button";
import Tabs from "../../UI/Tabs";
import ModifChargeInputs from "./CourriersFields/ModifChargeInputs";
import ModifLoyerInputs from "./CourriersFields/ModifLoyerInputs";
import ProrogationMobilite from "./CourriersFields/ProrogationMobilite";
import ChangementLocataire from "./CourriersFields/ChangementLocataire";
import { isCourrierWithFields } from "../../../models/courrier";
import CongeLegitime from "./CourriersFields/CongeLegitime";
import CongeReprise from "./CourriersFields/CongeReprise";
import CongeVenteNu from "./CourriersFields/CongeVenteNu";
import CourrierTrouble from "./CourriersFields/CourrierTrouble";
import RegulForm from "./RegulForm";
import RevisionForm from "./RevisionForm";
import React from "react";
import { BalanceType } from "../../../utils/constants/balances";
import ConvocationEdlForm from "./ConvocationEdlForm";
import RestitutionDGForm from "./CourriersFields/RestitutionDGForm";

export default function CourrierFields({
  courrierId,
  tenant,
  content,
  isSubmitting,
  profiles,
  onContentChange,
  onPreview,
  onAddProfile,
  onDeleteProfile,
  openAddProfileModal,
  balances,
  property,
}) {
  const { selectNextTab, selectPreviousTab } = Tabs.useActiveTab();

  const hasFields = isCourrierWithFields(courrierId);

  return (
    <>
      {hasFields ? (
        <p className={"primaryText text-center marginB10"}>
          Veuillez compléter les champs suivants
        </p>
      ) : (
        <p className={"primaryText text-center marginB10"}>
          Vous pouvez modifier le document selon vos besoins. Cliquez sur
          "suivant" lorsque vous êtes prêts.
        </p>
      )}
      {courrierId === "modif_charge" && (
        <ModifChargeInputs typeCharge={tenant?.type_charge} />
      )}
      {courrierId === "modif_loyer" && <ModifLoyerInputs />}
      {courrierId === "prorogation_mobilite" && <ProrogationMobilite />}
      {courrierId === "conge_legitime" && <CongeLegitime />}
      {courrierId === "conge_vente_nu" && <CongeVenteNu />}
      {(courrierId === "conge_reprise_nu" ||
        courrierId === "conge_reprise_meuble") && <CongeReprise />}
      {(courrierId === "courrier_trouble" ||
        courrierId === "courrier_med_trouble") && <CourrierTrouble />}
      {courrierId === "changement_locataire" && (
        <ChangementLocataire
          profiles={profiles}
          onAddProfile={onAddProfile}
          onDeleteProfile={onDeleteProfile}
          openAddProfileModal={openAddProfileModal}
        />
      )}
      {courrierId === "regul_charge" && (
        <RegulForm
          balances={balances.filter(
            (filtered) => filtered.type === BalanceType.APPEL_CHARGE,
          )}
        />
      )}
      {courrierId === "courrier_irl" && (
        <RevisionForm tenant={tenant} property={property} />
      )}
      {courrierId === "convocation_edl" && <ConvocationEdlForm />}
      {courrierId === "restitution_dg" && (
        <RestitutionDGForm property={property} balances={balances} />
      )}

      <div className="text-right marginB10">
        <Button
          type="button"
          onClick={onPreview}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          {content ? "Regénérer le document" : "Générer le document"}
        </Button>
      </div>
      {content && (
        <>
          {hasFields && (
            <Card type={"warning"} className={"marginT10 marginB10 padding5"}>
              Ne pas modifier les champs surlignés en jaune, utilisez les champs
              du formulaire ci-dessus. Autrement Qalimo ne pourra pas mettre sa
              base de donnée à jour avec ces nouvelles informations.
            </Card>
          )}
          <Editor onChange={onContentChange} value={content} />
          <div className={"text-right"}>
            <Button className={"marginR10"} onClick={() => selectPreviousTab()}>
              Précédent
            </Button>
            <Button onClick={() => selectNextTab()}>Suivant</Button>
          </div>
        </>
      )}
    </>
  );
}
