import Card from "../UI/Card";
import { Argent, DG, Interrogation } from "../UI/Icons";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import Tooltip from "../UI/Tooltip";
import { classnames, setBalanceAmountSignFromType } from "../../utils";
import { currencyFormatter } from "../../api/Functions";
import TenantDecompte from "./TenantDecompte";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useBalanceTypes, useTenantBalances } from "../../hooks/use-balances";
import IconAndText from "../UI/IconAndText";
import useFiles from "../../hooks/use-files";
import BalanceActionsCard from "../Balance/BalanceActionsCard";
import BalanceForm from "../Balance/BalanceForm";
import { sanitizeValues } from "../../models/utils";
import BalanceList from "../Balance/BalanceList";
import { BalanceDomain } from "../../utils/constants/balances";

export default function TenantBalance({
  tenant,
  balanceTotal,
  isLocataire,
  syncTransactionHandler,
  DGPercu,
  DGAppele,
}) {
  const {
    balances,
    deleteBalance,
    updateBalance,
    createBalance,
    duplicateBalance,
    batchUpdateBalances,
  } = useTenantBalances(tenant?.id, !!isLocataire);

  const [BalanceModal, closeBalanceModal, openBalanceModal] = useModal();
  const [balanceToEdit, setBalanceToEdit] = useState();
  const [DecompteModal, , openDecompteModal] = useModal();
  const [decompte, setDecompte] = useState();
  const [decompteFile, setDecompteFile] = useState();
  const axios = useAuthenticatedAxios();
  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  const { files } = useFiles({ tenantId: tenant?.id });
  if (isLoading) return null;

  const quittances = files.filter((filtered) => filtered.tag === "quittance");

  async function submitBalanceHandler(edit, values) {
    const sanitizedValues = { ...sanitizeValues(values) };
    sanitizedValues.domaine = BalanceDomain.TENANT;
    // change to negative if type is Appel .*
    setBalanceAmountSignFromType(sanitizedValues, balanceTypesByCategory);

    if (edit) {
      await updateBalance(balanceToEdit.id, sanitizedValues);
    } else {
      await createBalance(sanitizedValues);
    }
    setBalanceToEdit(null);
    closeBalanceModal();
  }

  async function getDecompteHandler() {
    try {
      const response = await axios.post(`/tenants/${tenant.id}/get-decompte`);
      setDecompte(response.data.file_html);
      setDecompteFile(response.data.file);
      openDecompteModal();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <BalanceForm
        balance={balanceToEdit}
        domaineBalance={BalanceDomain.TENANT}
        ModalElement={BalanceModal}
        onClose={() => setBalanceToEdit(null)}
        onSubmit={(values) =>
          submitBalanceHandler(balanceToEdit !== undefined, values)
        }
        onDelete={async (id) => {
          try {
            await deleteBalance(id);
            closeBalanceModal();
          } catch (error) {
            console.error(error);
          }
        }}
      />
      <DecompteModal>
        <TenantDecompte decompte={decompte} decompteFile={decompteFile} />
      </DecompteModal>
      <div className={"flex wrap-reverse gap10 marginB10"}>
        <Card className={"flex-grow flex padding10 align-items-center"}>
          <div className={"center text-center"}>
            <IconAndText
              icon={<Argent />}
              text={<p className={"primaryText"}>Solde du compte locataire</p>}
            />
            <p
              className={classnames(
                "primaryText",
                balanceTotal < 0 ? "error" : "green",
              )}
            >
              {currencyFormatter(balanceTotal) ?? "0€"}
            </p>
          </div>
        </Card>
        <Card className={"flex-grow flex padding10 align-items-center"}>
          <div className={"center text-center"}>
            <IconAndText
              icon={<DG />}
              text={<p className={"primaryText"}>Dépôt de garantie encaissé</p>}
            />
            <div
              className={classnames(
                "primaryText",
                DGPercu < DGAppele ? "error" : "green",
              )}
            >
              {currencyFormatter(DGPercu)}/{currencyFormatter(DGAppele)}{" "}
              <Tooltip
                content={"Dépôt de garantie perçu / dépôt de garantie appelé"}
              >
                <Interrogation />
              </Tooltip>{" "}
            </div>
          </div>
        </Card>{" "}
        {!isLocataire && (
          <BalanceActionsCard
            balances={balances}
            onBatchUpdate={batchUpdateBalances}
            onSyncTransactions={syncTransactionHandler}
            onGetDecompte={getDecompteHandler}
            onAddBalance={() => {
              setBalanceToEdit(undefined);
              openBalanceModal();
            }}
            domaineBalance={BalanceDomain.TENANT}
          />
        )}
      </div>
      <BalanceList
        balances={balances}
        quittances={quittances}
        isTenant
        isLocataire={isLocataire}
        onUpdate={(balance) => {
          setBalanceToEdit(balance);
          openBalanceModal();
        }}
        onDuplicate={duplicateBalance}
        onCreate={createBalance}
        onDelete={deleteBalance}
      />
    </>
  );
}
