import React, { createContext, useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./Stripe.module.css";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useModal } from "../../hooks/use-modal";
import { currencyFormatter } from "../../api/Functions";
import Spinner from "../UI/Spinner";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY,
);

const StripeContext = createContext({});

export function StripeProvider({ children }) {
  const [clientSecret, setClientSecret] = useState("");
  const [intentId, setIntentId] = useState("");
  const [intentStatus, setIntentStatus] = useState("");
  const [montant, setMontant] = useState("");
  const [onIntentSuccessful, setOnIntentSuccessful] = useState();
  const [intentType, setIntentType] = useState("");

  const options = {
    clientSecret,
    appearance: {
      theme: "flat",
    },
    paymentMethodCreation: "manual",
  };

  const [Modal, closeModal, openModal] = useModal();
  const [modalLoading, setModalLoading] = useState(false);
  const axios = useAuthenticatedAxios();

  async function createPaymentIntent(priceId, paymentMethodId) {
    const res = await axios.post(`/stripe/payment-intent`, {
      price_id: priceId,
      payment_method_id: paymentMethodId,
    });
    setMontant(res.data.price);
    setClientSecret(res.data.client_secret);
    setIntentId(res.data.id);
  }

  async function createSetupIntent() {
    const res = await axios.post(`/stripe/setup-intent`);
    setClientSecret(res.data.client_secret);
    setIntentId(res.data.id);
  }

  async function initiatePayment(priceId, options = {}) {
    const { onPaymentSuccessful, paymentMethodId } = options;
    try {
      setModalLoading(true);
      setIntentType("payment");
      openModal();
      await createPaymentIntent(priceId, paymentMethodId);
      setOnIntentSuccessful(() => onPaymentSuccessful);
      setModalLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function initiateSetup(options = {}) {
    const { onSetupSuccessful } = options;
    try {
      setModalLoading(true);
      setIntentType("setup");
      openModal();
      await createSetupIntent();
      setOnIntentSuccessful(() => onSetupSuccessful);
      setModalLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function cancelIntent() {
    if (intentStatus !== "succeeded" && intentId) {
      try {
        await axios.delete(`/stripe/${intentType}-intent/${intentId}`);
      } catch (e) {
        console.error(e);
      }
    }
    setIntentId("");
    setClientSecret("");
    setIntentStatus("");
    setIntentType("");
    closeModal();
  }

  function finishPayment() {
    setClientSecret("");
    setIntentId("");
    setIntentStatus("");
    setIntentType("");
    closeModal();
  }

  return (
    <StripeContext.Provider
      value={{
        initiatePayment,
        initiateSetup,
        cancelIntent,
        finishPayment,
        onIntentSuccessful,
      }}
    >
      {children}
      <Modal size="small" onCancel={cancelIntent}>
        {modalLoading && <Spinner width="3em" />}
        {clientSecret && (
          <>
            {intentType === "payment" && (
              <p className={"primaryText marginB10"}>
                Montant de votre achat : {currencyFormatter(montant)}{" "}
              </p>
            )}
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                intentType={intentType}
                clientSecret={clientSecret}
                intentStatus={intentStatus}
                setIntentStatus={setIntentStatus}
                finishPayment={finishPayment}
                onIntentSuccessful={onIntentSuccessful}
              />
            </Elements>
          </>
        )}
      </Modal>
    </StripeContext.Provider>
  );
}

export default function useStripePayment() {
  return useContext(StripeContext);
}
