import styles from "./ToggleSwitch.module.css";
import { classnames } from "../../utils";
import useLoader from "../../hooks/use-loader";
import { useRef, useState } from "react";

export default function ToggleSwitch({
  status,
  onToggle,
  className,
  disabled,
}) {
  const [isToggling, toggler] = useLoader(onToggle);
  const [fakeToggling, setFakeToggling] = useState(false);
  const timeoutRef = useRef(null);

  const isLoading = isToggling || fakeToggling;

  function toggleHandler(e) {
    setFakeToggling(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setFakeToggling(false), 300);
    return toggler(e);
  }

  return (
    <label className={classnames(styles.switch, className)}>
      <input
        type="checkbox"
        checked={status}
        onChange={toggleHandler}
        disabled={isLoading || disabled}
        className={classnames(styles.input)}
      />
      <span
        className={classnames(
          styles.slider,
          isLoading && styles.toggling,
          styles.round,
        )}
      ></span>
    </label>
  );
}
