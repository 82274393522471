import { classnames } from "../../utils";
import style from "./Tenant.module.css";
import InitialesLogo from "../UI/InitialesLogo";
import DisplayName from "../UI/DisplayName";
import { Link } from "react-router-dom";
import { ArrowRight, DotsAction, Interrogation, Property } from "../UI/Icons";
import moment from "moment/moment";
import InfoBoxList from "../UI/InfoBoxList";
import useMediaQuery from "../../hooks/use-media-query";
import Tooltip from "../UI/Tooltip";
import { isPropertyDisabled } from "../../utils/properties";
import BlurOverlay from "../UI/BlurOverlay";
import DisabledBienNote from "../Property/DisabledBienNote";

export default function TenantRow({
  properties,
  balances,
  profile,
  tenant,
  index,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 799px)");
  const property = properties?.find((prop) => prop.id === tenant.id_bien);
  const disabledProperty = isPropertyDisabled(property);
  return (
    <>
      <div className={classnames(style.column, style.item)}>
        <InitialesLogo
          nom={profile.nom}
          prenom={profile.prenom}
          shape={"rond"}
        />
        <div>
          <div className={style.nom}>
            {disabledProperty ? (
              <DisplayName profile={profile} />
            ) : (
              <Link to={`/bailleur/tenants/${tenant.id}`}>
                <DisplayName profile={profile} />
              </Link>
            )}
          </div>
          <div className={style.mail}>{profile.mail}</div>
        </div>
      </div>
      <div className={classnames(style.column, style.item)}>
        {property &&
          (disabledProperty ? (
            <span className={"smallText"}>
              <Property /> {property.nom}
            </span>
          ) : (
            <Link
              to={"/bailleur/properties/" + property.id}
              className={"smallText"}
            >
              <Property /> {property.nom}
            </Link>
          ))}
      </div>
      <div className={classnames(style.standard, "secondaryText", style.item)}>
        <div className={"marginR10"}>
          {moment(tenant.debut).format(
            isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
          )}
        </div>
        <ArrowRight />
        <div className={`marginL10 ${tenant.preavis && style.preavis}`}>
          {moment(tenant.fin).format(
            isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
          )}
          {tenant.preavis && (
            <span className={"marginL5"}>
              <Tooltip content={"Le locataire a déposé son préavis."}>
                <Interrogation stroke={"#C78F00"} />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
      <div
        className={classnames(
          style.columnCenter,
          style.item,
          style.statusPosition,
        )}
      >
        {balances
          ?.filter((bal) => bal.id_tenant === tenant.id)
          .map((bal) => {
            let color = "";
            let text = "";
            if (bal.balance >= 0) {
              color = "green";
              text = "A jour";
            } else if (bal.balance < (tenant.loyer_hc + tenant.charges) * -1) {
              color = "red";
              text = "Impayé";
            } else {
              color = "yellow";
              text = "Retard";
            }
            return <InfoBoxList color={color} text={text} key={color} />;
          })}
      </div>
      <div className={classnames(style.column, style.item, style.dotsPosition)}>
        <Link to={`/bailleur/tenants/${tenant.id}`}>
          <DotsAction />
        </Link>
      </div>
      {disabledProperty && (
        <BlurOverlay
          className={style.overlay}
          style={
            !isSmallScreen ? { gridRow: `${index} / ${index}` } : undefined
          }
        >
          <DisabledBienNote className={style.disabledNote} />
        </BlurOverlay>
      )}
    </>
  );
}
