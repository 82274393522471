import style from "./Table.module.css";
import { classnames } from "../../utils";

export function Table({ children, className, ...rest }) {
  return (
    <table className={classnames(style.table, className)} {...rest}>
      {children}
    </table>
  );
}

function Header({ children, className, ...rest }) {
  return (
    <thead className={classnames(style.thead, className)} {...rest}>
      <tr>{children}</tr>
    </thead>
  );
}

function TBody({ children, className, ...rest }) {
  return (
    <tbody className={classnames(className)} {...rest}>
      {children}
    </tbody>
  );
}

function TR({ children, className, ...rest }) {
  return (
    <tr className={classnames(style.tr, className)} {...rest}>
      {children}
    </tr>
  );
}

function TD({ children, align, className, ...rest }) {
  return (
    <td className={classnames(style.td, className, style[align])} {...rest}>
      {children}
    </td>
  );
}

function TH({ children, align = "left", className, ...rest }) {
  return (
    <th className={classnames(style.th, className, style[align])} {...rest}>
      {children}
    </th>
  );
}

Table.Header = Header;
Table.Column = TH;
Table.Body = TBody;
Table.Row = TR;
Table.Cell = TD;
