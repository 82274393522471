import { propertyFinanceSchema } from "../../../models/property";
import { Form, Formik } from "formik";
import useProperties from "../../../hooks/use-properties";
import Finance from "../AddFormComponents/Finance";
import {
  sanitizeValues,
  toInitialValuesFromSchema,
} from "../../../models/utils";
import { useState } from "react";
import Card from "../../UI/Card";
import { handleAPIError } from "../../../utils";
import FormikSubmitButton from "../../UI/FormikSubmitButton";

function DescriptionFinanceForm({ property, closeModal }) {
  const { updateProperty } = useProperties();
  const [error, setError] = useState("");

  if (!property) return null;

  async function submitHandler(values, { setSubmitting }) {
    try {
      await updateProperty(property.id, sanitizeValues(values));
      closeModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }

  const initialValues = toInitialValuesFromSchema(
    propertyFinanceSchema,
    property,
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={propertyFinanceSchema}
    >
      {(formikProps) => {
        return (
          <Form>
            <Finance
              values={formikProps.values}
              setFieldValue={formikProps.setFieldValue}
            />
            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card
                type={"error"}
                className={"padding10 text-center marginT10"}
              >
                {error}
              </Card>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default DescriptionFinanceForm;
