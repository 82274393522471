import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import {
  changePasswordInitialValues,
  changePasswordSchema,
} from "../../models/changePassword";

export default function ChangePasswordForm({ onSubmit }) {
  async function submitHandler(values) {
    try {
      await onSubmit(values);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Formik
      initialValues={changePasswordInitialValues}
      enableReinitialize={true}
      validationSchema={changePasswordSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <TextInput
          name={"old_password"}
          type={"password"}
          label={"Ancien mot de passe"}
        />
        <TextInput
          name={"new_password"}
          type={"password"}
          label={"Nouveau mot de passe"}
        />
        <TextInput
          name={"dnew_password"}
          type={"password"}
          label={"Entrez de nouveau le mot de passe"}
        />

        <div className={"text-right"}>
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  );
}
