import { useNavigate, useSearchParams } from "react-router-dom";
import TenantLeaseCard from "./TenantLeaseCard";
import TenantSynthese from "./TenantSynthese";
import TenantBalance from "./TenantBalance";
import TenantFinanceConfig from "./TenantFinanceConfig";
import ProfileIndivList from "../Profile/ProfileIndivList";
import HeaderTenant from "./HeaderTenant";
import DocumentShowList from "../File/DocumentShowList";
import { TenantStatus } from "../../models/tenant";
import Page from "../UI/Page";
import Tabs from "../UI/Tabs";
import Card from "../UI/Card";
import QuittanceForm from "./Forms/QuittanceForm";
import TenantAddForm, { TenantAddFormType } from "./Forms/TenantAddForm";
import ProfileForm from "../Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";
import React, { useEffect, useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  CalendarCheck,
  EDL,
  Message,
  People,
  Quittance,
} from "../UI/Icons";
import IconAndText from "../UI/IconAndText";
import style from "./TenantShow.module.css";
import ActionsCourriers from "./ActionsCourriers";
import ButtonCard from "../UI/ButtonCard";
import EdlRealisation from "../Edl/EdlRealisation";
import { ProfileStatus } from "../../models/profile";
import { classnames } from "../../utils";
import { getBalanceValues } from "../../api/Functions";
import { isPropertyDisabled } from "../../utils/properties";
import BlurOverlay from "../UI/BlurOverlay";
import DisabledBienNote from "../Property/DisabledBienNote";
import Processes from "../Processes/Processes";

export default function TenantShow({
  tenant,
  profiles,
  balances,
  property,
  deleteGarantHandler,
  archiveProfileHandler,
  activateProfileHandler,
  inviteProfilesHandler,
  syncTransactionHandler,
  activerLocataire,
  activerCandidat,
  refuserCandidat,
  supprimerCandidat,
}) {
  const nav = useNavigate();

  const { balanceTotal, totalPercus, DGAppele, DGPercu } =
    getBalanceValues(balances);

  const dateLastVirement = balances.find((trans) => trans.montant > 0); // Retourne le 1er element du tableau qui match
  const [TenantAddModal, closeTenantAddModal, openTenantAddModal] = useModal();
  const [ProcessModal, closeProcessModal, openProcessModal] = useModal();
  const [TenantQuittanceModal, , openTenantQuittanceModal] = useModal();
  const [AddProfileModal, closeAddProfileModal, openAddProfileModal] =
    useModal();
  const [EDLModal, closeEDLModal, openEDLModal, getEDLModalReady] = useModal();
  let [searchParams, setSearchParams] = useSearchParams();

  const isEdlModalReady = getEDLModalReady();
  const [showArchivedProfiles, setShowArchivedProfiles] = useState(false);

  useEffect(() => {
    if (!isEdlModalReady) {
      return;
    }
    if (searchParams.get("action") === "edl") {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete("action");
        return newParams;
      });
      openEDLModal();
    }
  }, [isEdlModalReady, openEDLModal, searchParams, setSearchParams]);

  const initialProfileToPush = [];
  if (tenant) {
    tenant.profile_nom.profiles
      .filter(
        (profile) =>
          profile.garantor_of === null &&
          profile.status === ProfileStatus.Actif.status,
      )
      .forEach((profile) =>
        initialProfileToPush.push({
          profile: profile,
          tenant: {
            id_bien: tenant.id_bien,
            status: tenant.status,
            id: tenant.id,
          },
        }),
      );
  }
  const [profileToPush, setProfileToPush] = useState(initialProfileToPush);
  function profileSubmittedHandler(values) {
    setProfileToPush((curr) => [...curr, { profile: values }]);
  }

  const archivedProfiles = profiles.filter(
    (profile) => profile.status === ProfileStatus.Archive.status,
  );

  if (!tenant) return null;

  const disabledProperty = isPropertyDisabled(property);

  const vueEnsemble = (
    <div className={"container"}>
      <ProcessModal size={"big"} maxContentWidth={"lg"}>
        <Processes onFinish={closeProcessModal} tenantId={tenant.id} />
      </ProcessModal>
      <QuittanceForm
        tenantId={tenant?.id}
        ModalElement={TenantQuittanceModal}
      />
      <TenantAddForm
        openAddProfileModal={openAddProfileModal}
        profileToPush={profileToPush}
        onAddProfile={(prof) => setProfileToPush((curr) => [...curr, prof])}
        onDeleteProfile={(index) =>
          setProfileToPush((curr) => curr.filter((profile, i) => i !== index))
        }
        propertyId={tenant?.id_bien}
        tenant={tenant}
        modal={TenantAddModal}
        closeTenantAddModal={closeTenantAddModal}
        type={TenantAddFormType.LOCATAIRE}
      />
      <ProfileForm
        onProfileSubmitted={(profile) => {
          profileSubmittedHandler(profile);
          closeAddProfileModal();
        }}
        addTenant
        modal={AddProfileModal}
      />
      {tenant && tenant.profile_nom ? (
        <>
          <Card>
            <div className={style.gridActions}>
              {tenant.status === TenantStatus.Actif.status && (
                <>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    onClick={openProcessModal}
                    buttonStyle={"blue"}
                  >
                    <IconAndText
                      icon={<CalendarCheck />}
                      text={"Fin de bail"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center  smallText smallIcon"
                    }
                    buttonStyle={"blue"}
                    onClick={() =>
                      nav(
                        `/bailleur/tenants/${tenant.id}?tab-tenant-show=actions`,
                      )
                    }
                  >
                    <IconAndText
                      icon={<Message />}
                      text={"Envoyer un courrier"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center  smallText smallIcon"
                    }
                    onClick={openEDLModal}
                    buttonStyle={"blue"}
                  >
                    <IconAndText
                      icon={<EDL />}
                      text={"Réaliser un état des lieux"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center  smallText smallIcon"
                    }
                    onClick={openTenantQuittanceModal}
                    buttonStyle={"blue"}
                  >
                    <IconAndText
                      icon={<Quittance />}
                      text={"Editer une quittance"}
                    />
                  </ButtonCard>
                </>
              )}
              {tenant.status === TenantStatus.Archive.status && (
                <>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    onClick={openTenantQuittanceModal}
                    buttonStyle={"blue"}
                  >
                    <IconAndText
                      icon={<Quittance />}
                      text={"Editer une quittance"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"blue"}
                    onClick={activerLocataire}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Réactiver le candidat"}
                    />
                  </ButtonCard>
                </>
              )}
              {tenant.status === TenantStatus.Candidat.status && (
                <>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"blue"}
                    onClick={openTenantAddModal}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Convertir en locataire"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"error"}
                    onClick={refuserCandidat}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Refuser le dossier"}
                    />
                  </ButtonCard>
                </>
              )}
              {tenant.status === TenantStatus.CandidatArchive.status && (
                <>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"blue"}
                    onClick={openTenantAddModal}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Convertir en locataire"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"blue"}
                    onClick={activerCandidat}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Réactiver le candidat"}
                    />
                  </ButtonCard>
                  <ButtonCard
                    className={
                      "padding5 w-100 h-3em flex align-items-center justify-content-center smallText smallIcon"
                    }
                    buttonStyle={"error"}
                    onClick={supprimerCandidat}
                  >
                    <IconAndText
                      icon={<People />}
                      text={"Supprimer le candidat"}
                    />
                  </ButtonCard>
                </>
              )}
            </div>
          </Card>
          <div className={"flex wrap-reverse gap10 marginT10 space-between"}>
            {tenant.status !== TenantStatus.Candidat.status && (
              <>
                <TenantLeaseCard tenant={tenant} allowEdit />
                <TenantSynthese
                  tenant={tenant}
                  balanceTotal={balanceTotal}
                  totalPercus={totalPercus}
                  dateLastVirement={dateLastVirement?.date_transac}
                  DGPercu={DGPercu}
                  DGAppele={DGAppele}
                />
              </>
            )}
          </div>
          <div className={"marginT10"}>
            <ProfileIndivList
              profiles={profiles.filter(
                (profile) => profile.status === ProfileStatus.Actif.status,
              )}
              editProfileHandler={(profileId) => nav(`${profileId}/edit`)}
              archiveProfileHandler={archiveProfileHandler}
              inviteProfileHandler={(profileId) =>
                inviteProfilesHandler([profileId], tenant.id)
              }
              removeGarant={deleteGarantHandler}
              dontAddGarant
            />
          </div>
          {archivedProfiles.length > 0 && (
            <div
              className={classnames(
                style.archivedProfiles,
                "border-blue primaryText",
              )}
              onClick={() => setShowArchivedProfiles((profile) => !profile)}
            >
              <p>Afficher les profils archivés</p>
              <p>{showArchivedProfiles ? <ArrowUp /> : <ArrowDown />}</p>
            </div>
          )}
          {showArchivedProfiles && (
            <div className={"marginT10"}>
              <ProfileIndivList
                profiles={archivedProfiles}
                editProfileHandler={(profileId) => nav(`${profileId}/edit`)}
                activateProfileHandler={activateProfileHandler}
                removeGarant={deleteGarantHandler}
                dontAddGarant
              />
            </div>
          )}
        </>
      ) : (
        <p>Aucun profil trouvé</p>
      )}
    </div>
  );

  return (
    <>
      <EdlRealisation
        tenant={tenant}
        property={property}
        modal={EDLModal}
        onSubmitted={closeEDLModal}
      />
      <Page
        header={tenant && <HeaderTenant tenant={tenant} property={property} />}
        body={
          <Tabs id="tenant-show">
            <div className="container bg-blue paddingT10 border-blue">
              <div className="content">
                <Tabs.TabList>
                  <Tabs.Tab panelId="vue-ensemble">Vue d'ensemble</Tabs.Tab>
                  {tenant?.status !== TenantStatus.Candidat.status &&
                    !disabledProperty && (
                      <>
                        <Tabs.Tab panelId="historique-transactions">
                          Historique des paiements
                        </Tabs.Tab>
                        <Tabs.Tab panelId="documents-courriers">
                          Documents
                        </Tabs.Tab>
                        <Tabs.Tab panelId="actions">
                          Actions / Courriers
                        </Tabs.Tab>
                        <Tabs.Tab panelId="parametres-banque">
                          Paramètres
                        </Tabs.Tab>
                      </>
                    )}
                </Tabs.TabList>
              </div>
            </div>
            <div className="blueSpace"></div>
            <div className="content" style={{ position: "relative" }}>
              <div inert={disabledProperty ? "true" : undefined}>
                <Tabs.TabPanel id="vue-ensemble">{vueEnsemble}</Tabs.TabPanel>
                {tenant?.status !== TenantStatus.Candidat.status && (
                  <>
                    <Tabs.TabPanel id="historique-transactions">
                      <TenantBalance
                        tenant={tenant}
                        syncTransactionHandler={syncTransactionHandler}
                        balanceTotal={balanceTotal}
                        DGPercu={DGPercu}
                        DGAppele={DGAppele}
                      />
                    </Tabs.TabPanel>
                    <Tabs.TabPanel id="documents-courriers">
                      <div className={"w-100"}>
                        <DocumentShowList
                          tenant={tenant}
                          allowDelete
                          allowEdit
                          isBailleur
                        />
                      </div>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel id="actions">
                      <ActionsCourriers
                        tenant={tenant}
                        property={property}
                        balances={balances}
                      />
                    </Tabs.TabPanel>
                    <Tabs.TabPanel id="parametres-banque">
                      <TenantFinanceConfig tenantId={tenant?.id} />
                    </Tabs.TabPanel>
                  </>
                )}
              </div>
              {disabledProperty && (
                <BlurOverlay className={style.overlay}>
                  <DisabledBienNote className={style.disabledNote} />
                </BlurOverlay>
              )}
            </div>
          </Tabs>
        }
      />
    </>
  );
}
