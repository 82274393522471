import useLeaseTemplates from "../../hooks/use-lease-templates";
import Card from "../UI/Card";
import { TextOneOrMany } from "../../utils";
import CardToggle from "../UI/CardToggle";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import LeaseTemplateForm from "./LeaseTemplateForm";
import { useState } from "react";
import ButtonCard from "../UI/ButtonCard";
import { PlusBank } from "../UI/Icons";
import IconAndText from "../UI/IconAndText";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import {
  LEASE_TEMPLATE_INITIAL_VALUE,
  leaseTemplateValidationSchema,
} from "../../models/leaseTemplate";

function LeaseTemplateShow() {
  const { leaseTemplates, updateLeaseTemplate, addLeaseTemplate } =
    useLeaseTemplates();
  const [FormModal, closeForm, openForm] = useModal();
  const [leaseToEdit, setLeaseToEdit] = useState();

  let initialValues = toInitialValuesFromSchema(
    leaseTemplateValidationSchema,
    leaseToEdit,
    LEASE_TEMPLATE_INITIAL_VALUE,
  );

  async function submitHandler(values) {
    const sanitizedValues = sanitizeValues(values);
    try {
      if (leaseToEdit) {
        await updateLeaseTemplate(leaseToEdit.id, sanitizedValues);
      } else {
        await addLeaseTemplate(sanitizedValues);
      }
      closeForm();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <FormModal>
        <LeaseTemplateForm
          initialValues={initialValues}
          onSubmit={submitHandler}
        />
      </FormModal>
      {leaseTemplates.map((lease) => (
        <Card className={"marginB10"}>
          <div className={"flex space-between"}>
            <p className={"cardTitle"}>{lease.nom}</p>
            <div>
              <Button
                onClick={() => {
                  setLeaseToEdit(lease);
                  openForm();
                }}
              >
                Modifier
              </Button>
            </div>
          </div>

          <CardToggle
            title={
              <p className="primaryText">
                Type : {lease.type} - Durée : {lease.duree}{" "}
                <TextOneOrMany one={"an"} many={"ans"} number={lease.duree} />
              </p>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: lease.content }} />
          </CardToggle>
        </Card>
      ))}
      <ButtonCard
        className={"w-100 flex align-items-center justify-content-center"}
        onClick={() => {
          setLeaseToEdit(null);
          openForm();
        }}
      >
        <IconAndText icon={<PlusBank />} text={"Ajouter un bail"} />
      </ButtonCard>
    </>
  );
}

export default LeaseTemplateShow;
