import AuthFormSignup from "../../components/Auth/AuthFormSignup";
import TemplateAuthentication from "../../components/UI/TemplateAuthentication";

function LoginPage() {
  return (
    <TemplateAuthentication>
      <div>
        <p className={"marginB10 text-center primaryText"}>
          Vous n'avez pas encore de compte?
        </p>
      </div>
      <div>
        <AuthFormSignup />
        {/*
          <div>
            <p> - Ou -</p>
            <p>
              <ButtonWithIcon style={{ width: "100%" }} imgsrc={Google}>
                Continuer avec Google
              </ButtonWithIcon>
              <ButtonWithIcon style={{ width: "100%" }} imgsrc={Facebook}>
                Continuer avec Facebook
              </ButtonWithIcon>
            </p>
          </div>
        */}
      </div>
    </TemplateAuthentication>
  );
}

export default LoginPage;
