import useAuth from "./use-auth";
import useRefreshToken from "./use-refresh-token";
import { useMemo } from "react";
import axios from "axios";

export default function useAuthenticatedAxios() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  return useMemo(() => {
    const instanceConfig = {
      baseURL: process.env.REACT_APP_BACKEND_URL,
      withCredentials: true,
    };
    if (auth.accessToken) {
      instanceConfig.headers = { Authorization: `Bearer ${auth.accessToken}` };
    }

    const instance = axios.create(instanceConfig);

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return instance(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    return instance;
  }, [auth.accessToken, refresh]);
}
