import { Form, Formik } from "formik";
import { object, string } from "yup";
import useAuth from "../../../hooks/use-auth";
import useProperties from "../../../hooks/use-properties";
import { sendToBackend } from "../../../api";
import Select from "../../UI/Select";
import TextInput from "../../UI/TextInput";
import PropertyDisplaySmallCard from "../../Property/PropertyDisplaySmallCard";
import Card from "../../UI/Card";
import IconAndText from "../../UI/IconAndText";
import { Message } from "../../UI/Icons";
import FormikSubmitButton from "../../UI/FormikSubmitButton";
import CopyToClipboard from "../../UI/CopyToClipboard";
import Button from "../../UI/Button";

function TenantProspectForm({
  propertyId,
  closeTenantAddModal,
  afterForm,
  setAfterForm,
}) {
  const { auth } = useAuth();
  const { properties } = useProperties();

  async function submitHandler(values, { setSubmitting }) {
    try {
      await sendToBackend("bailleur/candidat", auth.accessToken, {
        method: "POST",
        data: values,
      });
      setAfterForm(true);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  }

  return afterForm ? (
    <div className={"text-center"}>
      <h2>Invitation envoyée avec succès</h2>
      <p className={"marginT10"}>
        L'invitation a été envoyée à votre candidat.
      </p>
      <p className={"marginT10"}>
        Vous serez alerté lorsqu'il aura complété son dossier.
      </p>
      <p className={"marginT10"}>
        Vous pourrez ensuite étudier le dossier, et approuver ou non le
        candidat.
      </p>
      <div className={"text-right marginT20"}>
        <Button onClick={closeTenantAddModal}>Fermer</Button>
      </div>
    </div>
  ) : (
    <Formik
      initialValues={{ id_bien: propertyId, mail: "" }}
      enableReinitialize={true}
      validationSchema={object({
        mail: string().email().required("Mail requis"),
        id_bien: string().required("Vous devez sélectionner un bien"),
      })}
      onSubmit={submitHandler}
    >
      {(formikProps) => (
        <Form>
          <p className={"secondaryText text-center"}>
            Ce formulaire vous permet d'inviter un candidat à déposer son
            dossier pour votre bien. Lorsque le dossier de votre candidat sera
            complet, vous pourrez le consulter. Si vous le souhaitez, vous
            pourrez le transformer en locataire (génération de bail, etc)
            automatiquement
          </p>
          <Select name="id_bien">
            <option value="">Choisir le bien concerné</option>
            {properties.map((option) => (
              <option key={option.id} value={option.id}>
                {option.nom}
              </option>
            ))}
          </Select>
          {formikProps.values.id_bien &&
            properties
              .filter(
                (filtered) =>
                  filtered.id === parseInt(formikProps.values.id_bien),
              )
              .map((property) => (
                <PropertyDisplaySmallCard
                  property={property}
                  key={property.id}
                />
              ))}
          <Card className={"marginB10"}>
            <TextInput
              type="text"
              name="mail"
              placeholder="Adresse e-mail"
              label={"Adresse e-mail du candidat"}
            />
            {formikProps.values.id_bien && (
              <Card className={"padding10"}>
                <IconAndText
                  icon={<Message stroke="#9D9D9D" />}
                  text={
                    <>
                      <p className={"secondaryText marginB5"}>
                        Ou transmettez directement ce lien
                      </p>
                      <p className={"secondaryText"}>
                        {process.env.REACT_APP_FRONTEND_URL}/candidat?bien=
                        {formikProps.values.id_bien}
                        <CopyToClipboard
                          toCopy={`${process.env.REACT_APP_FRONTEND_URL}/candidat?bien=${formikProps.values.id_bien}`}
                        />
                      </p>
                    </>
                  }
                />
              </Card>
            )}
            {formikProps.values.id_bien && (
              <Card className={"marginT10"}>
                <p className={"primaryText"}>
                  Vous pouvez également mettre ce texte dans votre annonce ou
                  l'envoyer sur la messagerie Leboncoin :
                </p>
                <p className={"secondaryText marginT5"}>
                  "Pour soumettre votre candidature, rendez vous sur le site
                  Qalimo . fr, espace candidature, et entrez le numéro de bien :{" "}
                  <span className={"primaryText"}>
                    {formikProps.values.id_bien}
                  </span>
                  <br />
                  Vous serez invité à créer un compte, puis à compléter votre
                  dossier. <br />
                  Vos données sont sécurisées, cet outil est 100% français, et
                  co-fondé par un ancien huissier de justice."
                </p>
              </Card>
            )}
          </Card>
          <div className={"text-right"}>
            <FormikSubmitButton>Envoyer</FormikSubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default TenantProspectForm;
