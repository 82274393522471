import { useParams } from "react-router-dom";
import ProfileNomForm from "../../components/Profile/ProfileNomForm";
import useProfileNoms from "../../hooks/use-profile-noms";

function ProfilesNomEditPage() {
  const { profileNomId } = useParams();

  const { profileNoms } = useProfileNoms();

  return (
    <ProfileNomForm
      edit
      profileNom={profileNoms?.find(
        (profile) => profile.id === parseInt(profileNomId),
      )}
    />
  );
}

export default ProfilesNomEditPage;
