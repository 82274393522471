import useResources from "./use-resources";

function useEdlItemsTemplates() {
  const root_path = `edl/templates`;
  const {
    resources: edlItemsTemplates,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources(root_path);

  return {
    edlItemsTemplates,
    error,
    isLoading,
    updateEdlItemTemplate: update,
    addEdlItemTemplate: create,
    deleteEdlItemTemplate: del,
  };
}

export default useEdlItemsTemplates;
