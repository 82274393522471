import Card from "../UI/Card";
import ReactECharts from "echarts-for-react";
import { useRawBalances } from "../../hooks/use-balances";
import { groupBy, mapValues } from "lodash";
import moment from "moment";
import { currencyFormatter } from "../../api/Functions";
import { useState } from "react";
import Button from "../UI/Button";
import useProperties from "../../hooks/use-properties";
import useTenants from "../../hooks/use-tenants";

export default function Statistiques() {
  const { properties } = useProperties();
  const { tenants } = useTenants();
  const { balances } = useRawBalances("tenants/payments");
  const [selectedYears, setSelectedYears] = useState([moment().year()]);
  const [showLegend, setShowLegend] = useState(false);

  function toggleYearSelection(year) {
    setSelectedYears((prevSelectedYears) =>
      prevSelectedYears.includes(year)
        ? prevSelectedYears.filter((y) => y !== year)
        : [...prevSelectedYears, year],
    );
  }

  function toggleAllYears() {
    if (selectedYears.length === years.length) {
      setSelectedYears([]);
    } else {
      setSelectedYears(years);
    }
  }

  function toggleLegendVisibility() {
    setShowLegend((prevShowLegend) => !prevShowLegend);
  }

  function sumBalanceReducer(acc, balance) {
    return acc + balance.montant;
  }

  function aggregateData(balances, groupByFn) {
    return mapValues(groupBy(balances, groupByFn), (balances) => {
      return balances.reduce(sumBalanceReducer, 0);
    });
  }

  function prepareChartData(aggregatedData, nameGetter) {
    return Object.entries(aggregatedData).map(([key, value]) => {
      return {
        value,
        name: nameGetter(parseInt(key)),
      };
    });
  }

  function chartOptionsGetter(title, data) {
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) =>
          `${params.name}: ${currencyFormatter(params.value)}`,
      },
      legend: {
        show: showLegend,
        bottom: "5%",
        left: "center",
        width: "80%",
      },
      title: {
        text: title,
        left: "center",
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
  }

  const filteredBalances = balances.filter((balance) =>
    selectedYears.includes(moment(balance.date_transac).year()),
  );

  const totalSum = filteredBalances.reduce(sumBalanceReducer, 0);

  const years = [
    ...new Set(balances.map((balance) => moment(balance.date_transac).year())),
  ].sort((a, b) => b - a);

  const aggregatedDataByTenant = aggregateData(
    filteredBalances,
    (balance) => balance.id_tenant,
  );
  const chartDataByTenant = prepareChartData(
    aggregatedDataByTenant,
    (id) =>
      tenants.find((t) => t.id === id)?.profile_nom.nom_profile ??
      `Tenant ${id}`,
  );

  const aggregatedDataByProperty = aggregateData(
    filteredBalances,
    (balance) => tenants.find((t) => t.id === balance.id_tenant)?.id_bien,
  );
  const chartDataByProperty = prepareChartData(
    aggregatedDataByProperty,
    (id) => properties.find((t) => t.id === id)?.nom ?? `Property ${id}`,
  );

  const chartStyle = { height: 300, width: 500 };

  return (
    <Card>
      <div className={"flex gap10 wrap"}>
        <Button
          onClick={toggleAllYears}
          buttonStyle={
            selectedYears.length === years.length ? "primary" : "secondary"
          }
        >
          Tout
        </Button>
        {years.map((year) => (
          <Button
            key={year}
            onClick={() => toggleYearSelection(year)}
            buttonStyle={selectedYears.includes(year) ? "primary" : "secondary"}
          >
            {year}
          </Button>
        ))}
        <Button
          onClick={toggleLegendVisibility}
          buttonStyle={showLegend ? "primary" : "secondary"}
        >
          Légende
        </Button>
      </div>

      <div className={"flex wrap gap10 marginT20 align-items-center"}>
        <ReactECharts
          option={chartOptionsGetter(
            "Loyers perçus par locataire",
            chartDataByTenant,
          )}
          style={chartStyle}
        />
        <ReactECharts
          option={chartOptionsGetter(
            "Loyers perçus par bien",
            chartDataByProperty,
          )}
          style={chartStyle}
        />
      </div>
      <div className={"text-right"}>
        <p className={"primaryText"}>Total : {currencyFormatter(totalSum)}</p>
      </div>
    </Card>
  );
}
