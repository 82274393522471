import { useEffect, useState } from "react";
import useRefreshToken from "../../hooks/use-refresh-token";
import useAuth from "../../hooks/use-auth";
import { Outlet } from "react-router-dom";

function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    async function verifyRefreshToken() {
      try {
        await refresh();
      } catch (err) {
        if (err?.response?.status !== 401) {
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    }

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, [auth, refresh]);

  return isLoading ? <p>Loading...</p> : <Outlet />;
}

export default PersistLogin;
