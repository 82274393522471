import { Form, Formik } from "formik";

import { useParams } from "react-router-dom";
import { BANK_INITIAL_VALUES, bankSchema } from "../../models/bank";
import Button from "../UI/Button";
import TextInput from "../UI/TextInput";
import { useModal } from "../../hooks/use-modal";
import useBanks from "../../hooks/use-banks";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { useState } from "react";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Select from "../UI/Select";
import useProfileNoms from "../../hooks/use-profile-noms";

function BankForm({ edit }) {
  const { profileNoms } = useProfileNoms();
  const { bankId } = useParams();
  const [Modal, closeModal] = useModal(true);
  const { banks, updateBank, addBank, deleteBank } = useBanks();
  const [error, setError] = useState("");

  if (!banks) {
    return null;
  }
  const bank = banks.find((b) => b.id === parseInt(bankId));

  async function submitHandler(values) {
    try {
      if (edit) {
        await updateBank(parseInt(bankId), sanitizeValues(values));
      } else {
        await addBank(sanitizeValues(values));
      }
      closeModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  async function deleteBankHandler(bankId) {
    try {
      await deleteBank(bankId);
      closeModal();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Modal>
      <Formik
        initialValues={toInitialValuesFromSchema(
          bankSchema,
          bank,
          BANK_INITIAL_VALUES,
        )}
        enableReinitialize={true}
        validationSchema={bankSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <TextInput
            type="text"
            name="nom"
            placeholder="SCI HENRY"
            label={"Nom du compte"}
          />
          <TextInput
            type="number"
            name="alert"
            placeholder="Pas d'alerte"
            label={"Seuil d'alerte"}
            unit={"€"}
          />
          <Select name="id_profile_nom">
            <option value="">Affecter à un profil bailleur</option>
            {profileNoms.map((option) => (
              <option key={option.id} value={option.id}>
                {option.nom_profile}
              </option>
            ))}
          </Select>
          <div className="text-right">
            <Button
              type="button"
              buttonStyle={"secondaryDelete"}
              onClick={() => deleteBankHandler(parseInt(bankId))}
              className={"marginR10"}
            >
              Supprimer
            </Button>
            <FormikSubmitButton>Valider</FormikSubmitButton>
          </div>
          {error && (
            <Card type={"error"} className={"padding10 text-center marginT10"}>
              {error}
            </Card>
          )}
        </Form>
      </Formik>
    </Modal>
  );
}

export default BankForm;
