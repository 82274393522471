import { FieldArray, Form, Formik } from "formik";
import {
  BALANCE_MASSE_INITIAL_VALUE,
  balanceMasseValidationSchema,
  balanceTenantIV,
} from "../../models/balanceTenant";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import Card from "../UI/Card";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { Croix, PlusBank } from "../UI/Icons";
import style from "./BalanceMasseForm.module.css";
import BalanceFields from "./BalanceFields";
import { produce } from "immer";
import {
  classnames,
  handleAPIError,
  setBalanceAmountSignFromType,
} from "../../utils";
import { useBalanceTypes } from "../../hooks/use-balances";
import { useState } from "react";

export default function BalanceMasseForm({
  balances,
  onSubmit,
  ModalElement,
  domaineBalance,
}) {
  const initialValues = toInitialValuesFromSchema(
    balanceMasseValidationSchema,
    { balances },
    BALANCE_MASSE_INITIAL_VALUE,
  );
  const [error, setError] = useState("");

  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  if (isLoading) return null;

  async function submitHandler(values, { resetForm }) {
    const vals = produce(sanitizeValues(values), (draft) => {
      draft.balances.forEach((balance) => {
        // change to negative if type is Appel .*
        setBalanceAmountSignFromType(balance, balanceTypesByCategory);
        if (!balance.domaine) balance.domaine = domaineBalance;
      });
    });
    try {
      await onSubmit(vals.balances);
      resetForm();
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={balanceMasseValidationSchema}
        onSubmit={submitHandler}
      >
        {({ values, dirty }) => {
          return (
            <ModalElement
              stickyHeader
              confirmCloseText={
                "Votre formulaire n'a pas été sauvegardé. Voulez-vous vraiment quitter ?"
              }
              shouldConfirm={() => dirty}
            >
              <Form>
                <p className={"cardTitle text-center marginB20"}>
                  <img
                    src={`/photo_balance.png`}
                    alt="Balance locataire"
                    className={"center"}
                  />
                  Édition multiple
                </p>
                <FieldArray
                  name="balances"
                  render={(arrayHelpers) => (
                    <>
                      <Card
                        className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
                        onClick={() =>
                          arrayHelpers.unshift({
                            ...balanceTenantIV,
                            domaine: domaineBalance,
                          })
                        }
                      >
                        <PlusBank />
                        <p className={"secondaryText marginL10"}>
                          Ajouter une entrée
                        </p>
                      </Card>
                      {values.balances.map((balance, i) => (
                        <Card
                          className={classnames(
                            "padding10",
                            "marginB10",
                            balance.id && "bg-blue",
                          )}
                          key={i}
                        >
                          <div
                            className={style.delete}
                            onClick={() => arrayHelpers.remove(i)}
                          >
                            <Croix />
                          </div>
                          <div className="flex wrap gap10">
                            <BalanceFields
                              name={`balances.${i}.`}
                              isFromTransac={!!balance.id_transaction}
                              domaineBalance={balance.domaine}
                            />
                          </div>
                        </Card>
                      ))}
                      <Card
                        className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
                        onClick={() =>
                          arrayHelpers.push({
                            ...balanceTenantIV,
                            domaine: domaineBalance,
                          })
                        }
                      >
                        <PlusBank />
                        <p className={"secondaryText marginL10"}>
                          Ajouter une entrée
                        </p>
                      </Card>
                    </>
                  )}
                />
                <div className={"text-right"}>
                  <FormikSubmitButton>Valider</FormikSubmitButton>
                </div>
                {error && (
                  <Card
                    type={"error"}
                    className={"padding10 text-center marginT10"}
                  >
                    {error}
                  </Card>
                )}
              </Form>
            </ModalElement>
          );
        }}
      </Formik>
    </>
  );
}
