import ButtonCard from "./ButtonCard";
import { classnames } from "../../utils";
import style from "./ButtonAction.module.css";

export default function ButtonAction({
  logo,
  tooltip,
  onClick,
  className,
  buttonStyle = "blue",
  ...props
}) {
  return (
    <ButtonCard
      buttonStyle={buttonStyle}
      className={classnames(className, style.button)}
      onClick={onClick}
      title={tooltip}
      {...props}
    >
      {logo}
    </ButtonCard>
  );
}
