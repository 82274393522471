import { array, boolean, date, number, object, string } from "yup";
import { produce } from "immer";
import moment from "moment/moment";

export const stringMax = (maxLength) =>
  string().max(maxLength, `${maxLength} caractères maximum`);

export const dateMax = () =>
  date().max(moment("9999-12-31"), "La date est invalide");

export const timeSchema = () => {
  const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return string().matches(timeFormat, "L'heure doit être au format HH:mm");
};

export const numberOrDefault = (def = 0) =>
  number()
    .transform((current) => {
      if (isNaN(current)) {
        return def;
      }
      return current;
    })
    .default(def);

export const integerOrDefault = (def = 0) =>
  numberOrDefault(def).integer().truncate();

export function sanitizeValues(values) {
  try {
    return produce(values, (draft) => {
      Object.keys(draft).forEach((key) => {
        if (draft[key] === "" || draft[key] === null) {
          draft[key] = null;
        } else if (Array.isArray(draft[key])) {
          draft[key].forEach((item, index) => {
            if (typeof item === "object") {
              draft[key][index] = sanitizeValues(item);
            }
          });
        } else if (typeof draft[key] === "object") {
          draft[key] = sanitizeValues(draft[key]);
        }
      });
    });
  } catch (e) {
    console.error("Couldn't sanitize values, returning original", values, e);
    return values;
  }
}

export function toStringSchema(schema) {
  if (schema === undefined) return;
  const type = schema.type;

  if (
    type === "string" ||
    type === "number" ||
    type === "date" ||
    type === "mixed"
  ) {
    return string().nullable().ensure();
  }

  if (type === "boolean") {
    return boolean()
      .nullable()
      .transform((value) => !!value);
  }

  if (type === "array") {
    return array().of(toStringSchema(schema.innerType)).nullable().ensure();
  }

  if (type === "object") {
    const newSchema = {};
    for (const [f, s] of Object.entries(schema.fields)) {
      newSchema[f] = toStringSchema(s);
    }
    return object(newSchema);
  }
}

export function toInitialValuesFromSchema(schema, toConvert, defaultValue) {
  if (toConvert === null || toConvert === undefined) {
    return defaultValue;
  }

  return toStringSchema(schema).cast(toConvert);
}

export function toInitialValuesRecursive(toConvert, defaultValue) {
  if (defaultValue === undefined || defaultValue === null) {
    // No default value means we should not convert that value
    return toConvert;
  }

  if (toConvert === null || toConvert === undefined) {
    return defaultValue;
  }

  if (typeof toConvert === "string") {
    return toConvert;
  }

  if (typeof toConvert === "number") {
    return toConvert.toString();
  }

  if (Array.isArray(toConvert)) {
    return toConvert.map((item) =>
      toInitialValuesRecursive(item, defaultValue[0]),
    );
  }

  // General case when toConvert is an object, we recursively convert every value
  // Convert everything that needs converting based on defaultValue
  return produce(toConvert, (draft) => {
    Object.entries(draft).forEach(([k, v]) => {
      draft[k] = toInitialValuesRecursive(v, defaultValue[k]);
    });
  });
}

export function toNestedStructure(
  flatlist,
  foreignKey,
  nestedKey,
  primaryKey = "id",
) {
  const nestedStruct = {};
  for (let item of flatlist) {
    nestedStruct[item[primaryKey]] = { ...item };
    nestedStruct[item[primaryKey]][nestedKey] = [];
  }
  for (let item of flatlist) {
    if (item[foreignKey]) {
      nestedStruct[item[foreignKey]][nestedKey].push(
        nestedStruct[item[primaryKey]],
      );
    }
  }
  for (let item of flatlist) {
    if (item[foreignKey]) {
      delete nestedStruct[item[primaryKey]];
    }
  }
  return Object.values(nestedStruct);
}

export function upperCaseFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
