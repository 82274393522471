import ButtonCard from "../UI/ButtonCard";
import IconAndText from "../UI/IconAndText";
import { ArrowRight } from "../UI/Icons";
import React from "react";
import { useFormikContext } from "formik";
import TenantSelect from "../Tenant/TenantSelect";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import useProcesses from "../../hooks/use-processes";

export default function ProcessChoice({ processType, text, icon }) {
  const { values, setFieldValue } = useFormikContext();
  const { activeProcesses } = useProcesses();
  return (
    <>
      <ButtonCard
        onClick={() =>
          values.type === processType
            ? setFieldValue("type", null)
            : setFieldValue("type", processType)
        }
        className={"w-100 flex space-between align-items-center"}
      >
        <IconAndText text={text} icon={icon} />
        <ArrowRight classname={"marginR10"} />
      </ButtonCard>
      {values.type === processType && (
        <div className={"flex align-items-center gap10 wrap"}>
          <TenantSelect
            name={"id_tenant"}
            filterCallback={(tenant) =>
              !activeProcesses.some(
                (proc) =>
                  proc.id_tenant === tenant.id && proc.type === values.type,
              )
            }
          />
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      )}
    </>
  );
}
