import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import TitleSearchAdd from "../../components/UI/TitleSearchAdd";
import Page from "../../components/UI/Page";
import useTenants from "../../hooks/use-tenants";
import CandidatList from "../../components/Tenant/CandidatList";
import { useState } from "react";

function CandidatListPage() {
  const { properties, profiles } = useOutletContext();
  const nav = useNavigate();
  const { tenants } = useTenants();
  const [filter, setFilter] = useState("");

  return (
    <>
      <Page
        header={
          <TitleSearchAdd
            title="Candidats"
            placeholder="Rechercher un candidat"
            textButton="Ajouter un candidat"
            addAction={() => nav("add")}
            setFilter={setFilter}
          />
        }
        body={
          <CandidatList
            profiles={profiles}
            properties={properties}
            tenants={tenants.filter((tenant) =>
              tenant.profile_nom.nom_profile.toLowerCase().includes(filter),
            )}
          />
        }
      />

      <Outlet />
    </>
  );
}

export default CandidatListPage;
