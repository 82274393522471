import TextArea from "../../../UI/TextArea";

export default function CourrierTrouble() {
  return (
    <>
      <TextArea
        type="text"
        name="motif"
        label="Liste des troubles"
        placeholder="Nuisances sonores répétées"
      />
    </>
  );
}
