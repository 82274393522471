import useAuthenticatedAxios from "./use-authenticated-axios";
import useSWRImmutable from "swr/immutable";

export default function usePhoto(photoId, isThumbnail, type) {
  const axios = useAuthenticatedAxios();
  let url = null;
  if (photoId) {
    if (type === "photo") {
      url = `photos/${photoId}/download`;
    } else if (type === "edl") {
      url = `edl/photos/${photoId}/download`;
    } else if (type === "file") {
      url = `documents/${photoId}/download?doctype=file`;
    }
  }

  const {
    data: photo,
    error,
    isLoading,
  } = useSWRImmutable(
    url,
    (url) => {
      return axios
        .get(url, {
          responseType: "blob",
          params: { thumbnail: isThumbnail || undefined },
        })
        .then((res) => res.data);
    },
    {
      shouldRetryOnError: (error) => {
        // We skip retrying if the API is returning 404
        return error.response?.status !== 404;
      },
    },
  );

  return {
    photo,
    error,
    isLoading,
  };
}
