import Img from "./Img";
import { isImage, isPdf } from "../../utils";
import { DocType } from "../../utils/constants/documents";
import useThumbnail from "../../hooks/use-thumbnail";
import Spinner from "./Spinner";
import style from "./DocumentThumbnail.module.css";
import { Croix } from "./Icons";
import { SignatureContratStatus } from "../../models/signatureElectronique";

export default function DocumentThumbnail({
  documentId,
  file,
  doctype = DocType.GENERIC_FILE,
  isSigned = file?.signature_electronique_status ===
    SignatureContratStatus.Archived.status,
  onDelete,
}) {
  let { thumbnail, isLoading, error } = useThumbnail(
    documentId,
    file,
    doctype,
    isSigned,
  );
  if (isLoading) return <Spinner />;
  if (error || !(isImage(file) || isPdf(file))) {
    return (
      <div className={style.fallback}>
        <span>{file.filename || file.src}</span>
        {onDelete && (
          <div className="marginL10" onClick={() => onDelete(file)}>
            <Croix />
          </div>
        )}
      </div>
    );
  }
  const deleteHandler =
    onDelete &&
    ((e) => {
      e.stopPropagation();
      onDelete(file);
    });

  return (
    <Img
      b64data={file.thumbnail}
      blob={thumbnail}
      alt="aperçu document"
      isThumbnail
      onDelete={deleteHandler}
    />
  );
}
