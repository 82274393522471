import { Form, Formik } from "formik";
import Button from "../UI/Button";
import { toInitialValuesFromSchema } from "../../models/utils";
import TextInput from "../UI/TextInput";
import {
  FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  financeDecoupeValidationSchema,
} from "../../models/financeDecoupe";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Select from "../UI/Select";
import useProfileNoms from "../../hooks/use-profile-noms";
import TransactionSplitFieldArray from "../Transaction/TransactionSplitFieldArray";

export default function FinanceConfigDecoupeForm({
  config,
  onSubmit,
  onDelete,
}) {
  const { profileNoms } = useProfileNoms();
  const initialValues = toInitialValuesFromSchema(
    financeDecoupeValidationSchema,
    config,
    FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={financeDecoupeValidationSchema}
    >
      <Form>
        <h2 className={"margin0"}>Template de découpe de transaction</h2>

        <TextInput
          type={"text"}
          label={"Nom du template"}
          name={"nom"}
          className={"marginT10"}
          mandatory
        />
        <Select name="id_profile_nom">
          <option value="">Affecter à un profil bailleur</option>
          {profileNoms.map((option) => (
            <option key={option.id} value={option.id}>
              {option.nom_profile}
            </option>
          ))}
        </Select>
        <TransactionSplitFieldArray name={"splits"} />
        <div className={"text-right"}>
          <Button
            type="button"
            buttonStyle={"secondaryDelete"}
            className={"marginR10"}
            onClick={() => onDelete(config.id)}
          >
            Supprimer
          </Button>
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  );
}
