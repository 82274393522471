import { BalanceDomain } from "../../utils/constants/balances";
import Card from "../UI/Card";
import { TRANSACTION_CHILD_INITIAL_VALUE } from "../../models/transactionChild";
import { Croix, PlusBank } from "../UI/Icons";
import { FieldArray, useFormikContext } from "formik";
import style from "./TransactionSplitFieldArray.module.css";
import TextInput from "../UI/TextInput";
import { ReactSelect } from "../UI/Select";
import BalanceTypeSelect from "../Balance/BalanceTypeSelect";
import useProperties from "../../hooks/use-properties";
import useTenants from "../../hooks/use-tenants";
import { TenantStatus } from "../../models/tenant";
import React from "react";
import usePropertyFolders from "../../hooks/use-property-folders";

function tenantToOption(tenant) {
  return {
    label: tenant.profile_nom.nom_profile,
    value: `${BalanceDomain.TENANT}${DOMAINE_ID_SEPARATOR}${tenant.id}`,
  };
}

const DOMAINE_ID_SEPARATOR = ":";

const DOMAIN_TO_PARENT_ID_FIELD_NAME = {
  [BalanceDomain.PROPERTY]: "id_bien",
  [BalanceDomain.TENANT]: "id_tenant",
  [BalanceDomain.FOLDER]: "id_property_folder",
};

function getAffectationValue(balance) {
  return `${balance.domaine}${DOMAINE_ID_SEPARATOR}${
    balance[DOMAIN_TO_PARENT_ID_FIELD_NAME[balance.domaine]]
  }`;
}

export default function TransactionSplitFieldArray({ name }) {
  const { values, setFieldValue } = useFormikContext();
  const { properties } = useProperties();
  const { tenants } = useTenants();
  const { propertyFolders } = usePropertyFolders();

  const propFolderOptions = propertyFolders.map((prop) => ({
    value: `${BalanceDomain.FOLDER}${DOMAINE_ID_SEPARATOR}${prop.id}`,
    label: prop.nom,
  }));
  const propOptions = properties.map((prop) => ({
    value: `${BalanceDomain.PROPERTY}${DOMAINE_ID_SEPARATOR}${prop.id}`,
    label: prop.nom,
  }));
  const activeTenantOptions = tenants
    .filter((tenant) => tenant.status === TenantStatus.Actif.status)
    .map(tenantToOption);
  const archivedTenantOptions = tenants
    .filter((tenant) => tenant.status === TenantStatus.Archive.status)
    .map(tenantToOption);

  const affectationSelectOptions = [
    { label: "Dossiers", options: propFolderOptions },
    { label: "Biens", options: propOptions },
    { label: "Locataires Actifs", options: activeTenantOptions },
    { label: "Locataires Archivés", options: archivedTenantOptions },
  ];

  function affectationChangeHandler(prefix, option, domaineBalance) {
    if (option == null) return;

    const promises = [];
    const [newDomaineBalance, parentId] =
      option.value.split(DOMAINE_ID_SEPARATOR);
    if (newDomaineBalance !== domaineBalance) {
      promises.push(
        setFieldValue(`${prefix}.type`, ""),
        setFieldValue(`${prefix}.domaine`, newDomaineBalance),
      );
    }
    promises.push(
      setFieldValue(
        `${prefix}.${DOMAIN_TO_PARENT_ID_FIELD_NAME[newDomaineBalance]}`,
        parentId,
      ),
    );
    return Promise.all(promises);
  }

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values[name].map((child, index) => (
            <Card className={style.wrapper} padding={"md"} key={index}>
              <div
                className={style.close}
                onClick={() => arrayHelpers.remove(index)}
              >
                <Croix />
              </div>

              <TextInput
                type="text"
                name={`${name}[${index}].comment`}
                placeholder="Commentaire"
                label="Commentaire"
                marginForm={""}
                mandatory
              />
              <TextInput
                type="number"
                name={`${name}[${index}].montant`}
                placeholder="0"
                label="Montant"
                mandatory
                min={null}
                unit={"€"}
                step={"0.01"}
                size="sm"
                marginForm={""}
              />
              <ReactSelect
                label="Affectation"
                placeholder="Chambre 10 ou Jean Dupond"
                name={`${name}[${index}].parent`}
                value={getAffectationValue(child)}
                options={affectationSelectOptions}
                onChange={(option) =>
                  affectationChangeHandler(
                    `${name}[${index}]`,
                    option,
                    child.domaine,
                  )
                }
                mandatory
              />
              <BalanceTypeSelect
                name={`${name}[${index}].type`}
                domaineBalance={child.domaine}
                mandatory
              />
            </Card>
          ))}
          <Card
            className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
            onClick={() => arrayHelpers.push(TRANSACTION_CHILD_INITIAL_VALUE)}
          >
            <PlusBank />
            <p className={"secondaryText marginL10"}>Ajouter une entrée</p>
          </Card>
        </>
      )}
    />
  );
}
