import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";
import { useId } from "react";
import { classnames } from "../../utils";
import styles from "./TextInput.module.css";
import style from "./Checkbox.module.css";

export default function Radio({ label, pillStyle, className, ...props }) {
  const [field, meta] = useField({ ...props, type: "radio" });
  const id = useId();

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <span className={classnames(pillStyle && style.pillStyle, className)}>
      <input {...field} {...props} type="radio" id={id} />
      {label && (
        <label
          className={classnames(meta.error && meta.touched && styles.error)}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </span>
  );
}
