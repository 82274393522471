import { useNavigate } from "react-router-dom";
import { PlusBank } from "../UI/Icons";
import style from "./BankList.module.css";
import BankCard from "./BankCard";
import Card from "../UI/Card";

function BankList({ banks }) {
  const nav = useNavigate();

  return (
    <div className={style.grid}>
      {banks.map((bank, i) => (
        <BankCard bank={bank} key={i} />
      ))}
      <Card
        className={`flex justify-content-center align-items-center`}
        onClick={() => nav("add")}
      >
        <div>
          <div className={"flex justify-content-center"}>
            <PlusBank />
          </div>
          <div className={"secondaryText marginT5"}>
            Ajouter un compte bancaire
          </div>
        </div>
      </Card>
    </div>
  );
}

export default BankList;
