import useResources from "./use-resources";

export default function useOodriveWebhooks() {
  const {
    resources: webhooks,
    error,
    isLoading,
    create,
    del,
    mutate,
  } = useResources("oodrive/webhooks");

  async function createWebhook(webhook) {
    await create(webhook);
    await mutate();
  }

  async function deleteWebhook(webhookId) {
    await del(webhookId);
    await mutate();
  }

  return { webhooks, error, isLoading, createWebhook, deleteWebhook };
}

export function useOodriveWebhooksLogs(webhookId) {
  const url = webhookId
    ? `oodrive/webhooks/${webhookId}/logs`
    : "oodrive/webhooks/logs";
  const { resources: logs, error, isLoading, mutate } = useResources(url);

  return { logs, error, isLoading, refreshLogs: () => mutate() };
}
