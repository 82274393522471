import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

export default function ChoseGarant({
  profile,
  closeModalChoseGarant,
  propertyId,
}) {
  const nav = useNavigate();
  const bienSearchParam = propertyId ? `?bien=${propertyId}` : "";
  return (
    <div className={"text-center w-30em"}>
      <img
        className={"smallImage"}
        src={`/photo_ajout_profil_candidat.jpg`}
        alt="profil ajouté"
      />
      <p className={"blueText"}>Félicitation !</p>
      <h3>Votre profil est complété</h3>
      <p>
        <strong>
          Vous pouvez ajouter ou modifier vos informations. <br />
          Vous pouvez également ajouter un garant à votre dossier de candidature
          (parents, ami, proches, employeur, garantie Visale, assurance loyers
          impayés, etc.)
        </strong>
      </p>
      <Button
        className={"marginT20"}
        type={"button"}
        onClick={() => {
          nav(`${profile.id}/add_garant${bienSearchParam}`);
          closeModalChoseGarant();
        }}
      >
        Ajouter un garant ou une caution
      </Button>
    </div>
  );
}
