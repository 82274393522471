import Button from "../../UI/Button";
import Card from "../../UI/Card";
import WebhookForm from "./WebhookForm";
import useOodriveWebhooks from "../../../hooks/use-oodrive-webhooks";
import { useModal } from "../../../hooks/use-modal";
import useLoader from "../../../hooks/use-loader";
import ButtonLink from "../../UI/ButtonLink";

export default function WebhookList() {
  const { webhooks, createWebhook, deleteWebhook } = useOodriveWebhooks();
  const [WebhookModal, closeWebhookModal, openWebhookModal] = useModal();
  const [deleting, deleteWebhookLoader] = useLoader(deleteWebhook);

  async function submitHandler(values) {
    try {
      await createWebhook(values);
      closeWebhookModal();
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      <WebhookForm onSubmit={submitHandler} ModalElement={WebhookModal} />
      <div className="marginT10">
        <Button onClick={openWebhookModal}>Ajouter un webhook</Button>
        {webhooks.map((webhook, i) => (
          <Card key={i} className={"marginT10"}>
            <pre>{JSON.stringify(webhook, null, 2)}</pre>
            <div className="flex gap10">
              <ButtonLink
                buttonStyle={"secondary"}
                to={`/admin?tab-admin=oodrive&tab-oodrive-webhooks=logs&webhook-id=${webhook.webHookId}`}
              >
                Afficher logs
              </ButtonLink>
              <Button
                buttonStyle={"secondaryDelete"}
                isLoading={deleting}
                onClick={async () => {
                  try {
                    await deleteWebhookLoader(webhook.webHookId);
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                Supprimer
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
}
