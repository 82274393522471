import styles from "./Hamburger.module.css";
import { classnames } from "../../utils";

function Hamburger({ open, toggle }) {
  return (
    <button className={styles.button} aria-expanded={open} onClick={toggle}>
      <svg viewBox="0 0 100 100">
        <rect
          className={classnames(styles.line, styles.top)}
          width="80"
          height="10"
          x="10"
          y="25"
          rx="5"
        ></rect>
        <rect
          className={classnames(styles.line, styles.middle)}
          width="80"
          height="10"
          x="10"
          y="45"
          rx="5"
        ></rect>
        <rect
          className={classnames(styles.line, styles.bottom)}
          width="80"
          height="10"
          x="10"
          y="65"
          rx="5"
        ></rect>
      </svg>
    </button>
  );
}

export default Hamburger;
