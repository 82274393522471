import PropertyRow from "./PropertyRow";
import Card from "../UI/Card";
import style from "./PropertyList.module.css";
import { classnames } from "../../utils";
import useMediaQuery from "../../hooks/use-media-query";

export default function PropertyList({
  propertiesAndFolders,
  onDuplicateProperty,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 1022px)");
  let listBiens = [];
  if (propertiesAndFolders?.property_folders.length > 0) {
    listBiens.push(
      ...propertiesAndFolders.property_folders.map((propertyFolder) => (
        <PropertyRow
          propertyFolder={propertyFolder}
          key={propertyFolder.id}
          onDuplicateProperty={onDuplicateProperty}
        />
      )),
    );
  }
  if (propertiesAndFolders?.properties.length > 0) {
    listBiens.push(
      ...propertiesAndFolders.properties.map((property) => (
        <PropertyRow
          property={property}
          key={property.id}
          onDuplicateProperty={onDuplicateProperty}
        />
      )),
    );
  }
  return (
    <>
      {isSmallScreen ? (
        <div>{listBiens}</div>
      ) : (
        <Card className={`${style.row}`}>
          <div className={classnames(style.head)}>Nom du bien</div>
          <div className={classnames(style.head)}>Status</div>
          <div className={classnames(style.head)}>Loyer CC</div>
          <div className={classnames(style.head)}>Dates du bail</div>
          <div className={style.head}></div>
          {listBiens.length > 0 ? listBiens : <div>Aucun bien trouvé</div>}
        </Card>
      )}
    </>
  );
}
