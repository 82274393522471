import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import { object, string } from "yup";
import FormikSubmitButton from "../UI/FormikSubmitButton";

export default function ProfileNomShareForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{ mail: "" }}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={object({
        mail: string()
          .matches(
            "^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
            "Email invalide. Les caractères spéciaux sont interdits.",
          )
          .required("L'adresse e-mail est obligatoire "),
      })}
    >
      <Form>
        <h2 className={"text-center"}>Partager votre profil bailleur</h2>
        <p className={"text-justify marginT10"}>
          Entrez ici l'adresse mail de votre associé, gestionnaire, ou toute
          personne ayant besoin d'accéder à la gestion des biens / candidats /
          locataires / banques de ce profil bailleur.
        </p>
        <p className={"text-justify marginT10"}>
          Attention, l'association des <strong>comptes en banque</strong> n'est
          pas automatique. Rendez-vous dans la page "Finance -> Mes banques" et
          associez les comptes souhaités au profil bailleur adéquat. La personne
          à qui vous partagerez votre profil ne verra que les comptes en banques
          et les transactions associés à ce profil bailleur.
        </p>
        <p className={"text-justify marginT10"}>
          Si la personne à qui vous partagez ce profil à déjà un compte Qalimo,
          il n'a rien à faire. Si celle-ci n'a pas encore de compte Qalimo, elle
          recevra un mail indiquant qu'un compte lui a été créé, et qu'elle peut
          s'authentifier pour la 1ère fois en cliquant sur l'option "mot de
          passe oublié".
        </p>
        <p className={"text-justify marginT10 marginB10"}>
          Pour partager ce profil à plusieurs personnes, répétez cette opération
          plusieurs fois.
        </p>
        <TextInput
          type="email"
          name="mail"
          label={"Adresse e-mail"}
          placeholder="contact@qalimo.fr"
        />
        <div className="text-right">
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  );
}
