import { Outlet, useSearchParams } from "react-router-dom";
import { sendToBackend } from "../../api";
import Menu from "../../components/Menu/Menu";
import { useProfilesIndiv } from "../../hooks/use-profiles-indiv";
import useAuth from "../../hooks/use-auth";
import { usePublicProperty } from "../../hooks/use-properties";

export default function CandidatRootPage() {
  const {
    profiles,
    structProfiles,
    addOrUpdateProfile,
    attachGarantor,
    deleteProfile,
    detachGarantor,
    isLoading,
    error: errorProfiles,
  } = useProfilesIndiv(null, true);
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("bien");
  const { property } = usePublicProperty(propertyId);
  const { auth } = useAuth();

  async function deleteProfileHandler(profileId) {
    if (window.confirm("Voulez-vous vraiment supprimer ce profile ?")) {
      try {
        await sendToBackend(`profiles-indiv/${profileId}`, auth.accessToken, {
          method: "DELETE",
        });
        deleteProfile(profileId);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function profileSubmittedHandler(profile) {
    addOrUpdateProfile(profile);
  }

  function addGarantToStateProfile(profileId, garantId) {
    attachGarantor(profileId, garantId);
  }

  async function removeGarant(garantId) {
    try {
      await sendToBackend(`profiles-indiv/${garantId}`, auth.accessToken, {
        method: "PUT",
        data: { garantor_of: null },
      });
      detachGarantor(garantId);
    } catch (error) {
      console.error(error);
    }
  }

  const outletContext = {
    property,
    profiles,
    isLoadingProfiles: isLoading,
    errorProfiles,
    structProfiles,
    profileSubmittedHandler: profileSubmittedHandler,
    deleteProfileHandler: deleteProfileHandler,
    addGarantToStateProfile: addGarantToStateProfile,
    removeGarant: removeGarant,
  };

  return (
    <>
      <Menu isTenant />
      <Outlet context={outletContext} />
    </>
  );
}
