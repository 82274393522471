import styles from "./TemplateAuthentication.module.css";
import { classnames } from "../../utils";
import { Link } from "react-router-dom";

function TemplateAuthentication({ children }) {
  return (
    <div className={styles.page}>
      <div className={classnames(styles.side, styles.left)}>
        <div>
          <Link
            className={styles.logo}
            to={"https://www.qalimo.fr"}
            target={"_blank"}
          >
            <img alt={"Qalimo"} src={"/imageAccueil.jpg"} />
          </Link>
          <p className={styles.title}>Simplifiez votre gestion locative</p>
        </div>
      </div>
      <div className={classnames(styles.side, styles.right)}>
        <div className={styles.form}>{children}</div>
      </div>
    </div>
  );
}

export default TemplateAuthentication;
