import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import TenantList from "../../components/Tenant/TenantList";
import TitleSearchAdd from "../../components/UI/TitleSearchAdd";
import Page from "../../components/UI/Page";
import useTenants from "../../hooks/use-tenants";
import { useState } from "react";

function ListPage() {
  const { properties, balances } = useOutletContext();
  const nav = useNavigate();
  const { tenants } = useTenants();
  const [filter, setFilter] = useState("");

  return (
    <>
      <Page
        header={
          <TitleSearchAdd
            title="Locataires"
            placeholder="Rechercher un locataire"
            textButton="Ajouter un locataire"
            addAction={() => nav("add")}
            setFilter={setFilter}
          />
        }
        body={
          <TenantList
            properties={properties}
            balances={balances}
            tenants={tenants.filter((tenant) =>
              tenant.profile_nom.nom_profile.toLowerCase().includes(filter),
            )}
          />
        }
      />

      <Outlet />
    </>
  );
}

export default ListPage;
