import ListFiles from "../UI/ListFiles";

function TenantShowFiles({ files }) {
  if (!files) return null;
  return (
    <>
      <h2>Liste des documents</h2>
      <h3>Justificatif d'identité</h3>
      <ListFiles
        files={files.filter((filtered) => filtered.tag === "identite")}
      />
      <h3>Justificatif de domicile</h3>
      <ListFiles
        files={files.filter((filtered) => filtered.tag === "domicile")}
      />
      <h3>Justificatif de revenus</h3>
      <ListFiles files={files.filter((filtered) => filtered.tag === "pro")} />
    </>
  );
}

export default TenantShowFiles;
