import PropertyShow from "../../components/Property/PropertyShow";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchFilesProperties } from "../../api/Files";
import useAuth from "../../hooks/use-auth";

function PropertyShowPage() {
  const { profiles, balances } = useOutletContext();
  const { propertyId } = useParams();
  const { auth } = useAuth();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    (async () => {
      setFiles(await fetchFilesProperties(auth.accessToken, propertyId));
    })();
  }, [auth.accessToken, propertyId]);

  return (
    <>
      <PropertyShow
        propertyId={propertyId}
        profiles={profiles}
        balances={balances}
        files={files}
      />
      <Outlet context={{ profiles: profiles }} />
    </>
  );
}

export default PropertyShowPage;
