import CardStep from "../../UI/CardStep";
import React from "react";
import Button from "../../UI/Button";
import { TenantStatus } from "../../../models/tenant";
import { Link } from "react-router-dom";
import useTenants from "../../../hooks/use-tenants";
import moment from "moment";
import FormikSubmitButton from "../../UI/FormikSubmitButton";
import { Form, Formik } from "formik";
import { object } from "yup";
import { dateMax } from "../../../models/utils";
import TextInput from "../../UI/TextInput";
import useProcesses from "../../../hooks/use-processes";
import { ProcessType } from "../../../utils/constants/processes";
import useLoader from "../../../hooks/use-loader";

export default function ProcessDepart({ processId, onFinish }) {
  const { activeProcesses, updateProcessEntry } = useProcesses();
  const { tenants, updateTenant } = useTenants();

  const [isFinishing, finishProcessHandler] = useLoader(async () => {
    try {
      await updateTenantHandler({
        status: TenantStatus.Archive.status,
      });
      await onFinish(process.id);
    } catch (error) {
      console.error(error);
    }
  });

  const process = activeProcesses.find((p) => p.id === processId);
  if (!process) return null;

  const tenant = tenants.find((t) => t.id === process.id_tenant);
  if (!tenant) return null;

  async function toggleHandler(entry) {
    try {
      await updateProcessEntry(process.id, entry);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateTenantHandler(values) {
    try {
      await updateTenant(tenant.id, values);
    } catch (error) {
      console.error(error);
    }
  }

  let step = 1;
  return (
    <div style={{ maxWidth: 600 }}>
      {process.type === ProcessType.DEPART && (
        <>
          <CardStep
            step={<p className={"primaryText"}>Etape {step++}</p>}
            title={"Réception du préavis"}
            check={!!tenant.preavis}
            onToggle={() => updateTenantHandler({ preavis: !tenant.preavis })}
            text={
              <>
                {tenant.preavis ? (
                  <>
                    Votre locataire a donné son préavis de départ pour le{" "}
                    {moment(tenant.fin).format("DD/MM/YYYY")}. Vous pouvez
                    changer sa date de fin de bail si besoin.
                  </>
                ) : (
                  <>
                    Votre locataire n'a pas encore déposé son préavis. Si c'est
                    le cas insérez la date de fin de bail et cliquez sur
                    valider.
                  </>
                )}
                <div>
                  <Formik
                    initialValues={{ fin: tenant.fin ?? "" }}
                    enableReinitialize={true}
                    validationSchema={object({
                      fin: dateMax().required(" "),
                    })}
                    onSubmit={(values) =>
                      updateTenantHandler({ ...values, preavis: true })
                    }
                  >
                    <Form className={"flex wrap gap10 marginT20"}>
                      <TextInput type={"date"} name="fin" marginForm={""} />
                      <FormikSubmitButton>Valider</FormikSubmitButton>
                    </Form>
                  </Formik>
                </div>
              </>
            }
          />
        </>
      )}
      {process.type === ProcessType.DEPART_BAILLEUR && (
        <>
          <CardStep
            step={<p className={"primaryText"}>Etape {step++}</p>}
            title={"Envoyer le congé"}
            check={!!process.entries?.envoie_conge}
            onToggle={() =>
              toggleHandler({
                envoie_conge: !process.entries?.envoie_conge,
              })
            }
            text={
              <>
                Complétez et envoyez le congé pour mettre fin au bail de votre
                locataire. Attention à bien respecter le délai du préavis.{" "}
                <Link
                  to={`/bailleur/tenants/${process.id_tenant}?tab-tenant-show=actions&action=avenant&avenant-type=rappel_fin_bail`}
                >
                  Utilisez notre générateur de courrier.
                </Link>
              </>
            }
          />
          <CardStep
            step={<p className={"primaryText"}>Etape {step++}</p>}
            title={"Accusé reception du courrier"}
            check={!!process.entries?.ar_conge}
            onToggle={() =>
              toggleHandler({
                ar_conge: !process.entries?.ar_conge,
              })
            }
            text={
              <>
                Le délai de préavis ne commence à courir qu’à compter de la{" "}
                <strong>réception</strong> du congé par le locataire. Vérifiez
                qu’il a bien été reçu.
              </>
            }
          />
        </>
      )}

      <CardStep
        step={<p className={"primaryText"}>Etape {step++}</p>}
        title={"Rappel de fin de bail"}
        check={!!process.entries?.rappel_fin_bail}
        onToggle={() =>
          toggleHandler({
            rappel_fin_bail: !process.entries?.rappel_fin_bail,
          })
        }
        text={
          <>
            Envoyez un courrier à votre locataire pour lui rappeler la date de
            fin de bail et lui donner les consignes de sorties.{" "}
            <Link
              to={`/bailleur/tenants/${process.id_tenant}?tab-tenant-show=actions&action=avenant&avenant-type=rappel_fin_bail`}
            >
              Utilisez notre générateur de courrier.
            </Link>
          </>
        }
      />

      <CardStep
        step={<p className={"primaryText"}>Etape {step++}</p>}
        title={"Convocation état des lieux"}
        check={!!process.entries?.convocation_edl}
        onToggle={() =>
          toggleHandler({
            convocation_edl: !process.entries.convocation_edl ?? true,
          })
        }
        text={
          <>
            Envoyer un courrier à votre locataire pour l’informer de la date et
            de l’heure de l’état des lieux.{" "}
            <Link
              to={`/bailleur/tenants/${process.id_tenant}?tab-tenant-show=actions&action=avenant&avenant-type=convocation_edl`}
            >
              Utilisez notre générateur de courrier.
            </Link>
          </>
        }
      />

      <CardStep
        step={<p className={"primaryText"}>Etape {step++}</p>}
        title={"Réaliser l'état des lieux"}
        check={!!process.entries?.edl}
        onToggle={() => toggleHandler({ edl: !process.entries?.edl })}
        text={
          <>
            C’est le jour J. Effectuez l’état des lieux de sortie de votre
            locataire. Soyez précis et pensez à prendre des photos.{" "}
            <Link to={`/bailleur/tenants/${tenant.id}?action=edl`}>
              Réaliser l'état des lieux.
            </Link>
          </>
        }
      />
      <CardStep
        step={<p className={"primaryText"}>Etape {step++}</p>}
        title={"Dépôt de garantie"}
        check={!!process.entries?.dg}
        onToggle={() => toggleHandler({ dg: !process.entries?.dg })}
        text={
          <>
            C’est l’heure des comptes. Si l’état des lieux est conforme vous
            pouvez restituer le dépôt de garantie au locataire. Dans le cas
            contraire, une retenue s’impose.{" "}
            <Link
              to={`/bailleur/tenants/${process.id_tenant}?tab-tenant-show=actions&action=avenant&avenant-type=restitution_dg`}
            >
              Utilisez notre générateur de courrier et générez les lignes
              comptables.
            </Link>
          </>
        }
      />
      <CardStep
        step={<p className={"primaryText"}>Etape {step++}</p>}
        title={"Vérification de l'affectation du virement du dépôt de garantie"}
        check={!!process.entries?.affectation_dg}
        onToggle={() =>
          toggleHandler({ affectation_dg: !process.entries?.affectation_dg })
        }
        text={
          <>
            Si vos comptes bancaires sont synchronisés avec Qalimo, et que vous
            avez fait un virement pour rembourser le dépôt de garantie, vérifiez
            que celui ci a été correctement affecté à votre locataire, catégorie
            "remboursement dépôt de garantie".
          </>
        }
      />
      <div className={"text-center marginT20"}>
        <Button onClick={finishProcessHandler} isLoading={isFinishing}>
          Terminer le processus et archiver le locataire
        </Button>
      </div>
    </div>
  );
}
