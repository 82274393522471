import React, { useId } from "react";
import Floater from "react-floater";
import styles from "./Tooltip.module.css";

function Tooltip({ content, direction, children }) {
  const id = useId();
  return (
    <span className={styles.tooltip}>
      <span id={id}></span>
      <Floater
        content={content}
        placement={direction}
        event="hover"
        portalElement={`#${CSS.escape(id)}`}
      >
        <span className={styles.target}>{children}</span>
      </Floater>
    </span>
  );
}

export default Tooltip;
