import { useModal } from "../../hooks/use-modal";
import MandataireForm from "./MandataireForm";
import useMandataire from "../../hooks/use-mandataire";
import { sanitizeValues } from "../../models/utils";
import ProfileCard from "../Profile/ProfileCard";
import { PlusBank } from "../UI/Icons";
import ButtonCard from "../UI/ButtonCard";
import Spinner from "../UI/Spinner";

export default function Mandataire() {
  const [EditMandataire, closeEditMandataire, openEditMandataire] = useModal();
  const { mandataire, createOrUpdateMandataire, isLoading } = useMandataire();

  async function submitHandler(values) {
    await createOrUpdateMandataire(sanitizeValues(values));
    closeEditMandataire();
  }
  return (
    <>
      <MandataireForm
        mandataire={mandataire}
        onSubmit={submitHandler}
        ModalElement={EditMandataire}
      />

      {isLoading ? (
        <div className="marginT30">
          <Spinner width={"3em"} />
        </div>
      ) : mandataire ? (
        <ProfileCard
          modifierAction={openEditMandataire}
          mandataire={mandataire}
        />
      ) : (
        <ButtonCard
          className="flex align-items-center marginB10 marginT10 padding10"
          onClick={openEditMandataire}
        >
          <PlusBank />
          <span className="marginL5">Ajouter un profil mandataire</span>
        </ButtonCard>
      )}
    </>
  );
}
