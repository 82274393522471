import styles from "./Button.module.css";
import { classnames } from "../../utils";
import Spinner from "./Spinner";

function Button({
  buttonStyle,
  className,
  children,
  disabled,
  isLoading,
  ...props
}) {
  return (
    <button
      className={classnames(styles.button, styles[buttonStyle], className)}
      disabled={disabled ?? isLoading}
      {...props}
    >
      <div className={styles.inner}>
        {isLoading && <Spinner />}
        {children}
      </div>
    </button>
  );
}

export default Button;
