import { Check, Clipboard } from "./Icons";
import { useState } from "react";
import ButtonAction from "./ButtonAction";

export default function CopyToClipboard({ toCopy }) {
  const [copied, setCopied] = useState(false);
  async function copy(copy) {
    try {
      await navigator.clipboard.writeText(copy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (e) {
      console.error("Cannot copy to clipboard");
    }
  }
  return (
    <ButtonAction
      onClick={() => copy(toCopy)}
      logo={
        copied ? (
          <Check width={15} height={15} color={"#419d02"} />
        ) : (
          <Clipboard />
        )
      }
      disabled={copied}
      tooltip={"Copier"}
      buttonStyle={"flat"}
    />
  );
}
