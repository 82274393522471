import { useOutletContext } from "react-router-dom";
import LocataireList from "../../components/Locataire/LocataireList";

function LocataireListPage() {
  const { balances, properties } = useOutletContext();

  return (
    <>
      <LocataireList balances={balances} properties={properties} />
    </>
  );
}

export default LocataireListPage;
