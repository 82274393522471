import { TextOneOrMany } from "../../utils";
import InitialesLogo from "../UI/InitialesLogo";
import Card from "../UI/Card";

export default function SignatairesCard({ profiles, bailleur, caution }) {
  return (
    <Card className={"padding10"}>
      <p className={"cardTitle text-center"}>
        <TextOneOrMany
          one={`Signataire ${
            bailleur ? "bailleur" : caution ? "caution" : "locataire"
          }`}
          many={`Signataires ${
            bailleur ? "bailleurs" : caution ? "cautions" : "locataires"
          }`}
          number={profiles?.length}
        />
      </p>
      {profiles?.map((profile, i) => (
        <div className={"flex align-items-center marginT10"} key={i}>
          <InitialesLogo
            shape={"rond"}
            nom={profile.nom}
            prenom={profile.prenom}
          />
          <div className={"marginL10"}>
            <p className={"primaryText"}>
              {profile.prenom} {profile.nom}
            </p>
            <p>{profile.mail}</p>
          </div>
        </div>
      ))}
    </Card>
  );
}
