import { Form, Formik } from "formik";
import {
  balanceTenantIV,
  balanceTenantSchema,
} from "../../models/balanceTenant";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { useState } from "react";
import Card from "../UI/Card";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { handleAPIError } from "../../utils";
import BalanceFields from "./BalanceFields";
import Button from "../UI/Button";
import { getBalanceParentId } from "../../utils/balances";

export default function BalanceForm({
  balance,
  ModalElement,
  onClose,
  onSubmit,
  onDelete,
  domaineBalance,
}) {
  const isFromTransac = balance && balance.id_transaction;
  const [error, setError] = useState("");

  const initialValues = toInitialValuesFromSchema(
    balanceTenantSchema,
    balance,
    balanceTenantIV,
  );
  initialValues.files = initialValues.file ? [initialValues.file] : [];

  async function submitHandler(values, { resetForm }) {
    setError("");
    const sanitizedValues = { ...sanitizeValues(values) };
    sanitizedValues.file = sanitizedValues.files[0] || null;
    delete sanitizedValues.files;
    try {
      await onSubmit(sanitizedValues);
      resetForm();
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={balanceTenantSchema}
      onSubmit={submitHandler}
    >
      {({ resetForm }) => (
        <ModalElement
          size={"medium"}
          onClose={() => {
            onClose && onClose();
            resetForm();
            setError("");
          }}
        >
          <p className={"cardTitle text-center marginB20"}>
            <img
              src={`/photo_balance.png`}
              alt="Balance locataire"
              className={"center"}
            />
            {balance ? "Modifier une opération" : "Ajouter une opération"}
          </p>
          {isFromTransac && (
            <p className={"secondaryText marginB10 text-center"}>
              Vous ne pouvez pas éditer le montant ni la description, car cette
              entrée a été exportée depuis vos relevés de comptes.
            </p>
          )}
          <Form>
            <BalanceFields
              isFromTransac={isFromTransac}
              domaineBalance={domaineBalance}
              balanceId={balance ? balance.id : null}
              withJustificatif
            />

            <div className={"text-right"}>
              <Button
                type={"button"}
                buttonStyle={"secondaryDelete"}
                className={"marginR10"}
                onClick={async () => {
                  if (
                    window.confirm(
                      "Voulez-vous vraiment supprimer cette entrée ?",
                    )
                  ) {
                    try {
                      await onDelete(
                        balance.id,
                        balance.domaine,
                        getBalanceParentId(balance),
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  }
                }}
              >
                Supprimer
              </Button>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card
                type={"error"}
                className={"padding10 text-center marginT10"}
              >
                {error}
              </Card>
            )}
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
