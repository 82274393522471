import axios from "axios";

export const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

export default axiosPrivate;

export async function sendToBackend(path, token, opts = {}) {
  return axiosPrivate.request({
    method: opts.method || "GET",
    url: path,
    params: opts.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: opts.data,
  });
}
