import TextInput from "../../UI/TextInput";

export default function ConvocationEdlForm() {
  return (
    <TextInput
      name={"date_edl"}
      type={"date"}
      label={"Date de l'état des lieux"}
    />
  );
}
