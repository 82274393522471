import HeaderProperty from "./Show/HeaderProperty";
import { sanitizeValues } from "../../models/utils";
import useProperties from "../../hooks/use-properties";
import Page from "../UI/Page";
import VueEnsemble from "./Show/VueEnsemble";
import Tabs from "../UI/Tabs";
import useTenants from "../../hooks/use-tenants";
import { TenantStatus } from "../../models/tenant";
import EdlShow from "../Edl/EdlShow";
import ShowCandidatList from "../Tenant/ShowCandidatList";
import DocumentShowList from "../File/DocumentShowList";
import { PropertyStatus } from "../../models/property";
import { useNavigate } from "react-router-dom";
import { BalanceDomain } from "../../utils/constants/balances";
import ComptaShow from "./ComptaShow";
import BlurOverlay from "../UI/BlurOverlay";
import DisabledBienNote from "./DisabledBienNote";
import styles from "./PropertyShow.module.css";
import { isPropertyDisabled } from "../../utils/properties";

export default function PropertyShow({
  propertyId,
  profiles,
  balances,
  files,
}) {
  const { properties, updateProperty, deleteProperty } = useProperties();
  const property = properties.find((prop) => prop.id === parseInt(propertyId));

  const { tenants } = useTenants();
  const nav = useNavigate();

  async function submitHandler(values) {
    await updateProperty(property.id, sanitizeValues(values));
  }

  const candidats = tenants.filter(
    (tenant) =>
      tenant.id_bien === parseInt(propertyId) &&
      tenant.status === TenantStatus.Candidat.status,
  );

  const disabledProperty = isPropertyDisabled(property);

  return (
    <Page
      header={
        property && (
          <HeaderProperty
            profiles={profiles}
            property={property}
            onDelete={async () => {
              try {
                await deleteProperty(property.id);
                nav("/bailleur/properties");
              } catch (error) {
                console.error(error);
              }
            }}
            onArchive={async () => {
              try {
                await updateProperty(property.id, {
                  status: PropertyStatus.Archive.status,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            onReactivate={async () => {
              try {
                await updateProperty(property.id, {
                  status: PropertyStatus.Actif.status,
                });
              } catch (error) {
                console.error(error);
              }
            }}
            onUpdate={submitHandler}
          />
        )
      }
      body={
        <Tabs id="prop-show">
          <div className="container bg-blue paddingT10 border-blue">
            <div className="content">
              <Tabs.TabList>
                <Tabs.Tab panelId="vue-ensemble">Vue d'ensemble</Tabs.Tab>
                {!disabledProperty && (
                  <>
                    <Tabs.Tab panelId="documents">Documents</Tabs.Tab>
                    <Tabs.Tab panelId="candidats">
                      Candidats ({candidats.length})
                    </Tabs.Tab>
                    <Tabs.Tab panelId="edl">Etat des lieux</Tabs.Tab>
                    <Tabs.Tab panelId="compta">Comptabilité</Tabs.Tab>
                  </>
                )}
              </Tabs.TabList>
            </div>
          </div>
          <div className="blueSpace"></div>
          <div className="content" style={{ position: "relative" }}>
            <div inert={disabledProperty ? "true" : undefined}>
              <Tabs.TabPanel id="vue-ensemble">
                <VueEnsemble
                  profiles={profiles}
                  balances={balances}
                  files={files}
                  property={property}
                  submitHandler={submitHandler}
                />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="documents">
                <DocumentShowList property={property} allowEdit allowDelete />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="candidats">
                <ShowCandidatList
                  tenants={candidats}
                  profiles={profiles}
                  properties={properties}
                />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="edl">
                <EdlShow propertyId={property?.id} property={property} />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="compta">
                <ComptaShow
                  parentId={property?.id}
                  domaineBalance={BalanceDomain.PROPERTY}
                />
              </Tabs.TabPanel>
            </div>
            {disabledProperty && (
              <BlurOverlay className={styles.overlay}>
                <DisabledBienNote className={styles.disabledNote} />
              </BlurOverlay>
            )}
          </div>
        </Tabs>
      }
    />
  );
}
