import useResources from "./use-resources";

const ROOT_PATH = "property-folders";

export default function usePropertyFolders() {
  const {
    resources: propertyFolders,
    error,
    isLoading,
    update,
    del,
    create,
  } = useResources(ROOT_PATH);

  return {
    propertyFolders,
    error,
    isLoading,
    createPropertyFolder: create,
    updatePropertyFolder: update,
    deletePropertyFolder: del,
  };
}
