import TextInput from "../UI/TextInput";
import FormUpload from "../File/FormUpload";
import AddressFields from "../UI/AddressFields";

function ProfileFormDom({ isBailleur, prefix = "", isMandataire }) {
  return (
    <>
      {!isMandataire && <h3>Adresse actuelle</h3>}

      <div>
        <AddressFields namePrefix={prefix} />
        <TextInput
          type="text"
          name={`${prefix}pays`}
          placeholder="Pays"
          label="Pays"
          mandatory
        />
        {!isBailleur && !isMandataire && (
          <>
            Justificatif de domicile
            <FormUpload tag="domicile" />
          </>
        )}
      </div>
    </>
  );
}

export default ProfileFormDom;
