import Menu from "../components/Menu/Menu.js";
import Dashboard from "../components/Dashboard/Dashboard.js";

function DashboardPage() {
  return (
    <>
      <Menu isBailleur />
      <Dashboard />
    </>
  );
}

export default DashboardPage;
