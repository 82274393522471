import useResources from "./use-resources";

function useEdlMateriauxTemplates() {
  const root_path = `edl/materiaux`;
  const {
    resources: edlMateriauxTemplates,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources(root_path);

  return {
    edlMateriauxTemplates,
    error,
    isLoading,
    updateEdlItemMateriaux: update,
    addEdlItemMateriaux: create,
    deleteEdlItemMateriaux: del,
  };
}

export default useEdlMateriauxTemplates;
