import TextInput from "../../../UI/TextInput";

export default function ModifLoyerInputs() {
  return (
    <>
      <TextInput
        type="date"
        name={"date_effet"}
        label={"Date d'effet de l'avenant"}
      />
      <TextInput
        type={"number"}
        name={"loyer"}
        label={"Nouveau montant du loyer"}
        placeholder={"48,20"}
        unit={"€"}
        step={"0.01"}
      />
    </>
  );
}
