import { Form, Formik } from "formik";
import React from "react";
import { useEdlSharedItems } from "../../hooks/use-edl-items";
import Select from "../UI/Select";
import { number, object } from "yup";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";

function EdlAddSharedItem({ currentItems, onSubmit }) {
  const { edlSharedItems } = useEdlSharedItems();

  const availableSharedItems = edlSharedItems.filter(
    (item) =>
      currentItems.find((currentItem) => item.id === currentItem.id) ===
      undefined,
  );

  return (
    <>
      <h2 className={"marginT0 marginB10 text-center"}>
        Gestion des pièces partagées
      </h2>
      <p className={"secondaryText text-justify"}>
        Cette option permet de gérer les pièces partagées entre plusieurs
        locataires, très utile dans le cas d'une colocation. Par exemple, pour
        une salle de bain partagée, créez une pièce, et cochez la case "ceci est
        une pièce partagée", en haut du formulaire. Une fois cochée, vous
        pourrez ajouter cette pièce sur chacun de vos "biens" en la
        selectionnant dans le menu ci-dessous.
      </p>
      <Formik
        initialValues={{ id: 0 }}
        enableReinitialize={true}
        validationSchema={object({
          id: number().required(" "),
        })}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          return (
            <Form>
              {availableSharedItems.length > 0 ? (
                <>
                  <Select name="id">
                    <option value="">Sélectionnez la pièce partagée</option>
                    {availableSharedItems.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.nom}
                      </option>
                    ))}
                  </Select>

                  <div className={"text-right"}>
                    <FormikSubmitButton className={"marginL10"}>
                      Ajouter
                    </FormikSubmitButton>
                  </div>
                </>
              ) : (
                <Card type={"error"} className={"marginT10 padding10"}>
                  Pas de pièce partagée disponible.
                  <br />
                  Pour en configurer une, ajouter une pièce classique, et
                  cliquez sur "partager"
                </Card>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default EdlAddSharedItem;
