import useResources from "./use-resources";

const ROOT_PATH = "finance/config";

function useFinanceConfigs(tenantId) {
  const {
    resources: financeConfigs,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources(ROOT_PATH, { params: { id_tenant: tenantId } });

  return {
    financeConfigs,
    error,
    isLoading,
    addFinanceConfig: create,
    updateFinanceConfig: update,
    deleteFinanceConfig: del,
  };
}

export default useFinanceConfigs;
