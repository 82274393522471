import FileDownloadLink from "./FileDownloadLink";
import Img from "./Img";
import Photo from "./Photo";
import { formatFileSize } from "../../api/Functions";
import { isImage, isPdf } from "../../utils";
import { Croix } from "./Icons";

/** @deprecated */
function ListFiles({
  files,
  fileType = "file",
  displayTag,
  displayName,
  onDelete,
}) {
  return (
    <div className={"flex wrap gap10"}>
      {files?.map((file, i) => {
        let thumbnail;
        if (isImage(file) || isPdf(file)) {
          const deleteHandler =
            onDelete &&
            ((e) => {
              e.stopPropagation();
              onDelete(file);
            });

          if (file.thumbnail) {
            thumbnail = (
              <Img
                b64data={file.thumbnail}
                alt="aperçu document"
                isThumbnail
                onDelete={deleteHandler}
              />
            );
          } else if (file.id) {
            thumbnail = (
              <Photo
                photoId={file.id}
                isThumbnail
                alt="aperçu document"
                type={fileType}
                onDelete={deleteHandler}
              />
            );
          }
        }

        return (
          <div key={i} className={"flex align-items-center"}>
            {displayTag && `${file.tag} - `}
            {displayName &&
              `${file.filename || file.src} - ${formatFileSize(file.size)} `}
            {thumbnail ? (
              <FileDownloadLink
                file={file}
                icon={thumbnail}
                fileType={fileType}
              />
            ) : (
              <>
                {file.filename || file.src}
                <FileDownloadLink file={file} fileType={fileType} />
                <div className="marginL10" onClick={() => onDelete(file)}>
                  <Croix />
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ListFiles;
