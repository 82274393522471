import Checkbox from "./Checkbox";
import AddNewCheckbox from "./AddNewCheckbox";
import style from "./ExpandableCheckboxList.module.css";

function ExpandableCheckboxList({
  name,
  items,
  addItem,
  classNameG,
  dependOn,
}) {
  return (
    <div className={`${style.container} ${classNameG}`}>
      {items.map((checkboxItem) => (
        <Checkbox
          key={checkboxItem}
          name={name}
          value={checkboxItem}
          pillStyle
          dependOn={dependOn}
        >
          {checkboxItem}
        </Checkbox>
      ))}
      <AddNewCheckbox
        addItem={(item) => {
          addItem(name, item);
        }}
      />
    </div>
  );
}

export default ExpandableCheckboxList;
