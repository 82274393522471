import React from "react";
import TenantAddProfile from "../../TenantAddProfile";
import TextInput from "../../../UI/TextInput";

export default function ChangementLocataire({
  profiles,
  onAddProfile,
  onDeleteProfile,
  openAddProfileModal,
}) {
  return (
    <>
      <TextInput
        type="date"
        name={"date_effet"}
        label={"Date d'effet de l'avenant"}
      />
      <TenantAddProfile
        profileToPush={profiles.map((profile) => ({ profile }))}
        onAdd={(profile) => onAddProfile(profile.profile)}
        onDelete={onDeleteProfile}
        openAddProfileModal={openAddProfileModal}
        label={
          "Ajoutez les locataires que vous souhaitez et / ou supprimez ceux qui partent"
        }
      />
    </>
  );
}
