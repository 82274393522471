import { Outlet, useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import Page from "../../components/UI/Page";
import TitleSearchAdd from "../../components/UI/TitleSearchAdd";
import ProfileList from "../../components/Profile/ProfileList";
import Tabs from "../../components/UI/Tabs";

function ProfilesBailleurRootPage() {
  const nav = useNavigate();

  return (
    <>
      <Menu isBailleur />
      <Page
        header={
          <TitleSearchAdd
            title={"Mes profils"}
            disableSearch
            addAction={() => nav("add")}
            textButton={"Ajouter un profil"}
          />
        }
        body={
          <Tabs id="profil">
            <div className="container bg-blue paddingT10 border-blue">
              <div className="content">
                <Tabs.TabList>
                  <Tabs.Tab panelId="vue-ensemble">Vue d'ensemble</Tabs.Tab>
                </Tabs.TabList>
              </div>
            </div>
            <div className="blueSpace"></div>
            <div className="content">
              <Tabs.TabPanel id="vue-ensemble">
                <ProfileList />
              </Tabs.TabPanel>
            </div>
          </Tabs>
        }
      />
      <Outlet />
    </>
  );
}

export default ProfilesBailleurRootPage;
