import { object, string } from "yup";
import { stringMax } from "./utils";

export const contactSchema = object({
  sujet: stringMax(250).required(" "),
  message: string().required(" "),
});

export const contactInitialValues = {
  sujet: "",
  message: "",
};
