import styles from "./ButtonCard.module.css";
import { classnames } from "../../utils";

export default function ButtonCard({
  buttonStyle,
  className,
  children,
  type = "button",
  display,
  padding = "xs",
  border = "solid",
  ...props
}) {
  return (
    <button
      className={classnames(
        styles.card,
        styles[buttonStyle],
        className,
        styles[display],
        styles[`padding-${padding}`],
        styles[`border-${border}`],
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}
