import { classnames } from "../../utils";
import style from "./Tenant.module.css";
import InitialesLogo from "../UI/InitialesLogo";
import DisplayName from "../UI/DisplayName";
import { Link } from "react-router-dom";
import { DotsAction, Notes, Property } from "../UI/Icons";
import moment from "moment/moment";
import { currencyFormatter } from "../../api/Functions";
import ButtonAction from "../UI/ButtonAction";
import { useModal } from "../../hooks/use-modal";
import AddNoteTenant from "./AddNoteTenant";
import IndiceConfiance from "../Candidat/IndiceConfiance";

export default function CandidatRow({
  properties,
  profiles,
  prof,
  tenant,
  isSmallScreen,
}) {
  const [AddNoteModal, closeAddNoteModal, openAddNoteModal] = useModal();
  return (
    <>
      <AddNoteModal size={"medium"}>
        <AddNoteTenant tenant={tenant} onClose={closeAddNoteModal} />
      </AddNoteModal>
      <div className={classnames(style.column, style.item)}>
        <InitialesLogo nom={prof.nom} prenom={prof.prenom} shape={"rond"} />
        <div>
          <div className={style.nom}>
            <Link to={`/bailleur/tenants/${tenant.id}`}>
              <DisplayName profile={prof} />
            </Link>
          </div>
          <div className={style.mail}>{prof.mail}</div>
        </div>
      </div>
      <div className={classnames(style.column, style.item)}>
        {properties &&
          properties
            .filter((prop) => prop.id === tenant.id_bien)
            .map((property) => (
              <Link
                key={property.id}
                to={"/bailleur/properties/" + property.id}
                className={"smallText"}
              >
                {<Property />} {property.nom}
              </Link>
            ))}

        {profiles &&
          profiles
            .filter((prof) => prof.id === tenant.id_profile)
            .map((profile) => (
              <Link
                key={profile.id}
                to={"/bailleur/profiles/" + profile.id}
                className={"smallText"}
              >
                <DisplayName profile={profile} />
              </Link>
            ))}
      </div>
      <div className={classnames(style.standard, style.item, style.noPadding)}>
        {isSmallScreen && <strong>Emménagement souhaité : </strong>}
        {moment(tenant.debut).format(
          isSmallScreen ? "DD/MM/YYYY" : "DD MMMM YYYY",
        )}
      </div>

      <div className={classnames(style.standard, style.item, style.noPadding)}>
        {isSmallScreen && <strong>Status professionnel : </strong>}
        {prof.status_pro ?? "Non renseigné"}
      </div>
      <div className={classnames(style.standard, style.item, style.noPadding)}>
        {isSmallScreen && <strong>Revenus mensuels : </strong>}
        {currencyFormatter(prof.revenus_brut) || "Non renseigné"}
      </div>
      <div className={classnames(style.standard, style.item, style.noPadding)}>
        {isSmallScreen && <strong>Type de caution : </strong>}
        {prof.caution_type ?? "Non renseigné"}
      </div>
      <div className={classnames(style.standard, style.item, style.noPadding)}>
        <IndiceConfiance tenant={tenant} profile={prof} />
      </div>
      <div
        className={classnames(style.standard, style.item, style.dotsPosition)}
      >
        <ButtonAction
          logo={<Notes />}
          tooltip={"Ajouter une note"}
          onClick={openAddNoteModal}
        />
        <Link to={`${tenant.id}`} className={"marginL10"}>
          <DotsAction />
        </Link>
      </div>
    </>
  );
}
