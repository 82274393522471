import { Link } from "react-router-dom";

export default function CGVCheckbox({ checked, onChange }) {
  return (
    <div className={"secondaryText marginT10"}>
      <input id="cgv" type="checkbox" onChange={onChange} checked={checked} />
      <label htmlFor="cgv" className={"marginL5"}>
        J'accepte les{" "}
        <Link to={"https://www.qalimo.fr/cgv/"} target={"_blank"}>
          conditions générales de vente
        </Link>
        . Utilisable sans date de validité. Non remboursable
      </label>
    </div>
  );
}
