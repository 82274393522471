import useResources from "./use-resources";

export function useEdlSharedItems() {
  const {
    resources: edlSharedItems,
    error,
    isLoading,
  } = useResources("biens/edl-items/shared");

  return {
    edlSharedItems,
    error,
    isLoading,
  };
}

export default function useEdlItems(propertyId) {
  const root_path = `biens/${propertyId}/edl-items`;
  const {
    resources: edlItems,
    error,
    isLoading,
    mutate,
    axios,
    create,
    update,
    del,
  } = useResources(propertyId ? root_path : null);

  async function addSharedItem(id) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.post(`${root_path}/shared`, id);
        newResource = response.data;
        return [...current, newResource];
      },
      { revalidate: false },
    );
    return newResource;
  }

  async function duplicateEdlItem(id) {
    return mutate(
      async (current) => {
        const response = await axios.post(`${root_path}/${id}/duplicate`);
        return [...current, response.data];
      },
      { revalidate: false },
    );
  }
  async function importEdlItems(itemIds) {
    let newResource;
    await mutate(
      async () => {
        const response = await axios.put(`${root_path}/import`, itemIds);
        newResource = response.data;
        return newResource;
      },
      { revalidate: false },
    );
    return newResource;
  }

  async function generateEdlItems() {
    if (
      window.confirm(
        "Attention, regénérer les pièces supprimera les éléments de l'état des lieux actuels. Voulez vous continuer ?",
      )
    ) {
      let newResource;
      await mutate(
        async () => {
          const response = await axios.post(
            `/biens/${propertyId}/generate-edl-items`,
          );
          newResource = response.data;
          return response.data;
        },
        { revalidate: false },
      );
      return newResource;
    }
  }

  async function updateEdlItemsOrder(itemsOrder) {
    return mutate(
      async () => {
        const res = await axios.put(`${root_path}/order`, itemsOrder);
        return res.data;
      },
      {
        optimisticData: itemsOrder.map((itemId) =>
          edlItems.find((item) => item.id === itemId),
        ),
        rollbackOnError: () => true,
        revalidate: false,
      },
    );
  }

  return {
    edlItems,
    edlItemsOrder: edlItems?.map((item) => item.id),
    error,
    isLoading,
    duplicateEdlItem,
    updateEdlItem: update,
    updateEdlItemsOrder,
    addEdlItem: create,
    deleteEdlItem: del,
    addSharedItem,
    generateEdlItems,
    importEdlItems,
  };
}
