import BankList from "../../components/Bank/BankList.js";
import TransactionList from "../../components/Transaction/TransactionList.js";
import TitleSearchAdd from "../../components/UI/TitleSearchAdd.js";

import Menu from "../../components/Menu/Menu.js";
import FinanceConfigList from "../../components/Bank/FinanceConfigList.js";
import { Outlet, useNavigate } from "react-router-dom";
import Page from "../../components/UI/Page";
import Tabs from "../../components/UI/Tabs";
import useBanks from "../../hooks/use-banks";

function BankRootPage() {
  const nav = useNavigate();
  const { banks } = useBanks();

  return (
    <>
      <Menu isBailleur />
      <Page
        header={
          <TitleSearchAdd
            title="Mes banques"
            disableAdd
            disableSearch
            addAction={() => nav("add")}
          />
        }
        body={
          <>
            <Tabs id="bank">
              <div className="container bg-blue paddingT10 border-blue">
                <div className="content">
                  <Tabs.TabList>
                    <Tabs.Tab panelId="banques">Mes banques</Tabs.Tab>
                    <Tabs.Tab panelId="transactions">Transactions</Tabs.Tab>
                    <Tabs.Tab panelId="configuration">Configuration</Tabs.Tab>
                  </Tabs.TabList>
                </div>
              </div>
              <div className="blueSpace"></div>
              <div className="content">
                <Tabs.TabPanel id="banques">
                  <BankList banks={banks} />
                </Tabs.TabPanel>
                <Tabs.TabPanel id="transactions">
                  <TransactionList banks={banks} />
                </Tabs.TabPanel>
                <Tabs.TabPanel id="configuration">
                  <FinanceConfigList displayNom />
                </Tabs.TabPanel>
              </div>
            </Tabs>
            <Outlet />
          </>
        }
      />
    </>
  );
}

export default BankRootPage;
