import TextInput from "../UI/TextInput";
import { ArrowDown, ArrowUp, Croix } from "../UI/Icons";
import ExpandableCheckboxList from "../UI/ExpandableCheckboxList";
import Card from "../UI/Card";
import ButtonCard from "../UI/ButtonCard";
import useEdlMateriauxTemplates from "../../hooks/use-edl-materiaux-templates";
import EdlItemEtatInput from "./EdlItemEtatInput";
import Select from "../UI/Select";
import styles from "./EdlItemNomEtatInputs.module.css";

function EdlItemNomEtatInputs({
  isChild,
  item,
  inputNamePrefix,
  isTemplate,
  setFieldValue,
  onDelete,
  onNoteChange,
  onToggle,
  isActive,
}) {
  const { edlMateriauxTemplates } = useEdlMateriauxTemplates();

  const nameRadio = `${inputNamePrefix}note`;
  const degradationsTemplate =
    edlMateriauxTemplates?.find(
      (filtered) => filtered.id === parseInt(item?.template_materiaux),
    )?.degradations ?? [];
  const currentDegradationsValues =
    item?.degradations.filter((i) => !degradationsTemplate.includes(i)) ?? [];

  const degradationsCheckboxItems = [
    ...degradationsTemplate,
    ...currentDegradationsValues,
  ];

  const materiauxTemplate =
    edlMateriauxTemplates?.find(
      (filtered) => filtered.id === parseInt(item?.template_materiaux),
    )?.materiaux ?? [];
  const currentMateriauxValues =
    item?.materiaux.filter((i) => !materiauxTemplate.includes(i)) ?? [];
  const materiauxCheckboxItems = [
    ...materiauxTemplate,
    ...currentMateriauxValues,
  ];

  const label = isChild ? "Nom de l'élément" : "Nom de la pièce";

  function addItem(fieldName, newItem) {
    const currentValue = item[fieldName.replace(inputNamePrefix, "")];
    if (currentValue === undefined || currentValue === null) {
      setFieldValue(fieldName, [newItem]);
    } else if (!currentValue.includes(newItem)) {
      setFieldValue(fieldName, [...currentValue, newItem]);
    }
  }

  return (
    <Card className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.firstCol}>
          <TextInput
            name={`${inputNamePrefix}nom`}
            placeholder={label}
            marginForm={""}
          />
          {isChild && item.type === "meubles" && (
            <TextInput
              name={`${inputNamePrefix}quantite`}
              type={"number"}
              placeholder={"1"}
              unit={"Quantité"}
              size="s"
              marginForm={""}
            />
          )}
        </div>
        {!isTemplate && (
          <div className={styles.secondCol} onChange={onNoteChange}>
            <EdlItemEtatInput name={nameRadio} />
          </div>
        )}
        {isChild && (
          <div className={styles.thirdCol}>
            <div className={"flex"}>
              <ButtonCard
                type="button"
                onClick={onToggle}
                className={"padding5"}
              >
                {isActive ? <ArrowUp /> : <ArrowDown />}
              </ButtonCard>
              <ButtonCard
                type="button"
                onClick={onDelete}
                className={"padding5 marginL5"}
              >
                <Croix color={"red"} />
              </ButtonCard>
            </div>
          </div>
        )}
      </div>
      {isChild && isActive && (
        <div className={"marginT10"}>
          <Select
            name={`${inputNamePrefix}template_materiaux`}
            className={"margin0"}
          >
            <option value={""}>Type de surface</option>
            {edlMateriauxTemplates?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.nom}
              </option>
            ))}
          </Select>
          <Card>
            <p>Matériaux</p>
            <ExpandableCheckboxList
              name={`${inputNamePrefix}materiaux`}
              items={materiauxCheckboxItems}
              addItem={addItem}
              classNameG={"marginT10 marginB10"}
            />
          </Card>
          <Card className={"marginT10"}>
            <p>Dégradations</p>
            <ExpandableCheckboxList
              name={`${inputNamePrefix}degradations`}
              items={degradationsCheckboxItems}
              addItem={addItem}
              classNameG={"marginT10 marginB10"}
            />
          </Card>
        </div>
      )}
    </Card>
  );
}

export default EdlItemNomEtatInputs;
