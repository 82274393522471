import { useEffect, useMemo, useState } from "react";

export default function useMediaQuery(query) {
  const media = useMemo(() => window.matchMedia(query), [query]);
  const [matches, setMatches] = useState(media.matches);

  useEffect(() => {
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [media, query]);

  return matches;
}
