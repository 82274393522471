import styles from "../Account/CreditsCard.module.css";
import { Credits } from "../UI/Icons";
import { currencyFormatter } from "../../api/Functions";
import Card from "../UI/Card";
import { classnames } from "../../utils";

export default function CreditsCard({ credits, className }) {
  return (
    <Card className={classnames(styles.card, className)}>
      <Credits />
      <div className={"marginL10"}>
        <p className={"primaryBlue text-center"}>Vos crédits</p>
        <p className={styles.credits}>+ {currencyFormatter(credits)}</p>
      </div>
    </Card>
  );
}
