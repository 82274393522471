import style from "./Property.module.css";
import PhotoUpload from "../File/PhotoUpload";
import Photo from "../UI/Photo";

function PropertyDisplayImgAddress({ property, onPhotoUploaded }) {
  return (
    <div className={"flex align-items-start"}>
      {onPhotoUploaded ? (
        <PhotoUpload
          photo={property.photo1}
          onUploaded={onPhotoUploaded}
          alt="logement"
          isPublic
        />
      ) : property.photo1 ? (
        <Photo
          photoId={property.photo1}
          className={style.smallImage}
          alt="logement"
        />
      ) : property.type === "garage" ? (
        <img
          src={`/default_garage.jpg`}
          alt="logement"
          className={style.smallImage}
        />
      ) : (
        <img
          src={`/default_house.jpg`}
          alt="logement"
          className={style.smallImage}
        />
      )}
      <div className={"marginL10 marginB5"}>
        <p className={style.primaryText}>{property.nom}</p>
        {property.ville && (
          <>
            <p className={`marginT10 ${style.secondaryText2}`}>
              {property.rue}
              {property.complement_adresse && (
                <>
                  <br />
                  <span className={`${style.secondaryText2}`}>
                    {property.complement_adresse}
                  </span>
                  <br />
                </>
              )}
            </p>
            <p className={` ${style.secondaryText2}`}>
              {`${property.cp} ${property.ville}`}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default PropertyDisplayImgAddress;
