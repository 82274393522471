import useResources from "./use-resources";

export default function useCustomerCases() {
  const {
    resources: customerCases,
    error,
    isLoading,
    create,
  } = useResources("customer-cases");

  return {
    customerCases,
    error,
    isLoading,
    addCustomerCase: create,
  };
}
