import { NavLink } from "react-router-dom";
import styles from "./Menu.module.css";
import useLogout from "../../hooks/use-logout";
import useAuth from "../../hooks/use-auth";
import { useEffect, useRef, useState } from "react";
import { Profile, Qalimo } from "../UI/Icons";
import Hamburger from "./Hamburger";
import Dropdown from "../UI/Dropdown";
import useMediaQuery from "../../hooks/use-media-query";

function Menu({ isTenant, isBailleur }) {
  const logout = useLogout();
  const { auth } = useAuth();
  const ref = useRef();

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 870px)");

  function toggleHamburger() {
    setHamburgerOpen((prev) => !prev);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (hamburgerOpen && ref.current && !ref.current.contains(e.target)) {
        toggleHamburger();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [hamburgerOpen]);

  const setClassName = () => styles.inactive;

  const dropdownLinks = (
    <>
      <li>
        <NavLink to="/mon-compte" className={setClassName}>
          Mon compte
        </NavLink>
      </li>
      {isBailleur && (
        <li>
          <NavLink to="/locataire" className={setClassName}>
            Mode locataire
          </NavLink>
        </li>
      )}
      {isTenant && (
        <li>
          <NavLink to="/bailleur" className={setClassName}>
            Mode bailleur
          </NavLink>
        </li>
      )}
      {auth.role?.length > 0 && (
        <li>
          <NavLink to="/admin" className={setClassName}>
            Admin
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to={"/"} onClick={logout} className={setClassName}>
          Déconnexion
        </NavLink>
      </li>
    </>
  );

  return (
    <header className={styles.header}>
      <nav className={styles.nav} ref={ref}>
        <div className={styles.burgerLogo}>
          <Hamburger
            open={hamburgerOpen}
            toggle={toggleHamburger}
            aria-controls="primary-navigation"
          />
          <NavLink
            to={isBailleur ? "/bailleur" : "/locataire"}
            className={styles.logo}
          >
            <Qalimo />
            <p className={styles.brand}>alimo</p>
          </NavLink>
        </div>
        <ul
          id="primary-navigation"
          className={styles.navList}
          data-open={hamburgerOpen}
        >
          {isBailleur && (
            <>
              <li>
                <NavLink to="/bailleur" className={setClassName} end>
                  Tableau de bord
                </NavLink>
              </li>
              <li data-joyride="profil-bailleur">
                <NavLink to="/bailleur/profiles" className={setClassName}>
                  Profils bailleur
                </NavLink>
              </li>
              <li data-joyride="add-property">
                <NavLink to="/bailleur/properties" className={setClassName}>
                  Biens immobiliers
                </NavLink>
              </li>
              <li>
                <NavLink to="/bailleur/tenants" className={setClassName}>
                  Locataires
                </NavLink>
              </li>
              <li>
                <NavLink to="/bailleur/candidats" className={setClassName}>
                  Candidats
                </NavLink>
              </li>
              <li>
                <NavLink to="/bailleur/finances" className={setClassName}>
                  Finances
                </NavLink>
              </li>
            </>
          )}
          {isTenant && (
            <>
              <li>
                <NavLink to="/locataire" className={setClassName} end>
                  Tableau de bord
                </NavLink>
              </li>
              <li>
                <NavLink to="/candidat" className={setClassName} end>
                  Dossiers de candidature
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/locataire/candidatures"
                  className={setClassName}
                  end
                >
                  Liste des candidatures
                </NavLink>
              </li>
            </>
          )}

          {!isSmallScreen && (
            <li className={styles.push}>
              <Dropdown
                header={
                  <div className={"paddingT10 paddingL10 paddingR10"}>
                    <Profile />
                  </div>
                }
                type={"click"}
                body={<ul>{dropdownLinks}</ul>}
              />
            </li>
          )}
          {isSmallScreen && (
            <>
              <li className={styles.push}></li>
              {dropdownLinks}
            </>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Menu;
