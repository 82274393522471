import Card from "../UI/Card";
import Photo from "../UI/Photo";
import style from "./Property.module.css";

function PropertyDisplaySmallCard({ property }) {
  return (
    <Card
      className={"padding10 flex align-items-center marginB10"}
      key={property.id}
    >
      {property.photo1 ? (
        <Photo
          photoId={property.photo1}
          className={style.smallImage}
          alt="logement"
        />
      ) : property.type === "garage" ? (
        <img
          src={`/default_garage.jpg`}
          alt="logement"
          className={style.smallImage}
        />
      ) : (
        <img
          src={`/default_house.jpg`}
          alt="logement"
          className={style.smallImage}
        />
      )}
      <div className={"marginL10"}>
        <div className={"primaryText marginB5"}>{property.nom}</div>
        <div className={"secondaryText"}>
          {`${property.rue}, ${property.complement_adresse || ""} ${
            property.cp
          } ${property.ville}`}
        </div>
      </div>
    </Card>
  );
}

export default PropertyDisplaySmallCard;
