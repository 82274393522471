import { Form, Formik } from "formik";
import { useState } from "react";
import { object, string } from "yup";
import TextInput from "../UI/TextInput";
import styles from "./AuthForm.module.css";
import { axiosPublic } from "../../api";
import FormikSubmitButton from "../UI/FormikSubmitButton";

function AuthForm() {
  const [message, setMessage] = useState("");

  async function resetPassword(values) {
    try {
      const res = await axiosPublic.post(`/reset-password`, {
        mail: values.mail,
      });
      if (res) {
        setMessage(
          "Un mail vient d'être envoyé, cliquez sur le lien afin de réinitialiser votre mot de passe",
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Formik
      className={styles.form}
      initialValues={{ mail: "" }}
      validationSchema={object({
        mail: string()
          .email("Adresse e-mail invalide")
          .required("Adresse e-mail requise "),
      })}
      onSubmit={resetPassword}
    >
      <Form className={styles.form}>
        <TextInput type="email" name="mail" placeholder="Adresse e-mail" />
        <FormikSubmitButton>Envoyer</FormikSubmitButton>
        {message}
      </Form>
    </Formik>
  );
}

export default AuthForm;
