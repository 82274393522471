import { DocType } from "../utils/constants/documents";
import useDocument from "./use-document";

export default function useSignature(withBlob = false) {
  const {
    document,
    documentBlob,
    error,
    errorBlob,
    isLoading,
    isLoadingBlob,
    createDocument,
    deleteDocument,
  } = useDocument("signature", DocType.SIGNATURE, withBlob);

  return {
    signature: document,
    signatureBlob: documentBlob,
    error,
    errorBlob,
    isLoading,
    isLoadingBlob,
    createSignature: createDocument,
    deleteSignature: deleteDocument,
  };
}
