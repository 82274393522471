import { useCallback, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import useProperties from "./use-properties";
import useAuth from "./use-auth";

function useDependentField(fieldName, dependOnFieldName, valueExtractor) {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const dependOnFieldValue = values[dependOnFieldName];
  const dependOnFieldInitialValue = initialValues[dependOnFieldName];
  // Use to determine if dependent field value should be updated. Default to false on first render
  const shouldUpdateRef = useRef(false);

  useEffect(() => {
    let isCurrent = true;
    if (
      !shouldUpdateRef.current &&
      dependOnFieldInitialValue !== dependOnFieldValue
    ) {
      // Once the field this depends on has been changed, the value should be updated
      shouldUpdateRef.current = true;
    }
    // your business logic around when to fetch goes here.
    if (dependOnFieldName && dependOnFieldValue && shouldUpdateRef.current) {
      valueExtractor(dependOnFieldValue).then((value) => {
        if (!!isCurrent && value !== undefined && value != null) {
          // prevent setting old values
          setFieldValue(fieldName, value);
        }
      });
    }
    return () => {
      isCurrent = false;
    };
  }, [
    dependOnFieldInitialValue,
    dependOnFieldName,
    dependOnFieldValue,
    fieldName,
    setFieldValue,
    valueExtractor,
  ]);
}

export function usePropertyExtractor(fieldName) {
  const { auth } = useAuth();
  const { properties } = useProperties({ noFetch: !auth.accessToken });

  return useCallback(
    (dependOnFieldValue) => {
      const property = properties.find(
        (property) => property.id === parseInt(dependOnFieldValue),
      );
      if (property === undefined) {
        return undefined;
      }

      return Promise.resolve(property[fieldName]);
    },
    [fieldName, properties],
  );
}

export default useDependentField;
