import styles from "./EdlItemEtatInput.module.css";
import RadioGroup from "../UI/RadioGroup";
import Radio from "../UI/Radio";

export default function EdlItemEtatInput({ name }) {
  return (
    <RadioGroup styling="button" className={styles.etats}>
      <Radio name={name} value="1" label="Neuf" data-color="blue" />
      <Radio name={name} value="2" label="Bon état" data-color="green" />
      <Radio name={name} value="3" label="État d'usage" data-color="yellow" />
      <Radio name={name} value="4" label="Mauvais état" data-color="red" />
    </RadioGroup>
  );
}
