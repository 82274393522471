import ProfileForm from "../../components/Profile/ProfileForm";
import { useModal } from "../../hooks/use-modal";
import TenantAddForm from "../../components/Tenant/Forms/TenantAddForm";
import { useImmer } from "use-immer";

function TenantAddPage({ type }) {
  const [TenantAddModal, closeTenantAddModal] = useModal(true);
  const [AddProfileModal, closeAddProfileModal, openAddProfileModal] =
    useModal();
  const [profileToPush, setProfileToPush] = useImmer([]);

  function profileSubmittedHandler(values) {
    setProfileToPush((curr) => [...curr, { profile: values }]);
  }
  return (
    <>
      <TenantAddForm
        openAddProfileModal={openAddProfileModal}
        profileToPush={profileToPush}
        onAddProfile={(prof) => setProfileToPush((curr) => [...curr, prof])}
        onDeleteProfile={(index) => {
          setProfileToPush((curr) => curr.filter((profile, i) => i !== index));
        }}
        modal={TenantAddModal}
        closeTenantAddModal={closeTenantAddModal}
        type={type}
      />
      <ProfileForm
        onProfileSubmitted={(profile) => {
          profileSubmittedHandler(profile);
          closeAddProfileModal();
        }}
        addTenant
        modal={AddProfileModal}
      />
    </>
  );
}

export default TenantAddPage;
