import useResources from "./use-resources";
import useSWR from "swr";
import useAuth from "./use-auth";

const ROOT_PATH = "/stripe/payment-methods";
const DEFAULT_PAYMENT_METHOD_PATH = ROOT_PATH + "/default";

export default function useStripePaymentMethods() {
  const { auth } = useAuth();
  const {
    resources: paymentMethods,
    error,
    isLoading,
    del,
    axios,
    mutate,
  } = useResources(ROOT_PATH);

  const { data: defaultPaymentMethod, mutate: mutateDefault } = useSWR(
    { url: DEFAULT_PAYMENT_METHOD_PATH, userId: auth.userId },
    ({ url }) => {
      return axios.get(url).then((res) => res.data);
    },
  );

  async function markDefaultPaymentMethod(paymentMethodId) {
    return mutateDefault(
      async () => {
        const response = await axios.put(DEFAULT_PAYMENT_METHOD_PATH, {
          payment_method_id: paymentMethodId,
        });
        return response.data;
      },
      { revalidate: false },
    );
  }

  return {
    paymentMethods,
    defaultPaymentMethod,
    error,
    isLoading,
    deletePaymentMethod: del,
    markDefaultPaymentMethod,
    refresh: () => mutate(),
  };
}
