import DisplayName from "./DisplayName";
import Radio from "./Radio";
import TextInput from "./TextInput";
import InitialesLogo from "./InitialesLogo";

function RepresentedBy({ profile, prefixName, check }) {
  return (
    <>
      <div
        className={"flex space-between marginT10 align-items-center wrap gap10"}
      >
        <div className="flex align-items-center">
          <InitialesLogo
            prenom={profile.prenom}
            nom={profile.nom}
            shape={"rond"}
          />
          <div className={"marginL10 nowrap"}>
            <DisplayName profile={profile} />
          </div>
        </div>

        <div className={"flex wrap gap10"}>
          <Radio
            name={`${prefixName}.present`}
            value="Présent"
            label="Présent"
            pillStyle
            className={"marginR10"}
          />
          <Radio
            name={`${prefixName}.present`}
            value="Absent"
            label="Absent"
            pillStyle
          />
          <Radio
            name={`${prefixName}.present`}
            value="Représenté"
            label="Représenté"
            pillStyle
          />
        </div>
      </div>
      {check?.present === "Représenté" && (
        <>
          <div className={"flex marginT10"}>
            <TextInput
              name={`${prefixName}.represente_prenom`}
              placeholder={"Jean"}
            />
            <TextInput
              name={`${prefixName}.represente_nom`}
              placeholder={"MAURICE"}
              className={"marginL10"}
            />
          </div>
          <TextInput
            name={`${prefixName}.represente_qualite`}
            placeholder={"Qualité (lien familial, tutelle, huissier, ...)"}
            marginForm={false}
          />
        </>
      )}
    </>
  );
}

export default RepresentedBy;
