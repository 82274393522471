import useAuthenticatedAxios from "./use-authenticated-axios";
import { findAndUpdate } from "../utils";
import useResources from "./use-resources";

const ROOT_PATH = "transactions";

export default function useTransactions({ path, params }) {
  const axios = useAuthenticatedAxios();
  const url = path ? `${ROOT_PATH}/${path}` : ROOT_PATH;
  const {
    resources: transactions,
    error,
    isLoading,
    mutate,
    del,
  } = useResources(url, {
    params,
  });

  function deleteMultipleTransactions(transactionIds) {
    return mutate(
      async (currentTransactions) => {
        const res = await axios.post(`${ROOT_PATH}/batch-delete`, {
          ids: transactionIds,
        });

        return currentTransactions.filter(
          (transaction) => !res.data.includes(transaction.id),
        );
      },
      { revalidate: true },
    );
  }

  function assignMultipleTransactions(transactionIds, payload) {
    return mutate(
      async (currentTransactions) => {
        const res = await axios.put(`${ROOT_PATH}/batch-assign`, {
          ids: transactionIds,
          payload,
        });

        let newTransactions = [...currentTransactions];
        for (let newTransaction of res.data) {
          newTransactions = findAndUpdate(
            newTransactions,
            (transaction) => transaction.id === newTransaction.id,
            newTransaction,
          );
        }
        return newTransactions;
      },
      { revalidate: false },
    );
  }

  function deleteTransactionBalances(transactionId) {
    return mutate(
      async (currentTransactions) => {
        // if either property or tenant is null, we are deleting the affectation,
        // so we delete the balance that was created
        const res = await axios.delete(
          `${ROOT_PATH}/${transactionId}/balances`,
        );

        return findAndUpdate(
          currentTransactions,
          (transaction) => transaction.id === transactionId,
          res.data,
        );
      },
      { revalidate: false },
    );
  }

  function createTransactionBalances(transactionId, balances) {
    return mutate(
      async (currentTransactions) => {
        // if either property or tenant is null, we are deleting the affectation,
        // so we delete the balance that was created
        const res = await axios.put(
          `${ROOT_PATH}/${transactionId}/balances`,
          balances,
        );

        return findAndUpdate(
          currentTransactions,
          (transaction) => transaction.id === transactionId,
          res.data,
        );
      },
      { revalidate: false },
    );
  }

  return {
    transactions,
    error,
    isLoading,
    deleteTransaction: del,
    deleteMultipleTransactions,
    assignMultipleTransactions,
    deleteTransactionBalances,
    createTransactionBalances,
    refreshTransactions: () => mutate(),
  };
}
