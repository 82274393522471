import TextInput from "../../../UI/TextInput";

export default function ProrogationMobilite() {
  return (
    <>
      <TextInput
        type={"number"}
        name={"duree_sup"}
        label={"Durée supplémentaire"}
        placeholder={"3"}
        unit={"mois"}
        step={"1"}
      />
    </>
  );
}
