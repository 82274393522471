import Joyride from "react-joyride";
import useLocalStorage from "../../hooks/use-local-storage";

function JoyrideQalimo({ steps = [], currentStep }) {
  const [showJoyride, setShowJoyride] = useLocalStorage(currentStep, true);
  if (!steps || !currentStep) return null;
  return (
    steps.length > 0 && (
      <Joyride
        steps={steps}
        spotlightClicks
        locale={{
          back: "Précédent",
          close: "Fermer",
          last: "Fermer",
          next: "Suivant",
          open: "Ouvrir la modale",
          skip: "Passer",
        }}
        styles={{
          options: {
            overlayColor: "rgba(0, 0, 0, 0.3)",
            primaryColor: "#0c69f5",
          },
          tooltipContainer: {
            textAlign: "left",
          },
        }}
        showSkipButton
        continuous
        run={showJoyride}
        callback={(tour) => {
          if (tour.lifecycle === "complete") {
            setShowJoyride(false);
          }
        }}
      />
    )
  );
}

export default JoyrideQalimo;
