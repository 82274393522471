import AuthFormForgottenPasswordReconf from "../../components/Auth/AuthFormForgottenPasswordReconf";
import TemplateAuthentication from "../../components/UI/TemplateAuthentication";

function ForgottenPassword({ isInvite }) {
  return (
    <TemplateAuthentication>
      <div>
        <h3>
          {isInvite
            ? "Créez votre mot de passe"
            : "Réinitialisez votre mot de passe"}
        </h3>
      </div>
      <div>
        <AuthFormForgottenPasswordReconf />
      </div>
    </TemplateAuthentication>
  );
}

export default ForgottenPassword;
