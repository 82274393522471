import { object, string } from "yup";
import { stringMax } from "./utils";

export const mailValidationSchema = object({
  subject: stringMax(250).required(" "),
  html: string().required(" "),
  plain: string().required(" "),
});

export const MAIL_INITIAL_VALUE = {
  subject: "",
  html: "",
  plain: "",
};
