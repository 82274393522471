import { FieldArray, Form, Formik } from "formik";
import Button from "../UI/Button";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import TextInput from "../UI/TextInput";
import { Croix, Plus } from "../UI/Icons";
import React from "react";
import {
  EDL_CLE_INITIAL_VALUE,
  EDL_CLES_INITIAL_VALUE,
  edlClesValidationSchema,
} from "../../models/edl";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";

export default function ClesForm({ property, onSubmit, ModalElement }) {
  async function submitHandler(values, { resetForm }) {
    try {
      await onSubmit(sanitizeValues(values));
      resetForm();
    } catch (error) {
      console.error(error);
    }
  }

  const initialValues = toInitialValuesFromSchema(
    edlClesValidationSchema,
    property,
    EDL_CLES_INITIAL_VALUE,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={edlClesValidationSchema}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      {({ values, resetForm }) => (
        <ModalElement size="medium" onClose={resetForm}>
          <Form>
            <FieldArray
              name={"cles"}
              render={(arrayHelpers) => (
                <>
                  {values.cles.map((cle, index) => {
                    return (
                      <div
                        key={index}
                        className={"flex align-items-center gap10 wrap"}
                      >
                        <TextInput
                          name={`cles[${index}].nombre`}
                          placeholder={"1"}
                          type={"number"}
                          label={"Quantité"}
                        />
                        <TextInput
                          name={`cles[${index}].description`}
                          className={"marginL10 flex-grow"}
                          placeholder={"Clé de l'entrée"}
                          label={"Type de clé"}
                        />
                        <div
                          onClick={() => arrayHelpers.remove(index)}
                          className={"marginL10 marginB10 cursorPointer"}
                        >
                          <Croix />
                        </div>
                      </div>
                    );
                  })}
                  <div className={"text-center"}>
                    <Button
                      buttonStyle={"link"}
                      type={"button"}
                      onClick={() => {
                        arrayHelpers.push(EDL_CLE_INITIAL_VALUE);
                      }}
                    >
                      <Plus stroke={"#0C69F5"} />
                      <span>Ajouter un type de clé</span>
                    </Button>
                  </div>
                </>
              )}
            />
            <div className={"text-right marginT10"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
          </Form>
        </ModalElement>
      )}
    </Formik>
  );
}
