import useResources from "./use-resources";

const ROOT_PATH = "events";

export default function useEvents() {
  const {
    resources: events,
    error,
    isLoading,
    create,
    update,
    del,
  } = useResources(ROOT_PATH);

  return {
    events,
    error,
    isLoading,
    addEvent: create,
    updateEvent: update,
    deleteEvent: del,
  };
}
