import Quill from "quill";

const BlockEmbed = Quill.import("blots/block/embed");

class CustomSvgBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}

CustomSvgBlot.blotName = "customSvg";
CustomSvgBlot.tagName = "div";

Quill.register(CustomSvgBlot);
