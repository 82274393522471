import Photo from "../UI/Photo";
import styles from "./PropertyDisplayNomLocataire.module.css";
import { Link } from "react-router-dom";
import { ROOT_PATH } from "./PropertyListShow";
import IconAndText from "../UI/IconAndText";
import { House, People } from "../UI/Icons";
import { classnames } from "../../utils";

export default function PropertyDisplayNomLocataire({
  property,
  locataire,
  className,
  isPropertyFolder,
  disabled,
}) {
  const propertyDisplay = <IconAndText icon={<House />} text={property.nom} />;
  const locataireDisplay = locataire && (
    <IconAndText
      icon={<People stroke="#14323A" size="12" strokeWidth={"1.5"} />}
      text={locataire.profile_nom.nom_profile}
    />
  );
  return (
    <div className={classnames(styles.wrapper, className)}>
      {property.photo1 ? (
        <Photo
          photoId={property.photo1}
          className={styles.smallImage}
          alt="logement"
        />
      ) : isPropertyFolder ? (
        property.id_parent ? (
          <img
            src="/default_appart.jpg"
            alt="logement"
            className={styles.smallImage}
          />
        ) : (
          <img
            src="/default_immeuble.jpg"
            alt="logement"
            className={styles.smallImage}
          />
        )
      ) : property.type === "garage" ? (
        <img
          src="/default_garage.jpg"
          alt="logement"
          className={styles.smallImage}
        />
      ) : (
        <img
          src="/default_house.jpg"
          alt="logement"
          className={styles.smallImage}
        />
      )}
      <div className={"marginL10"}>
        {disabled ? (
          propertyDisplay
        ) : (
          <Link
            to={`${ROOT_PATH}${isPropertyFolder ? "folder/" : ""}${
              property.id
            }`}
            className={"smallText"}
          >
            {propertyDisplay}
          </Link>
        )}
        {locataire &&
          (disabled ? (
            locataireDisplay
          ) : (
            <Link
              to={"/bailleur/tenants/" + locataire.id}
              className={"smallText"}
            >
              {locataireDisplay}
            </Link>
          ))}
      </div>
    </div>
  );
}
