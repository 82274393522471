import CardToggle from "../../UI/CardToggle";
import moment from "moment";

function isSuccess(status) {
  return status >= 200 && status < 300;
}

export default function WebhookLog({ log, webhooks }) {
  const webhookDesc = webhooks.find(
    (w) => w.webHookId === log.webHookId,
  )?.description;
  return (
    <CardToggle
      className={"marginT5"}
      padding={"sm"}
      type={isSuccess(log.status) ? "green" : "error"}
      title={
        <>
          {moment(log.timestamp).format("YYYY/MM/DD HH:mm:ss")} - Status :{" "}
          {log.status} - Webhook : {webhookDesc || "Non renseigné"} (ID :{" "}
          {log.webHookId}) - {isSuccess(log.status) ? "Success" : log.message}
        </>
      }
    >
      <pre>{log.url}</pre>
      <pre>{log.payload}</pre>
    </CardToggle>
  );
}
