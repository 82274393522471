import IconAndText from "./IconAndText";
import ButtonCard from "./ButtonCard";

function CardAction({ icon, title, text, onClick, type, className, disabled }) {
  return (
    <ButtonCard
      onClick={onClick}
      type={type}
      className={className}
      disabled={disabled}
      padding={"sm"}
    >
      <IconAndText icon={icon} text={<p className={"cardTitle"}>{title}</p>} />
      <p className={"marginT10 secondaryText text-left"}>{text}</p>
    </ButtonCard>
  );
}

export default CardAction;
