import TextInput from "../../UI/TextInput";
import Radio from "../../UI/Radio";

function ZoneTendue({ loyerReference }) {
  return (
    <>
      <p className={"marginT10"}>
        Soumis à l'encadrement des loyers fixé par arrêté prefectoral
      </p>
      <div className={"gridColWrap240 marginB10"}>
        <Radio name="loyer_reference" value="true" label="Oui" />
        <Radio name="loyer_reference" value="false" label="Non" />
      </div>

      {loyerReference && (
        <>
          <div className={"gridColWrap240"}>
            <TextInput
              type={"text"}
              name={"montant_loyer_ref"}
              label="Loyer de référence pour la zone"
              placeholder={"10"}
              unit={"€/m²"}
            />
            <TextInput
              type={"text"}
              name={"montant_loyer_ref_maj"}
              label="Loyer de référence majoré"
              placeholder={"11"}
              unit={"€/m²"}
            />
          </div>

          <TextInput
            type={"text"}
            name={"complement_loyer"}
            label="Si besoin, complément de loyer prévu"
            placeholder={"100"}
            unit={"€"}
          />
          <TextInput
            type={"text"}
            name={"justif_complement_loyer"}
            label="Justification du complément de loyer"
            placeholder={"Vue imprenable sur la ville"}
          />
        </>
      )}
    </>
  );
}

export default ZoneTendue;
