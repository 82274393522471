import { TenantStatus } from "../../models/tenant";
import useProperties from "../../hooks/use-properties";
import Tabs from "../UI/Tabs";
import ShowTenantList from "./ShowTenantList";

export default function TenantList({ balances, tenants }) {
  const { properties } = useProperties();

  const actifs = tenants.filter(
    (tenant) => tenant.status === TenantStatus.Actif.status,
  );

  return (
    <Tabs id="tenant-list">
      <div className="container bg-blue paddingT10 border-blue">
        <div className="content">
          <Tabs.TabList>
            <Tabs.Tab panelId="locataires-actifs">
              Locataires actifs ({actifs.length})
            </Tabs.Tab>
            <Tabs.Tab panelId="archives">Locataires archivés</Tabs.Tab>
          </Tabs.TabList>
        </div>
      </div>
      <div className="blueSpace"></div>
      <div className="content">
        <Tabs.TabPanel id="locataires-actifs">
          <ShowTenantList
            tenants={actifs}
            properties={properties}
            balances={balances}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel id="archives">
          <ShowTenantList
            tenants={tenants}
            properties={properties}
            balances={balances}
            isArchived
          />
        </Tabs.TabPanel>
      </div>
    </Tabs>
  );
}
