import TextArea from "../../../UI/TextArea";
import Card from "../../../UI/Card";

export default function CongeLegitime() {
  return (
    <>
      <Card className={"marginB10"} type={"warning"}>
        Attention : un congé ne peut avoir lieu qu'a l'échéance du bail. il doit
        être envoyé :
        <ul>
          <li>
            Pour un logement meublé, au moins 3 mois avant l'échéance du bail
          </li>
          <li>Pour un logement nu, au moins 6 mois avant l'échéance du bail</li>
        </ul>
      </Card>
      <Card className={"marginB10"}>
        Exemples de motif :
        <ul>
          <li>Retard de loyers fréquent</li>
          <li>N'a pas communiqué son attestation d'assurance habitation</li>
          <li>Trouble du voisinage</li>
          <li>
            travaux de rénovation total du logement à effectuer pour respecter
            les critère de décence du DPE
          </li>
        </ul>
      </Card>

      <TextArea
        type="text"
        name="motif"
        label="Motif du congé"
        placeholder="Retard de loyers fréquent"
      />
    </>
  );
}
