import ListFiles from "../UI/ListFiles";
import Card from "../UI/Card";

function TenantDecompte({ decompte, decompteFile }) {
  return (
    <>
      {decompteFile && (
        <Card>
          <p className={"primaryText"}>Télécharger le document</p>
          <ListFiles files={[decompteFile]} displayName />
        </Card>
      )}
      <Card className={"marginT10"}>
        <p className={"primaryText"}>Prévisualisation du document</p>
        <div dangerouslySetInnerHTML={{ __html: decompte }} />
      </Card>
    </>
  );
}

export default TenantDecompte;
