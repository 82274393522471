import { object, ref, string } from "yup";
import { stringMax } from "./utils";

export const changePasswordInitialValues = {
  old_password: "",
  new_password: "",
  dnew_password: "",
};
export const changePasswordSchema = object({
  old_password: stringMax(250).required(" "),
  new_password: stringMax(250).required(" "),
  dnew_password: string()
    .oneOf([ref("new_password")], "Les mots de passe doivent être identiques")
    .required(" "),
});
