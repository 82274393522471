import Card from "../../../UI/Card";
import TextInput from "../../../UI/TextInput";

export default function CongeVenteNu() {
  return (
    <>
      <Card type={"warning"} className={"marginB10"}>
        Attention, les règles pour les congés pour vente sont stricts :
        <ul>
          <li>
            Lorsque le terme du contrat de location en cours intervient moins de
            trois ans après la date d'acquisition, le bailleur ne peut donner
            congé à son locataire pour vendre le logement qu'au terme de la
            première reconduction tacite ou du premier renouvellement du contrat
            de location en cours
          </li>

          <li>
            lorsque le terme du contrat en cours intervient moins de deux ans
            après l'acquisition, le congé pour reprise donné par le bailleur au
            terme du contrat de location en cours ne prend effet qu'à
            l'expiration d'une durée de deux ans à compter de la date
            d'acquisition.
          </li>
        </ul>
        un congé ne peut avoir lieu qu'a l'échéance du bail. il doit être envoyé
        :
        <ul>
          <li>
            Pour un logement meublé, au moins 3 mois avant l'échéance du bail
          </li>
          <li>Pour un logement nu, au moins 6 mois avant l'échéance du bail</li>
        </ul>
      </Card>
      <TextInput
        type="number"
        name="prix_vente"
        label="Prix de vente"
        placeholder="88000"
        unit={"€"}
      />
    </>
  );
}
