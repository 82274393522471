import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import style from "./Tabs.module.css";
import { classnames } from "../../utils";

const TabsContext = createContext({});

function moduloInc(current, length) {
  return (current + 1) % length;
}

function moduloDec(current, length) {
  return (current - 1 + length) % length;
}

const Tabs = forwardRef(({ children, id }, ref) => {
  const [tabs, setTabs] = useState([]);
  const [params, setParams] = useSearchParams();

  const nextTabIndex = tabs.findIndex(
    (tab) => tab.id === params.get(`next-tab-${id}`),
  );
  useEffect(() => {
    // Switch to the preselected tab when it's available
    if (nextTabIndex !== -1) {
      setParams((cur) => {
        const newParams = new URLSearchParams(cur.toString());
        newParams.set(`tab-${id}`, newParams.get(`next-tab-${id}`));
        newParams.delete(`next-tab-${id}`);
        return newParams;
      });
    }
  }, [id, nextTabIndex, setParams]);

  const tabIndex = tabs.findIndex((tab) => tab.id === params.get(`tab-${id}`));
  const activeTabIndex = tabIndex !== -1 ? tabIndex : 0;
  const activeTab = tabs[activeTabIndex]?.id;

  const registerTab = useCallback((panelId, linkRef, index) => {
    const newTab = { id: panelId, ref: linkRef };
    setTabs((curTabs) => {
      const tabIndex = curTabs.findIndex((tab) => tab.id === panelId);
      const newTabs = [...curTabs];
      if (tabIndex >= 0) {
        newTabs[tabIndex] = newTab;
      } else {
        newTabs.splice(index ?? newTabs.length, 0, newTab);
      }
      return newTabs;
    });
  }, []);

  const unregisterTab = useCallback((panelId) => {
    setTabs((curTabs) => curTabs.filter((tab) => tab.id !== panelId));
  }, []);

  const resetTab = useCallback(() => {
    if (!id) {
      throw new Error("Tabs must have a unique ID prop");
    }
    setParams((cur) => {
      const newParams = new URLSearchParams(cur.toString());
      newParams.delete(`tab-${id}`);
      return newParams;
    });
  }, [id, setParams]);

  const selectTab = useCallback(
    (tabId, isPreselect = false) => {
      if (!id) {
        throw new Error("Tabs must have a unique ID prop");
      }
      setParams((cur) => {
        const newParams = new URLSearchParams(cur.toString());
        newParams.set(`${isPreselect ? "next-" : ""}tab-${id}`, tabId);
        return newParams;
      });
    },
    [id, setParams],
  );

  const selectNextTab = useCallback(() => {
    selectTab(tabs[moduloInc(activeTabIndex, tabs.length)].id);
  }, [activeTabIndex, selectTab, tabs]);

  const selectPreviousTab = useCallback(() => {
    selectTab(tabs[moduloDec(activeTabIndex, tabs.length)].id);
  }, [activeTabIndex, selectTab, tabs]);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetTab,
        selectTab,
        selectNextTab,
        selectPreviousTab,
      };
    },
    [resetTab, selectNextTab, selectPreviousTab, selectTab],
  );

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        activeTabIndex,
        tabs,
        registerTab,
        unregisterTab,
        resetTab,
        selectTab,
        selectNextTab,
        selectPreviousTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
});

function TabList({ children, className }) {
  const { activeTabIndex, tabs } = useContext(TabsContext);
  let focusedTabIndex = activeTabIndex;

  function keyDownHandler(e) {
    let nextFocusedTab;
    // eslint-disable-next-line default-case
    switch (e.key) {
      case "ArrowRight":
        focusedTabIndex = moduloInc(focusedTabIndex, tabs.length);
        nextFocusedTab = tabs[focusedTabIndex];
        nextFocusedTab.ref.current.focus();
        break;
      case "ArrowLeft":
        focusedTabIndex = moduloDec(focusedTabIndex, tabs.length);
        nextFocusedTab = tabs[focusedTabIndex];
        nextFocusedTab.ref.current.focus();
        break;
    }
  }

  return (
    <ul
      role="tablist"
      onKeyDown={keyDownHandler}
      className={classnames(className, style.tablist)}
    >
      {children}
    </ul>
  );
}

function Tab({ panelId, children, index, type }) {
  const linkRef = useRef();
  const { activeTab, registerTab, unregisterTab, selectTab } =
    useContext(TabsContext);
  useEffect(() => {
    registerTab(panelId, linkRef, index);
    return () => unregisterTab(panelId);
  }, [index, panelId, registerTab, unregisterTab]);

  const isActiveTab = activeTab === panelId;

  return (
    <li role="presentation">
      <button
        type="button"
        onClick={() => selectTab(panelId)}
        ref={linkRef}
        role="tab"
        tabIndex={isActiveTab ? 0 : -1}
        aria-selected={isActiveTab}
        autoFocus={isActiveTab}
        className={classnames(style[type], style.tab)}
      >
        {children}
      </button>
    </li>
  );
}

const TabPanel = forwardRef(({ id, children, shouldRenderInactive }, ref) => {
  const { activeTab } = useContext(TabsContext);
  const isActiveTab = activeTab === id;
  return (
    (isActiveTab || shouldRenderInactive) && (
      <div
        id={id}
        tabIndex="0"
        hidden={!isActiveTab}
        role="tabpanel"
        ref={ref}
        className={style.tabPanel}
      >
        {children}
      </div>
    )
  );
});

function useActiveTab() {
  const {
    activeTabIndex,
    tabs,
    resetTab,
    selectTab,
    selectNextTab,
    selectPreviousTab,
  } = useContext(TabsContext);

  return {
    isLastTab: activeTabIndex === tabs?.length - 1,
    isFirstTab: activeTabIndex === 0,
    resetTab,
    selectTab,
    selectNextTab,
    selectPreviousTab,
  };
}

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.TabPanel = TabPanel;
Tabs.useActiveTab = useActiveTab;

export default Tabs;
