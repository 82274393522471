import useAuth from "./use-auth";
import axios from "../api";
import { useCallback } from "react";

function useRefreshToken() {
  const { buildAndSetAuth } = useAuth();

  return useCallback(
    async function refresh() {
      const response = await axios.get("/refresh");
      buildAndSetAuth(response.data);

      return response.data.access_token;
    },
    [buildAndSetAuth],
  );
}

export default useRefreshToken;
