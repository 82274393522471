import { array, object, string } from "yup";

export const OODRIVE_WEBHOOK_INITIAL_VALUES = {
  description: "",
  events: [],
  type: "",
};

export const OODRIVE_WEBHOOK_VALIDATION_SCHEMA = object({
  description: string().required("Description is required"),
  events: array().of(string()).required("Events are required"),
  type: string().required("Type is required"),
});

export class OodriveWebhook {
  static contract = new OodriveWebhook("contract", [
    "OPENED",
    "SIGNED",
    "CLOSED",
    "ARCHIVED",
    "CANCELED",
  ]);
  static signature = new OodriveWebhook("signature", [
    "SENT",
    "SIGNED",
    "REFUSED",
  ]);

  static from(type) {
    switch (type) {
      case "contract":
        return OodriveWebhook.contract;
      case "signature":
        return OodriveWebhook.signature;
      default:
        throw new Error(`Webhook type '${type}' invalid`);
    }
  }

  constructor(type, events) {
    this.type = type;
    this.events = events;
  }
}
