import { useState } from "react";
import Card from "./Card";
import ButtonCard from "./ButtonCard";
import { ArrowDown, ArrowUp } from "./Icons";

export default function CardToggle({
  title,
  innerFlat,
  children,
  className,
  arrowBefore,
  ...props
}) {
  const [isToggleOn, setIsToggleOn] = useState(false);

  function onToggle() {
    setIsToggleOn((prevState) => !prevState);
  }

  const arrowButton = (
    <ButtonCard type="button" onClick={onToggle} className={"padding5"}>
      {isToggleOn ? <ArrowUp /> : <ArrowDown />}
    </ButtonCard>
  );

  return (
    <Card className={className || "bg-blue marginT10 padding10"} {...props}>
      <div className={"flex space-between align-items-center gap10"}>
        {arrowBefore && arrowButton}
        {title}
        {!arrowBefore && arrowButton}
      </div>
      {isToggleOn && (
        <Card className={"marginT10"} noCard={innerFlat}>
          {children}
        </Card>
      )}
    </Card>
  );
}
