import useAuthenticatedAxios from "./use-authenticated-axios";
import { DocType } from "../utils/constants/documents";
import useSWRImmutable from "swr/immutable";

export default function useDocumentBlob(
  documentId,
  docType = DocType.GENERIC_FILE,
) {
  const axios = useAuthenticatedAxios();
  let url = null;
  if (documentId) {
    url = `documents/${documentId}/download?doctype=${docType}`;
  }

  const {
    data: blob,
    error,
    isLoading,
    mutate,
  } = useSWRImmutable(
    url,
    (url) => {
      return axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => res.data);
    },
    {
      shouldRetryOnError: (error) => {
        // We skip retrying if the API is returning 404
        return error.response?.status !== 404;
      },
    },
  );

  return {
    blob,
    error,
    isLoading,
    refresh: () => mutate(),
  };
}
