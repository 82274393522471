import { FieldArray } from "formik";
import EdlItemNomEtatInputs from "./EdlItemNomEtatInputs";
import {
  ArrowDown,
  ArrowUp,
  Bati,
  Equipements,
  MobilierIcon,
  Plus,
} from "../UI/Icons";
import {
  EDL_ITEM_CHILD_VALUE,
  EDL_ITEM_TEMPLATE_CHILD_VALUE,
} from "../../models/edl";
import TextArea from "../UI/TextArea";
import ButtonCard from "../UI/ButtonCard";
import { Fragment, useEffect, useId, useState } from "react";
import Button from "../UI/Button";
import FormUpload from "../File/FormUpload";
import IconAndText from "../UI/IconAndText";

function EdlItemFormInputs({
  item,
  inputNamePrefix = "",
  isTemplate,
  onDelete,
  onDuplicate,
  setFieldValue,
}) {
  const [activeRow, setActiveRow] = useState();
  const [activeElement, setActiveElement] = useState();
  const formId = useId();

  function changeNotePieceHandler(e) {
    item?.items.forEach((item, index) => {
      if (item.note === "") {
        setFieldValue(`${inputNamePrefix}items[${index}].note`, e.target.value);
      }
    });
  }

  useEffect(() => {
    if (activeRow) {
      const element = document.getElementById(`${formId}-${activeRow}`);
      element.style.scrollMarginTop = `${element.parentElement.offsetTop}px`;
      element.scrollIntoView();
    }
  }, [activeRow, formId]);

  function changeActiveRow(index) {
    if (activeRow === index) {
      setActiveRow(null);
    } else {
      setActiveRow(index);
    }
  }

  function changeActiveElement(index) {
    if (activeElement === index) {
      setActiveElement(null);
    } else {
      setActiveElement(index);
    }
  }

  const typeEdls = [
    {
      type: "edl",
      nom: (
        <IconAndText
          text={<p className={"primaryText"}>Bâti</p>}
          icon={<Bati />}
        />
      ),
    },
    {
      type: "equipements",
      nom: (
        <IconAndText
          text={<p className={"primaryText"}>Equipements</p>}
          icon={<Equipements />}
        />
      ),
    },
    {
      type: "meubles",
      nom: (
        <IconAndText
          text={<p className={"primaryText"}>Mobilier</p>}
          icon={<MobilierIcon />}
        />
      ),
    },
  ];

  return (
    <>
      <EdlItemNomEtatInputs
        inputNamePrefix={inputNamePrefix}
        onNoteChange={changeNotePieceHandler}
      />

      {typeEdls.map((typeEdl, index) => (
        <Fragment key={index}>
          <ButtonCard
            className={"w-100 marginB10 relative"}
            padding={"xl"}
            onClick={() => changeActiveRow(index)}
            id={`${formId}-${index}`}
          >
            <div className={"flex space-between"}>
              <div>{typeEdl.nom}</div>
              <div>{activeRow === index ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
          </ButtonCard>
          {activeRow === index && (
            <FieldArray
              name={`${inputNamePrefix}items`}
              render={(arrayHelpers) => (
                <>
                  {item?.items.map((child, index) => (
                    <Fragment key={index}>
                      {typeEdl.type === child.type && (
                        <div className={"marginL10"}>
                          <EdlItemNomEtatInputs
                            isChild
                            item={child}
                            inputNamePrefix={`${inputNamePrefix}items[${index}].`}
                            onDelete={() => arrayHelpers.remove(index)}
                            setFieldValue={setFieldValue}
                            onToggle={() => changeActiveElement(index)}
                            isActive={activeElement === index}
                          />
                        </div>
                      )}
                    </Fragment>
                  ))}
                  <ButtonCard
                    type={"button"}
                    className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10 w-100 marginL10 bg-blue secondaryText gap10`}
                    onClick={() => {
                      arrayHelpers.push(
                        isTemplate
                          ? {
                              ...EDL_ITEM_TEMPLATE_CHILD_VALUE,
                              type: typeEdl.type,
                            }
                          : {
                              ...EDL_ITEM_CHILD_VALUE,
                              note: item?.note?.toString(),
                              type: typeEdl.type,
                            },
                      );
                    }}
                  >
                    <Plus /> Ajouter un élément
                  </ButtonCard>
                </>
              )}
            />
          )}
        </Fragment>
      ))}
      {!isTemplate && (
        <>
          <FormUpload
            tag={"edl_photo"}
            path={`${inputNamePrefix}photos`}
            text={"Ajouter des photos"}
            fileType={"edl"}
          />
          <p className={"secondaryText"}>
            Astuce : prendre les photos depuis l'application native du
            téléphone, puis les importer toutes en une fois
          </p>

          <TextArea
            type="text"
            name={`${inputNamePrefix}commentaire`}
            placeholder="Commentaires"
          />
        </>
      )}
      {onDelete && (
        <Button
          type="button"
          buttonStyle={"secondaryDelete"}
          className={"marginL10"}
          onClick={onDelete}
        >
          Supprimer
        </Button>
      )}
      {onDuplicate && (
        <Button type="button" className={"marginL10"} onClick={onDuplicate}>
          Dupliquer
        </Button>
      )}
    </>
  );
}

export default EdlItemFormInputs;
