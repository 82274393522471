import { Form, Formik } from "formik";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { toInitialValuesFromSchema } from "../../models/utils";
import {
  SIGNATURE_MAIL_INITIAL_VALUES,
  SignatureMailSchema,
} from "../../models/monCompte";
import TextArea from "../UI/TextArea";

export default function SignatureMailForm({ onSave, account }) {
  async function submitHandler(values) {
    try {
      await onSave(values);
    } catch (error) {
      console.error(error);
    }
  }

  const initialValues = toInitialValuesFromSchema(
    SignatureMailSchema,
    account,
    SIGNATURE_MAIL_INITIAL_VALUES,
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={SignatureMailSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <TextArea
          name={"smtp_signature"}
          rows="6"
          label={"Signature"}
        ></TextArea>

        <div className={"text-right"}>
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  );
}
