export class RemunerationType {
  static FIXE = "fixe";
  static POURCENTAGE_LOYER_CC = "pourcentage_loyer_cc";
  static POURCENTAGE_LOYER_HC = "pourcentage_loyer_hc";

  static isPourcentage(remunerationType) {
    return [this.POURCENTAGE_LOYER_HC, this.POURCENTAGE_LOYER_CC].includes(
      remunerationType,
    );
  }
}
