import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import EdlItemForm from "./EdlItemForm";
import { sanitizeValues } from "../../models/utils";
import { useState } from "react";
import styles from "./EdlShow.module.css";
import ButtonCard from "../UI/ButtonCard";
import useEdlItemsTemplates from "../../hooks/use-edl-items-templates";
import Card from "../UI/Card";
import useEdlMateriauxTemplates from "../../hooks/use-edl-materiaux-templates";
import EdlMateriauxTemplateForm from "./EdlMateriauxTemplateForm";

function EdlTemplateShow() {
  const [AddPieceFormModal, closeAddPieceFormModal, openAddPieceFormModal] =
    useModal();
  const [
    MateriauxTemplateFormModal,
    closeMateriauxTemplateFormModal,
    openMateriauxTemplateFormModal,
  ] = useModal();
  const [materiauxTemplateToEdit, setMateriauxTemplateToEdit] = useState();
  const {
    edlItemsTemplates,
    addEdlItemTemplate,
    updateEdlItemTemplate,
    deleteEdlItemTemplate,
  } = useEdlItemsTemplates();
  const {
    edlMateriauxTemplates,
    addEdlItemMateriaux,
    updateEdlItemMateriaux,
    deleteEdlItemMateriaux,
  } = useEdlMateriauxTemplates();
  const [itemToEdit, setItemToEdit] = useState(null);

  async function submitItemHandler(values, { resetForm }) {
    let sanitizedValues = { ...sanitizeValues(values), type: "edl" };
    delete sanitizedValues.template;
    delete sanitizedValues.commentaire;

    try {
      if (itemToEdit) {
        await updateEdlItemTemplate(itemToEdit.id, sanitizedValues);
      } else {
        await addEdlItemTemplate(sanitizedValues);
      }
      resetForm();
      closeAddPieceFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function submitMateriauxTemplateHandler(values, { resetForm }) {
    let sanitizedValues = { ...sanitizeValues(values) };

    try {
      if (materiauxTemplateToEdit) {
        await updateEdlItemMateriaux(
          materiauxTemplateToEdit.id,
          sanitizedValues,
        );
      } else {
        await addEdlItemMateriaux(sanitizedValues);
      }
      resetForm();
      closeMateriauxTemplateFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteHandler(id) {
    try {
      await deleteEdlItemTemplate(id);
      closeAddPieceFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteMateriauxHandler(id) {
    try {
      await deleteEdlItemMateriaux(id);
      closeMateriauxTemplateFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <AddPieceFormModal>
        <EdlItemForm
          itemToEdit={itemToEdit}
          onSubmit={submitItemHandler}
          onDelete={deleteHandler}
          template
        />
      </AddPieceFormModal>
      <MateriauxTemplateFormModal>
        <EdlMateriauxTemplateForm
          materiauxTemplateToEdit={materiauxTemplateToEdit}
          onSubmit={submitMateriauxTemplateHandler}
          onDelete={deleteMateriauxHandler}
        />
      </MateriauxTemplateFormModal>
      <Card>
        <div className={"flex space-between"}>
          <p className={"cardTitle"}>Template de pièce </p>
          <div className={"flex space-between align-items-center"}>
            <div>
              <Button
                type={"button"}
                onClick={() => {
                  setItemToEdit(null);
                  openAddPieceFormModal();
                }}
              >
                Ajouter une pièce
              </Button>
            </div>
          </div>
        </div>
        {edlItemsTemplates?.map((item) => (
          <ButtonCard
            key={item.id}
            onClick={() => {
              setItemToEdit(item);
              openAddPieceFormModal();
            }}
            className={styles.relative}
          >
            {item.is_shared && (
              <div className={styles.sharedRoom}>Pièce partagée</div>
            )}
            <p className={"cardTitle"}>{item.nom}</p>
            <div>
              <p className={"primaryText"}>Éléments</p>
              {item.items
                .filter((filtered) => filtered.type === "edl")
                .map((edl, i) => (
                  <p className={"secondaryText"} key={i}>
                    {edl.nom}
                  </p>
                ))}
            </div>
            <div>
              <p className={"primaryText"}>Equipements</p>
              {item.items
                .filter((filtered) => filtered.type === "equipements")
                .map((equipement, i) => (
                  <p className={"secondaryText"} key={i}>
                    {equipement.nom}
                  </p>
                ))}
            </div>
            <div>
              <p className={"primaryText"}>Mobilier</p>
              {item.items
                .filter((filtered) => filtered.type === "meubles")
                .map((meuble, i) => (
                  <p className={"secondaryText"} key={i}>
                    {meuble.nom}
                  </p>
                ))}
            </div>
          </ButtonCard>
        ))}
      </Card>
      <Card className={"marginT10"}>
        <div className={"flex space-between"}>
          <p className={"cardTitle"}>Template de type de surface</p>
          <div className={"flex space-between align-items-center"}>
            <div>
              <Button
                type={"button"}
                onClick={() => {
                  setMateriauxTemplateToEdit(null);
                  openMateriauxTemplateFormModal();
                }}
              >
                Ajouter un type de surface
              </Button>
            </div>
          </div>
        </div>
        {edlMateriauxTemplates?.map((item) => (
          <ButtonCard
            key={item.id}
            onClick={() => {
              setMateriauxTemplateToEdit(item);
              openMateriauxTemplateFormModal();
            }}
            className={styles.relative}
          >
            <p className={"cardTitle"}>{item.nom}</p>
            <div>
              <p className={"primaryText"}>Materiaux</p>
              {item.materiaux.map((elem, i) => (
                <p key={i}>{elem}</p>
              ))}
            </div>
            <div>
              <p className={"primaryText"}>Dégradations</p>
              {item.degradations.map((degradation, i) => (
                <p key={i}>{degradation}</p>
              ))}
            </div>
          </ButtonCard>
        ))}
      </Card>
    </>
  );
}

export default EdlTemplateShow;
