import { handleAPIError } from "../../utils";
import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";
import { useState } from "react";
import { toInitialValuesFromSchema } from "../../models/utils";
import { eventIV, eventSchema } from "../../models/event";
import Button from "../UI/Button";
import moment from "moment";
import { produce } from "immer";

export default function FormEvent({ event, onSubmit, onDelete }) {
  const initialValues = produce(
    toInitialValuesFromSchema(eventSchema, event, eventIV),
    (draft) => {
      draft.debut = draft.debut
        ? moment(draft.debut).format("YYYY-MM-DDTHH:mm")
        : "";
      draft.fin = draft.fin ? moment(draft.fin).format("YYYY-MM-DDTHH:mm") : "";
    },
  );
  const [error, setError] = useState("");

  async function submitHandler(values, { resetForm }) {
    try {
      await onSubmit(values);
      resetForm();
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <>
      <p className={"cardTitle text-center marginB20"}>
        <img
          src={`/photo_evenement.jpg`}
          alt="Evènement"
          className={"center"}
        />
        {event ? "Modifier un évènement" : "Ajouter un évènement"}
      </p>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={eventSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, setSubmitting }) => (
          <Form>
            <TextInput
              type="datetime-local"
              name="debut"
              label={"Début de l'évènement"}
              mandatory
            />
            <TextInput
              type="datetime-local"
              name="fin"
              label={"Fin de l'évènement"}
              mandatory
            />
            <TextInput
              type="text"
              name="subject"
              placeholder="Etat des lieux Brigitte"
              label={"Description"}
              mandatory
            />

            <div className={"text-right"}>
              {event && (
                <Button
                  buttonStyle={"secondaryDelete"}
                  type={"button"}
                  className={"marginR10"}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={async () => {
                    setSubmitting(true);
                    try {
                      await onDelete(event.id);
                    } catch (e) {
                      setError(handleAPIError(e));
                    }
                  }}
                >
                  Supprimer
                </Button>
              )}
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card
                type={"error"}
                className={"padding10 text-center marginT10"}
              >
                {error}
              </Card>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
