import { Form, Formik } from "formik";
import { toInitialValuesFromSchema } from "../../models/utils";
import {
  EDL_ITEM_CHILD_VALUE,
  EDL_ITEM_INITIAL_VALUE,
  EDL_ITEM_TEMPLATE_INITIAL_VALUE,
  edlItemChildValidationSchema,
  edlItemTemplateValidationSchema,
  edlItemValidationSchema,
} from "../../models/edl";
import EdlItemFormInputs from "./EdlItemFormInputs";
import useEdlItemsTemplates from "../../hooks/use-edl-items-templates";
import Select from "../UI/Select";
import Checkbox from "../UI/Checkbox";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";

function EdlItemForm({
  itemToEdit,
  onSubmit,
  onDelete,
  template,
  onDuplicate,
  error,
}) {
  const { edlItemsTemplates } = useEdlItemsTemplates();

  let initialValues = toInitialValuesFromSchema(
    edlItemValidationSchema,
    itemToEdit,
    EDL_ITEM_INITIAL_VALUE,
  );
  if (template) {
    initialValues = toInitialValuesFromSchema(
      edlItemTemplateValidationSchema,
      itemToEdit,
      EDL_ITEM_TEMPLATE_INITIAL_VALUE,
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        template ? edlItemTemplateValidationSchema : edlItemValidationSchema
      }
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, resetForm }) => {
        function changeTemplate(e) {
          setFieldValue("template", e.target.value);
          if (e.target.value === "") {
            resetForm();
            return;
          }
          const edlItemsTemplate = edlItemsTemplates?.find(
            (filtered) => filtered.id === parseInt(e.target.value),
          );
          const items =
            edlItemsTemplate?.items?.map((template) => {
              const item = toInitialValuesFromSchema(
                edlItemChildValidationSchema,
                template,
                EDL_ITEM_CHILD_VALUE,
              );
              delete item.id;
              return item;
            }) ?? [];
          setFieldValue("items", items);
          setFieldValue("nom", edlItemsTemplate.nom);
        }

        return (
          <>
            <h2 className={"marginT0 marginB10 text-center"}>
              {itemToEdit ? "Editer une pièce" : "Ajouter une pièce"}
            </h2>
            <Form>
              {!itemToEdit && (
                <Select name="template" onChange={(e) => changeTemplate(e)}>
                  <option value="">Choisissez un modèle</option>
                  {edlItemsTemplates.map((template) => (
                    <option value={template.id} key={template.id}>
                      {template.nom}
                    </option>
                  ))}
                </Select>
              )}
              <Checkbox name={"is_shared"} className={"marginB10"}>
                Cette pièce est partagée avec plusieurs locataires
              </Checkbox>
              <EdlItemFormInputs
                setFieldValue={setFieldValue}
                item={values}
                onDelete={() => onDelete(itemToEdit.id)}
                onDuplicate={() => onDuplicate(itemToEdit.id)}
              />
              <div className={"text-right"}>
                <FormikSubmitButton className={"marginL10"}>
                  Valider
                </FormikSubmitButton>
              </div>
              {error && (
                <Card
                  type={"error"}
                  className={"padding10 text-center marginT10"}
                >
                  {error}
                </Card>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default EdlItemForm;
