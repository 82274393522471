import useSWRImmutable from "swr/immutable";
import useAuthenticatedAxios from "./use-authenticated-axios";

function useIrls() {
  const axios = useAuthenticatedAxios();
  const {
    data: irls,
    error,
    isLoading,
  } = useSWRImmutable("irl", (url) => {
    return axios.get(url).then((res) => res.data);
  });

  return {
    irls: irls || [],
    error,
    isLoading,
  };
}

export default useIrls;
