import { DocType } from "../utils/constants/documents";
import useDocument from "./use-document";

export default function useLogo(withBlob = false) {
  const {
    document,
    documentBlob,
    error,
    errorBlob,
    isLoading,
    isLoadingBlob,
    createDocument,
    deleteDocument,
  } = useDocument("logo", DocType.LOGO, withBlob);

  return {
    logo: document,
    logoBlob: documentBlob,
    error,
    errorBlob,
    isLoading,
    isLoadingBlob,
    createLogo: createDocument,
    deleteLogo: deleteDocument,
  };
}
