import styles from "./CardStep.module.css";
import Card from "./Card";
import ToggleSwitch from "./ToggleSwitch";
import { ArrowDown, ArrowUp, RoundCheck, RoundWaiting } from "./Icons";
import { useState } from "react";
import ButtonAction from "./ButtonAction";

export default function CardStep({
  icon,
  step,
  title,
  text,
  check,
  onToggle,
  subtext,
}) {
  if (!icon) {
    icon = check ? <RoundCheck /> : <RoundWaiting />;
  }
  const [expand, setExpand] = useState(false);
  return (
    <Card className={"marginT20"}>
      <div className="flex">
        <div>{icon}</div>
        <div className={"marginL10"}>
          <div className={"flex space-between align-items-center"}>
            <div className={styles.step}>{step}</div>
            {onToggle && <ToggleSwitch status={check} onToggle={onToggle} />}
          </div>
          <div>{title}</div>
          <div className={"marginT10"}>{text}</div>
        </div>
      </div>
      {subtext && (
        <>
          <div className={styles.lineContainer}>
            <div className={styles.line}></div>
            <div className={styles.button}>
              <ButtonAction
                logo={expand ? <ArrowDown /> : <ArrowUp />}
                className={"padding10"}
                onClick={() => setExpand(!expand)}
              />
            </div>
          </div>
          {expand && <div className={"marginT10"}>{subtext}</div>}
        </>
      )}
    </Card>
  );
}
