import { createContext, useContext, useState } from "react";
import Button from "./Button";

const SelectableContext = createContext({});

export function Selectable({ items, children, rangeSelect }) {
  const [checked, setChecked] = useState(new Set());
  const [lastClickedItem, setLastClickedItem] = useState();

  function checkAllHandler(e) {
    if (e.target.checked) {
      setChecked(new Set(items.map((item) => item.id)));
    } else {
      setChecked(new Set());
    }
  }

  function resetChecked() {
    setChecked(new Set());
    setLastClickedItem(null);
  }

  function checkHandler(id, pos, e) {
    setChecked((curChecked) => {
      const newChecked = new Set(curChecked);
      if (
        rangeSelect &&
        e.nativeEvent.type === "click" &&
        e.nativeEvent.shiftKey &&
        lastClickedItem
      ) {
        // check/uncheck range of items, from last checked item to current
        for (
          let i = Math.min(lastClickedItem.pos, pos);
          i <= Math.max(lastClickedItem.pos, pos);
          i++
        ) {
          if (lastClickedItem.checked) {
            newChecked.delete(items[i].id);
          } else {
            newChecked.add(items[i].id);
          }
        }
      } else {
        if (curChecked.has(id)) {
          newChecked.delete(id);
        } else {
          newChecked.add(id);
        }
      }
      setLastClickedItem({ pos, checked: curChecked.has(id) });
      return newChecked;
    });
  }

  return (
    <SelectableContext.Provider
      value={{ items, checked, checkHandler, checkAllHandler, resetChecked }}
    >
      {children}
    </SelectableContext.Provider>
  );
}

function Checkbox({ value, index, selectAll }) {
  const { items, checked, checkHandler, checkAllHandler } =
    useContext(SelectableContext);
  return (
    <input
      type="checkbox"
      checked={selectAll ? checked.size === items.length : checked.has(value)}
      onChange={(e) => {
        if (selectAll) {
          checkAllHandler(e);
        } else {
          checkHandler(value, index, e);
        }
      }}
    />
  );
}

function ActionButton({ children, resetOnClick, onClick, ...rest }) {
  const { checked, resetChecked } = useContext(SelectableContext);
  return (
    <Button
      type="button"
      disabled={checked.size === 0}
      onClick={() => {
        onClick(Array.from(checked));
        if (resetOnClick) {
          resetChecked();
        }
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

function Action({ render }) {
  const { checked, resetChecked } = useContext(SelectableContext);
  return render(Array.from(checked), resetChecked);
}

Selectable.Checkbox = Checkbox;
Selectable.ActionButton = ActionButton;
Selectable.Action = Action;
