import useResources from "./use-resources";

export default function useBalancesDashboard() {
  const { resources: balancesDashboard } = useResources(
    "balance_tenants/balance_dashboard",
  );

  return {
    balancesDashboard,
  };
}
