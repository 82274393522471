import DisplayField from "../UI/DisplayField";
import { HousePrivatif, Pieces, Surface } from "../UI/Icons";
import Card from "../UI/Card";
import PropertyDisplayImgAddress from "./PropertyDisplayImgAddress";

function PropertyShowCandidat({ property }) {
  if (!property) {
    return <p>Bien non trouvé</p>;
  }
  return (
    <Card className={"flex space-between wrap gap10"}>
      <div>
        <p className={"cardTitle marginB10"}>Bien à louer</p>
        <PropertyDisplayImgAddress property={property} />
      </div>
      <div className={"flex-column space-between"}>
        <DisplayField
          icon={<Surface />}
          fieldValue="Superficie habitable"
          fieldName={
            property?.surface_total
              ? `${property.surface_total}m²`
              : "Non renseigné"
          }
        />
        <DisplayField
          icon={<Pieces />}
          fieldValue="Nombre de pièces"
          fieldName={property?.nb_pieces_principales || "Non renseigné"}
        />
        <DisplayField
          icon={<HousePrivatif />}
          fieldValue="Parties à usage privatif"
          fieldName={property?.locaux_privatifs || "Non renseigné"}
        />
      </div>
    </Card>
  );
}

export default PropertyShowCandidat;
