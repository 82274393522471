import Card from "../UI/Card";
import ButtonAction from "../UI/ButtonAction";
import { MassEdit, Oeil, Plus, Refresh } from "../UI/Icons";
import BalanceMasseForm from "./BalanceMasseForm";
import { useModal } from "../../hooks/use-modal";
import useLoader from "../../hooks/use-loader";

export default function BalanceActionsCard({
  balances,
  domaineBalance,
  onAddBalance,
  onBatchUpdate,
  onSyncTransactions,
  onGetDecompte,
}) {
  const [BatchUpdateModal, closeBatchUpdateModal, openBatchUpdateModal] =
    useModal();
  const [isRefreshingTransaction, syncTransactionsLoader] =
    useLoader(onSyncTransactions);

  async function submitMasseBalanceHandler(balances) {
    await onBatchUpdate(balances);
    closeBatchUpdateModal();
  }

  return (
    <>
      <BalanceMasseForm
        balances={balances}
        ModalElement={BatchUpdateModal}
        onSubmit={submitMasseBalanceHandler}
        domaineBalance={domaineBalance}
      />
      <Card
        className={
          "flex-center align-items-center flex-grow text-center padding10"
        }
      >
        {onSyncTransactions && (
          <div>
            <ButtonAction
              className={"marginR5"}
              onClick={syncTransactionsLoader}
              logo={
                <Refresh size={"20"} refreshing={isRefreshingTransaction} />
              }
              tooltip={
                "Ce bouton permet de vérifier si parmis les transactions synchonisées à Qalimo, nous retrouvons " +
                "ce locataire. Il est utile d'utiliser ce bouton après avoir ajouté un mot clé, ou après avoir " +
                "supprimé par erreur une transaction. Si rien ne se passe lorsque vous cliquez, c'est qu'aucune transaction n'a été trouvée"
              }
            />
            <p className={"text-center"}>Rafraichir</p>
          </div>
        )}
        {onBatchUpdate && (
          <div>
            <ButtonAction
              className={"marginR5"}
              onClick={openBatchUpdateModal}
              tooltip={"Edition groupée des transactions."}
              logo={<MassEdit size={"20"} />}
            />
            <p className={"text-center"}>Multiple</p>
          </div>
        )}
        {onGetDecompte && (
          <div>
            <ButtonAction
              onClick={onGetDecompte}
              tooltip={"Visualiser le décompte"}
              logo={<Oeil size={"20"} />}
              className={"marginR5"}
            />
            <p className={"text-center"}>Décompte</p>
          </div>
        )}

        {onAddBalance && (
          <div>
            <ButtonAction
              onClick={onAddBalance}
              logo={<Plus size={"20"} />}
              tooltip={"Ajouter une entrée"}
            />
            <p className={"text-center"}>Ajouter</p>
          </div>
        )}
      </Card>
    </>
  );
}
